import { useState, useEffect } from "react";
import { Input, Button, Spin, Form } from "antd";
import { GET_USER } from "../graphql/queries";
import {
  UPDATE_USER,
  CREATE_ADDRESS,
  UPDATE_ADDRESS,
} from "../../../graphql/mutations";
import { isEmpty } from "lodash";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { GET_ADDRESS_LIST, GET_ADDRESS_TYPES } from "../../../graphql/queries";
import { notifyError, openNotificationWithIcon } from "@/utils/notification";
import { HOME_PHYSICAL_ADDRESS } from "../../../constants";

function ProfileForm() {
  const [form] = Form.useForm();
  const [_email, setEmail] = useState("");
  const [userId, setUserId] = useState<number>();
  const [_userInfo, setUserInfo] = useState<any>();
  const [userAddress, setUserAddress] = useState<any>();
  const [submitting, setSubmitting] = useState(false);
  const [addressTypeId, setAddressTypeId] = useState<number>();
  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: (data) => setUserInfo(data.updateUser),
  });

  const [updateUserAddress] = useMutation(
    userAddress ? UPDATE_ADDRESS : CREATE_ADDRESS,
    {
      onCompleted: (data) => {
        const addressList = userAddress ? [...userAddress] : [];
        const newAddress = data.updateAddress || data.createAddress;
        const updatedAddressList = [...addressList, newAddress];
        setUserAddress(updatedAddressList);
      },
    }
  );

  const [getUser, { loading }] = useLazyQuery(GET_USER, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setUserInfo(data.userData);
      fillUserInfo(data.userData);
    },
  });

  const { data: addressTypes } = useQuery(GET_ADDRESS_TYPES, {
    onCompleted: () => {
      //get home physical address type
      const { addressTypeList } = addressTypes;
      const physical = addressTypeList.filter(
        (x: any) => x.name === HOME_PHYSICAL_ADDRESS
      );
      if (physical) setAddressTypeId(physical[0].id);
    },
  });

  const [getUserAddress] = useLazyQuery(GET_ADDRESS_LIST, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (!isEmpty(data.addresses)) {
        setUserAddress(data.addresses);
        fillAddressInfo(data.addresses);
      }
    },
  });

  useEffect(() => {
    setEmail(localStorage.getItem("username"));
    setUserId(+localStorage.getItem("userId"));
    getUser({
      variables: { input: { email: localStorage.getItem("username") } },
    });
    getUserAddress({
      variables: { userId: +localStorage.getItem("userId") },
    });
  }, []);

  function fillUserInfo(userInfo) {
    form.setFieldsValue({
      id: userInfo?.idNumber,
      firstName: userInfo?.firstName,
      lastName: userInfo?.lastName,
      email: userInfo?.email,
      phoneNumber: userInfo?.phone,
      userRole: userInfo?.userUserRoles[0]?.userRole?.name,
    });
  }

  function fillAddressInfo(addresses: any) {
    form.setFieldsValue({
      country: addresses[0].country,
      city: addresses[0].city,
      streetAddress: addresses[0].streetAddress,
    });
  }

  const handleSubmit = async () => {
    try {
      setSubmitting(true);

      const values = await form.validateFields();

      await Promise.all([
        updateUser({
          variables: {
            input: {
              id: userId,
              firstName: values.firstName,
              lastName: values.lastName,
              idNumber: values.id,
              phone: values.phoneNumber,
            },
          },
        }),
        updateUserAddress({
          variables: {
            input: {
              id: userAddress ? userAddress[0].id : undefined,
              userId: userAddress ? undefined : userId,
              country: values.country,
              city: values.city,
              streetAddress: values.streetAddress,
              addressTypeId: addressTypeId,
            },
          },
        }),
      ]);

      openNotificationWithIcon(
        "success",
        "Save Success",
        "Profile updated successfully"
      );
    } catch (err) {
      if (!err.errorFields) {
        console.log(err);
        notifyError(err);
      }
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) return <Spin />;

  return (
    <>
      <div className="card-form">
        <h3>Basic Profile</h3>
        <br />

        <span>Please complete your profile before proceeding</span>
        <br />
        <br />
        <Form form={form} layout="vertical">
          <Form.Item
            label="ID"
            name={"id"}
            rules={[
              {
                required: true,
                message: "Please enter your ID number",
              },
            ]}
          >
            <Input placeholder="Enter ID number" />
          </Form.Item>
          <Form.Item
            label="First Name"
            {...{
              rules: [
                {
                  required: true,
                  message: "Please enter your first name",
                },
              ],
              name: "firstName",
            }}
          >
            <Input placeholder="Enter your first name" />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name={"lastName"}
            {...{
              rules: [
                {
                  required: true,
                  message: "Please enter your last name",
                },
              ],
            }}
          >
            <Input placeholder="Enter your last name" />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            {...{
              rules: [
                {
                  required: true,
                  message: "Please enter your email address",
                },
              ],
            }}
          >
            <Input placeholder="Enter your email address" />
          </Form.Item>
          <Form.Item
            label="Country"
            name="country"
            {...{
              rules: [
                {
                  required: true,
                  message: "Please enter your country",
                },
              ],
            }}
          >
            <Input placeholder="Enter your country" />
          </Form.Item>
          <Form.Item
            label="City"
            name={"city"}
            {...{
              rules: [
                {
                  required: true,
                  message: "Please enter your city",
                },
              ],
            }}
          >
            <Input placeholder="Enter your city" />
          </Form.Item>
          <Form.Item
            label="Street Address"
            name={"streetAddress"}
            {...{
              rules: [
                {
                  required: true,
                  message: "Please enter street address",
                },
              ],
            }}
          >
            <Input placeholder="Enter street address" />
          </Form.Item>
          <Form.Item
            label="Phone Number"
            name="phoneNumber"
            {...{
              rules: [
                {
                  required: true,
                  message: "Please enter phone number",
                },
              ],
            }}
          >
            <Input placeholder="Enter phone number" />
          </Form.Item>

          <Form.Item label="User Role" name="userRole">
            <Input placeholder="Select user role" disabled />
          </Form.Item>

          <br />
          <Button
            className="btn-upload"
            loading={submitting}
            onClick={handleSubmit}
          >
            Update
          </Button>
        </Form>
      </div>
    </>
  );
}

export default ProfileForm;
