import { useCallback } from "react";
import { ReloadOutlined } from "@ant-design/icons";
import { Button, Card, Table, Tabs } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import { GET_BLACKLISTED_ENTITIES } from "@/containers/Admin/graphql/queries";
import { notifyError, notifySuccess } from "@/utils/notification";
import { format } from "date-fns";
import { UN_BLACKLIST_ENTITY } from "@/containers/Admin/graphql/mutations";
import { useNavigate, useLocation } from "react-router-dom";

function BlackListTabs() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get("tab") ?? "company";

  const handleChange = useCallback((tabKey: string) => {
    navigate(`/admin/blacklist?tab=${tabKey}`);
  }, []);

  const tabsItems = [
    {
      label: "Company",
      key: "company",
      children: <BlackList type="company" />,
    },
    {
      label: "User",
      key: "user",
      children: <BlackList type="user" />,
    },
  ];

  return <Tabs onChange={handleChange} activeKey={tab} items={tabsItems} />;
}

type Props = {
  type: "company" | "user";
};

function BlackList({ type }: Props) {
  const { loading, data, refetch } = useQuery(GET_BLACKLISTED_ENTITIES, {
    variables: {
      type,
    },
    onError: notifyError,
  });

  const [unBlacklistEntity, { loading: unBlacklisting }] = useMutation(
    UN_BLACKLIST_ENTITY,
    {
      onCompleted: () => {
        notifySuccess("Company un-blacklisted successfully");
        refetch();
      },
      onError: notifyError,
    }
  );

  async function unBlacklist(id: number) {
    await unBlacklistEntity({
      variables: {
        id,
        type,
      },
    });
  }

  return (
    <div className="col-sm-12 col-md-12 placeholder-table-card">
      <Card>
        <div className="card-header">
          <h3>
            Blacklisted {type === "company" ? "Companies" : "Users/Employees"}
          </h3>
          <Button
            icon={<ReloadOutlined />}
            className="purple-button"
            onClick={() => refetch()}
          >
            Refresh
          </Button>
        </div>

        <Table
          size="small"
          loading={loading || unBlacklisting}
          columns={[
            {
              title: "#",
              render: (record) => record.id,
              key: "id",
            },
            {
              title: type === "company" ? "Company" : "User",
              render: (record: any) => {
                if (type === "company" && !record.company)
                  return "<Unspecified>";
                else if (type === "user" && !record.user)
                  return "<Unspecified>";

                return type === "company"
                  ? record.company.registeredName
                  : `${record.user.firstName} ${record.user.lastName}`;
              },
              key: "entiityName",
            },
            ...(type === "user"
              ? [
                  {
                    title: "ID Number",
                    render: (record: any) => {
                      if (!record.user) return "<Unspecified>";
                      return record.user.idNumber;
                    },
                    key: "idNumber",
                  },
                ]
              : []),
            {
              title: "Reason for Blacklisting",
              render: (record) => record.reason,
              key: "reason",
            },
            {
              title: "Listed By",
              render: (record: any) => {
                if (!record.listedBy) return "<Unspecified>";
                return `${record.listedBy.firstName} ${record.listedBy.lastName}`;
              },
              key: "listedBy",
            },
            {
              title: "Date Listed",
              render: (record: any) => {
                return format(new Date(record.createdAt), "dd/MM/yyyy");
              },
              key: "dateListed",
            },
            {
              title: "Listed Until",
              render: (record: any) => {
                return record.listedUntil
                  ? format(new Date(record.listedUntil), "dd/MM/yyyy")
                  : "<Unspecified>";
              },
              key: "listedUntil",
            },
            {
              title: "Active",
              render: (record: any) => {
                return record.isActive ? "Yes" : "No";
              },
              key: "isActive",
            },
            {
              title: "Action",
              render: (_, record) => {
                return (
                  <Button
                    disabled={unBlacklisting || !record.isActive}
                    onClick={() => {
                      const id =
                        type === "company" ? record.company.id : record.user.id;
                      unBlacklist(id);
                    }}
                    type="link"
                  >
                    UnBlacklist
                  </Button>
                );
              },
              key: "actions",
            },
          ]}
          dataSource={data?.getBlacklist || []}
          rowKey="id"
          pagination={false}
        />
      </Card>
    </div>
  );
}

export default BlackListTabs;
