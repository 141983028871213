import { Auth } from "aws-amplify";
import { Modal, Input, Checkbox, Form } from "antd";

Auth.configure({
  Auth: {
    identityPoolId: import.meta.env.VITE_USER_POOL_CLIENT_ID,
    region: import.meta.env.VITE_REGION,
    userPoolId: import.meta.env.VITE_USER_POOL_ID,
    UserPoolId: import.meta.env.VITE_USER_POOL_ID,
    ClientId: import.meta.env.VITE_USER_POOL_CLIENT_ID,
    userPoolWebClientId: import.meta.env.VITE_USER_POOL_CLIENT_ID,
  },
});

function ConfirmAccountForm({
  visible,
  onCancel,
  onCreate,
  okText = "Save",
  modalHeading = "Email Address Confirmation",
  cancelText = "Cancel",
  hideOkButton = false,
  form,
}) {
  return (
    <Modal
      open={visible}
      title={modalHeading}
      okText={okText}
      onCancel={onCancel}
      onOk={onCreate}
      cancelText={cancelText}
      okButtonProps={hideOkButton ? { style: { display: "none" } } : {}}
    >
      <Form form={form} layout="vertical">
        <>
          <span>
            Please enter the confirmation code that has been sent to your email.
          </span>
          <br />
          <br />
          <Form.Item
            label="Confirmation code"
            name="code"
            rules={[
              {
                required: true,
                message: "Please input the confirmation code!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={"confirm"}
            valuePropName="checked"
            rules={[
              {
                required: true,
                message: "Please confirm that you understand this.",
              },
            ]}
          >
            <Checkbox>
              I understand that updating this email will change the email
              address I use to log in
            </Checkbox>
          </Form.Item>
        </>
      </Form>
    </Modal>
  );
}

export default ConfirmAccountForm;
