import { useState } from "react";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  Checkbox,
  Select,
  Skeleton,
  Table,
  Row,
  Typography,
  Form,
} from "antd";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_ADDRESS, UPDATE_ADDRESS } from "../../graphql/mutations";
import { GET_ADDRESS_TYPES, GET_ADDRESS_LIST } from "@/graphql/queries";
import { isEmpty } from "lodash";

import InternalErrorPage from "@/containers/InternalErrorPage";
import { openNotificationWithIcon } from "@/utils/notification";
import { useRegistrationState } from "@/stores/registration";
import { If } from "@/components/If";

type Props = {
  sendAddressInfo?: (postalPhysicalAddressesSaved: boolean) => void;
  useCompanyId?: number | false;
  disableHeading?: boolean;
};

function AddressForm({
  sendAddressInfo,
  useCompanyId = false,
  disableHeading = false,
}: Props) {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDocicillium, setIsDocicillium] = useState(false);
  const [domicilliumAddressExists, setDomicilliumAddressExists] =
    useState(false);
  const [addressTypeList, setAddressTypeList] = useState<any>();
  const { Option } = Select;
  const [addingAddress, setAddingAddress] = useState(false);
  const [editingAddress, setEditingAddress] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<any>();
  const [selectedAddressType, setSelectedAddressType] = useState<any>();
  const [companyAddressInfo, setCompanyAddressInfo] = useState([]);
  const [createUserContact] = useMutation(CREATE_ADDRESS);
  const [updateUserAddres] = useMutation(UPDATE_ADDRESS);

  const { companyId: cid } = useRegistrationState();

  const companyId = useCompanyId ? +useCompanyId : cid;

  const {
    loading: loadingAddressTypes,
    error: errorAddressTypes,
    data: dataAddressTypes,
  } = useQuery(GET_ADDRESS_TYPES, {
    onCompleted: () => setAddressTypeList(dataAddressTypes.addressTypeList),
  });

  const {
    loading: loadingAddress,
    error: errorAddress,
    data: dataAddress,
    refetch: refetchCompanyAddress,
  } = useQuery(GET_ADDRESS_LIST, {
    variables: {
      companyId,
    },
    onCompleted: () => {
      // set company address info from query result
      setCompanyAddressInfo(dataAddress.addresses);
      if (!isEmpty(dataAddress.addresses)) {
        const postalPhysicalAddressesSaved =
          dataAddress.addresses.filter((x) =>
            ["Work Physical", "Work Postal"].includes(x.addressType?.name)
          ).length >= 2;

        if (sendAddressInfo) {
          sendAddressInfo(postalPhysicalAddressesSaved);
        }
        if (
          dataAddress.addresses.filter((item) => item.domicilliumAddress)
            .length > 0
        ) {
          setDomicilliumAddressExists(true);
        }
      }
    },
    fetchPolicy: "network-only",
  });

  if (errorAddress || errorAddressTypes) {
    return <InternalErrorPage />;
  }

  //selectable address types options
  const addressTypeOptions: any =
    !loadingAddress && addressTypeList
      ? addressTypeList
          .filter((x) => {
            return editingAddress
              ? x.name?.includes("Work")
              : x.name?.includes("Work") &&
                  x.name !== companyAddressInfo[0]?.addressType?.name;
          })
          .map((type) => {
            if (
              companyAddressInfo.filter(
                (item) =>
                  item.addressType && item.addressType?.name === "Work Postal"
              ).length > 0 &&
              type.name === "Work Postal" &&
              !editingAddress
            ) {
              return false;
            }
            return (
              <Option key={type.id} value={type.id}>
                {type.name}
              </Option>
            );
          })
      : [];

  const handleSubmitAddressInfo = async (values: any) => {
    try {
      setIsSubmitting(true);
      const createUpdatemutation = selectedAddress?.id
        ? updateUserAddres
        : createUserContact;

      await createUpdatemutation({
        variables: {
          input: {
            id: selectedAddress?.id,
            addressName: values.physicalComplexName,
            addressTypeId: values.addressType,
            city: values.physicalCity,
            suburb: values.physicalSuburb,
            streetAddress: values.physicalStreetAddress,
            postalCode: values.physicalPostalCode,
            postalAddress: values.physicalPostalAddress,
            domicilliumAddress: values.domicilliumAddress,
            location: values.location,
            companyId: !selectedAddress?.id ? companyId : undefined,
          },
        },
      });

      const contactsResp = await refetchCompanyAddress({
        companyId,
      });
      if (contactsResp.errors) {
        setIsSubmitting(false);
        return;
      }

      setAddingAddress(false);
      setEditingAddress(false);
      setIsSubmitting(false);
      // clear selected address
      setSelectedAddress({});
      if (!isEmpty(contactsResp.data.addresses)) {
        ///check if both postal and physical address information has been saved
        setCompanyAddressInfo(contactsResp.data.addresses);
        const postalPhysicalAddressesSaved =
          contactsResp.data.addresses.filter((x) =>
            ["Work Physical", "Work Postal"].includes(x.addressType?.name)
          ).length >= 2;
        if (postalPhysicalAddressesSaved && sendAddressInfo) {
          sendAddressInfo(true);
        }
      }
      if (values.domicilliumAddress) {
        setDomicilliumAddressExists(true);
      }
      form.resetFields();
      return openNotificationWithIcon(
        "success",
        "Save Success",
        "Address saved successfully"
      );
    } catch (error) {
      setIsSubmitting(false);
      return openNotificationWithIcon(
        "error",
        "Error",
        // @ts-ignore
        error.message.replace("GraphQL Error:")
      );
    }
  };

  const checkAddressType = (e) => {
    // if selected is home physical check domicillium address
    const addressType = addressTypeList.filter((a) => a.id === e);
    setSelectedAddressType(addressType[0]);

    let setLocation = {};
    let setDomicilliumAddress = {
      domicilliumAddress: false,
    };
    if (
      selectedAddressType &&
      selectedAddressType.name === "Work Physical" &&
      companyAddressInfo.filter((item) => item.location === "Head Office")
        .length > 0
    ) {
      setLocation = {
        location: "Branch",
      };
    } else if (
      selectedAddressType &&
      selectedAddressType.name === "Work Postal"
    ) {
      setLocation = {
        location: "Head Office",
      };
    }
    if (
      selectedAddressType &&
      selectedAddressType.name === "Work Physical" &&
      !domicilliumAddressExists
    ) {
      setDomicilliumAddress = {
        domicilliumAddress: true,
      };
    }
    form.setFieldsValue({
      ...setDomicilliumAddress,
      ...setLocation,
    });
  };

  const isPhysicalAddressSelected = () => {
    if (!selectedAddressType) return true;
    if (
      selectedAddressType &&
      selectedAddressType.name.toLowerCase().includes("physical")
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <If condition={!disableHeading}>
        <Row justify="space-between" style={{ margin: "30px 0" }}>
          <Typography.Title level={4}>Address Information</Typography.Title>
          <Button
            type="link"
            icon={<PlusOutlined />}
            className="purple-text"
            onClick={() => setAddingAddress(true)}
          >
            Add Address
          </Button>
        </Row>
      </If>

      <If condition={loadingAddress || loadingAddressTypes}>
        <Skeleton active paragraph={{ rows: 6 }} />
      </If>

      <If condition={!isEmpty(companyAddressInfo)}>
        <Table
          dataSource={companyAddressInfo}
          pagination={false}
          rowKey={(record) => String(record.id)}
          style={{ marginBottom: "30px" }}
          columns={[
            {
              key: "addressType",
              title: "Address Type",
              render: (record) => record.addressType?.name || "",
            },
            {
              key: "location",
              title: "Location",
              render: (record) => record.location || "",
            },
            {
              key: "buildingName",
              title: "Address Type",
              render: (record) => record.addressName,
            },
            {
              key: "streetAddress",
              title: "Street Address",
              render: (record) => record.streetAddress,
            },
            {
              key: "suburb",
              title: "Suburb",
              render: (record) => record.suburb,
            },
            {
              key: "city",
              title: "City",
              render: (record) => record.city,
            },
            {
              key: "postalAddress",
              title: "Postal Address",
              render: (record) => record.postalAddress,
            },
            {
              key: "postalCode",
              title: "Postal Code",
              dataIndex: "postal Code",
            },
            {
              key: "domicillium",
              title: "Domicillium",
              render: (record: any) =>
                record.domicilliumAddress ? "Yes" : "No",
            },
            {
              key: "actions",
              title: "Actions",
              render: (record: any) => (
                <Button
                  icon={<EditOutlined />}
                  type="link"
                  className="purple-text"
                  onClick={() => {
                    setSelectedAddress(record);
                    setSelectedAddressType(record.addressType);
                    setAddingAddress(true);
                    setEditingAddress(true);
                  }}
                >
                  Edit
                </Button>
              ),
            },
          ]}
        />
      </If>

      <If condition={addingAddress}>
        <Form
          form={form}
          onFinish={handleSubmitAddressInfo}
          onFieldsChange={(changes) => {
            const domicile = changes.find(
              (x) => x.name[0] === "domicilliumAddress"
            );
            if (domicile) {
              setIsDocicillium(domicile.value);
            }
          }}
        >
          <div className="input-block-wrapper">
            <div className="flex-column input-block">
              <label>Address type</label>
              <Form.Item
                {...{
                  name: "addressType",
                  initialValue: selectedAddress?.addressType?.id,
                  rules: [
                    {
                      required: true,
                      message: "Please select address type",
                    },
                  ],
                }}
              >
                <Select className="input-select" onChange={checkAddressType}>
                  {addressTypeOptions}
                </Select>
              </Form.Item>
            </div>
            <div className="flex-column input-block">
              <label>Location</label>
              <Form.Item
                {...{
                  name: "location",
                  initialValue: selectedAddress?.location,
                  rules: [
                    {
                      required: true,
                      message: "Please select address location",
                    },
                  ],
                }}
              >
                <Select className="input-select">
                  <Select.Option value={"Head Office"}>
                    Head Office
                  </Select.Option>
                  <Select.Option value={"Branch"}>Branch</Select.Option>
                </Select>
              </Form.Item>
            </div>
            {isPhysicalAddressSelected() ? (
              <>
                <div className="flex-column input-block">
                  <label>Complex/Building/Apartment Name</label>
                  <Form.Item
                    {...{
                      name: "physicalComplexName",
                      initialValue: selectedAddress?.addressName,
                      rules: [
                        {
                          required: true,
                          message:
                            "Please input Complex/Building/Apartment Name",
                        },
                      ],
                    }}
                  >
                    <Input placeholder="Enter Complex/Building/Apartment Name" />
                  </Form.Item>
                </div>
                <div className="flex-column input-block">
                  <label>Street Address</label>

                  <Form.Item
                    {...{
                      name: "physicalStreetAddress",
                      initialValue: selectedAddress?.streetAddress,
                      rules: [
                        {
                          required: true,
                          message: "Please input street address",
                        },
                      ],
                    }}
                  >
                    <Input placeholder="Enter street address" />
                  </Form.Item>
                </div>
                <div className="flex-column input-block">
                  <label>Town/Suburb</label>
                  <Form.Item
                    {...{
                      name: "physicalSuburb",
                      initialValue: selectedAddress?.suburb,
                      rules: [
                        {
                          required: true,
                          message: "Please input suburb",
                        },
                      ],
                    }}
                  >
                    <Input placeholder="Enter suburb" />
                  </Form.Item>
                </div>
                <div className="flex-column input-block">
                  <label>City</label>
                  <Form.Item
                    {...{
                      name: "physicalCity",
                      initialValue: selectedAddress?.city,
                      rules: [
                        {
                          required: true,
                          message: "Please input city",
                        },
                      ],
                    }}
                  >
                    <Input placeholder="Enter city" />
                  </Form.Item>
                </div>
              </>
            ) : (
              <>
                <div className="flex-column input-block">
                  <label>Postal Address</label>
                  <Form.Item
                    {...{
                      name: "physicalPostalAddress",
                      initialValue: selectedAddress?.postalAddress,
                      rules: [
                        {
                          required: true,
                          message: "Please input postal address",
                        },
                      ],
                    }}
                  >
                    <Input placeholder="Enter postal address" />
                  </Form.Item>
                </div>
                <div className="flex-column input-block">
                  <label>Postal Code</label>
                  <Form.Item
                    {...{
                      name: "physicalPostalCode",
                      initialValue: selectedAddress?.postalCode,
                      rules: [
                        {
                          required: true,
                          message: "Please input postal code",
                        },
                      ],
                    }}
                  >
                    <Input placeholder="Enter postal code" />
                  </Form.Item>
                </div>
              </>
            )}
            <div className="flex-column input-block input-select">
              <Form.Item
                {...{
                  name: "domicilliumAddress",
                  initialValue: !isEmpty(selectedAddress)
                    ? selectedAddress.domicilliumAddress
                    : isDocicillium,
                  valuePropName: "checked",
                  rules: [
                    {
                      required: true,
                      message: "Please input address",
                    },
                  ],
                }}
              >
                <Checkbox disabled={domicilliumAddressExists}>
                  Domicillium Address
                </Checkbox>
              </Form.Item>
            </div>
            <div className="flex-column input-block input-select">
              <Button
                htmlType="submit"
                className="btn-registration-capacity-selected"
                loading={isSubmitting}
              >
                Save address
              </Button>
            </div>
          </div>
          <div className="flex-column input-block input-select input-spacer">
            <Button
              type="default"
              className="purple-button"
              style={{ width: "100%", height: "40px" }}
              onClick={() => setAddingAddress(true)}
              disabled={addingAddress || companyAddressInfo.length === 2}
            >
              + Add another address
            </Button>
          </div>
        </Form>
      </If>
    </>
  );
}

export default AddressForm;
