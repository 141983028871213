import { Skeleton, Card } from "antd";

const InformationLoader: React.FC<any> = () => {
  return (
    <div className="card">
      <Card>
        <Skeleton loading active />
      </Card>
      <br />
      <Card>
        <Skeleton loading active />
      </Card>
      <br />
      <Card>
        <Skeleton loading active />
      </Card>
      <br />
      <Card>
        <Skeleton loading active />
      </Card>
    </div>
  );
};
export default InformationLoader;
