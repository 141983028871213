import { useState } from "react";
import { Modal } from "antd";
import { useMutation } from "@apollo/client";
import { UPDATE_COMPANY_REPRESENTATIVE } from "../../../graphql/mutations";
import { DEFAULT_PASSWORD } from "../../../constants";
import { signUp } from "@/utils/auth/createUser";
import { notifyError, openNotificationWithIcon } from "@/utils/notification";
import { formatPhoneNumber } from "@/utils/validatePhoneNumber";

const CompanyRepModal: React.FC<any> = ({
  onCancel,
  newRepInformation,
  visible,
  setNewRepInformation,
  refresh,
}) => {
  const [loading, setLoading] = useState(false);

  const [updateCompanyRepresentative] = useMutation(
    UPDATE_COMPANY_REPRESENTATIVE,
    {
      fetchPolicy: "no-cache",
      onCompleted: () => {
        setLoading(false);
        setNewRepInformation(undefined);
        refresh();
      },
      onError: (error) => {
        openNotificationWithIcon(
          "error",
          "Authorization error",
          "Error updating user information"
        );
      },
    }
  );

  const setUserAsDefault = async () => {
    try {
      setLoading(true);
      await new Promise<boolean>((resolve) => {
        signUp(
          {
            ...newRepInformation,
            password: DEFAULT_PASSWORD,
            phone: formatPhoneNumber(newRepInformation?.phone),
          },
          (err, result) => {
            if (err) {
              if (err.message === "User already exists") return resolve(false);
              openNotificationWithIcon("error", "Error", err.message);
            }
            resolve(result);
          }
        );
      });

      updateCompanyRepresentative({
        variables: {
          input: newRepInformation,
        },
      });
    } catch (err) {
      notifyError(err);
    }
  };

  return (
    <Modal
      open={visible}
      title="Update Company Representative"
      okText="Set as Default User"
      onCancel={onCancel}
      onOk={setUserAsDefault}
      confirmLoading={loading}
    >
      Are you sure you want to make this user a company representative, doing so
      would update the login details for this company:
      <br />
      <br />
      New login details will be the following:
      <br />
      <br />
      <strong>Email:</strong> {newRepInformation?.email}
      <br />
      <strong>Password:</strong> {DEFAULT_PASSWORD}
    </Modal>
  );
};

export default CompanyRepModal;
