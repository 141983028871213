import MemberList from "./TeamList";
import PermissionsGuard from "../../../components/Auth/can";
import AccessDenied from "../../../components/Auth/accessDenied";
import { useNavigate } from "react-router-dom";

function TeamMemberManagement() {
  const navigate = useNavigate();
  return (
    <PermissionsGuard
      perform={"admin_team:view"}
      yes={() => <MemberList navigate={navigate} />}
      no={() => <AccessDenied permission="admin_team:view" />}
    />
  );
}

export default TeamMemberManagement;
