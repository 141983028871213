import CompanyDocuments from "../Admin/Documents/memberDocuments";
import { useUserState } from "@/stores/user";

function Documents() {
  const getRole = useUserState((s) => s.getActiveRole);
  const userId = useUserState((s) => s.id);
  const idNumber = useUserState((s) => s.idNumber);

  const role = getRole();

  return (
    <CompanyDocuments
      mode={
        role.name === "Sole Proprietor"
          ? "soleprop-overview-documents"
          : "company-overview-documents"
      }
      memberId={userId}
      companyId={role.companyId}
      memberIdNumber={idNumber}
    />
  );
}
export default Documents;
