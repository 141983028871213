import { useCallback, useState, useEffect, useMemo } from "react";
import { useLazyQuery } from "@apollo/client";
import { Modal, Select, AutoComplete, Radio, Input, Form } from "antd";
import debounce from "lodash/debounce";
import { GET_USERS_BY_FILTER, GET_ADMINS } from "../graphql/queries";
import { GET_COMPANIES_BY_FILTER } from "@/graphql/queries";
import { useGlobalState } from "../../../globalStore";
import { CREATE_TASK_TYPES, ADMIN_STREAMS } from "../../../constants";
import { openNotificationWithIcon } from "@/utils/notification";
import Uploader from "../../../components/Uploader";
import { isAdmin } from "@/utils";
import { If, IfNot } from "@/components/If";

const { Option } = Select;
const { Option: OptionAutoComplete } = AutoComplete;
const RadioGroup = Radio.Group;

const defaultMultiFilter = {
  user: false,
  type: false,
  userStatus: false,
  company: false,
  capacity: false,
  sort: "new",
};

interface IModalFormComponent {
  visible?: boolean;
  onCancel?: any;
  onCreate?: (data: { documentList: any; companyId?: number }) => void;
  form?: any;
  assignToCompany?: boolean;
  enableDirectCreate?: boolean;
}

function CreateTaskForm({
  visible,
  onCancel,
  onCreate,
  form,
  enableDirectCreate,
}: IModalFormComponent) {
  const { state } = useGlobalState();
  const [userList, setUserList] = useState([]);
  const [validUserList, setValidUserList] = useState([]);
  const [role, setRole] = useState(localStorage.getItem("userRoles"));
  const [companyId, setCompanyId] = useState<number>();
  const [documentList, setDocumentList] = useState();
  const [adminsList, setAdminsList] = useState<any[]>();
  const [taskType, setTaskType] = useState<any>("");
  const [adminFilter, setAdminFilter] = useState<
    "Finance Admin" | "Administration Support" | "Legal Support"
  >("Administration Support");

  const assignToCompany = ADMIN_STREAMS.includes(
    localStorage.getItem("roleStream")
  );

  const taskTypes = (state?.lookups?.taskTypes || []).sort();

  const validateUserSelection = (rules, value, callback) => {
    if (value && validUserList.indexOf(value) === -1) {
      callback("Please select a valid member option");
    }
    callback();
  };

  const _isAdmin = useMemo(() => isAdmin(), []);

  const [getAdmins] = useLazyQuery(GET_ADMINS, {
    onCompleted: ({ admins }) => setAdminsList(admins.userList),
  });

  const [searchCompanies] = useLazyQuery(GET_COMPANIES_BY_FILTER, {
    fetchPolicy: "network-only",
    variables: {},
    onError: (error) => {
      console.error("get_companies_by_filter_error", error);
      openNotificationWithIcon(
        "error",
        "Error",
        "Error loading employer information"
      );
    },
    onCompleted: (data) => {
      setUserList(data.companiesByFilter?.companyList);
    },
  });

  const searchCompanyByName = (value) => {
    if (value.length > 2) {
      searchCompanies({ variables: { criteria: "searchText", filter: value } });
    }
  };

  const searchUserById = async (userId) => {
    if (userId && userId.length > 4) {
      setUserList(["Loading..."]);
      getUsers({
        variables: {
          role,
          filter: JSON.stringify(
            Object.assign(defaultMultiFilter, { user: userId })
          ),
          includeTotal: false,
          criteria: "multiFilter",
          skip: 0,
          take: 10,
        },
      });
    } else {
      setUserList(["Please enter at least 5 characters"]);
    }
  };

  const [getUsers] = useLazyQuery(GET_USERS_BY_FILTER, {
    onCompleted: (data) => {
      if (data && data.usersByFilter && data.usersByFilter.userList) {
        if (data.usersByFilter.userList.length > 0) {
          setUserList(data.usersByFilter.userList);
          setValidUserList(
            data.usersByFilter.userList.map((item) => item.idNumber)
          );
        } else {
          setValidUserList([]);
          setUserList(["No results found"]);
        }
      }
    },
    fetchPolicy: "network-only",
  });

  const debouncedUserFilterUpdate = useCallback(
    debounce(enableDirectCreate ? searchCompanyByName : searchUserById, 250),
    []
  );

  //selectable options of project status
  const taskTypesList = (
    enableDirectCreate ? CREATE_TASK_TYPES : taskTypes || []
  ).sort();

  const taskTypeOptions: any = taskTypesList.map((type) => {
    return (
      <Option key={type.id || type} value={type.id || type}>
        {type.name || type}
      </Option>
    );
  });

  const userListOptions: any = userList
    ? userList.map((item) => {
        if (item.firstName) {
          return (
            <OptionAutoComplete key={item.idNumber} value={item.idNumber}>
              {`${item.firstName} ${item.lastName} - ${item.idNumber}`}
            </OptionAutoComplete>
          );
        } else if (item.registeredName) {
          return (
            <OptionAutoComplete key={item.id} value={item.registeredName}>
              {item.registeredName}
            </OptionAutoComplete>
          );
        } else {
          return (
            <OptionAutoComplete key={item} disabled={true} value={item}>
              {item}
            </OptionAutoComplete>
          );
        }
      })
    : [];

  useEffect(() => {
    if (taskType) {
      setAdminFilter(() => {
        if (["Finance Support", "Payment Disputes"].includes(taskType)) {
          return "Finance Admin";
        } else if (["Complaint"].includes(taskType)) {
          return "Legal Support";
        } else {
          return "Administration Support";
        }
      });
    }
  }, [taskType]);

  useEffect(() => {
    if (_isAdmin) {
      !assignToCompany &&
        enableDirectCreate &&
        getAdmins({ variables: { disablePermissions: true } });
    }
    setRole(localStorage.getItem("userRoles"));
  }, []);

  return (
    <Modal
      open={visible}
      title="Create new task"
      okText="Create"
      onCancel={onCancel}
      onOk={() => onCreate({ documentList, companyId })}
      okButtonProps={{
        className: "purple-button",
      }}
      okType="default"
    >
      <Form form={form} layout="vertical">
        <Form.Item label="Task Type" name="taskType" required>
          <Select
            style={{ width: "100%" }}
            placeholder="Select a task type"
            onSelect={(value) => setTaskType(value)}
          >
            {taskTypeOptions}
          </Select>
        </Form.Item>
        {enableDirectCreate && !assignToCompany ? (
          adminsList && (
            <Form.Item label="Admin User" name={"adminUserId"} required>
              <Select
                style={{ width: "100%" }}
                placeholder="Select a admin user"
              >
                {adminsList
                  .filter(
                    (admin) =>
                      admin.userUserRoles[0]?.userRole?.name === adminFilter
                  )
                  .map((admin) => {
                    return (
                      <Option key={admin.id} value={admin.id}>
                        {admin.firstName} {admin.lastName} (
                        {admin.userUserRoles[0]?.userRole?.name})
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          )
        ) : (
          <Form.Item
            label={assignToCompany ? "Company Name" : "Member/User"}
            name={assignToCompany ? "companyId" : "userIdNumber"}
            rules={[
              {
                required: true,
                message: "Please input user's ID number!",
              },
              {
                validator: !assignToCompany && validateUserSelection,
              },
            ]}
          >
            <AutoComplete
              allowClear={true}
              onSearch={(search) => debouncedUserFilterUpdate(search)}
              placeholder={
                assignToCompany
                  ? "Search company by registered name"
                  : "Search member by ID number"
              }
              // optionLabelProp="value"
              onSelect={(value, option) => setCompanyId((option as any).key)}
            >
              {userListOptions}
            </AutoComplete>
          </Form.Item>
        )}
        <If condition={taskType === "Audit Certificate"}>
          <Form.Item label="Financial year" name="financialYear" required>
            <Input type="number" placeholder="Enter a financial year" />
          </Form.Item>
        </If>
        <IfNot condition={taskType === "Audit Certificate"}>
          <Form.Item
            label="Priority Status"
            name={"priorityStatus"}
            required={taskType !== "Audit Certificate"}
          >
            <RadioGroup>
              <Radio value="Urgent">Urgent</Radio>
              <Radio value="In progress">In progress</Radio>
            </RadioGroup>
          </Form.Item>
          {enableDirectCreate && (
            <Form.Item name="comment">
              <Input.TextArea
                style={{ resize: "none" }}
                rows={4}
                placeholder="Comment..."
              />

              <Uploader
                companyId={companyId}
                defaults={{ directory: "Task", filename: "Direct_Task" }}
                onPresigned={(list: any) => setDocumentList(list)}
              />
            </Form.Item>
          )}
        </IfNot>
      </Form>
    </Modal>
  );
}

export default CreateTaskForm;
