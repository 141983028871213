import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { Modal, Input, Select, Spin, Form, FormInstance } from "antd";
import { GET_USER_FILTERS } from "../graphql/queries";
const { Option } = Select;

type Props = {
  visible: boolean;
  onCancel: () => void;
  onCreate: () => Promise<void>;
  showSpin: boolean;
  initialData?: any;
  form: FormInstance;
};

function InviteUserForm({
  visible,
  onCancel,
  onCreate,
  showSpin,
  initialData,
  form,
}: Props) {
  const [userTypes, setUserTypes] = useState<any>();
  const [getUserTypes, { loading }] = useLazyQuery(GET_USER_FILTERS, {
    onCompleted: (data) => {
      let userTypesCollection = data.userFilters.userTypes.filter(
        (x) =>
          !["Employee", "Company", "Sole Proprietor", "Super User"].includes(
            x.name
          )
      );
      setUserTypes(userTypesCollection);
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    getUserTypes();
  }, []);

  const userTypeOptions: any = userTypes
    ? userTypes.map((type) => {
        return (
          <Option key={"user-type-" + type.id} value={type.id}>
            {type.name}
          </Option>
        );
      })
    : [];

  return (
    <Modal
      open={visible}
      title="Create Team member"
      okText="Invite"
      onCancel={onCancel}
      onOk={onCreate}
      okButtonProps={{
        className: "purple-button",
      }}
      okType="default"
    >
      {" "}
      <Spin
        tip="Loading..."
        className="loader"
        style={{ display: showSpin ? "block" : "none" }}
      />
      <Form form={form} layout="vertical">
        <Form.Item
          label="First Name"
          name={"firstName"}
          {...{
            initialValue: initialData ? initialData.firstName : "",
            rules: [
              {
                required: true,
                message: "Please input user's first name!",
              },
            ],
          }}
        >
          <Input placeholder="Please input user's first name!" />
        </Form.Item>

        <Form.Item
          label="Last Name"
          name="lastName"
          {...{
            initialValue: initialData ? initialData.lastName : "",
            rules: [
              {
                required: true,
                message: "Please input user's last name!",
              },
            ],
          }}
        >
          <Input placeholder="Please input user's last name!" />
        </Form.Item>

        <Form.Item
          label="Email"
          name={"email"}
          {...{
            initialValue: initialData ? initialData.email : "",
            rules: [
              {
                required: true,
                message: "Please input user's email!",
              },
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
            ],
          }}
        >
          <Input placeholder="Please input user's email!" />
        </Form.Item>

        <Form.Item
          label="ID Number"
          name="idNumber"
          {...{
            rules: [
              {
                required: true,
                message: "Please input user's ID Number!",
              },
            ],
          }}
        >
          <Input placeholder="Please input user's ID Number!" />
        </Form.Item>

        <Form.Item
          label="Phone Number"
          name="phone"
          {...{
            rules: [
              { required: true, message: "Please input user's phone number!" },
            ],
          }}
        >
          <Input
            addonBefore={
              <div>
                <span role="img" aria-label="flag" style={{ color: "black" }}>
                  🇿🇦
                </span>{" "}
                +27
              </div>
            }
            placeholder="Please input user's phone number"
          />
        </Form.Item>

        <Form.Item
          label="User Role"
          name="userRoles"
          {...{
            rules: [
              {
                required: true,
                message: "Please select user type",
              },
            ],
          }}
        >
          <Select loading={loading} placeholder="Select type">
            {userTypeOptions}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default InviteUserForm;
