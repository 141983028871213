import { jwtDecode } from "jwt-decode";
import { ID_TOKEN_NAME } from "../constants";

/**
 * Checks if a token has not expired
 * @param token
 */
export function isTokenValid(token: string | null): boolean {
  if (!token) {
    return false;
  }
  try {
    const decodedJwt: any = jwtDecode(token);
    return decodedJwt.exp >= Date.now() / 1000;
  } catch (e) {
    return false;
  }
}

/**
 * Checks if a user is logged in
 */
export function isLoggedIn(): boolean {
  const isValid = isTokenValid(window.localStorage.getItem(ID_TOKEN_NAME));

  if (!isValid) {
    localStorage.removeItem("taskFiltersHistory");
  }

  return isValid;
}
