import { COMPANY_TYPES } from "../../constants";
import BusinessInfoCompany from "../RegisterCompany/businessInformation";
import BusinessInfoSoleProprietor from "../RegisterSoleProprietor/businessInformation";
import PersonalInfoEmployee from "../RegisterEmployee/personalInformation";
import { useRegistrationState } from "@/stores/registration";
import { If } from "@/components/If";

type Props = { nextStep: () => void };

function BusinessInfo(props: Props) {
  const { registrationCapacity, registeringAs } = useRegistrationState();

  return (
    <>
      <If
        condition={
          registrationCapacity === "Business" &&
          COMPANY_TYPES.includes(registeringAs)
        }
      >
        <BusinessInfoCompany nextStep={props.nextStep} />
      </If>
      <If condition={registeringAs === "A Sole Proprietor"}>
        <BusinessInfoSoleProprietor nextStep={props.nextStep} />
      </If>
      <If condition={registeringAs === "Employee of a Business"}>
        <PersonalInfoEmployee nextStep={props.nextStep} />
      </If>
    </>
  );
}
export default BusinessInfo;
