import { useState } from "react";
import { Input, Button, Checkbox, Spin, Form } from "antd";
import { Auth } from "aws-amplify";
import { Link, useSearchParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { useMutation } from "@apollo/client";
import cfdcLogo from "../../assets/images/cfdc-logo.png";
import { authenticateUser } from "@/utils/auth/authenticateUser";
import { confirmUserRegistration } from "@/utils/auth/confirmUserRegistration";
import ConfirmAccountForm from "./confirmAccount";
import { useNavigate } from "react-router-dom";
import { notifyError, openNotificationWithIcon } from "@/utils/notification";
import { useLazyQuery } from "@apollo/client";
import {
  GET_USER,
  GET_USER_TYPES,
} from "../../containers/Admin/graphql/queries";
import {
  UPDATE_EMAIL_RESEND_VERIFICATION,
  UPDATE_USER,
} from "../../graphql/mutations";
import {
  MEMBER_STREAM,
  ADMIN_STREAM,
  APPLICATION_TO_BE_VERIFIED,
  APPLICATION_IN_PROGRESS,
  APPLICATION_VERIFIED,
  FINANCE_STREAM,
  SUPER_USER_STREAM,
  LEGAL_STREAM,
  EXECUTIVE_STREAM,
  EMPLOYEE_USER_ROLE,
} from "../../constants";
import { useUserState } from "@/stores/user";
import { isAdmin } from "@/utils";

Auth.configure({
  Auth: {
    identityPoolId: import.meta.env.VITE_USER_POOL_CLIENT_ID,
    region: import.meta.env.VITE_REGION,
    userPoolId: import.meta.env.VITE_USER_POOL_ID,
    UserPoolId: import.meta.env.VITE_USER_POOL_ID,
    ClientId: import.meta.env.VITE_USER_POOL_CLIENT_ID,
    userPoolWebClientId: import.meta.env.VITE_USER_POOL_CLIENT_ID,
  },
});

interface Props {
  runTokenEmailVerification?: any;
  employeeInvitationCode?: string;
}

function LoginForm(props: Props) {
  const [params] = useSearchParams();
  const [form] = Form.useForm();
  const [confirmForm] = Form.useForm();
  const { setUserStateObject, id: userId } = useUserState();
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [confirmationEmailPrefill, setConfirmationEmailPrefill] =
    useState(null);
  const [accountConfirmMode, setAccountConfirmMode] = useState(null);
  const navigate = useNavigate();
  const [updateUser] = useMutation(UPDATE_USER);
  const [updateUserEmailAndResendVerification] = useMutation(
    UPDATE_EMAIL_RESEND_VERIFICATION
  );

  const [getUserTypes, { loading: loadingUserTypes }] = useLazyQuery(
    GET_USER_TYPES,
    {
      fetchPolicy: "network-only",
      onCompleted: async (data) => {
        const role = data.userTypesList.find(
          (x: any) => x.userRole?.name === EMPLOYEE_USER_ROLE
        ).userRole;

        await updateUser({
          variables: {
            input: {
              id: userId,
              userRoles: [role?.id],
            },
          },
        });
      },
    }
  );

  function redirectToRelevantPage(
    stream: string,
    applicationStatus: string = ""
  ) {
    const destination = params.get("from");
    if (destination) {
      navigate(destination);
    } else {
      if (stream === SUPER_USER_STREAM) {
        navigate("/admin/dashboard");
      } else if (
        [ADMIN_STREAM, FINANCE_STREAM, LEGAL_STREAM].includes(stream) &&
        applicationStatus.includes(APPLICATION_TO_BE_VERIFIED)
      ) {
        navigate("/admin/profile");
      } else if (
        ![
          ADMIN_STREAM,
          FINANCE_STREAM,
          LEGAL_STREAM,
          EXECUTIVE_STREAM,
        ].includes(stream) &&
        !isEmpty(applicationStatus) &&
        applicationStatus !== APPLICATION_IN_PROGRESS &&
        !props.employeeInvitationCode
      ) {
        navigate("/profile");
      } else if (
        stream === MEMBER_STREAM &&
        ![APPLICATION_TO_BE_VERIFIED, APPLICATION_VERIFIED].includes(
          applicationStatus
        ) &&
        !props.employeeInvitationCode
      ) {
        navigate("/register");
      } else if (
        [ADMIN_STREAM, FINANCE_STREAM, LEGAL_STREAM, EXECUTIVE_STREAM].includes(
          stream
        )
      ) {
        navigate("/admin/tasks");
      } else
        navigate(
          props.employeeInvitationCode
            ? `/register/employee-invitation/${props.employeeInvitationCode}`
            : "/register"
        );
    }
  }

  function initialisePlatform(
    userData: any,
    setState: (state: any) => void,
    applications: any[] = [],
    employments: any[] = []
  ) {
    if (!userData) return;

    const permissions =
      userData.userUserRoles
        .map((t: any) => t.userRole?.permissions?.map((p: any) => p.name))
        ?.flat() || [];

    const userRoles = userData.userUserRoles?.map((r: any, i: number) => {
      const cid = r.company?.id;

      return {
        id: r?.id,
        name: r.userRole?.name,
        stream: r.userRole?.stream?.name,
        company:
          (r.company?.registeredName || r.company?.tradingName) ??
          (isAdmin(r.userRole?.stream?.name)
            ? "Council for Debt Collectors"
            : "Unknown Company"),
        companyId: cid,
        companyStatus: r.company?.status,
        capacityId: employments.find((c) => c.company?.id === cid)?.capacity
          ?.id,
        applicationId: applications[i]?.id,
        applicationStatus: applications[i]?.applicationStatus?.name,
      };
    });

    const stream = userRoles[0]?.stream;

    localStorage.setItem("username", userData?.email);
    localStorage.setItem("idNumber", userData?.idNumber);
    localStorage.setItem(
      "userRoles",
      userRoles.map((r: any) => r.name)
    );
    localStorage.setItem("userPermissions", permissions);
    localStorage.setItem("userId", userData?.id);
    localStorage.setItem("capacityId", userData?.capacity?.id);
    localStorage.setItem("roleStream", stream);
    localStorage.setItem(
      "applicationStatus",
      applications[0]?.applicationStatus?.name
    );
    localStorage.setItem("applicationId", userData.applications[0]?.id);

    setState({
      id: userData.id,
      email: userData.email,
      firstName: userData?.firstName,
      lastName: userData?.lastName,
      idNumber: userData?.idNumber,
      stream,
      phone: userData?.phone,
      permissions: permissions,
      roles: userRoles.map((r: any) => r.name),
      applicationId: userData.applications[0]?.id,
      companyId: employments[0]?.company?.id,
      activeUserRole: userRoles?.[0]?.id,
      userRoles,
    });

    redirectToRelevantPage(stream, applications[0]?.applicationStatus?.name);
  }

  const [getUserDetails] = useLazyQuery(GET_USER, {
    variables: { input: { email: "", login: true } },
    onError: (err) => {
      notifyError("An error occurred. Please try again.");
      setLoading(false);
    },
    onCompleted: async (data) => {
      const {
        id,
        userUserRoles,
        applications,
        employment: emp,
      } = data.userData;
      const employment = emp.filter((x: any) => x.company?.id !== 13132);

      if (employment.length > 0) {
        localStorage.setItem("companyStatus", employment[0].company.status);
      }

      setUserStateObject({ idNumber: data?.userData?.idNumber, id });

      localStorage.setItem("idNumber", data?.userData?.idNumber);
      localStorage.setItem("userId", id);

      // if userUserRoles is null - assign default role
      if (isEmpty(userUserRoles)) {
        localStorage.setItem("username", data?.userData?.email);
        localStorage.setItem("userRoles", EMPLOYEE_USER_ROLE);
        localStorage.setItem("roleStream", MEMBER_STREAM);
        getUserTypes();
      }

      if (data.userData.requirePasswordReset) {
        await Auth.forgotPassword(data?.userData?.idNumber);
        form.resetFields(["password"]);
        localStorage.removeItem("isAuthenticated");
        setAccountConfirmMode("update-password-confirmation");
        setModalVisible(true);
        setLoading(false);
      } else if (
        data.userData &&
        data.userData.userUserRoles &&
        data.userData.emailVerified
      ) {
        const stream = userUserRoles[0]?.userRole?.stream?.name;
        const applicationStatus = applications?.map(
          (x) => x.applicationStatus?.name
        );

        initialisePlatform(
          data.userData,
          setUserStateObject,
          applications,
          employment
        );
        setLoading(false);

        redirectToRelevantPage(stream, applicationStatus);
      } else if (
        data.userData &&
        data.userData.userUserRole &&
        !data.userData.emailVerified
      ) {
        localStorage.removeItem("isAuthenticated");
        setAccountConfirmMode("update-email-confirmation");
        setModalVisible(true);
        setLoading(false);
      }
    },
    fetchPolicy: "network-only",
  });

  const handleSubmit = async (values: any) => {
    if (isEmpty(values.email)) {
      return openNotificationWithIcon(
        "error",
        "Error",
        "Please enter either Email Address or ID/Passport number"
      );
    }

    const credentials = {
      email: values.email?.trim(),
      password: values.password?.trim(),
    };

    try {
      setLoading(true);

      await authenticateUser(
        credentials,
        async (err, result, useCognitoUser) => {
          if (err) {
            switch (err.code) {
              case "UserNotConfirmedException":
                setAccountConfirmMode("aws-email-confirmation");
                setModalVisible(true);
                break;
              default:
                openNotificationWithIcon(
                  "error",
                  "Authentication Error",
                  err.message
                );
                break;
            }
            setLoading(false);
          } else {
            if (useCognitoUser) {
              setConfirmationEmailPrefill(credentials.email);
            }
            if (props.runTokenEmailVerification) {
              await props.runTokenEmailVerification();
            }

            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            getUserDetails({
              variables: {
                input: {
                  login: true,
                  email: credentials.email,
                  [emailRegex.test(credentials.email) ? "email" : "idNumber"]:
                    credentials.email,
                },
              },
            });
          }
        }
      );
    } catch (_) {
      notifyError("An error occurred. Please try again.");
      setLoading(false);
    }
  };

  const handleAccountConfirm = async (values: any) => {
    setModalVisible(false);
    await confirmUserRegistration({
      idNumber: values.idNumber,
      code: values.code,
    });
  };

  const handleUpdateEmailConfirm = async (values: any) => {
    updateUserEmailAndResendVerification({
      variables: {
        newEmail: values.email,
      },
    }).then((res) => {
      if (res.data.updateEmailResendVerificationToken) {
        localStorage.clear();
        openNotificationWithIcon(
          "success",
          "Verification Link Resent",
          "Please navigate to the link which has been emailed to you in order to verify your email address and log in."
        );
      } else {
        openNotificationWithIcon(
          "error",
          "Verification Link Resend Failed",
          "Please try again or contact support."
        );
      }
      setModalVisible(false);
      form.resetFields();
    });
  };

  const ModalProps = {
    form: confirmForm,
    visible: modalVisible,
    onCancel: () => setModalVisible(false),
    onCreate:
      accountConfirmMode === "aws-email-confirmation"
        ? handleAccountConfirm
        : handleUpdateEmailConfirm,
    mode: accountConfirmMode,
    emailPrefill:
      accountConfirmMode === "update-email-confirmation"
        ? confirmationEmailPrefill
        : "",
    okText:
      accountConfirmMode === "update-email-confirmation"
        ? "Request verification link"
        : "Save",
    modalHeading:
      accountConfirmMode === "update-password-confirmation"
        ? "Password Reset Required"
        : "Account Confirmation",
    cancelText:
      accountConfirmMode === "update-password-confirmation"
        ? "Close"
        : "Cancel",
    hideOkButton:
      accountConfirmMode === "update-password-confirmation" ? true : false,
  };

  return (
    <Spin spinning={loadingUserTypes} className="loader">
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        className="login-form"
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ textAlign: "center" }}>
            <img src={cfdcLogo} alt="cfdc" className="logo-login" />
          </div>
          <div
            style={{
              textAlign: "center",
              marginTop: "2vh",
              marginBottom: "1vh",
            }}
          >
            <h3>Sign In</h3>
          </div>
        </div>
        <Form.Item
          label="Email / ID Number"
          className="form-item"
          name={"email"}
          normalize={(s?: string) => s?.toLowerCase()}
          rules={[
            {
              required: true,
              message: "Please input your email or ID number!",
            },
          ]}
        >
          <Input placeholder="Please enter your email" readOnly={loading} />
        </Form.Item>

        <Form.Item
          label="Password"
          className="form-item"
          name="password"
          rules={[{ required: true, message: "Please input your Password!" }]}
        >
          <Input.Password
            placeholder="Please enter your password"
            readOnly={loading}
          />
        </Form.Item>

        <Form.Item
          className="form-item"
          name="remember"
          valuePropName="checked"
          initialValue={true}
        >
          <Checkbox disabled={loading}>Remember me</Checkbox>
        </Form.Item>

        <Form.Item {...{ name: "" }}>
          <Button
            type="primary"
            htmlType="submit"
            className="btn-signin"
            loading={loading}
          >
            Sign In
          </Button>
        </Form.Item>

        <Link to="/forgot-password">Forgot password?</Link>

        <span style={{ marginTop: "3vh" }}>Don't have an account yet?</span>

        <Button
          type="default"
          className="btn-signup"
          disabled={loading}
          onClick={() =>
            props.employeeInvitationCode
              ? window.location.replace(
                  `/employee-invitation/${props.employeeInvitationCode}`
                )
              : window.location.replace("/signup")
          }
        >
          Sign Up
        </Button>
      </Form>
      <ConfirmAccountForm {...ModalProps} />
    </Spin>
  );
}

export default LoginForm;
