import { useState, useEffect } from "react";
import { Radio, Input, Button, Skeleton, Divider, Form } from "antd";

import { COMPANY_TYPES } from "../../constants";
import { GET_AUDITOR_ACCOUNTANT_INFO } from "../../graphql/queries";
import {
  CREATE_AUDITOR_ACCOUNTANT_INFO,
  UPDATE_AUDITOR_ACCOUNTANT_INFO,
  REQUEST_OTP,
} from "../../graphql/mutations";
import { useMutation, useQuery } from "@apollo/client";
import { openNotificationWithIcon } from "@/utils/notification";
import { isInteger } from "lodash";
import { useRegistrationState } from "@/stores/registration";
import { useUserState } from "@/stores/user";

interface IProps {
  useCompanyId?: number;
  isRegistrationMode?: boolean;
  useRegisteringAs?: string;
  nextStep?: () => void;
}

function AuditorAccountantInfoForm({
  useCompanyId,
  useRegisteringAs,
  isRegistrationMode = true,
  ...props
}: IProps) {
  const [form] = Form.useForm();
  const {
    setRegistrationStateObject: setRegistrationState,
    registeringAs,
    companyId: cid,
    ...rest
  } = useRegistrationState();

  const { id: userId } = useUserState();

  const [isSubmitting, setIsSubmiting] = useState(false);
  const [role, setRole] = useState("Auditor");
  const [businessName, setBusinessName] = useState("");
  const [contactPersonName, setContactPersonName] = useState("");
  const [contactOfficeNumber, setContactOfficeNumber] = useState("");
  const [contactCellNumber, setContactCellNumber] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [currentStep, setCurrentStep] = useState("step5Saved");

  const registeredAs = useRegisteringAs || registeringAs;
  const companyId = useCompanyId ? +useCompanyId : cid;
  const isCompany = COMPANY_TYPES.includes(registeredAs);

  const [auditorAccountantId, setAuditorAccountantId] = useState<number>();
  const [upsertAuditorInfo] = useMutation(
    isInteger(auditorAccountantId)
      ? UPDATE_AUDITOR_ACCOUNTANT_INFO
      : CREATE_AUDITOR_ACCOUNTANT_INFO,
    {
      onError: () => {
        setIsSubmiting(false);
        openNotificationWithIcon(
          "error",
          "Save Error",
          "Error when saving auditor/accountant information. Please try again"
        );
      },
      onCompleted: () => {
        setIsSubmiting(false);
        if (isRegistrationMode) {
          // trigger OTP
          requestOTP()
            .then(() => {
              setRegistrationState({ [currentStep]: true });
              openNotificationWithIcon(
                "success",
                "Save Success",
                "Company auditor/accountant information saved successfully"
              );
            })
            .catch(() => {
              openNotificationWithIcon(
                "error",
                "OTP Service Error",
                "Information saved, but could not send the OTP"
              );
            });
        }
      },
    }
  );

  useEffect(() => {
    isCompany ? setCurrentStep("step5Saved") : setCurrentStep("step7Saved");
  }, [registeredAs, isCompany]);

  useEffect(() => {
    if (!!rest[currentStep] || !isRegistrationMode) {
      refetchAuditorInfo({ companyId }).then((resp) => {
        setAuditorAccountantInfo(resp.data);
      });
    }
  }, []);

  const { loading: loadingAuditorInfo, refetch: refetchAuditorInfo } = useQuery(
    GET_AUDITOR_ACCOUNTANT_INFO,
    {
      variables: {
        companyId,
      },
      onError: () =>
        openNotificationWithIcon(
          "error",
          "Error",
          "Error loading auditor/accountant information"
        ),
      onCompleted: (dataAccountantInfo) => {
        setAuditorAccountantInfo(dataAccountantInfo);
      },
    }
  );
  const [requestOTP] = useMutation(REQUEST_OTP, {
    variables: {
      input: {
        userId,
      },
    },
  });

  const step = {
    title: `Step ${isCompany ? 5 : 7}: Auditor/Accountant information`,
    content: "Complete your auditor/accountant information",
  };

  const setAuditorAccountantInfo = (dataAccountantInfo) => {
    if (!isInteger(dataAccountantInfo.companyAccountant)) {
      setBusinessName(dataAccountantInfo.companyAccountant?.businessName);
      setContactPersonName(
        dataAccountantInfo.companyAccountant?.contactPersonName
      );
      setContactOfficeNumber(
        dataAccountantInfo.companyAccountant?.contactOfficeNumber
      );
      setContactCellNumber(
        dataAccountantInfo.companyAccountant?.contactCellNumber
      );
      setBusinessEmail(dataAccountantInfo.companyAccountant?.email);
      setRole(dataAccountantInfo.companyAccountant?.role);
      setAuditorAccountantId(dataAccountantInfo.companyAccountant?.id);
    }
  };

  const handleSubmit = async (values: any) => {
    setIsSubmiting(true);
    upsertAuditorInfo({
      variables: {
        input: {
          id: isInteger(auditorAccountantId) ? auditorAccountantId : undefined,
          businessName: values.auditorBusinessName,
          contactPersonName: values.auditorContactPerson,
          contactOfficeNumber: values.auditorOfficeNumber,
          contactCellNumber: values.auditorCellphone,
          email: values.auditorEmail,
          role: values.role,
          companyId: isInteger(auditorAccountantId) ? undefined : companyId,
        },
      },
    });
  };

  if (loadingAuditorInfo) {
    return <Skeleton active paragraph={{ rows: 6 }} />;
  }

  return (
    <div className="flex-column">
      {isRegistrationMode && (
        <>
          <div>
            <span className="step-title">{step.title}</span>
            <p>{step.content}</p>
            <Divider />
          </div>
        </>
      )}
      <div>
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <div className="flex-row gap-4">
            <div className="flex-1">
              <Form.Item
                label="Please select role"
                {...{
                  name: "role",
                  initialValue: role,
                  rules: [
                    {
                      required: true,
                      message: "Please enter branch code",
                    },
                  ],
                }}
              >
                <Radio.Group
                  onChange={(e) => {
                    setRole(e.target.value);
                  }}
                  className="input-select input-spacer"
                >
                  <Radio value={"Auditor"}>Auditor</Radio>
                  <Radio value={"Accountant"} disabled>
                    Accountant
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </div>

            <div className="flex-1">
              <Form.Item
                label="Name of business"
                {...{
                  name: "auditorBusinessName",
                  initialValue: businessName,
                  rules: [
                    {
                      required: true,
                      message: "Please enter name of auditor",
                    },
                  ],
                }}
              >
                <Input placeholder="Please enter name of auditor / accountant" />
              </Form.Item>
            </div>
          </div>
          <div className="flex-row gap-4">
            <div className="flex-column input-block">
              <Form.Item
                label="Contact person name"
                {...{
                  name: "auditorContactPerson",
                  initialValue: contactPersonName,
                  rules: [
                    {
                      required: true,
                      message: "Please enter branch code",
                    },
                  ],
                }}
              >
                <Input placeholder="Please enter name and surname" />
              </Form.Item>
            </div>

            <div className="flex-column input-block">
              <Form.Item
                label="Contact number (office)"
                {...{
                  name: "auditorOfficeNumber",
                  initialValue: contactOfficeNumber,
                  rules: [
                    {
                      required: true,
                      message: "Please enter office number",
                    },
                  ],
                }}
              >
                <Input placeholder="Please enter office number" />
              </Form.Item>
            </div>
          </div>
          <div className="flex-row gap-4">
            <div className="flex-column input-block">
              <Form.Item
                label="Contact number (cell)"
                {...{
                  name: "auditorCellphone",
                  initialValue: contactCellNumber,
                  rules: [
                    {
                      required: false,
                      message: "Please enter cellphone number",
                    },
                  ],
                }}
              >
                <Input placeholder="Please enter cellphone number (optional)" />
              </Form.Item>
            </div>

            <div className="flex-column input-block">
              <Form.Item
                label="Email address"
                {...{
                  name: "auditorEmail",
                  initialValue: businessEmail,
                  rules: [
                    {
                      required: true,
                      message: "Please enter email address",
                    },
                    {
                      type: "email",
                      message: "Please enter a valid email address",
                    },
                  ],
                }}
              >
                <Input placeholder="Please enter email address" />
              </Form.Item>
            </div>
          </div>
          <div className="flex-row justify-end">
            <Button
              htmlType="submit"
              className="btn-registration-capacity-selected"
              loading={isSubmitting}
            >
              Save information
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default AuditorAccountantInfoForm;
