import { useState, useEffect, useRef } from "react";
import { Button, Card, Divider, Steps, Breadcrumb, Result, Tag } from "antd";

import { parseISO, format } from "date-fns";
import BusinessInformation from "./businessInformation";
import DocumentationInformation from "./documentationInformation";
import PersonalInformation from "./personalInformation";
import Prequalification from "./prequalification";
import EmploymentInformation from "./employmentInformation";
import FinancialInformation from "./financialInformation";
import AuditorInformation from "./auditorInformation";
import AuthenticationInformation from "./authenticationInformation";
import PaymentInformation from "./paymentInformation";
import EducationExperienceInformation from "./educationExperience";
import CompleteReview from "./completeReview";
import { GET_APPLICATION_REVIEW_INFO } from "../../../graphql/queries";
import { useQuery } from "@apollo/client";
import { isEmpty } from "lodash";
import "./index.css";
import InformationLoader from "./informationLoader";
import { openNotificationWithIcon } from "@/utils/notification";
import PermissionsGuard from "../../../components/Auth/can";
import AccessDenied from "../../../components/Auth/accessDenied";
import { useLocation, useNavigate } from "react-router-dom";

function Review() {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [reviewInfo, setReviewInfo] = useState<any>();
  const [stickySteps, setStickySteps] = useState(false);
  const stepsRef = useRef(null);

  const task = location.state;
  const taskId = task.id;

  useEffect(() => {
    document.querySelector("main").scrollTop = 0;
  }, [currentStep]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        stepsRef.current &&
        stepsRef.current.getBoundingClientRect().top > 93 &&
        stickySteps
      ) {
        setStickySteps(false);
      } else if (
        stepsRef.current &&
        stepsRef.current.getBoundingClientRect().top < 93 &&
        !stickySteps
      ) {
        setStickySteps(true);
      }
    };

    document
      .querySelector("main")
      .addEventListener("scroll", handleScroll, { passive: true });

    return () =>
      document
        .querySelector("main")
        .removeEventListener("scroll", handleScroll);
  }, [stickySteps]);

  const { loading: loadingReview } = useQuery(GET_APPLICATION_REVIEW_INFO, {
    variables: { taskId },
    fetchPolicy: "no-cache",
    skip: isEmpty(task),
    onCompleted: (data) => {
      if (data.reviewByTask) {
        setReviewInfo(data.reviewByTask);

        // set step error status
        if (!data.reviewByTask?.steps?.[currentStep]?.hasPassed) {
          steps[currentStep].status = "error";
        }

        // allow next navigation for already saved reviews
        data.reviewByTask?.steps.map((x) =>
          localStorage.setItem(`${x.step}Saved`, "true")
        );

        //set current step to the last step of the review
        setCurrentStep(data.reviewByTask?.steps?.length - 1);
      }
    },
  });

  // if location state is empty
  if (isEmpty(task)) {
    return <InvalidTask />;
  }

  const {
    id,
    employee,
    company,
    application,
    type,
    createdDate,
    taskStatus,
    relatedTask,
    relatedTasks,
  } = task;
  const isCompanyReg = type.name === "Company";
  const isEmployeeReg = type.name === "Employee";
  const isSoleProprietorReg = type.name === "Sole Proprietor";
  const companyApplicationId = company?.application?.id;

  let employeeApplicationId = employee?.applications.find(
    (x) => x.taskId === id
  )?.id;
  if (!employeeApplicationId && employee?.applications.length === 1) {
    employeeApplicationId = employee?.applications[0].id;
  }

  let applicantType = "N/A";

  if (application?.applicantType) {
    applicantType = application?.applicantType;
  } else if (company?.application?.applicantType) {
    applicantType = company?.application?.applicantType;
  }
  const consolidatedSteps = [
    {
      title: "Business information",
      status: "wait",
    },
    {
      title: "Personal information",
      status: "wait",
    },
    {
      title: "Prequalification",
      status: "wait",
    },
    {
      title: "Employment information",
      status: "wait",
    },
    {
      title: "Education & Experience",
      status: "wait",
    },
    {
      title: "Financial information",
      status: "wait",
    },
    {
      title: "Auditor/Accountant information",
      status: "wait",
    },
    {
      title: "Authentication",
      status: "wait",
    },
    {
      title: "Documentation",
      status: "wait",
    },
    {
      title: "Registration fee and payment",
      status: "wait",
    },
  ];

  const employeeSteps = consolidatedSteps.filter(
    (x) =>
      ![
        "Business information",
        "Auditor/Accountant information",
        "Financial information",
      ].includes(x.title)
  );
  const companySteps = consolidatedSteps.filter(
    (x) =>
      ![
        "Education & Experience",
        "Employment information",
        "Personal information",
      ].includes(x.title)
  );
  const solePropSteps = consolidatedSteps.filter(
    (x) => !["Employment information"].includes(x.title)
  );

  const steps = isEmployeeReg
    ? employeeSteps
    : isCompanyReg
    ? companySteps
    : solePropSteps;

  const taskType = task.type.name;

  const isFinancialTicket =
    taskType === "Financial Ticket" || taskType === "Renewal";

  const previous = () => {
    if (currentStep > 0) setCurrentStep(currentStep - 1);
  };
  const next = () => {
    const stepTitle_array = steps[currentStep].title
      .replace(" & ", " ")
      .split(" ");
    const stepTitle =
      stepTitle_array.length > 1
        ? `${stepTitle_array[0]}${stepTitle_array[1]?.replace(
            stepTitle_array[1][0],
            stepTitle_array[1][0]?.toUpperCase()
          )}`
        : stepTitle_array[0];

    if (
      localStorage.getItem(`${stepTitle}Saved`) === "true" &&
      currentStep < steps.length
    )
      setCurrentStep(currentStep + 1);
    else openNotificationWithIcon("error", "Save Error", "Please save review");
  };

  const stepComponentProps = {
    transaction: location.state,
    reviewInfo,
  };

  if (loadingReview) return <InformationLoader />;

  return (
    <PermissionsGuard
      perform={"review:edit"}
      yes={() => (
        <>
          <Card className="detail-card" key="detail-card">
            <Breadcrumb>
              <Breadcrumb.Item>
                <a href="/admin/tasks">Tasks: New Applicants</a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {isEmployeeReg
                  ? `${employee.firstName} ${employee.lastName}`
                  : isCompanyReg
                  ? `${company?.registeredName}`
                  : `${company?.tradingName}`}
              </Breadcrumb.Item>
            </Breadcrumb>
            <br />
            <div className="flex-row justify-between">
              <h2>
                {isEmployeeReg
                  ? `${employee.firstName} ${employee.lastName}`
                  : isCompanyReg
                  ? `${company?.registeredName}`
                  : `${company?.tradingName}`}
              </h2>
              <h3>
                Status: <Tag color={"orange"}>{taskStatus}</Tag>
              </h3>
            </div>
            <div className="flex-row justify-between">
              <label>Task ID: {id}</label>
              <label>Type: {type.name}</label>
              <label>Applicant Type: {applicantType}</label>
              <label>
                Date Created: {format(parseISO(createdDate), "dd-MM-yyyy")}
              </label>
            </div>
          </Card>
          {!isFinancialTicket && (
            <div className={"review-sider"}>
              <div
                style={stickySteps ? { opacity: 0 } : { opacity: 1 }}
                key={1}
                ref={stepsRef}
              >
                <Steps direction="vertical" current={currentStep}>
                  {steps.map((item) => (
                    <Steps.Step key={item.title} title={item.title} />
                  ))}
                </Steps>
              </div>
              <div
                className="review-steps-sticky"
                style={stickySteps ? { opacity: 1 } : { opacity: 0 }}
              >
                <Steps direction="vertical" current={currentStep}>
                  {steps.map((item) => (
                    <Steps.Step key={item.title} title={item.title} />
                  ))}
                </Steps>
              </div>
            </div>
          )}
          <div className={!isFinancialTicket ? "review-content" : ""}>
            <Card
              key="review-card"
              className="card"
              title={
                <>
                  <h3>Review Process</h3>
                  {isFinancialTicket && (
                    <span>
                      Please review the task related to an online application
                      using <b>Direct Deposit </b> as a payment option.
                    </span>
                  )}
                  {!isFinancialTicket && (
                    <span>
                      This is documented in a {steps.length} step process
                      whereby each section will be reviewed and marked
                    </span>
                  )}
                </>
              }
            >
              <br />
              <div className="review-content-section">
                {currentStep < steps.length && (
                  <h3 className="review-step-content">
                    Review:{" "}
                    {isFinancialTicket
                      ? "Registration fee and payment"
                      : steps[currentStep].title}
                  </h3>
                )}

                {/* This may need to be refactored for readability */}
                <div className="review-step-content">
                  {isFinancialTicket && (
                    <PaymentInformation {...stepComponentProps} />
                  )}
                  {!isFinancialTicket &&
                    (!isEmployeeReg && currentStep === 0 ? (
                      <BusinessInformation {...stepComponentProps} />
                    ) : (isEmployeeReg && currentStep === 0) ||
                      (isSoleProprietorReg && currentStep === 1) ? (
                      <PersonalInformation {...stepComponentProps} />
                    ) : (!isSoleProprietorReg && currentStep === 1) ||
                      (isSoleProprietorReg && currentStep === 2) ? (
                      <Prequalification {...stepComponentProps} />
                    ) : (isCompanyReg && currentStep === 2) ||
                      (isSoleProprietorReg && currentStep === 4) ? (
                      <FinancialInformation {...stepComponentProps} />
                    ) : isEmployeeReg && currentStep === 2 ? (
                      <EmploymentInformation {...stepComponentProps} />
                    ) : (isEmployeeReg && currentStep === 3) ||
                      (isSoleProprietorReg && currentStep === 3) ? (
                      <EducationExperienceInformation {...stepComponentProps} />
                    ) : (isSoleProprietorReg && currentStep === 5) ||
                      (isCompanyReg && currentStep === 3) ? (
                      <AuditorInformation {...stepComponentProps} />
                    ) : (isSoleProprietorReg && currentStep === 6) ||
                      (!isSoleProprietorReg && currentStep === 4) ? (
                      <AuthenticationInformation {...stepComponentProps} />
                    ) : (isSoleProprietorReg && currentStep === 7) ||
                      (!isSoleProprietorReg && currentStep === 5) ? (
                      <DocumentationInformation {...stepComponentProps} />
                    ) : (
                      ((isSoleProprietorReg && currentStep === 8) ||
                        (!isSoleProprietorReg && currentStep === 6)) && (
                        <PaymentInformation {...stepComponentProps} />
                      )
                    ))}
                  {currentStep === steps.length && (
                    <CompleteReview
                      taskId={taskId}
                      applicationId={
                        isEmployeeReg
                          ? employeeApplicationId
                          : companyApplicationId
                      }
                      setCurrentStep={() => setCurrentStep(currentStep - 1)}
                    />
                  )}
                </div>
              </div>
              <Divider />
              {currentStep < steps.length && (
                <div className="stepper-btn-container">
                  {!isFinancialTicket && (
                    <>
                      <Button
                        className="btn-previous-reject"
                        onClick={previous}
                        style={{
                          display: currentStep === 0 ? "none" : "inline",
                        }}
                      >
                        Previous
                      </Button>
                      <Button
                        type="default"
                        onClick={next}
                        className="btn-add-comment"
                        disabled={!!!(relatedTask || relatedTasks.length > 0)}
                      >
                        {currentStep < steps.length ? "Next Step" : "Complete"}
                      </Button>
                      {reviewInfo &&
                        ((isSoleProprietorReg && currentStep === 8) ||
                          (!isSoleProprietorReg && currentStep === 6)) &&
                        reviewInfo?.steps.some(
                          (item) =>
                            item.step === "RegistrationFee" &&
                            item.hasPassed === false
                        ) && (
                          <Button
                            type="default"
                            onClick={() => navigate("/admin/tasks")}
                            className="btn-previous-reject"
                          >
                            Close
                          </Button>
                        )}
                    </>
                  )}
                </div>
              )}
            </Card>
          </div>
        </>
      )}
      no={() => <AccessDenied permission="review:edit" />}
    />
  );
}

const InvalidTask = () => {
  return (
    <Result
      status="warning"
      title="Invalid task review"
      subTitle="Please go back to tasks page and select a task to review"
      extra={
        <Button type="default" className="btn-add-comment">
          <a href="/admin/tasks">Go To Tasks</a>
        </Button>
      }
    />
  );
};

export default Review;
