import { useState, useEffect } from "react";
import { Button } from "antd";
import { useMutation, useLazyQuery } from "@apollo/client";
import { CREATE_DEBT_COLLECTOR_RESPONSE } from "@/graphql/mutations";
import { GET_DEBT_COLLECTOR_RESPONSE } from "@/graphql/queries";
import Comment from "./Comment";
import PermissionsGuard from "../../../../components/Auth/can";
import { notifyError } from "@/utils/notification";
import { CreateDebtCollectorResponseInput } from "@/typings";

type Props = {
  complaint: any;
  createActivity: (args: any) => void;
  loading: boolean;
  userId: number;
  setInitiateProsecution: (val: boolean) => void;
  setResolveComplaint: (val: boolean) => void;
  isComplaintAdmin: boolean;
  refreshComplaint: () => void;
};

export default ({
  complaint,
  setInitiateProsecution,
  setResolveComplaint,
  userId,
  isComplaintAdmin,
  refreshComplaint,
}: Props) => {
  const [loading, setLoading] = useState<boolean>();
  const [responses, setResponses] = useState<any>();

  const [submitResponse] = useMutation(CREATE_DEBT_COLLECTOR_RESPONSE);

  const [getResponses] = useLazyQuery(GET_DEBT_COLLECTOR_RESPONSE, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getDebtCollectorResponse }) => {
      setResponses(getDebtCollectorResponse);
    },
    onError: notifyError,
  });

  const getDebtCollectorResponses = () => {
    if (complaint?.debtCollectorResponse) {
      getResponses({ variables: { id: complaint.debtCollectorResponse.id } });
    } else {
      refreshComplaint();
    }
  };

  const handleSubmit = async (input: CreateDebtCollectorResponseInput) => {
    setLoading(true);
    try {
      await submitResponse({ variables: { input } });
      getDebtCollectorResponses();
    } catch (err) {
      notifyError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDebtCollectorResponses();
  }, []);

  return (
    <>
      <Comment
        mode="debt-collector-response"
        complaintId={complaint?.id}
        userId={userId}
        handleSubmitDebtCollectorResponse={handleSubmit}
        loading={loading}
        comments={responses}
        companyId={complaint?.company?.id}
      />
      {/* <div>
                        {!supportingDocsUploaded && isComplaintAdmin && (
                          <>
                            <span>
                              Notice has been sent.
                              <br /> Awaiting reply from Debt Collector.
                            </span>
                          </>
                        )}

                        <div>
                          <br />
                          <span>Supporting Documentation/communication:</span>
                          {!supportingDocsUploaded && !isComplaintAdmin && (
                            <DocumentUploader
                              taskId={state?.id}
                              debtCollectorId={complaint?.debtCollector?.id}
                              documentTypeId={
                                globalState.lookups?.documentTypes?.find(
                                  t => t.name === "Supporting Documents"
                                )?.id
                              }
                              companyName={companyName}
                              companyId={company?.id}
                              complaintId={complaint?.id}
                              updateComplaint={values =>
                                updateComplaint({
                                  variables: { input: values }
                                })
                              }
                              updateActivity={refreshActivities}
                            />
                          )}
                          <br />
                          {supportingDocsUploaded && (
                            <SupportingDocuments
                              taskId={state?.id}
                              debtCollectorId={complaint?.debtCollector?.id}
                              complaintId={complaint?.id}
                              documentTypeId={
                                globalState.lookups?.documentTypes?.find(
                                  t => t.name === "Supporting Documents"
                                )?.id
                              }
                              companyName={companyName}
                              getDownloadUrl={async input =>
                                getDownloadUrl({ variables: { input } })
                              }
                            />
                          )}
                        </div> */}
      {isComplaintAdmin && (
        <PermissionsGuard
          perform={"complaint:prosecute"}
          yes={() => (
            <div>
              <span>
                If action is to <strong>initiatiate prosecution</strong> then
                proceed to the next step. If action is to not prosecute, then
                resolve this task{" "}
              </span>

              <div>
                <br />
                <Button onClick={() => setResolveComplaint(true)}>
                  Resolve Task
                </Button>
                <Button
                  className="purple-button"
                  onClick={() => setInitiateProsecution(true)}
                  disabled={complaint?.recommendation}
                >
                  {!complaint?.recommendation
                    ? "Initiate Prosecution"
                    : "Prosecution Initiated"}
                </Button>
              </div>
            </div>
          )}
          no={() => <h3>You do not have the permission to prosecute</h3>}
        />
      )}
    </>
  );
};
