import { create } from "zustand";
import { devtools, persist, createJSONStorage } from "zustand/middleware";
import { ADMIN_STREAMS } from "../constants";
import { Application } from "@/typings/types";

interface UserRole {
  id: number;
  name: string;
  stream: string;
  company: string;
  companyId: number;
  capacityId: number;
  companyStatus: string;
  applicationId: number;
  applicationStatus: string;
}

interface UserState {
  setUserState: (key: keyof UserState, value: any) => void;
  setUserStateObject: (obj: Partial<UserState>) => void;
  clearUserState: (incBatchId?: boolean) => void;
  isAdmin: () => boolean;
  isSuperAdmin: () => boolean;
  getActiveRole: () => UserRole;
  email?: string;
  id?: number;
  firstName?: string;
  lastName?: string;
  idNumber?: string;
  phone?: string;
  type?: string;
  permissions?: string[];
  roles?: string[];
  stream?: string;
  companyId?: number;
  capacityId?: number;
  companyStatus?: string;
  applicationId?: number;
  applicationStatus: string;
  pendingApplications?: Application[];
  activeUserRole?: number;
  userRoles: UserRole[];
}

const initialState: Partial<UserState> = {
  email: undefined,
  id: undefined,
  firstName: undefined,
  lastName: undefined,
  idNumber: undefined,
  phone: undefined,
  type: undefined,
  permissions: undefined,
  roles: undefined,
  stream: undefined,
  companyId: undefined,
  applicationId: undefined,
  pendingApplications: [],
  activeUserRole: undefined,
  userRoles: [],
  applicationStatus: undefined,
  capacityId: undefined,
  companyStatus: undefined,
};

export const useUserState = create<UserState>()(
  devtools(
    persist(
      // @ts-ignore
      (set, get) => ({
        ...initialState,
        setUserState: (key, value) => set({ [key]: value }),
        setUserStateObject: (obj: Partial<UserState>) => {
          set((state) => ({ ...state, ...obj }));
        },
        clearUserState: (incBatchId = false) => {
          set(initialState);
        },
        isAdmin: () => {
          const userRoles = get().userRoles;
          const activeUserRole = get().activeUserRole;
          const stream = Boolean(activeUserRole)
            ? userRoles.find((ur) => ur.id === activeUserRole).stream
            : userRoles?.[0]?.stream;
          return ADMIN_STREAMS.includes(stream);
        },
        isSuperAdmin: () => {
          const userRoles = get().userRoles;
          const activeUserRole = get().activeUserRole;
          const stream = Boolean(activeUserRole)
            ? userRoles.find((ur) => ur.id === activeUserRole).stream
            : userRoles?.[0]?.stream;
          return stream === "Super User";
        },
        getActiveRole: () => {
          return (
            get().userRoles.find((ur) => ur.id === get().activeUserRole) ??
            get().userRoles[0]
          );
        },
      }),
      {
        name: "user-storage",
        storage: createJSONStorage(() => localStorage),
      }
    )
  )
);
