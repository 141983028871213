import { useState, useEffect } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Card, Table, Input, Button, Spin, Form } from "antd";
import { isEmpty } from "lodash";
import { useQuery, useMutation } from "@apollo/client";
import { GET_USER_TYPES } from "../../containers/Admin/graphql/queries";
import {
  UPDATE_ROLE,
  DELETE_ROLE,
  CREATE_ROLE,
} from "../../containers/Admin/graphql/mutations";
import { openNotificationWithIcon } from "@/utils/notification";
import EditRolesForm from "./EditRoles";
import { TableLoader } from "./TableContentPlaceholder";
import InternalErrorPage from "../../containers/InternalErrorPage";
import PermissionsGuard from "../../components/Auth/can";
import AccessDenied from "../../components/Auth/accessDenied";

function RolesList() {
  const [filterDropdownVisible, setFilterDropdownVisible] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [rolesList, setRolesList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [selectedRoles, setSelectedRoles] = useState();
  const [updateRoles] = useMutation(UPDATE_ROLE);
  const [deleteRole] = useMutation(DELETE_ROLE);
  const [createRoles] = useMutation(CREATE_ROLE);
  const [showSpin, setShowSpin] = useState(false);
  const [constantRolesList, setConstantRolesList] = useState([]);
  const permissions = localStorage.getItem("userPermissions");

  useEffect(() => {
    if (rolesList) {
      if (!isEmpty(searchText)) {
        const reg = new RegExp(searchText, "gi");
        const filteredRolesList = rolesList.filter((record: any) =>
          record.name.match(reg)
        );

        setRolesList([...filteredRolesList]);
      } else setRolesList([...constantRolesList]);
    }
  }, [searchText]);

  const onInputChange = (e) => {
    setSearchText(e.target.value);
  };
  const _deleteRole = (id) => {
    setShowSpin(true);
    deleteRole({ variables: { id } })
      .then(() => {
        refetch().then((res) => setRolesList(res.data.userTypesList)); // refetch updated roles list
        setShowSpin(false);
        openNotificationWithIcon(
          "success",
          "Delete operation success ",
          "Role deleted successfully"
        );
      })
      .catch((error) => {
        setShowSpin(false);
        openNotificationWithIcon(
          "error",
          "Delete operation error ",
          error.message
        );
      });
  };
  const columns = [
    {
      title: "Name",
      render: (record) => record.name,
      key: "name",
      filterDropdown: (
        <div className="custom-filter-dropdown">
          <Input
            placeholder="Search name"
            value={searchText}
            onChange={onInputChange}
          />
        </div>
      ),
      filterDropdownVisible: filterDropdownVisible,
      onFilterDropdownVisibleChange: (visible) =>
        setFilterDropdownVisible(visible),
    },
    {
      title: "Description",
      render: (record) => record.description,
      key: "description",
    },

    {
      title: <span>Action</span>,
      key: "id",
      render: (record) => (
        <div>
          <PermissionsGuard
            permissions={permissions}
            perform={"roles:edit"}
            yes={() => (
              <EditOutlined title="Edit" onClick={() => showModal(record)} />
            )}
          />
          {"  "}
          <PermissionsGuard
            permissions={permissions}
            perform={"roles:delete"}
            yes={() => (
              <DeleteOutlined
                title="Delete"
                onClick={() => _deleteRole(record.id)}
              />
            )}
          />
        </div>
      ),
    },
  ];

  const showModal = (e) => {
    setSelectedRoles(e);
    setModalVisible(true);
  };

  const handleCreate = async () => {
    const values = await form.validateFields();

    setShowSpin(true);

    //submit data
    const createUpdateMutation = values.id ? updateRoles : createRoles;

    createUpdateMutation({
      variables: {
        id: values.id,
        name: values.name,
        description: values.description,
        permissions: values.permissions,
      },
    })
      .then(() => {
        refetch().then((res) => setRolesList(res.data.userTypesList)); // refetch updated role list
        setShowSpin(false);
        openNotificationWithIcon(
          "success",
          "Update success ",
          "Role updated successfully"
        );
      })
      .catch((error) => {
        setShowSpin(false);
        openNotificationWithIcon("error", "Update Error ", error.message);
      });

    form.resetFields();
    setModalVisible(false);
  };

  const ModalProps = {
    visible: modalVisible,
    onCancel: () => setModalVisible(false),
    onCreate: () => handleCreate(),
    initialData: selectedRoles,
    form,
  };

  const { loading, error, data, refetch } = useQuery(GET_USER_TYPES, {
    pollInterval: 600000,
    onCompleted: (data) => {
      setRolesList(data.userTypesList);
      setConstantRolesList(data.userTypesList);
    },
  });

  if (loading) return <TableLoader />;
  if (error) return <InternalErrorPage />;

  if (isEmpty(data.userTypesList))
    return (
      <div className="col-sm-12 col-md-12 placeholder-table-card">
        <Table />
      </div>
    );

  return (
    <PermissionsGuard
      permissions={permissions}
      perform={"roles:list"}
      yes={() => (
        <>
          <Spin
            tip="Loading..."
            className="loader"
            style={{ display: showSpin ? "block" : "none" }}
          />
          <div className="col-sm-12 col-md-12 placeholder-table-card">
            <Card style={{ height: "14vh" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Card.Meta
                  title={"Platform User Role Management"}
                  description={
                    <p style={{ height: "fit-content" }}>
                      Manage Councilsmart Platform user roles and permissions
                    </p>
                  }
                />
                <PermissionsGuard
                  permissions={permissions}
                  perform={"roles:create"}
                  yes={() => (
                    <Button
                      type="primary"
                      style={{ alignSelf: "auto" }}
                      onClick={() => showModal(undefined)}
                    >
                      Add a Role
                    </Button>
                  )}
                />
              </div>
            </Card>
          </div>
          <div className="col-sm-12 col-md-12 placeholder-table-card">
            <Card>
              <Table
                columns={columns}
                pagination={{ pageSize: 10, showSizeChanger: false }}
                expandedRowRender={(record: any) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Table
                      columns={[
                        {
                          title: "Permission Name",
                          render: (record) => record.name,
                          key: "name",
                        },
                        {
                          title: "Permission Description",
                          render: (record) => record.description,
                          key: "description",
                        },
                      ]}
                      pagination={false}
                      dataSource={record.permissions}
                      rowKey="companyName"
                    />
                  </div>
                )}
                dataSource={rolesList}
                rowKey="id"
              />
            </Card>
          </div>
          <EditRolesForm {...ModalProps} />
        </>
      )}
      no={() => <AccessDenied permission={"roles:list"} />}
    />
  );
}

export default RolesList;
