import { gql } from "@apollo/client";

export const REMOVE_USER_EMPLOYMENT = gql`
  mutation deleteEmployment($input: RemoveEmploymentInput!) {
    deleteEmployment(input: $input)
  }
`;

export const UPSERT_USER_EMPLOYMENT = gql`
  mutation upsertUserEmployment($input: UpsertUserEmploymentInput!) {
    upsertUserEmployment(input: $input) {
      id
      councilMembershipNumber
    }
  }
`;

export const GENERATE_MEMBERSHIP_NUMBER = gql`
  mutation generateCouncilMembershipNumber($input: UpsertUserEmploymentInput!) {
    generateCouncilMembershipNumber(input: $input) {
      id
      councilMembershipNumber
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
    }
  }
`;

export const MARK_APPLICATION_AS_VERIFIED = gql`
  mutation markApplicationAsVerified($id: Int!) {
    markApplicationAsVerified(id: $id) {
      id
    }
  }
`;

export const INVITE_USER_SIGN_UP = gql`
  mutation inviteUserSignUp($email: String!) {
    inviteUserSignUp(email: $email) {
      message
    }
  }
`;

export const INVITE_TEAM_MEMBER = gql`
  mutation inviteTeamMember($input: InviteTeamMemberInput!) {
    inviteTeamMember(input: $input)
  }
`;

export const BULK_UPDATE_USERS = gql`
  mutation bulkUpdateUsers(
    $users: [Int!]
    $userTypes: [Int]
    $userStatus: String
  ) {
    bulkUpdateUsers(
      users: $users
      userTypes: $userTypes
      userStatus: $userStatus
    )
  }
`;

export const UPDATE_ROLE = gql`
  mutation updateUserRole(
    $id: Int!
    $name: String!
    $description: String!
    $permissions: [Int]
  ) {
    updateUserRole(
      id: $id
      name: $name
      description: $description
      permissions: $permissions
    ) {
      id
    }
  }
`;

export const CREATE_ROLE = gql`
  mutation createUserRole(
    $name: String!
    $description: String!
    $permissions: [Int]
  ) {
    createUserRole(
      name: $name
      description: $description
      permissions: $permissions
    ) {
      id
    }
  }
`;

export const UPDATE_PERMISSIONS = gql`
  mutation updatePermissions(
    $id: Int!
    $name: String!
    $description: String!
    $userRole: [Int]
  ) {
    updatePermissions(
      id: $id
      name: $name
      description: $description
      userRole: $userRole
    ) {
      id
    }
  }
`;

export const CREATE_PERMISSIONS = gql`
  mutation createPermissions(
    $name: String!
    $description: String!
    $userRole: [Int]
  ) {
    createPermissions(
      name: $name
      description: $description
      userRole: $userRole
    ) {
      id
    }
  }
`;

export const DELETE_PERMISSIONS = gql`
  mutation deletePermissions($id: Int!) {
    deletePermissions(id: $id)
  }
`;

export const DELETE_ROLE = gql`
  mutation deleteUserRole($id: Int!) {
    deleteUserRole(id: $id)
  }
`;

export const SUBMIT_SUPPORT_TICKET = gql`
  mutation createSupportTicket(
    $email: String!
    $subject: String!
    $message: String!
  ) {
    createSupportTicket(email: $email, subject: $subject, message: $message)
  }
`;
export const UPDATE_BUSINESS_UNIT = gql`
  mutation updateBusinessUnit(
    $id: Int!
    $name: String!
    $description: String!
  ) {
    updateBusinessUnit(id: $id, name: $name, description: $description) {
      id
    }
  }
`;

export const CREATE_BUSINESS_UNIT = gql`
  mutation createBusinessUnit($name: String!, $description: String!) {
    createBusinessUnit(name: $name, description: $description) {
      id
    }
  }
`;

export const DELETE_BUSINESS_UNIT = gql`
  mutation deleteBusinessUnit($id: Int!) {
    deleteBusinessUnit(id: $id)
  }
`;

export const UPDATE_GUILD = gql`
  mutation updateGuild(
    $id: Int!
    $name: String!
    $address: String!
    $timeZone: String!
    $projects: [Int]
  ) {
    updateGuild(
      id: $id
      name: $name
      address: $address
      timeZone: $timeZone
      projects: $projects
    ) {
      id
    }
  }
`;

export const CREATE_GUILD = gql`
  mutation createGuild(
    $name: String!
    $address: String!
    $timeZone: String!
    $projects: [Int]
  ) {
    createGuild(
      name: $name
      address: $address
      timeZone: $timeZone
      projects: $projects
    ) {
      id
    }
  }
`;

export const DELETE_GUILD = gql`
  mutation deleteGuild($id: Int!) {
    deleteGuild(id: $id)
  }
`;

export const DELETE_USER = gql`
  mutation removeUser($id: Int!) {
    removeUser(userId: $id) {
      success
    }
  }
`;

export const DELETE_COMPANY = gql`
  mutation removeCompany($id: Int!) {
    removeCompany(companyId: $id) {
      success
    }
  }
`;

export const ARCHIVE_COMPANY = gql`
  mutation archiveCompany($id: Int!) {
    archiveCompany(companyId: $id) {
      success
    }
  }
`;

export const DELETE_TASK = gql`
  mutation removeTask($id: Int!) {
    removeTask(taskId: $id) {
      success
    }
  }
`;

export const CREATE_COMPANY = gql`
  mutation createCompany(
    $name: String!
    $legalName: String!
    $logo: String!
    $website: String
    $email: String!
    $country: String!
    $timeZone: String!
    $currency: String
    $status: String!
    $vat: String
    $registrationNumber: String
    $users: [Int]
    $companyAdmin: [Int]
    $referredBy: Int
    $sovtechAccountOwner: Int
    $billingAddress: String!
    $companyBankingDetails: String!
    $consentGiven: Boolean
    $dateConsentGiven: Date
    $companySize: String!
  ) {
    createCompany(
      name: $name
      legalName: $legalName
      logo: $logo
      website: $website
      email: $email
      country: $country
      timeZone: $timeZone
      currency: $currency
      status: $status
      vat: $vat
      registrationNumber: $registrationNumber
      users: $users
      companyAdmin: $companyAdmin
      referredBy: $referredBy
      sovtechAccountOwner: $sovtechAccountOwner
      billingAddress: $billingAddress
      companyBankingDetails: $companyBankingDetails
      consentGiven: $consentGiven
      dateConsentGiven: $dateConsentGiven
      companySize: $companySize
    ) {
      id
    }
  }
`;

export const UPDATE_COMPANY = gql`
  mutation updateCompany(
    $id: Int!
    $name: String!
    $legalName: String!
    $logo: String!
    $website: String
    $email: String!
    $country: String!
    $timeZone: String!
    $currency: String
    $status: String!
    $vat: String
    $registrationNumber: String
    $users: [Int]
    $companyAdmin: [Int]
    $referredBy: Int
    $sovtechAccountOwner: Int
    $billingAddress: String!
    $companyBankingDetails: String!
    $consentGiven: Boolean
    $dateConsentGiven: Date
    $companySize: String!
  ) {
    updateCompany(
      id: $id
      name: $name
      legalName: $legalName
      logo: $logo
      website: $website
      email: $email
      country: $country
      timeZone: $timeZone
      currency: $currency
      status: $status
      vat: $vat
      registrationNumber: $registrationNumber
      users: $users
      companyAdmin: $companyAdmin
      referredBy: $referredBy
      sovtechAccountOwner: $sovtechAccountOwner
      billingAddress: $billingAddress
      companyBankingDetails: $companyBankingDetails
      consentGiven: $consentGiven
      dateConsentGiven: $dateConsentGiven
      companySize: $companySize
    ) {
      id
    }
  }
`;

export const ADMIN_RESET_USER_PASSWORD = gql`
  mutation adminResetUserPassword($username: String!) {
    adminResetUserPassword(username: $username)
  }
`;

export const PROMOTE_TO_COMPANY_ADMIN = gql`
  mutation promoteToCompanyAdmin($userId: Int!, $companyId: Int!) {
    promoteToCompanyAdmin(userId: $userId, companyId: $companyId) {
      success
    }
  }
`;

export const CHANGE_USER_STATUS = gql`
  mutation changeUserStatus($input: ChangeUserStatusInput!) {
    changeUserStatus(input: $input) {
      success
    }
  }
`;

export const CHANGE_MEMBERSHIP_NUMBER = gql`
  mutation changeMembershipNumber($input: ChangeMembershipNumberInput!) {
    changeMembershipNumber(input: $input) {
      success
    }
  }
`;

export const UPDATE_CERTIFICATE = gql`
  mutation updateCertificate($input: UpdateCertificateInput!) {
    updateCertificate(input: $input) {
      id
    }
  }
`;

export const BLACKLIST_ENTITY = gql`
  mutation blacklistEntity($input: BlackListEntityInput!) {
    blacklistEntity(input: $input) {
      id
    }
  }
`;

export const UN_BLACKLIST_ENTITY = gql`
  mutation unBlacklistEntity($id: Int!, $type: String!) {
    unBlacklistEntity(id: $id, type: $type)
  }
`;

export const CREATE_FINANCIAL_TASK = gql`
  mutation createFinancialTask($input: CreateFinancialTaskInput!) {
    createFinancialTask(input: $input) {
      id
    }
  }
`;

export const CHANGE_COMPANY_STATUS = gql`
  mutation changeCompanyStatus($input: ChangeCompanyStatusInput!) {
    changeCompanyStatus(input: $input) {
      success
    }
  }
`;

export const CHANGE_USER_CAPACITY = gql`
  mutation changeUserCapacity($input: ChangeUserCapacityInput!) {
    changeUserCapacity(input: $input) {
      success
    }
  }
`;

export const POPULATE_REPORT = gql`
  mutation populateReport($input: PopulateReportInput!) {
    populateReport(input: $input)
  }
`;

export const REFRESH_SUBLIST = gql`
  mutation refreshSublist($input: RefreshSublistInput!) {
    refreshSublist(input: $input)
  }
`;

export const CREATE_MANUAL_INVOICE = gql`
  mutation createManualInvoice($input: CreateManualInvoiceInput!) {
    createManualInvoice(input: $input) {
      sageInvoiceId
      result
    }
  }
`;
