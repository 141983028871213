import { useState, useEffect, useRef } from "react";
import { Card, Table, Button, Spin, Divider } from "antd";
import moment from "moment";
import { useLazyQuery } from "@apollo/client";
import { GET_FILTERED_REMOVED_EMPLOYMENTS_LIST } from "../../graphql/queries";
import EmployeeFilters from "./RemovedEmployeesFilters";
import { TableLoader } from "../../components/UserManagement/TableContentPlaceholder";
import InternalErrorPage from "../InternalErrorPage";
import EmptyState from "../../components/Styled/EmptyState";

const isActiveMultiFilter = (multiFilter) => {
  let activeStatus = false;
  for (let filterKey of Object.keys(multiFilter)) {
    if (multiFilter[filterKey]) {
      activeStatus = true;
    }
  }
  return activeStatus;
};

const defaultMultiFilter = {
  userId: false,
  capacityId: false,
  removalReason: false,
  councilMembershipNumber: false,
  identity: false,
  companyId: false,
  sort: "DESC",
};

const CompanyEmployeesList: React.FC<any> = ({
  companyId = false,
  mode,
  history,
}) => {
  const [removedEmploymentList, setRemovedEmploymentList] = useState([]);
  const [listTotal, setListTotal] = useState(0);
  const [showEmptyState, setShowEmptyState] = useState(true);
  const [skip, setSkip] = useState(0);
  const [initialising, setInitialising] = useState(true);
  const [showSpin, setShowSpin] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [multiFilter, setMultiFilter] = useState(
    Object.assign({}, defaultMultiFilter, {
      companyId: companyId,
    })
  );
  const [useMode] = useState(mode);
  const pageSize = 10;

  const handlePagination = (page) => {
    setSkip((page - 1) * pageSize);
    setCurrentPage(page);
  };

  const filtersRef = useRef();

  const clearFilters = () => {
    if (filtersRef && filtersRef.current) {
      // @ts-ignore: Object is possibly undefined
      filtersRef.current.resetFilters();
      let newMultiFilter = Object.assign(
        {},
        defaultMultiFilter,
        typeof companyId === "number" ? { companyId: companyId } : {}
      );
      setMultiFilter(newMultiFilter);
      setSkip(0);
      setCurrentPage(1);
      runMultiFilterEmployeeQuery(newMultiFilter, 0, true);
    }
  };

  const [getFilteredRemovedEmploymentList] = useLazyQuery(
    GET_FILTERED_REMOVED_EMPLOYMENTS_LIST,
    {
      onCompleted: (data) => {
        setRemovedEmploymentList(
          data.getRemovedEmploymentsByFilter.removedEmploymentList
        );
        if (data.getRemovedEmploymentsByFilter.total) {
          setListTotal(data.getRemovedEmploymentsByFilter.total);
          if (showEmptyState) setShowEmptyState(false);
        }
        setLoading(false);
        setInitialising(false);
        setShowSpin(false);
      },
      onError: (error) => {
        setError(error.message);
      },
      fetchPolicy: "network-only",
    }
  );

  const [getFilteredEmployeeInvitationsListNoCache] = useLazyQuery(
    GET_FILTERED_REMOVED_EMPLOYMENTS_LIST,
    {
      onCompleted: (data) => {
        setRemovedEmploymentList(
          data.getRemovedEmploymentsByFilter.removedEmploymentList
        );
        if (data.getRemovedEmploymentsByFilter.total) {
          setListTotal(data.getRemovedEmploymentsByFilter.total);
          if (showEmptyState) setShowEmptyState(false);
        }
        setLoading(false);
        setInitialising(false);
        setShowSpin(false);
      },
      onError: (error) => {
        setError(error.message);
      },
      fetchPolicy: "network-only",
    }
  );

  const runMultiFilterEmployeeQuery = (
    useMultiFilter,
    useSkip,
    skipCache = false
  ) => {
    setShowSpin(true);
    setLoading(true);
    let postFilters = {};
    for (let [key, value] of Object.entries(useMultiFilter)) {
      if (value) {
        postFilters[key] = value;
      }
    }
    if (!skipCache) {
      getFilteredRemovedEmploymentList({
        variables: {
          input: {
            ...postFilters,
            includeTotal: useSkip === 0 ? true : false,
            skip: useSkip,
            take: pageSize,
          },
        },
      });
    } else {
      getFilteredEmployeeInvitationsListNoCache({
        variables: {
          input: {
            ...postFilters,
            includeTotal: useSkip === 0 ? true : false,
            skip: useSkip,
            take: pageSize,
          },
        },
      });
    }
  };

  const handleEmployeeFilterChange = (value, filterType, reset) => {
    let newMultiFilter = Object.assign(multiFilter, { [filterType]: value });
    setMultiFilter(newMultiFilter);
    if (newMultiFilter && isActiveMultiFilter(newMultiFilter)) {
      setSkip(0);
      setCurrentPage(1);
      runMultiFilterEmployeeQuery(newMultiFilter, 0);
    }
  };

  useEffect(() => {
    if (companyId) {
      let newMultiFilter = Object.assign(
        {},
        multiFilter,
        typeof companyId === "number" ? { companyId: companyId } : {}
      );
      runMultiFilterEmployeeQuery(newMultiFilter, skip);
    }
  }, [currentPage, skip, companyId]);

  const columns = [
    { title: "User ID", render: (record) => record.userId, key: "userId" },
    {
      title: "Identity/Passport Number",
      render: (record) => record.identity,
      key: "identity",
    },
    {
      title: "Council Membership Number",
      render: (record) => record.councilMembershipNumber,
      key: "councilMembershipNumber",
    },
    {
      title: "Capacity",
      render: (record) => record.capacity.name,
      key: "capacity",
    },
    {
      title: "Removal Reason",
      dataIndex: "removalReason",
      key: "removalReason",
    },
    {
      title: "Date Removed",
      dataIndex: "dateRemoved",
      key: "dateRemoved",
      render: (record) => (record ? moment(record).format("YYYY-MM-DD") : ""),
    },
  ];

  if (error) {
    return <InternalErrorPage error={error} />;
  }

  return (
    <>
      <Spin
        tip="Loading..."
        className="loader"
        style={{ display: showSpin ? "block" : "none" }}
      />
      {!initialising && showEmptyState && (
        <EmptyState
          location="team"
          headingText="Nothing to see here"
          bodyText="You currently have no employee invitations. Feel free to create an invitation or alternatively create a ticket to our support team to help with any problems you may be facing."
        ></EmptyState>
      )}
      {initialising && <TableLoader />}
      {!showEmptyState && (
        <div className="col-sm-12 col-md-12 placeholder-table-card">
          <Card>
            <div className="card-header">
              <h3>Employee Invitation Management</h3>
              <div>
                <Button
                  style={{ marginRight: "20px" }}
                  className="purple-button"
                  onClick={clearFilters}
                >
                  Clear filters
                </Button>
              </div>
            </div>
            <div className={loading ? "no-interact" : null}>
              {!initialising && (
                <EmployeeFilters
                  ref={filtersRef}
                  setError={setError}
                  handleEmployeeFilterChange={handleEmployeeFilterChange}
                  defaultMultiFilter={defaultMultiFilter}
                  mode={useMode}
                />
              )}
              <Divider />
              <Table
                rowKey="userId"
                columns={columns}
                pagination={{
                  current: currentPage,
                  showSizeChanger: false,
                  pageSize,
                  onChange: (page) => handlePagination(page),
                  total: listTotal,
                  showTotal: () => <h3>Total: {listTotal}</h3>,
                }}
                dataSource={removedEmploymentList}
              />
            </div>
          </Card>
        </div>
      )}
    </>
  );
};

export default CompanyEmployeesList;
