import {
  useCallback,
  Fragment,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { TASK_STATUS_TYPES } from "../../constants";
import { useGlobalState } from "../../globalStore";
import { Input, Row, Col, Select } from "antd";
import debounce from "lodash/debounce";
import { useFilterState } from "@/contexts/AdminFilterContext";

const { Option } = Select;

export const TaskFilters = ({ defaultMultiFilter }, ref) => {
  const { state } = useGlobalState();
  const taskIdFilterRef = useRef();
  const taskTypeFilterRef = useRef();
  const taskStatusFilterRef = useRef();

  const {
    filters: { filter },
    saveFilter,
    filterReset,
  } = useFilterState();

  const taskTypeList = state?.lookups?.taskTypes || [];

  useImperativeHandle(ref, () => ({
    // @ts-ignore: function called from parent

    resetFilters() {
      filterReset();

      resetFilterCollection([
        {
          ref: taskIdFilterRef,
          defaultValue: defaultMultiFilter["taskId"],
        },
        {
          ref: taskTypeFilterRef,
          defaultValue: defaultMultiFilter["taskTypeId"],
        },
        {
          ref: taskStatusFilterRef,
          defaultValue: defaultMultiFilter["taskStatus"],
        },
      ]);
    },
  }));

  const resetFilterCollection = (refMetaCollection = []) => {
    for (let refMeta of refMetaCollection) {
      if (refMeta && refMeta.ref && refMeta.ref.current) {
        let currentRef = refMeta.ref.current;
        let useDefaultValue = refMeta.defaultValue
          ? refMeta.defaultValue
          : null;
        if (currentRef.rcSelect) {
          currentRef.rcSelect.setState({ value: [useDefaultValue] });
        } else if (currentRef.picker) {
          currentRef.picker.setState({
            value: useDefaultValue,
            showDate: useDefaultValue,
          });
        } else if (currentRef.input) {
          currentRef.input.value = useDefaultValue;
        }
      }
    }
  };

  const handleTaskFilterUpdate = (data) => {
    if (data * 1 >= 0) {
      const taskId = data * 1;
      saveFilter("taskId", taskId);
    }
  };

  const debouncedTaskIdFilterUpdate = useCallback(
    debounce(handleTaskFilterUpdate, 250),
    []
  );

  function handleTaskStatusFilterUpdate(value: any) {
    saveFilter("taskStatus", value);
  }

  function handleTaskTypeFilterUpdate(value: any) {
    saveFilter("taskTypeId", value);
  }

  const taskStatusTypeOptions = TASK_STATUS_TYPES.map((x) => {
    return (
      <Option key={x.name} value={x.name}>
        {x.name}
      </Option>
    );
  });

  return (
    <Fragment>
      <Row gutter={[24, 24]}>
        <Col span={8}>
          <div className="flex-center-space-between">
            <label>Task ID:</label>
            <Input
              ref={taskIdFilterRef}
              type="number"
              defaultValue={
                filter?.taskId ? filter?.taskId.toString() : undefined
              }
              placeholder="Search task by ID"
              style={{ width: "calc(100% - 75px)" }}
              onChange={(event) => {
                debouncedTaskIdFilterUpdate(event.target.value);
              }}
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="flex-center-space-between">
            <label>Type:</label>
            <Select
              ref={taskTypeFilterRef}
              placeholder="Select task type"
              defaultValue={filter?.taskTypeId || undefined}
              onChange={handleTaskTypeFilterUpdate}
              style={{ width: "calc(100% - 75px)" }}
              {...(defaultMultiFilter.taskTypeId
                ? { defaultValue: defaultMultiFilter.taskTypeId }
                : {})}
            >
              {taskTypeList.map((x) => {
                return (
                  <Option key={x.name} value={x.id}>
                    {x.name}
                  </Option>
                );
              })}
            </Select>
          </div>
        </Col>
        <Col span={8}>
          <div className="flex-center-space-between">
            <label>Status:</label>
            <Select
              ref={taskStatusFilterRef}
              placeholder="Please select task status"
              onChange={handleTaskStatusFilterUpdate}
              style={{ width: "calc(100% - 75px)" }}
            >
              {taskStatusTypeOptions}
            </Select>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

export default forwardRef(TaskFilters);
