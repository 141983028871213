import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export function RedirectComponent({ batchRegistrationId, applicationId }) {
  const navigate = useNavigate();

  useEffect(() => {
    const path =
      batchRegistrationId && applicationId
        ? `/register/batch-registration/${batchRegistrationId}/create-task/${applicationId}`
        : "/register/results";
    navigate(path);
  }, [batchRegistrationId, applicationId, navigate]);

  return null;
}
