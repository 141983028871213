import { useState } from "react";
import { Input, Button, Form, Skeleton } from "antd";
import { useMutation } from "@apollo/client";

import { UPDATE_COMPANY } from "@/graphql/mutations";
import { notifyError, openNotificationWithIcon } from "@/utils/notification";

interface IProps {
  companyDetails?: any;
}

export function EditBusinessDetailsForm({ companyDetails }: IProps) {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    membershipNumber,
    natureOfBusiness,
    registeredName,
    tradingName,
    tradingAs,
    registeredNumber,
    vatNumber,
    id,
  } = companyDetails;

  const [updateCompany] = useMutation(UPDATE_COMPANY, {
    onCompleted: () => {
      setIsSubmitting(false);
      openNotificationWithIcon("success", "Success", "Company details updated");
    },
    onError: (err) => {
      setIsSubmitting(false);
      notifyError(err);
    },
  });

  const handleSubmit = async (values: any) => {
    setIsSubmitting(true);
    updateCompany({
      variables: {
        input: { ...values, id },
      },
    });
  };

  if (isSubmitting) {
    return (
      <Skeleton
        active
        paragraph={{
          rows: 6,
        }}
      />
    );
  }

  return (
    <div className="flex-column">
      <Form
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        onFinishFailed={() => {
          notifyError("Please fill in all required fields");
        }}
      >
        <div className="input-block-wrapper">
          <div className="flex-column input-block full-width">
            <Form.Item
              label="Membership Number"
              {...{
                name: "membershipNumber",
                initialValue: membershipNumber ?? "",
                rules: [
                  {
                    required: true,
                    message:
                      "Please enter the  membership number of the company",
                  },
                ],
              }}
            >
              <Input placeholder="Please enter the membership number of the company" />
            </Form.Item>
          </div>
          <div className="flex-column input-block">
            <Form.Item
              label="Registered Name"
              {...{
                name: "registeredName",
                initialValue: registeredName ?? "",
                rules: [
                  {
                    required: true,
                    message: "Please enter the registered name of the company",
                  },
                ],
              }}
            >
              <Input placeholder="Please enter the registered name of the company" />
            </Form.Item>
          </div>
          <div className="flex-column input-block">
            <Form.Item
              label="Trading Name"
              {...{
                name: "tradingName",
                initialValue: tradingName ?? "",
                rules: [
                  {
                    required: true,
                    message: "Please enter the trading name of the company",
                  },
                ],
              }}
            >
              <Input placeholder="Please enter the trading name of the company" />
            </Form.Item>
          </div>
          <div className="flex-column input-block">
            <Form.Item
              label="Nature of Business"
              {...{
                name: "natureOfBusiness",
                initialValue: natureOfBusiness ?? "",
                rules: [
                  {
                    required: true,
                    message: "Please enter the nature of business",
                  },
                ],
              }}
            >
              <Input placeholder="Please enter the nature of business" />
            </Form.Item>
          </div>
          <div className="flex-column input-block">
            <Form.Item
              label="Trading As"
              {...{
                name: "tradingAs",
                initialValue: tradingAs ?? "",
                rules: [
                  {
                    required: false,
                    message: "Please enter the trading as",
                  },
                ],
              }}
            >
              <Input placeholder="Please enter the trading as" />
            </Form.Item>
          </div>
          <div className="flex-column input-block">
            <Form.Item
              label="VAT Number"
              {...{ name: "vatNumber", initialValue: vatNumber ?? "" }}
            >
              <Input placeholder="Please enter the VAT number" />
            </Form.Item>
          </div>
          <div className="flex-column input-block">
            <Form.Item
              label="Registration Number"
              {...{
                name: "registeredNumber",
                initialValue: registeredNumber ?? "",
                rules: [
                  {
                    required: true,
                    message: "Please enter the company registration number",
                  },
                ],
              }}
            >
              <Input placeholder="Please enter the company registration number" />
            </Form.Item>
          </div>

          <div className="flex-column full-width ">
            <Button
              className="purple-button self-end"
              htmlType="submit"
              loading={isSubmitting}
            >
              Save information
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
}
