import { useState, useEffect, useRef } from "react";
import {
  DownOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import {
  Card,
  Table,
  Button,
  Spin,
  Divider,
  Badge,
  Menu,
  Dropdown,
  Tooltip,
  Alert,
  Form,
} from "antd";
import { format, isAfter, parseISO, isBefore } from "date-fns";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { GET_FILTERED_EMPLOYMENT_LIST } from "../../../graphql/queries";
import {
  GET_MEMBER_OVERVIEW_INFORMATION,
  GENERATE_MEMBERSHIP_CARD,
} from "../../../containers/Admin/graphql/queries";
import {
  REMOVE_USER_EMPLOYMENT,
  CHANGE_USER_STATUS,
  CHANGE_USER_CAPACITY,
  PROMOTE_TO_COMPANY_ADMIN,
} from "../../../containers/Admin/graphql/mutations";
import {
  notifyError,
  notifyInfo,
  notifySuccess,
  openNotificationWithIcon,
} from "@/utils/notification";
import EmployeeFilters from "./filters";
import RemoveEmployeeModal from "../../Employees/RemoveEmployeeModal";
import ChangeMemberStatusModal from "../../Employees/ChangeMemberModal";
import { TableLoader } from "../../../components/UserManagement/TableContentPlaceholder";
import InternalErrorPage from "../../InternalErrorPage";
import EmptyState from "../../../components/Styled/EmptyState";
import {
  ADMIN_STREAMS,
  DEREGISTERED_COMPANY,
  MANUAL_REGISTRATION_ROLES,
} from "../../../constants";
import CompanyRepModal from "../Representatives/companyRepModal";
import { useRegistrationState } from "@/stores/registration";
import { CREATE_BATCH_REGISTRATION } from "@/graphql/mutations";
import { useUserState } from "@/stores/user";
import { Employment } from "@/typings/types";
import { downloadPdfBuffer } from "@/utils";

const isActiveMultiFilter = (multiFilter) => {
  let activeStatus = false;
  for (let filterKey of Object.keys(multiFilter)) {
    if (multiFilter[filterKey]) {
      activeStatus = true;
    }
  }
  return activeStatus;
};

const defaultMultiFilter = {
  username: false,
  status: false,
  businessUnitId: false,
  capacityId: false,
  companyId: false,
  sort: "DESC",
};

type TypeOfChange = "status" | "capacity";

type ChangeUserResponse = {
  success: boolean;
};

type Props = {
  companyId?: number;
  mode: "admin" | "member";
};

function CompanyEmployeesList({ companyId, mode }: Props) {
  const [removeEmpForm] = Form.useForm();
  const navigate = useNavigate();
  const { batchRegistrationId, manualRegistrationType, setRegistrationState } =
    useRegistrationState();
  const { userRoles, activeUserRole } = useUserState();
  const { pendingApplications } = useUserState();
  const [employmentList, setEmployeeList] = useState([]);
  const [listTotal, setListTotal] = useState(0);
  const [showEmptyState, setShowEmptyState] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [skip, setSkip] = useState(0);
  const [initialising, setInitialising] = useState(true);
  const [selectedEmployee, setSelectedEmployee] = useState<any>();
  const [showSpin, setShowSpin] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [newRepInformation, setNewRepInformation] = useState<any>();
  const [representative, setRepresentative] = useState<any>();
  const [multiFilter, setMultiFilter] = useState(
    Object.assign({}, defaultMultiFilter, {
      companyId: companyId,
    })
  );
  const [generatingCard, setGeneratingCard] = useState(false);
  const [useMode] = useState(mode);
  const pageSize = 10;
  const [employeeDetails, setEmployeeDetails] = useState<any>();
  const [employment, setEmployment] = useState([]);
  const [isLoadingEmployeeDetails, setIsLoadingEmployeeDetails] =
    useState(true);
  const [changeMemberModalVisible, setChangeMemberModalVisible] =
    useState(false);
  const [typeOfChange, setTypeOfChange] = useState<TypeOfChange>("status");
  const [isRemovingEmployment, setIsRemovingEmployment] = useState(false);
  const userId = localStorage.getItem("userId");
  const companyStatus = localStorage.getItem("companyStatus");

  const userRole = userRoles.find((role) => role.id === activeUserRole);

  const filterInactiveEmployees = (employeeList: any[]) => {
    if (companyStatus !== DEREGISTERED_COMPANY) {
      return { list: employeeList, count: employeeList.length };
    }

    const list = employeeList.filter((e) => {
      if (e.user.certificates.length === 0) return false;
      return e.user.certificates.some(({ toDate }) =>
        isBefore(new Date(), parseISO(toDate))
      );
    });

    return { list, count: list.length };
  };

  const changeUserOpts = {
    onCompleted: () => {
      openNotificationWithIcon(
        "success",
        "Success",
        "Successfully updated the user details"
      );
      setChangeMemberModalVisible(false);
      setIsLoadingEmployeeDetails(false);
      clearFilters();
    },
    onError: (err: any) => {
      setIsLoadingEmployeeDetails(false);
      notifyError(err);
    },
  };

  const [generateCard] = useLazyQuery(GENERATE_MEMBERSHIP_CARD);

  const [changeStatus] = useMutation<ChangeUserResponse>(
    CHANGE_USER_STATUS,
    changeUserOpts
  );

  const [changeCapacity] = useMutation<ChangeUserResponse>(
    CHANGE_USER_CAPACITY,
    changeUserOpts
  );

  const [createBatchRegistration, { loading: loadingBatch }] = useMutation(
    CREATE_BATCH_REGISTRATION
  );

  const [promiteToCompanyAdmin, { loading: promoting }] = useMutation(
    PROMOTE_TO_COMPANY_ADMIN,
    {
      onError: (err) => {
        notifyError(err);
      },
      onCompleted: () => {
        notifySuccess("User promoted to company admin");
      },
    }
  );

  const [removeUserEmployment] = useMutation(REMOVE_USER_EMPLOYMENT, {
    onCompleted: () => {
      openNotificationWithIcon(
        "success",
        "Delete operation successful",
        "User removed"
      );
      setIsRemovingEmployment(false);
      setModalVisible(false);
      let newMultiFilter = Object.assign(
        {},
        defaultMultiFilter,
        typeof companyId === "number" ? { companyId: companyId } : {}
      );
      setMultiFilter(newMultiFilter);
      setSkip(0);
      setCurrentPage(1);
      setShowEmptyState(false);
      return runMultiFilterEmployeeQuery(newMultiFilter, 0);
    },
    onError: (err) => {
      notifyError(err);
      setModalVisible(false);
      setIsRemovingEmployment(false);
    },
  });
  const [fetchMemberOverviewInformation] = useLazyQuery(
    GET_MEMBER_OVERVIEW_INFORMATION,
    {
      variables: {
        userId: selectedEmployee?.user.id,
      },
      onCompleted: (data) => {
        if (data.userData?.employment) {
          const employmentDetails = data.userData.employment?.filter(
            (employ) => employ.company.id === companyId
          );
          setEmployment(employmentDetails);
        }
        setEmployeeDetails(data.userData);
        setIsLoadingEmployeeDetails(false);
      },
      onError: (err) => {
        notifyError(err);
        setModalVisible(false);
      },
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    setIsLoadingEmployeeDetails(true);
    if (selectedEmployee) fetchMemberOverviewInformation();
  }, [selectedEmployee]);

  const handleRemoveEmployee = async () => {
    setIsRemovingEmployment(true);
    const values = await removeEmpForm.validateFields().catch((err) => {});
    if (!values) {
      notifyError("Please provide a reason for removal");
    }

    await removeUserEmployment({
      variables: {
        input: {
          id: employment[0]?.id,
          reason: values.removalReason,
        },
      },
    });
  };

  const handleCancelModal = () => {
    setModalVisible(false);
  };

  const handleOpenModal = (record: any) => {
    setSelectedEmployee(record);
    setModalVisible(true);
  };

  const handleOpenChangeMemberModal = (record, type: TypeOfChange) => {
    setTypeOfChange(type);
    setSelectedEmployee(record);
    setChangeMemberModalVisible(true);
  };

  const generateMembershipCard = (record: Employment) => {
    setGeneratingCard(true);
    notifyInfo(
      `Generating membership card for ${record.user.lastName} ${record.user.firstName}. Card will be downloaded automatically once ready. Please use the employee's ID number as the password to open the PDF file.`,
      120
    );
    generateCard({
      variables: {
        userId: record.user.id,
        companyId,
      },
      onCompleted: ({ generateMembershipCard }) => {
        downloadPdfBuffer(
          generateMembershipCard,
          `${record.user.lastName}-${record.user.firstName}-membership-card.pdf`
            .toLowerCase()
            .replace(/\ /g, "-")
        );
        setGeneratingCard(false);
      },
      onError: (err) => {
        setGeneratingCard(false);
        notifyError(err);
      },
    });
  };

  const RemoveUserModalProps = {
    form: removeEmpForm,
    visible: modalVisible,
    companyId,
    modalTitle: "Remove Employee",
    actionText: "Remove",
    employment,
    isLoadingEmployeeDetails,
    isRemovingEmployment,
    employeeDetails,
    onCancel: () => handleCancelModal(),
    onAction: () => handleRemoveEmployee(),
  };

  const changeMemberProps = {
    visible: changeMemberModalVisible,
    typeOfChange,
    employeeDetails,
    setLoading: setIsLoadingEmployeeDetails,
    loading: isLoadingEmployeeDetails,
    onCancel: () => setChangeMemberModalVisible(false),
    changeStatus,
    changeCapacity,
  };

  const handlePagination = (page) => {
    setSkip((page - 1) * pageSize);
    setCurrentPage(page);
  };

  const filtersRef = useRef();

  const clearFilters = () => {
    if (filtersRef && filtersRef.current) {
      // @ts-ignore: Object is possibly undefined
      filtersRef.current.resetFilters();
      let newMultiFilter = Object.assign(
        {},
        defaultMultiFilter,
        typeof companyId === "number" ? { companyId: companyId } : {}
      );
      setMultiFilter(newMultiFilter);
      setSkip(0);
      setCurrentPage(1);
      runMultiFilterEmployeeQuery(newMultiFilter, 0);
    }
  };

  const [getFilteredEmployeeList] = useLazyQuery(GET_FILTERED_EMPLOYMENT_LIST, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const { list } = filterInactiveEmployees(
        data.getEmploymentsByFilter.employmentList
      );
      setEmployeeList(list);
      if (data.getEmploymentsByFilter.total) {
        setListTotal(data.getEmploymentsByFilter.total);
        if (showEmptyState) setShowEmptyState(false);
      }
      setLoading(false);
      setInitialising(false);
      setShowSpin(false);
    },
    onError: (error) => {
      setError(error.message);
    },
  });

  const [getFilteredRepresentativeList] = useLazyQuery(
    GET_FILTERED_EMPLOYMENT_LIST,
    {
      fetchPolicy: "network-only",
      onCompleted: ({ getEmploymentsByFilter }) => {
        setRepresentative(getEmploymentsByFilter?.representative);
      },
      onError: (error) => {
        console.error("get_filtered_representative_list_error", error);
      },
    }
  );

  const runMultiFilterEmployeeQuery = (useMultiFilter, useSkip) => {
    setShowSpin(true);
    setLoading(true);
    let postFilters = {};
    for (let [key, value] of Object.entries(useMultiFilter)) {
      if (value) {
        postFilters[key] = value;
      }
    }

    getFilteredEmployeeList({
      variables: {
        input: {
          ...postFilters,
          includeTotal: useSkip === 0 ? true : false,
          skip: useSkip,
          take: pageSize,
        },
      },
    });

    getFilteredRepresentativeList({
      variables: {
        input: {
          ...postFilters,
          includeTotal: useSkip === 0 ? true : false,
          skip: useSkip,
          take: pageSize,
          onlyRelatedParties: true,
        },
      },
    });
  };

  const handleEmployeeFilterChange = (value, filterType, reset) => {
    let newMultiFilter = Object.assign(multiFilter, { [filterType]: value });
    setMultiFilter(newMultiFilter);
    if (newMultiFilter && isActiveMultiFilter(newMultiFilter)) {
      setSkip(0);
      setCurrentPage(1);
      runMultiFilterEmployeeQuery(newMultiFilter, 0);
    }
  };

  const getLatestDate = (certificates: any[], employmentId?: number) => {
    if (!certificates || certificates.length === 0) return "n/a";

    let currentCompanyCerts = certificates;
    if (employmentId) {
      currentCompanyCerts = certificates.filter(
        ({ debtCollectorEmployment }) =>
          debtCollectorEmployment?.id === employmentId
      );
    }

    if (!currentCompanyCerts.length) return "n/a";

    let latestCert;

    if (currentCompanyCerts.length === 1) {
      latestCert = currentCompanyCerts[0];
    } else {
      // Create a shallow copy of the array before sorting
      const sortedCerts = [...currentCompanyCerts].sort((a, b) =>
        isAfter(parseISO(a.toDate), parseISO(b.toDate)) ? -1 : 1
      );
      [latestCert] = sortedCerts;
    }

    return format(parseISO(latestCert.toDate), "yyyy-MM-dd");
  };

  useEffect(() => {
    runMultiFilterEmployeeQuery(multiFilter, skip);
  }, [currentPage, skip]);

  const columns = [
    {
      title: "Employee",
      key: "id",
      render: ({ user: record }) =>
        (record?.firstName && record?.lastName
          ? record.firstName + " " + record.lastName
          : ""
        ).toUpperCase(),
    },
    {
      title: "ID Number",
      render: (record: any) => record?.user?.idNumber || "",
      key: "user",
    },
    {
      title: "Capacity",
      render: ({ capacity }) => capacity?.name || "",
      key: "capacity",
    },
    {
      title: "Membership No",
      render: (record: any) => record.councilMembershipNumber,
      key: "councilMembershipNumber",
    },
    {
      title: "Valid Until",
      render: (record: any) =>
        getLatestDate(record?.user?.certificates, record?.id),
    },
    {
      title: "Status",
      render: ({ user }) => {
        if (!user?.userStatus) return "n/a";
        const status =
          user.userStatus.charAt(0).toUpperCase() +
          user.userStatus.slice(1).toLowerCase();
        return (
          <span style={{ display: "flex" }}>
            <Badge status={status === "Active" ? "success" : "warning"} />{" "}
            {status}
          </span>
        );
      },
    },
    {
      title: <span>Action</span>,
      render: (record: any) => {
        const menuItems = [
          <Menu.Item
            key="View"
            className={`${!record?.user && "no-interact"}`}
            onClick={() =>
              navigate(`/admin/member/${record.user && record.user.id}`)
            }
          >
            View
          </Menu.Item>,
        ];

        if (record?.user?.id !== +userId) {
          menuItems.push(
            <Menu.Item
              key="Remove Employee"
              onClick={() => handleOpenModal(record)}
            >
              Remove Employee
            </Menu.Item>
          );
        }

        if (userRole?.name === "Super User") {
          menuItems.push(
            <Menu.Item
              key="Change Status"
              onClick={() => handleOpenChangeMemberModal(record, "status")}
            >
              Change Status
            </Menu.Item>,
            <Menu.Item
              key="Change Capacity"
              onClick={() => handleOpenChangeMemberModal(record, "capacity")}
            >
              Change Capacity
            </Menu.Item>,
            <Menu.Item
              key={
                generatingCard
                  ? "Generating, please wait ..."
                  : "Generate Membership Card"
              }
              onClick={() => generateMembershipCard(record)}
              disabled={generatingCard}
            >
              Generate Membership Card
            </Menu.Item>
          );
        }

        if (ADMIN_STREAMS.includes(userRole?.stream)) {
          const user = record?.user;
          if (representative && representative?.idNumber !== user?.idNumber) {
            menuItems.push(
              <Menu.Item
                key="Set as Default User"
                onClick={() =>
                  setNewRepInformation({
                    firstName: user?.firstName,
                    lastName: user?.lastName,
                    idNumber: user?.idNumber,
                    id: representative?.id,
                    userId: user?.id,
                    capacityId: record?.capacity?.id || user?.capacity?.id,
                    email: user?.email,
                    phone: user?.phone,
                  })
                }
              >
                Set as Default User
              </Menu.Item>
            );
          }
        }

        if (ADMIN_STREAMS.includes(userRole?.stream)) {
          const user = record?.user;
          menuItems.push(
            <Menu.Item
              key="Promote to Company Admin"
              onClick={() =>
                promiteToCompanyAdmin({
                  variables: {
                    userId: user?.id,
                    companyId: companyId,
                  },
                })
              }
            >
              Promote to Company Admin
            </Menu.Item>
          );
        }

        const menu = <Menu>{menuItems.map((item) => item)}</Menu>;

        return (
          <Dropdown overlay={menu} placement="bottomRight" trigger={["click"]}>
            <a
              className="ant-dropdown-link purple-link"
              onClick={(e) => e.preventDefault()}
            >
              More
              <DownOutlined title="member actions dropdown" />
            </a>
          </Dropdown>
        );
      },
    },
  ];

  async function batchRegistration() {
    if (batchRegistrationId) {
      navigate(`/register/batch-registration/${batchRegistrationId}`);
    }

    try {
      const { data, errors } = await createBatchRegistration({
        variables: {
          input: {},
        },
      });

      if (errors) {
        throw errors[0];
      }

      const batchRegData = data.createBatchRegistration;
      setRegistrationState("batchRegistrationId", batchRegData.id);

      navigate(`/register/batch-registration/${batchRegData.id}`);
    } catch (error) {
      notifyError(error);
    }
  }

  if (error) {
    return <InternalErrorPage error={error} />;
  }

  return (
    <Spin tip="Loading..." className="loader" spinning={showSpin || promoting}>
      {Boolean(pendingApplications?.length) && (
        <Alert
          showIcon
          type="info"
          description={`You have an incomplete registration for ${pendingApplications[0].employee.lastName} ${pendingApplications[0].employee.firstName}. To proceed with the registration process, kindly click on the "Continue Registration" button provided below to finalize your registration.`}
          banner={false}
          message="Pending Registration"
          style={{ marginLeft: "10px", marginRight: "10px" }}
        />
      )}

      {!initialising && showEmptyState && (
        <EmptyState
          location="team"
          headingText="Nothing to see here"
          bodyText="You currently have no team members. You can create a ticket to our support team to help with any problems you may be facing."
        ></EmptyState>
      )}

      {initialising && <TableLoader />}

      {!showEmptyState && (
        <div className="col-sm-12 col-md-12 placeholder-table-card">
          <Card>
            <div className="card-header">
              <h3>Employee Management</h3>
              <div>
                {MANUAL_REGISTRATION_ROLES.includes(userRole?.name) && (
                  <>
                    <Tooltip title="Register multiple employees at once and only have one financial task.">
                      <Button
                        icon={<UsergroupAddOutlined />}
                        onClick={batchRegistration}
                        disabled={loadingBatch || !!manualRegistrationType}
                        loading={loadingBatch}
                        style={{
                          marginRight: "10px",
                          backgroundColor: "#0000ff",
                          color: "#fff",
                        }}
                      >
                        {batchRegistrationId
                          ? "Continue Batch Registration"
                          : "Batch Registration"}
                      </Button>
                    </Tooltip>
                    <Tooltip title="Register a single employee">
                      <Button
                        icon={<UserAddOutlined />}
                        onClick={() => {
                          navigate("/admin/team/manual-register");
                        }}
                        disabled={loadingBatch || !!batchRegistrationId}
                        className="red-button"
                        style={{ marginRight: "10px" }}
                      >
                        {!!manualRegistrationType
                          ? "Continue Registration"
                          : "Manual Registration"}
                      </Button>
                    </Tooltip>
                  </>
                )}
                <Button
                  style={{ marginRight: "20px" }}
                  className="purple-button"
                  onClick={clearFilters}
                >
                  Clear filters
                </Button>
              </div>
            </div>
            <div className={loading ? "no-interact" : null}>
              {!initialising && (
                <EmployeeFilters
                  ref={filtersRef}
                  setError={setError}
                  handleEmployeeFilterChange={handleEmployeeFilterChange}
                  defaultMultiFilter={defaultMultiFilter}
                  mode={useMode}
                />
              )}
              <Divider />
              <Table
                rowKey="id"
                columns={columns}
                pagination={{
                  current: currentPage,
                  showSizeChanger: false,
                  pageSize,
                  onChange: (page) => handlePagination(page),
                  total: listTotal,
                  showTotal: () => <h3>Total: {listTotal}</h3>,
                }}
                dataSource={employmentList}
              />
            </div>
          </Card>
        </div>
      )}
      <CompanyRepModal
        open={!!newRepInformation}
        newRepInformation={newRepInformation}
        onCancel={() => setNewRepInformation(undefined)}
        setNewRepInformation={setNewRepInformation}
        refresh={clearFilters}
      />
      <RemoveEmployeeModal {...RemoveUserModalProps} />
      <ChangeMemberStatusModal {...changeMemberProps} />
    </Spin>
  );
}

export default CompanyEmployeesList;
