import { useState, useEffect } from "react";
import { Button, Radio, Input, Select, Spin, Form } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_COMPANY,
  UPDATE_COMPANY,
  CREATE_APPLICATION,
  UPDATE_APPLICATION,
  UPDATE_USER,
} from "../../graphql/mutations";
import { isEmpty, isInteger } from "lodash";

import { openNotificationWithIcon } from "@/utils/notification";
import { GET_CAPACITY_LIST, GET_USER_COMPANY } from "@/graphql/queries";
import { GET_USER_TYPES } from "../Admin/graphql/queries";
import InternalErrorPage from "@/containers/InternalErrorPage";
import { SOLE_PROPRIETOR_USER_ROLE } from "../../constants";
import { useRegistrationState } from "@/stores/registration";
import { useUserState } from "@/stores/user";

const { Option } = Select;

function RegistrationForm(props: any) {
  const [form] = Form.useForm();
  const { id: userId } = useUserState();
  const {
    setRegistrationStateObject: setRegistrationState,
    registeringAs,
    companyId,
    capacityId,
    registrationType,
    registrationCapacity,
  } = useRegistrationState();
  const [isSubmitting, setIsSubmiting] = useState(false);

  const [createCompany] = useMutation(CREATE_COMPANY);
  const [updateCompany] = useMutation(UPDATE_COMPANY);
  const [isVatRegistered, setIsVatRegistered] = useState(false);
  const [companyInfo, setCompanyInfo] = useState<any>();
  const [applicationInfo, setApplicationInfo] = useState<any>();
  const [roles, setRoles] = useState<any>();
  const [updateUserRole] = useMutation(UPDATE_USER);

  const isNewRegistration = registrationType === "New";
  const isBusiness = registrationCapacity === "Business";

  useEffect(() => {
    if (companyId) {
      refetchCompany({ userId }).then((resp) => {
        setCompanyInfo(resp.data.userCompany);
        setIsVatRegistered(resp.data.userCompany?.isRegisteredForVat);
      });
    }
  }, []);

  const {
    loading: loadingCapacity,
    error: errorCapacity,
    data: dataCapacity,
  } = useQuery(GET_CAPACITY_LIST);

  const [upsertUserApplication] = useMutation(
    isEmpty(applicationInfo) ? CREATE_APPLICATION : UPDATE_APPLICATION,
    {
      onError: () => {
        setIsSubmiting(false);
        openNotificationWithIcon(
          "error",
          "Save Error",
          "Error when saving company application. Please try again"
        );
      },
      onCompleted: (data) => {
        const res = isEmpty(applicationInfo)
          ? data.createApplication
          : data.updateApplication;
        setApplicationInfo(res);
        setIsSubmiting(false);
        setRegistrationState({ applicationId: res.id });
      },
    }
  );
  const { loading: loadingUserTypes } = useQuery(GET_USER_TYPES, {
    onCompleted: (data) => {
      const role = data.userTypesList.filter(
        (x) => x.name === SOLE_PROPRIETOR_USER_ROLE
      );
      setRoles(role[0]?.id);
    },
  });

  const {
    loading: loadingCompany,
    data: dataCompany,
    refetch: refetchCompany,
  } = useQuery(GET_USER_COMPANY, {
    variables: { userId },
    onCompleted: () => {
      if (!isEmpty(dataCompany) && !isEmpty(dataCompany.userCompany)) {
        setRegistrationState({
          companyId: dataCompany.userCompany?.id,
          companyName: dataCompany.userCompany?.registeredName,
          tradingName: dataCompany.userCompany?.tradingName,
          step1Saved: true,
        });

        setCompanyInfo(dataCompany.userCompany);
        setIsVatRegistered(dataCompany.userCompany.isRegisteredForVat);

        const application = dataCompany.userCompany?.application;
        if (!isEmpty(application)) {
          setApplicationInfo(application);
          setRegistrationState({ applicationId: application.id });
        }
      }
    },
    onError: () => {
      return <InternalErrorPage />;
    },
  });

  if (errorCapacity) {
    return <InternalErrorPage />;
  }

  const { capacityList } = !loadingCapacity && dataCapacity;

  //selectable options of capacity
  const capacityOptions: any = capacityList
    ? capacityList
        .filter(
          (value) => ["Officer", "Sole Proprietor"].indexOf(value.name) > -1
        )
        .map((type) => {
          return (
            <Option key={type.id} value={type.id}>
              {type.name}
            </Option>
          );
        })
    : [];

  const handleSubmit = async (values: any) => {
    setIsSubmiting(true);
    //if capacity is Sole Proprietor - update user role
    const selectedCapacity = capacityList.filter(
      (x) => x.id === values.capacity
    );

    if (
      [SOLE_PROPRIETOR_USER_ROLE].includes(
        selectedCapacity && selectedCapacity[0].name
      )
    ) {
      await updateUserRole({
        variables: {
          input: {
            id: userId,
            userRoles: [roles],
            capacityId: values.capacity,
          },
        },
      });
    }

    const upsertCompany = isInteger(companyId) ? updateCompany : createCompany;

    upsertCompany({
      variables: {
        input: {
          id: isInteger(companyId) ? companyId : undefined,
          registrationType: isNewRegistration
            ? "New Registration"
            : "Transfer from Existing Member",
          registrationCapacity: registeringAs,
          registeredName: values.registeredName,
          registeredNumber: values.registrationNumber,
          tradingName: values.tradingAsName,
          capacityId: values.capacity,
          userId: isInteger(companyId) ? undefined : userId,
          tradingAs: registeringAs.toUpperCase(),
          natureOfBusiness: "DEBT COLLECTOR",
          isRegisteredForVat: values.isVatRegistered,
          vatNumber: values.vatNumber,
        },
      },
    })
      .then((resp) => {
        if (!companyId) {
          setRegistrationState({
            companyId: resp.data.createCompany?.id,
            companyName: resp.data.createCompany?.registeredName,
          });
        } else {
          setRegistrationState({
            companyId: resp.data.updateCompany?.id,
            companyName: resp.data.updateCompany?.registeredName,
          });
        }

        setRegistrationState({
          capacityId: values.capacity,
          registeringAs,
          step1Saved: true,
        });

        openNotificationWithIcon(
          "success",
          "Create Success",
          `Company created successfully`
        );

        const useCompanyId = resp.data.updateCompany?.id
          ? resp.data.updateCompany?.id
          : resp.data.createCompany?.id;

        upsertUserApplication({
          variables: {
            input: {
              id: applicationInfo ? applicationInfo.id : undefined,
              registrationType,
              registrationCapacity,
              registeredAs: registeringAs,
              applicantType: "Online Registration",
              applicantionType: registeringAs,
              companyId: useCompanyId,
              employerId: useCompanyId,
              employeeId: userId,
            },
          },
        });
      })
      .catch((err) => {
        setIsSubmiting(false);
        openNotificationWithIcon(
          "error",
          "Error",
          err.message.replace("GraphQL error:", "")
        );
      });
  };

  return (
    <div className="flex-column">
      <div
        className="business-basic-details"
        style={{ display: isBusiness ? "block" : "none" }}
      >
        <Form form={form} onFinish={handleSubmit}>
          {(loadingCapacity || loadingCompany || loadingUserTypes) && <Spin />}
          <div className="input-block-wrapper">
            <div className="flex-column input-block">
              <label>Trading as name</label>
              <Form.Item
                hasFeedback
                {...{
                  name: "tradingAsName",
                  initialValue: companyInfo?.tradingName,
                  rules: [
                    {
                      required: true,
                      message: "Please input the business trading name",
                    },
                  ],
                }}
              >
                <Input placeholder="Enter Business Trading Name" />
              </Form.Item>
            </div>

            <div className="flex-column input-block" style={{ width: "100%" }}>
              <label>
                Please select from the dropdown below the capacity in which you
                are duly authorized to complete and submit this registration
              </label>
              <Form.Item
                {...{
                  name: "capacity",
                  initialValue: capacityId,
                  rules: [
                    {
                      required: true,
                      message:
                        "Please select the capacity in which you're duly authorized",
                    },
                  ],
                }}
              >
                <Select
                  id="name"
                  placeholder="Select"
                  style={{ width: "100%", height: "40px" }}
                >
                  {capacityOptions}
                </Select>
              </Form.Item>
            </div>
            <div className="flex-column input-block input-select">
              <label>Is this business VAT registered?</label>
              <Form.Item
                {...{
                  name: "isVatRegistered",
                  initialValue: companyInfo?.isRegisteredForVat,
                }}
              >
                <Radio.Group
                  onChange={(e) => {
                    setIsVatRegistered(e.target.value);
                  }}
                >
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
            <div
              className="flex-column input-block input-select"
              style={{ display: isVatRegistered ? "block" : "none" }}
            >
              <label>Please provide VAT number</label>
              <Form.Item
                {...{ name: "vatNumber", initialValue: companyInfo?.vatNumber }}
              >
                <Input placeholder="Enter VAT number" />
              </Form.Item>
            </div>
            <Form.Item {...{ name: "" }}>
              <Button
                htmlType="submit"
                className="btn-registration-capacity-selected"
                loading={isSubmitting}
              >
                Save information
              </Button>
            </Form.Item>
          </div>
        </Form>
        <span>
          Based on the information above, the registration is subject to Form
          [1C] per Regulations relating to Debt Collectors Act, 2003
        </span>
      </div>
    </div>
  );
}

export default RegistrationForm;
