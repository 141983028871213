import { useState, useEffect, useMemo } from "react";
import { Divider, Skeleton } from "antd";

import { useLazyQuery } from "@apollo/client";
import { GET_EMPLOYEE_REGISTRATION_FEES } from "@/graphql/queries";
import { notifyError } from "@/utils/notification";
import { formatCurrency } from "@/utils/formatCurrency";
import { ISageInvoice } from "../../interfaces/sage";
import { If, IfNot } from "@/components/If";
import { useRegistrationState } from "@/stores/registration";
import { useUserState } from "@/stores/user";
import { useLocation } from "react-router-dom";

interface IProps {
  companyId?: number;
  taskId?: number;
  registeringAs?: string;
  isRenewal?: boolean;
  sagePayload?: string;
}

function RegistrationFeePaymentForm(props: IProps) {
  const location = useLocation();
  const { sagePayload } = props;
  const {
    setRegistrationStateObject: setRegistrationState,
    companyId: cid,
    manualRegistrationType,
    manualRegisterId,
    registeringAs,
  } = useRegistrationState();
  const { id: uid } = useUserState();

  const [employeeFee, setEmployeeFee] = useState<number>();
  const [existingUserWithPenalties, setExistingUserWithPenalties] =
    useState(false);

  const companyId = !isNaN(props.companyId) ? props.companyId : cid;
  const userId = manualRegistrationType ? manualRegisterId : uid;

  const registeredAs = props.registeringAs
    ? props.registeringAs
    : registeringAs;

  const isSP = registeredAs === "A Sole Proprietor";

  const sageInvoice = useMemo<ISageInvoice | undefined>(() => {
    if (!sagePayload) return undefined;
    const invoice = JSON.parse(sagePayload) as ISageInvoice;
    return invoice;
  }, [sagePayload]);

  const amount = useMemo<string>(() => {
    let a = 0;
    if (sageInvoice) a = sageInvoice?.AmountDue || sageInvoice.Total;
    else if (!isNaN(employeeFee)) a = employeeFee;
    return formatCurrency(a);
  }, [sageInvoice, employeeFee]);

  //TO DO: if existing user, query db for penalties
  const [getRegFees, { loading }] = useLazyQuery(
    GET_EMPLOYEE_REGISTRATION_FEES,
    {
      variables: {
        userId,
        companyId,
      },
      onError: notifyError,
      onCompleted: ({ employeeRegistrationFees }) => {
        setEmployeeFee(employeeRegistrationFees?.employeeFee);
        setRegistrationState({
          regFees: employeeRegistrationFees?.employeeFee,
        });
      },
    }
  );

  useEffect(() => {
    setExistingUserWithPenalties(false);
    if (!sageInvoice) {
      getRegFees();
    }
  }, []);

  const step = props.isRenewal
    ? {
        title: "Renewal",
        content: sageInvoice?.Message || null,
      }
    : {
        title: `Step ${isSP ? 9 : 8}: Registration Fee and Payment`,
        content: "Complete your payment for the registration fee",
      };

  if (loading) {
    return <Skeleton active paragraph={{ rows: 6 }} />;
  }

  const title = location.pathname.includes("/tasks/payment/")
    ? step.title.split(":")[1].trim()
    : step.title;

  return (
    <div className="flex-column">
      <div>
        <span className="step-title">{title}</span>
        <p>{step.content}</p>
        <Divider />
      </div>

      <div>
        <IfNot condition={props.isRenewal}>
          <div className="flex-column input-block input-select">
            <label>Registration Fees</label>
          </div>
          <div className="flex-column input-block input-spacer input-select">
            <span>
              Registration and/or Transfer Fees, as outlined in the Debit
              Collectors Act 114 of 1998 and Regulations of 2003, for submission
              of this registration as calculated amounts to:
            </span>
          </div>

          <div className="flex-column input-block input-spacer input-select">
            <table className="fees-table">
              <tbody>
                <tr>
                  <td className="fees-table-cell-title">
                    New Registration Fees (inclusive of Annual Fees):
                  </td>
                  <td className="step-title fees-table-cell-fee">R {amount}</td>
                </tr>
                <tr>
                  <td className="fees-table-cell-title">
                    Total Amount due and payable
                  </td>
                  <td className="step-title fees-table-cell-fee">R {amount}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </IfNot>

        <If condition={existingUserWithPenalties}>
          <div className="flex-column input-block input-select input-spacer">
            <label>Outstanding penalties</label>
          </div>
          <div className="flex-column input-block input-spacer input-select">
            <span>
              Below is a list of outstanding penalties which have to be paid
            </span>
          </div>

          <div className="flex-column input-block input-spacer input-select">
            <table className="fees-table">
              <tbody>
                <tr>
                  <td className="fees-table-cell-title"></td>
                  <td className="step-title fees-table-cell-fee">R</td>
                </tr>
                <tr>
                  <td className="fees-table-cell-title">
                    Total Amount due and payable
                  </td>
                  <td className="step-title fees-table-cell-fee">R</td>
                </tr>
              </tbody>
            </table>
          </div>
        </If>

        <IfNot condition={props.isRenewal}>
          <div className="flex-column input-block input-spacer input-select">
            <span>
              The above amounts are inclusive of VAT and your employer will
              receive notification and electronic copy via email of the invoice
              due and payable.
            </span>
          </div>
        </IfNot>
      </div>
    </div>
  );
}

export default RegistrationFeePaymentForm;
