import { Tabs } from "antd";
import MemberTransactions from "./memberTransactions";
import PermissionsGuard from "../../../components/Auth/can";
import AccessDenied from "../../../components/Auth/accessDenied";

function AllTransactions() {
  const tabItems = [
    {
      label: "Transactions",
      key: "1",
      children: <MemberTransactions mode="all-transactions" />,
    },
  ];

  return (
    <PermissionsGuard
      perform={"transactions:view"}
      yes={() => (
        <>
          <div className="tasks-header-div">
            <h2>Transactions</h2>
          </div>
          <Tabs tabPosition="top" items={tabItems} />
        </>
      )}
      no={() => <AccessDenied permission="transactions:view" />}
    />
  );
}

export default AllTransactions;
