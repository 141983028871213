import { create } from "zustand";
import { devtools, persist, createJSONStorage } from "zustand/middleware";

interface AppState {
  setAppState: (obj: Partial<AppState> | string, val: any) => void;
  clear: () => void;

  companyId?: number;
  lookups?: {
    renewalMonths: string[];
    admins: any;
    taskTypes: any[];
    userRoles: {
      id: number;
      name: string;
    }[];
    registrationCount: number;
    transactionStatuses: {
      id: number;
      name: string;
    }[];
    capacities: { id: number; name: string }[];
    companyTypes: string[];
    assignedTaskCount: number;
    documentTypes: {
      id: number;
      name: string;
      umbrella: string;
      extendedName: string;
    }[];
  };
}

const initialState = {
  companyId: undefined,
  lookups: {
    renewalMonths: [],
    admins: [],
    taskTypes: [],
    userRoles: [],
    registrationCount: 0,
    transactionStatuses: [],
    capacities: [],
    companyTypes: [],
    assignedTaskCount: 0,
    documentTypes: [],
  },
};

export const useAppState = create<AppState>()(
  devtools(
    persist(
      // @ts-ignore
      (set, _) => ({
        ...initialState,

        setAppState: (obj: Partial<AppState> | string, val?: any) => {
          if (typeof obj === "string") {
            set((state) => ({ ...state, [obj]: val }));
          } else {
            set((state) => ({ ...state, ...obj }));
          }
        },

        clear: () => {
          set(initialState);
        },
      }),
      {
        name: "__app__",
        storage: createJSONStorage(() => localStorage),
      }
    )
  )
);
