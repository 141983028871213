import { useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Upload, Button } from "antd";
import { notifyError } from "@/utils/notification";
import { isEmpty } from "lodash";
import { BASE_ENDPOINT } from "../../constants";

function UploadDocuments({
  complaintId,
  companyId,
  debtCollectorId,
  companyName,
  updateDocumentList,
}: any) {
  const [documentsToUpload, setDocumentsToUpload] = useState([]);
  const [uploading, setUploading] = useState(false);

  const customRequest = async (option) => {
    try {
      const { file, onSuccess } = option;
      setUploading(true);

      const response = await fetch(`${BASE_ENDPOINT}/presignedUrl`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          input: {
            filePath: `${companyId}/Complaints/Complaint_${complaintId}/Supporting_Documents/${file.name}`,
            contentType: file.type,
            companyName,
            forCompany: true,
          },
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const resp = await response.json();

      if (!isEmpty(resp.result)) {
        const docList = documentsToUpload;
        docList.push({
          documentName: file.name,
          preSignedUrl: resp.result,
          file,
          contentType: file.type,
        });
        setDocumentsToUpload(docList);
        updateDocumentList(docList);
        setUploading(false);
      }

      onSuccess();
    } catch (err) {
      notifyError(err);
    }
  };

  return (
    <>
      <Upload
        disabled={uploading}
        multiple={true}
        customRequest={customRequest}
      >
        <Button
          className="ant-upload-drag-icon"
          style={{ marginBottom: 50, marginTop: 10 }}
        >
          <UploadOutlined /> <label>Upload</label>
        </Button>
      </Upload>
    </>
  );
}

export default UploadDocuments;
