import { Button, Result, Spin } from "antd";
import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import Completed from "../../assets/images/task_complete.svg";
import Unverified from "../../assets/images/unverified.svg";
import { COMPANY_TYPES } from "../../constants";
import {
  CREATE_TASKS,
  CREATE_COMPANY_REGISTRATION_TASK,
} from "../../graphql/mutations";
import { cleanup } from "../../utils";
import { useNavigate } from "react-router-dom";
import { useRegistrationState } from "@/stores/registration";
import { useUserState } from "@/stores/user";

export default function RegistrationSuccess() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [retry, setRetry] = useState(false);
  const [success, setSuccess] = useState(false);
  const {
    clearRegistrationState,
    manualRegistrationType,
    applicationId: appId,
    manualRegistrationApplicationId,
    registrationCapacity,
    registeringAs,
    previousCompanyId,
    paymentTaskId,
  } = useRegistrationState();

  const { isAdmin } = useUserState();

  const isManualRegistration = !!manualRegistrationType;
  const isTransfer = manualRegistrationType === "transfer";

  const applicationId = isManualRegistration
    ? manualRegistrationApplicationId
    : appId;

  const companyTypes = [...COMPANY_TYPES, "A Sole Proprietor"];

  const isCompany =
    registrationCapacity === "Business" && companyTypes.includes(registeringAs);

  const handleDone = () => {
    const path = isAdmin() ? "/admin/tasks" : "/tasks";
    navigate(path, { replace: true });
    clearRegistrationState();
  };

  const mutationOptions = {
    onCompleted: (data: any) => {
      setSuccess(true);
      setIsLoading(false);
      cleanup(isAdmin());
    },
    onError: (error: any) => {
      setIsLoading(false);
      setRetry(true);
      console.error("complete_reg_error", error.message);
    },
  };

  const [completeRegistration] = useMutation(CREATE_TASKS, mutationOptions);

  const [completeCompanyRegistration] = useMutation(
    CREATE_COMPANY_REGISTRATION_TASK,
    mutationOptions
  );

  const handleCompleteRegistration = () => {
    setIsLoading(true);
    if (!success)
      if (isCompany) {
        completeCompanyRegistration({
          variables: {
            input: {
              applicationId,
              retry,
              registeringAs,
              ...(paymentTaskId && { paymentTaskId }),
            },
          },
        });
      } else {
        completeRegistration({
          variables: {
            input: {
              applicationId,
              retry,
              ...(isTransfer && { previousCompanyId: +previousCompanyId }),
            },
          },
        });
      }
  };

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    handleCompleteRegistration();
  }, []);

  return (
    <>
      {isLoading && (
        <div className="verification-result">
          <Result
            className="flex-column vertical-center"
            title="Completing Registration"
            subTitle={
              <b>
                Please do not refresh this page. This may take up to 2 minutes.
              </b>
            }
            icon={<Spin />}
          />
        </div>
      )}
      {!isLoading && success && (
        <div className="verification-result">
          <Result
            className="flex-column"
            title={
              isManualRegistration
                ? "Manual Registration Complete"
                : "Registration Complete"
            }
            subTitle={
              isManualRegistration ? (
                <div>
                  <p>
                    Your registration has been submitted successfully to the
                    Council for Debt Collectors for review and approval. Please
                    note that should any additional documentation or information
                    be required to approve the registration; you will be
                    notified via Task Request. Upon approval your Membership
                    Number and Certificate will be made available for download.
                  </p>
                  <br />
                  <p>
                    Please refer to the Tasks Tab as a New task has been created
                    for Amounts Due per this Registration. Please upload the
                    Proof of Payment as indicated on the Task and submit to
                    Council for Debt Collectors to complete the Registration
                    Process.
                  </p>
                </div>
              ) : (
                `Your registration has been submitted successfully to the Council of Debt Collectors. Please note that we will notify you via email if we require any further documentation from you. Your registration needs to be reviewed and approved by the Council before you will receive your Membership number and certificate. You will receive an email once your registration has been approved or rejected and you will be able to access your portal.`
              )
            }
            extra={[<Button onClick={handleDone}>Done</Button>]}
            icon={
              <img
                width="40%"
                src={Completed}
                alt="Successful registration"
                className="result-icon"
              />
            }
          />
        </div>
      )}
      {!isLoading && !success && (
        <div className="verification-result">
          <Result
            className="flex-column"
            title="Registration failed"
            subTitle="We were unable to complete your registration due to an error. Please try again later. If the issue persists, please contact the council for assistance."
            icon={
              <img
                width="40%"
                src={Unverified}
                alt="Unsuccessful registration"
                className="result-icon"
              />
            }
            extra={[
              <Button onClick={goBack} type="primary">
                Retry
              </Button>,
            ]}
          />
        </div>
      )}
    </>
  );
}
