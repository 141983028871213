import { useState, useEffect, useRef } from "react";
import { DownOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { Card, Table, Button, Spin, Divider, Menu, Dropdown, Tag } from "antd";
import { format, parseISO } from "date-fns";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { GET_FILTERED_NEW_TASK_LIST } from "../../../graphql/queries";
import { DELETE_TASK } from "../graphql/mutations";
import NewTaskFilters from "./filters";
import { TableLoader } from "../../../components/UserManagement/TableContentPlaceholder";
import InternalErrorPage from "../../InternalErrorPage";
import PermissionsGuard from "../../../components/Auth/can";
import EmptyState from "../../../components/Styled/EmptyState";
import { ADMIN_STREAMS, TASK_PAGE_SIZE } from "../../../constants";
import RemoveTaskModal from "./RemoveTaskModal";
import {
  prioritize,
  formatName,
  writeCSV,
  formatFilters,
  isAdmin,
} from "@/utils";
import { notifyError } from "@/utils/notification";
import { useFilterState } from "@/contexts/AdminFilterContext";
import { useNavigate } from "react-router-dom";

export default function TaskList() {
  const navigate = useNavigate();
  const [taskList, setTaskList] = useState([]);
  const [showEmptyState, setShowEmptyState] = useState(true);
  const [listTotal, setListTotal] = useState(0);
  const [initialising, setInitialising] = useState(true);
  const [showSpin, setShowSpin] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [isRemovingTask, setIsRemovingTask] = useState(false);
  const [selectedTask, setSelectedTask] = useState<any>();
  const [downloading, setDownloading] = useState(false);
  const isadmin = isAdmin();

  const {
    filters: { filter, savedSkip: skip, savedCurrentPage: currentPage },
    savePageNumber: setCurrentPage,
    filterReset,
  } = useFilterState();

  const defaultFilters = {
    ...filter,
    excludeIds: [4, 10, 22],
  };

  const filtersRef = useRef();

  const clearFilters = () => {
    if (filtersRef && filtersRef.current) {
      // @ts-ignore: Object is possibly undefined
      filtersRef.current.resetFilters();
      filterReset();
      runMultiFilterTaskQuery();
    }
  };

  const [getFilteredTaskList] = useLazyQuery(GET_FILTERED_NEW_TASK_LIST, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setTaskList(data.tasks.taskList);
      if (Number.isInteger(data.tasks.total)) {
        setListTotal(data.tasks.total);
        if (showEmptyState) setShowEmptyState(false);
      }
      setLoading(false);
      setInitialising(false);
      setShowSpin(false);
    },
    onError: (error) => {
      setError(error.message);
    },
  });

  const runMultiFilterTaskQuery = () => {
    setShowSpin(true);
    setLoading(true);

    getFilteredTaskList({
      variables: {
        input: {
          ...formatFilters(defaultFilters),
          includeTotal: true,
          skip,
          take: TASK_PAGE_SIZE,
        },
      },
    });
  };

  const { refetch: downloadTasks } = useQuery(GET_FILTERED_NEW_TASK_LIST, {
    fetchPolicy: "no-cache",
    skip: true,
  });

  const handleDownload = async () => {
    try {
      setDownloading(true);
      const data = [];
      let t = 100;
      let s = 0;

      while (data.length < listTotal) {
        const { data: d, errors } = await downloadTasks({
          input: {
            excludeIds: [4, 10, 22],
            includeTotal: false,
            skip: s,
            take: t,
          },
        });

        if (errors && errors.length) {
          setDownloading(false);
          return notifyError(errors[0].message);
        }

        data.push(
          ...d.tasks.taskList.map((t: any) => {
            return {
              "Task ID": t.id,
              "Linked ID": t?.relatedTask ? t.relatedTask.id : "N/A",
              Applicant: (t?.employee
                ? `${t.employee.lastName} ${t.employee.firstName}`
                : `${t.company?.createdBy?.lastName} ${t.company?.createdBy?.firstName}`
              ).toUpperCase(),
              Company: t?.company
                ? (
                    t.company.tradingName || t.company.registeredName
                  ).toUpperCase()
                : "",
              Type: t?.type?.name,
              "Date Created": format(parseISO(t.createdDate), "yyyy-MM-dd"),
              Status: t.taskStatus,
            };
          })
        );
        s += t;
      }

      writeCSV("New Tasks", `New Tasks ${Date.now()}.csv`, data);
    } catch (err) {
      // @ts-ignore
      notifyError(err);
    } finally {
      setDownloading(false);
    }
  };

  // initial load
  useEffect(() => {
    runMultiFilterTaskQuery();
  }, []);

  // load on filter change
  useEffect(() => {
    runMultiFilterTaskQuery();
  }, [currentPage, skip, JSON.stringify(filter)]);

  const columns = [
    {
      title: "",
      key: "priority",
      render: ({ type, priorityStatus, assignedTo }) =>
        prioritize(type, priorityStatus, assignedTo) ? (
          <ExclamationCircleFilled style={{ color: "red", fontSize: "15px" }} />
        ) : (
          ""
        ),
    },
    {
      title: "Task ID",
      key: "id",
      render: ({ id, type, priorityStatus, assignedTo }) => (
        <span
          className={prioritize(type, priorityStatus, assignedTo) ? "bold" : ""}
        >
          {id}
        </span>
      ),
    },
    {
      title: "Linked ID",
      key: "relatedTasks",
      render: ({
        type,
        relatedTask,
        relatedTasks,
        priorityStatus,
        assignedTo,
        isManual,
      }) => {
        if (relatedTask?.id) {
          return (
            <span
              className={
                prioritize(type, priorityStatus, assignedTo) ? "bold" : ""
              }
            >
              {relatedTask?.id}
            </span>
          );
        }

        if (relatedTasks?.length) {
          return (
            <span
              className={
                prioritize(type, priorityStatus, assignedTo) ? "bold" : ""
              }
            >
              {relatedTasks.map((t: any) => t.id).join(", ")}
            </span>
          );
        }

        if (isManual) {
          return (
            <span
              className={
                prioritize(type, priorityStatus, assignedTo) ? "bold" : ""
              }
            >
              M
            </span>
          );
        }

        return null;
      },
    },
    {
      title: "Applicant",
      key: "firstName",
      render: (record) => (
        <span
          className={
            prioritize(record.type, record.priorityStatus, record.assignedTo)
              ? "bold"
              : ""
          }
        >
          {formatName(record).toUpperCase()}
        </span>
      ),
    },
    {
      title: "Company",
      key: "company",
      render: ({ company, type, priorityStatus, assignedTo }) => (
        <span
          className={prioritize(type, priorityStatus, assignedTo) ? "bold" : ""}
          style={{ cursor: isadmin ? "pointer" : "default" }}
          onClick={
            isadmin ? () => navigate(`/admin/company/${company.id}`) : undefined
          }
        >
          {company?.registeredName?.toUpperCase() || "n/a"}
        </span>
      ),
    },
    {
      title: "Type",
      key: "type",
      render: ({ type, priorityStatus, assignedTo }) => (
        <span
          className={prioritize(type, priorityStatus, assignedTo) ? "bold" : ""}
        >
          {type?.name}
        </span>
      ),
    },
    {
      title: "Date Created",
      key: "createdDate",
      render: ({ type, createdDate, priorityStatus, assignedTo }) => (
        <span
          className={prioritize(type, priorityStatus, assignedTo) ? "bold" : ""}
        >
          {createdDate ? format(parseISO(createdDate), "yyyy-MM-dd") : ""}
        </span>
      ),
    },
    {
      title: "Status",
      key: "status",
      render: ({
        taskStatus,
        type,
        priorityStatus,
        assignedTo,
        relatedTask,
      }) => {
        const badgeStatus =
          relatedTask?.taskStatus === "Complete" ? "green" : "red";

        return taskStatus ? (
          ["Employee", "Company", "Sole Proprietor"].includes(type?.name) ? (
            <Tag color={badgeStatus}>{taskStatus}</Tag>
          ) : (
            <Tag color="">{taskStatus}</Tag>
          )
        ) : (
          ""
        );
      },
    },
    {
      title: <span>Action</span>,
      key: "action",
      render: (record) => {
        const items = [];

        if (
          [
            "Financial Ticket",
            "Sole Proprietor",
            "Company",
            "Employee",
          ].includes(record?.type?.name)
        )
          items.push(
            <Menu.Item key={"1"}>
              <PermissionsGuard
                key="review"
                perform={"application:review"}
                yes={() => (
                  <a
                    className="ant-dropdown-link purple-link"
                    onClick={() => {
                      localStorage.removeItem("reviewId");
                      navigate("/admin/tasks/review", {
                        state: { ...record },
                      });
                    }}
                  >
                    Review
                  </a>
                )}
              />
            </Menu.Item>
          );

        if (record?.type?.name === "Renewal") {
          items.push(
            <Menu.Item key={"2"}>
              <PermissionsGuard
                perform={"application:review"}
                yes={() => (
                  <a
                    className="ant-dropdown-link purple-link"
                    onClick={() => {
                      localStorage.removeItem("reviewId");
                      navigate("/admin/tasks/review/renewal", {
                        state: { ...record },
                      });
                    }}
                  >
                    Review
                  </a>
                )}
              />
            </Menu.Item>
          );
        }

        if (record?.type?.name === "Penalty") {
          items.push(
            <Menu.Item key={"3"}>
              <PermissionsGuard
                perform={"application:review"}
                yes={() => (
                  <a
                    className="ant-dropdown-link purple-link"
                    onClick={() => {
                      localStorage.removeItem("reviewId");
                      navigate("/admin/tasks/review/penalty", {
                        state: { ...record },
                      });
                    }}
                  >
                    Review
                  </a>
                )}
              />
            </Menu.Item>
          );
        }

        if (record?.type?.name === "Audit Certificate") {
          items.push(
            <Menu.Item key={"4"}>
              <PermissionsGuard
                perform={"application:review"}
                yes={() => (
                  <a
                    className="ant-dropdown-link purple-link"
                    onClick={() => {
                      localStorage.removeItem("reviewId");
                      navigate(`/admin/tasks/review/audit/${record.id}`, {
                        state: { ...record },
                      });
                    }}
                  >
                    Review
                  </a>
                )}
              />
            </Menu.Item>
          );
        }

        if (record.type?.name === "Complaints")
          items.push(
            <Menu.Item key={"5"}>
              <PermissionsGuard
                perform={"complaint:review"}
                yes={() => (
                  <a
                    className="ant-dropdown-link purple-link"
                    onClick={() => {
                      navigate(`/admin/tasks/${record.id}`, {
                        state: record,
                      });
                    }}
                  >
                    Review
                  </a>
                )}
              />
            </Menu.Item>
          );

        if (ADMIN_STREAMS.includes(localStorage.getItem("userRoles"))) {
          items.push(
            <Menu.Item
              onClick={() => {
                setSelectedTask(record);
                setModalVisible(true);
              }}
              key="6"
            >
              Remove task
            </Menu.Item>
          );
        }

        return (
          <Dropdown
            overlay={<Menu>{items.map((i) => i)}</Menu>}
            placement="bottomRight"
            trigger={["click"]}
          >
            <span
              className="ant-dropdown-link purple-link flex"
              onClick={(e) => e.preventDefault()}
            >
              More <DownOutlined title="member actions dropdown" />
            </span>
          </Dropdown>
        );
      },
    },
  ];

  const [deleteTask, {}] = useMutation(DELETE_TASK);

  const removeTaskModalProps = {
    ref: undefined,
    visible: modalVisible,
    refreshList: clearFilters,
    setVisible: setModalVisible,
    taskDetails: selectedTask,
    isLoading: isRemovingTask,
    setIsLoading: setIsRemovingTask,
    onCancel: () => setModalVisible(false),
    deleteTask,
  };

  if (error) {
    return <InternalErrorPage />;
  }

  return (
    <>
      <Spin tip="Loading..." className="loader" spinning={showSpin}>
        {initialising && <TableLoader />}
        {!initialising && showEmptyState && (
          <EmptyState
            location="tasks"
            headingText="Nothing to see here"
            bodyText="You currently have no incoming tickets. You can create a ticket to our support team to help with any problems you may be facing."
          ></EmptyState>
        )}
        {!showEmptyState && (
          <>
            <div className="col-sm-12 col-md-12 placeholder-table-card">
              <Card>
                <div className="card-header">
                  <h3>New Tasks</h3>
                  <div>
                    <Button
                      loading={downloading}
                      className="purple-button"
                      onClick={handleDownload}
                    >
                      Download
                    </Button>
                    <Button className="purple-button" onClick={clearFilters}>
                      Clear filters
                    </Button>
                  </div>
                </div>
                <div className={loading ? "no-interact" : null}>
                  {!initialising && (
                    <NewTaskFilters
                      ref={filtersRef}
                      defaultMultiFilter={defaultFilters}
                      mode="new-tasks"
                    />
                  )}
                  <Divider />
                  <Table
                    rowKey={(record) => record.id}
                    columns={columns}
                    size="small"
                    pagination={{
                      current: currentPage,
                      showSizeChanger: false,
                      pageSize: TASK_PAGE_SIZE,
                      onChange: setCurrentPage,
                      total: listTotal,
                      showTotal: () => <h3>Total: {listTotal}</h3>,
                    }}
                    dataSource={taskList}
                  />
                </div>
              </Card>
            </div>
          </>
        )}
      </Spin>
      <RemoveTaskModal {...removeTaskModalProps} />
    </>
  );
}
