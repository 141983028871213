import { Modal } from "antd";

function ModalFormComponent({ visible, onCancel, initialData }) {
  return (
    <Modal
      open={visible}
      title="Complaint creator information"
      onCancel={onCancel}
      footer={null}
    >
      {" "}
      <span>
        Created By:{"  "}{" "}
        <label>{`${initialData?.firstName} ${initialData?.lastName}`}</label>
        <br />
        Email:{"  "} <label>{initialData?.email}</label>
        <br />
        Contact:{"  "} <label>{initialData?.contactNumber}</label>
        <br />
        ID:{"  "} <label>{initialData?.identificationNumber}</label>
        <br />
      </span>
    </Modal>
  );
}

export default ModalFormComponent;
