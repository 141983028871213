import { Row, Col, Divider } from "antd";
import ContactForm from "../../../RegisterCompany/contactDetailsForm";
import AddressInfo from "../../../RegisterCompany/addressForm";
import RelatedPartyForm from "../../../RegisterCompany/relatedPartyForm";
import FinancialInformationCompany from "../../../RegisterCompany/financialInformation";
import AuditorAccountantCompany from "../../../RegisterCompany/auditorAccountantInfo";
import BasicInfoCompany from "../../../RegisterSoleProprietor/registration";
import { EditBusinessDetailsForm } from "@/components/EditBusinessDetailsForm";

const CompanyProfileEditable = ({ companyId, companyDetails }) => {
  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <BasicInfoCompany />
        </Col>
      </Row>
      <Divider />

      <EditBusinessDetailsForm companyDetails={companyDetails} />

      <Divider />

      <div className="card-header">
        <h3>Business location info</h3>
      </div>
      <AddressInfo useCompanyId={companyId} disableHeading={true} />

      <Divider />

      <div className="card-header">
        <h3>Financial Information</h3>
      </div>
      <FinancialInformationCompany
        isRegistrationMode={false}
        useRegisteringAs="A Sole Proprietor"
        useCompanyId={companyId}
      />

      <Divider />

      <div className="card-header">
        <h3>Related party/team members</h3>
      </div>
      <RelatedPartyForm
        useRegisteringAs="A Sole Proprietor"
        useCompanyId={companyId}
      />

      <Divider />

      <div className="card-header">
        <h3>Contact info</h3>
      </div>
      <ContactForm useCompanyId={companyId} />

      <Divider />

      <div className="card-header">
        <h3>Auditor info</h3>
      </div>
      <AuditorAccountantCompany
        useRegisteringAs="A Sole Proprietor"
        isRegistrationMode={false}
        useCompanyId={companyId}
      />

      <Divider />
    </>
  );
};

export default CompanyProfileEditable;
