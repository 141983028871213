import { useState, useEffect } from "react";
import { Button, Card, Breadcrumb, Badge, Result, Table } from "antd";

import { parseISO, format } from "date-fns";
import { useLazyQuery } from "@apollo/client";
import ResultComment from "../resultComment";
import {
  GET_DOCUMENTS,
  GET_TRANSACTION_BY_FILTER,
  GET_DOWNLOAD_URL,
  GENERATE_SAGE_DOC,
} from "../../../../graphql/queries";
import { isEmpty } from "lodash";
import "../index.css";
import InformationLoader from "./../informationLoader";
import { openNotificationWithIcon } from "@/utils/notification";
import PermissionsGuard from "../../../../components/Auth/can";
import AccessDenied from "../../../../components/Auth/accessDenied";
import fileIcon from "../../../../assets/images/pdf_icon.png";
import { useLocation } from "react-router-dom";

function Review() {
  const location = useLocation();
  const [transaction, setTransaction] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [loadingDoc, setLoadingDoc] = useState(false);
  const [popDocs, setPoPDocs] = useState([]);
  const [downloadUrl, setDownloadUrl] = useState();

  const task = location.state;
  const { id: taskId, createdDate, taskStatus, company, invoice } = task;
  const companyName = company.registeredName || company.tradingName;

  const [getTransaction] = useLazyQuery(GET_TRANSACTION_BY_FILTER, {
    fetchPolicy: "no-cache",
    variables: {
      input: {
        taskId,
        includeTotal: false,
      },
    },
    onError: () => {
      setLoading(false);
      openNotificationWithIcon(
        "error",
        "Error",
        "Error loading transactions for review"
      );
    },
    onCompleted: ({ transactions }) => {
      if (transactions.transactionList && transactions.transactionList[0]) {
        setTransaction(transactions.transactionList[0]);
      }
      setLoading(false);
    },
  });

  const [getDocuments] = useLazyQuery(GET_DOCUMENTS, {
    variables: {
      input: { taskId },
    },
    fetchPolicy: "no-cache",
    onCompleted: ({ documents }) => {
      setLoadingDoc(false);
      if (documents?.documentList) {
        const popList = documents?.documentList.filter(
          (d) => d.documentType.extendedName === "Transactions - Banking"
        );
        setPoPDocs(popList);
      }
    },
    onError: (err) => {
      setLoadingDoc(false);
      console.error("generate document error", err);
      openNotificationWithIcon(
        "error",
        "Load Error",
        "There was an error retrieving the document"
      );
    },
  });

  const [getDownloadUrl] = useLazyQuery(GET_DOWNLOAD_URL, {
    fetchPolicy: "no-cache",
    onError: (err) => {
      setLoadingDoc(false);
      console.error("generate document error", err);
      openNotificationWithIcon(
        "error",
        "Load Error",
        "There was an error retrieving the document"
      );
    },
    onCompleted: (data) => {
      setLoadingDoc(false);
      setDownloadUrl(data.preSignedDownloadUrl);
    },
  });

  const [getSageDocument] = useLazyQuery(GENERATE_SAGE_DOC, {
    fetchPolicy: "no-cache",
    onError: (err) => {
      console.error("generate document error", err);
      setLoadingDoc(false);
      openNotificationWithIcon(
        "error",
        "Load Error",
        "There was an error retrieving the document"
      );
    },
    onCompleted: (data) => {
      setLoadingDoc(false);
      setDownloadUrl(data.generateSageDocument);
    },
  });

  const handleViewInvoice = () => {
    setLoadingDoc(true);
    getSageDocument({
      variables: {
        input: {
          docType: "invoice",
          companyId: company.id,
          sageInvoiceId: invoice.sageInvoiceId,
        },
      },
    });
  };

  const handleViewDocument = (filePath) => {
    setLoadingDoc(true);
    getDownloadUrl({
      variables: {
        input: {
          filePath,
          forCompany: true,
        },
      },
    });
  };

  useEffect(() => {
    getTransaction();
    getDocuments();
  }, []);

  useEffect(() => {
    if (downloadUrl) window.open(downloadUrl);
  }, [downloadUrl]);

  if (isEmpty(task)) {
    return <InvalidTask />;
  }

  const columns = [
    {
      title: "Description",
      render: (record) => record.description,
      key: "description",
    },
    {
      title: "Amount",
      render: (record) => record.fee,
      key: "fee",
    },
  ];

  const dataSource = [
    {
      key: "1",
      fee: invoice ? `R ${invoice?.amount}` : "n/a",
      description: "Penalty Fee:",
    },
    {
      key: "2",
      fee: invoice ? `R ${invoice?.amount}` : "n/a",
      description: "Total",
    },
  ];

  // TODO: > move to state
  const isAdmin = localStorage
    .getItem("userPermissions")
    .split(",")
    .includes("review:edit");

  if (loading) return <InformationLoader />;

  return (
    <PermissionsGuard
      perform={"review:edit"}
      yes={() => (
        <>
          <Card className="detail-card" key="detail-card">
            <Breadcrumb>
              <Breadcrumb.Item>
                <a href="/admin/tasks">Tasks: New Tasks</a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Penalty</Breadcrumb.Item>
            </Breadcrumb>
            <br />
            <div className="flex-row">
              <h2>{companyName}</h2>
              <h3>
                Status:{" "}
                <span>
                  <Badge color={"orange"} status={taskStatus} />
                  {taskStatus}
                </span>
              </h3>
            </div>
            <div className="flex-row">
              <label>
                Task ID: <strong>{taskId}</strong>
              </label>
              <label>
                Type: <strong>Penalty</strong>
              </label>
              <label>
                Date Created:{" "}
                <strong>{format(parseISO(createdDate), "dd-MM-yyyy")}</strong>
              </label>
            </div>
          </Card>
          <Card
            key="review-card"
            className="card"
            title={
              <>
                <h3>Review Process</h3>
                <span>
                  Please review the task related to a complaint penalty using{" "}
                  <b>Direct Deposit </b> as a payment option.
                </span>
              </>
            }
          >
            <div className="review-content-section">
              <div className="review-step-content">
                {!transaction && !loading && (
                  <div
                    style={{
                      background: "red",
                      width: "100%",
                      padding: "20px",
                      borderRadius: "10px",
                      color: "#fff",
                      textAlign: "center",
                    }}
                  >
                    The transaction linked to this task cannot be found.
                  </div>
                )}
                <br />
                <div className="flex-column information-block">
                  <span>
                    Status: <label>{transaction?.status.name || ""}</label>
                  </span>
                  <span>
                    Type: <strong>Penalty</strong>
                  </span>
                  <span>
                    Payee Responsible: <label>{companyName}</label>
                  </span>
                  <span>
                    Payment Method: <label>Direct Deposit</label>
                  </span>
                  <span>
                    Direct Deposit Reference:{" "}
                    <label>{transaction?.directDepositInvoiceNumber}</label>
                  </span>
                  {task?.comment && (
                    <span>
                      Comment: <label>{task.comment}</label>
                    </span>
                  )}
                  <span>Payee Breakdown: </span>
                </div>
                <Table
                  dataSource={dataSource}
                  columns={columns}
                  pagination={false}
                />
                <br />
                <div className="flex-column information-block">
                  <span>Invoice:</span>
                  <div style={{ paddingLeft: "15px" }}>
                    <div>
                      <span>
                        <img
                          style={{ height: "25px", width: "25px" }}
                          src={fileIcon}
                        />
                        &nbsp; &nbsp;
                        {"TAX INVOICE.pdf"}
                        <Button
                          type="link"
                          loading={loadingDoc}
                          onClick={() => handleViewInvoice()}
                        >
                          View
                        </Button>
                      </span>
                      <br />
                    </div>
                  </div>
                </div>
                <br />
                <div className="flex-column information-block">
                  <span>Proof of Payments:</span>
                  <div style={{ paddingLeft: "15px" }}>
                    {!loading && !popDocs.length && (
                      <p>Proof of payments not found</p>
                    )}
                    {!loading &&
                      popDocs.map((doc) => {
                        return (
                          <div key={doc.id}>
                            <span>
                              <img
                                style={{ height: "25px", width: "25px" }}
                                src={fileIcon}
                              />
                              &nbsp; &nbsp;
                              {doc.name.toUpperCase()}
                              <Button
                                type="link"
                                loading={loadingDoc}
                                onClick={() =>
                                  handleViewDocument(doc.documentPath)
                                }
                              >
                                View
                              </Button>
                            </span>
                            <br />
                          </div>
                        );
                      })}
                  </div>
                  <br />
                  <div className={isAdmin && transaction ? "" : "no-interact"}>
                    <ResultComment
                      {...{
                        ...task,
                        step: "RegistrationFee",
                        isForRenewal: true,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </>
      )}
      no={() => <AccessDenied permission="review:edit" />}
    />
  );
}

const InvalidTask = () => {
  return (
    <Result
      status="warning"
      title="Invalid task review"
      subTitle="Please go back to tasks page and select a task to review"
      extra={
        <Button type="default" className="btn-add-comment">
          <a href="/admin/tasks">Go To Tasks</a>
        </Button>
      }
    />
  );
};
export default Review;
