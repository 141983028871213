type IProps = {
  children?: React.ReactNode;
  flex?: string;
  width?: string;
  justify?: string;
  align?: string;
  marginRight?: string;
  marginLeft?: string;
  padding?: string;
  paddingTop?: string;
  paddingBottom?: string;
  flexWrap?: string;
};

const Row: React.FC<IProps> = ({
  children,
  flex,
  width = "100%",
  justify,
  align,
  marginRight,
  marginLeft,
  padding,
  paddingTop,
  paddingBottom,
  flexWrap = "wrap",
  ...props
}) => {
  const style: any = {
    display: "flex",
    flex: flex,
    width: width,
    justifyContent: justify,
    alignItems: align,
    marginRight: marginRight ? marginRight + "px" : "0",
    marginLeft: marginLeft ? marginLeft + "px" : "0",
    padding: padding ? padding + "px" : "0",
    paddingTop: paddingTop ? paddingTop + "px" : "0",
    paddingBottom: paddingBottom ? paddingBottom + "px" : "0",
    flexWrap: flexWrap,
    left: "50px",
    "@media only screen and (max-width: 320px)": {
      left: "30px",
    },
  };

  return (
    <div style={style} {...props}>
      {children}
    </div>
  );
};

export default Row;
