import { useState, useEffect, useRef, useMemo } from "react";
import { Card, Table, Button, Spin, Divider, Badge } from "antd";
import { isEmpty } from "lodash";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  GET_REPORTS,
  DOWNLOAD_REPORTS,
  GET_COMPLIANCE_REPORTS,
  GET_CUSTOM_REPORTS,
  GET_FUTURE_CONTROL_LIST_REPORT,
} from "../graphql/queries";
import CompanyFilters from "./ReportFilters";
import Filters from "./customReportFilters";
import { TableLoader } from "../../../components/UserManagement/TableContentPlaceholder";
import InternalErrorPage from "../../InternalErrorPage";
import ChangeCompanyModal from "./PopulateReportModal";
import UserTable from "./UserTable";
import { formatCurrency } from "@/utils/formatCurrency";

import {
  notifyError,
  openNotificationWithIcon,
  notifyInfo,
} from "@/utils/notification";
import CompanyTable from "./CompanyTable";
import { compareDesc, format } from "date-fns";
import AuditTable from "./AuditTable";
import AuditCertSubmissionTable from "./AuditCertSubmissionTable";
import FutureControlListTable from "./FutureControlListTable";
import { writeCSV } from "@/utils";
import { useNavigate } from "react-router-dom";
import { ActiveMemberReportButton } from "./ActiveMemberReportButton";

const currentYear = new Date().getFullYear();

const isActiveMultiFilter = (multiFilter) => {
  let activeStatus = false;
  for (let filterKey of Object.keys(multiFilter)) {
    if (multiFilter[filterKey]) {
      activeStatus = true;
    }
  }
  return activeStatus;
};

const defaultMultiFilter = Object.seal({
  generalSearch: false,
  type: "Subscription Control List",
  status: false,
  sort: "DESC",
  company: [],
  month: false,
  employee: false,
  complianceReportTo: false,
  complianceReportFrom: false,
  complianceReportType: false,
  customReportType: false,
  to: currentYear,
  from: currentYear - 1,
});

function ReportList() {
  const navigate = useNavigate();
  const [multiFilter, setMultiFilter] = useState(defaultMultiFilter);
  const [customReportFilter, setCustomReportFilter] = useState<any>({});
  const [reportList, setReportList] = useState([]);
  const [reportMonth, setReportMonth] = useState<string>();
  const [listTotal, setListTotal] = useState(0);
  const [skip, setSkip] = useState(0);
  const [initialising, setInitialising] = useState(false);
  const [showSpin, setShowSpin] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [from, setFrom] = useState<number>(currentYear - 1);
  const [to, setTo] = useState<number>(currentYear);
  const [downloading, setDownloading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalVisible, setModalVisible] = useState(false);
  const [reportType, setReportType] = useState("Subscription Control List");
  const [complianceReportType, setComplianceReportType] = useState<string>();
  const [companyId, setCompanyId] = useState<number>();
  const [employeeId, setEmployeeId] = useState<number>();
  const [selectedCompany] = useState<any>();

  const pageSize = reportType === "Compliance Report" ? 20 : 10;

  const handlePagination = (page) => {
    setSkip((page - 1) * pageSize);
    setCurrentPage(page);
  };

  const handleError = (error: any) => {
    console.error("getCustomReportError", error);
    setLoading(false);
    setDownloading(false);
    setInitialising(false);
    setShowSpin(false);

    openNotificationWithIcon(
      "error",
      "Error",
      error.message.replace("GraphQL error: ", "")
    );
  };

  const filtersRef = useRef();

  const clearFilters = () => {
    if (filtersRef && filtersRef.current) {
      // @ts-ignore: Object is possibly undefined
      filtersRef.current.resetFilters();
      setMultiFilter(defaultMultiFilter);
      setReportList([]);
      setReportType(defaultMultiFilter.type);
      setSkip(0);
      setCurrentPage(1);
    }
  };

  const title = useMemo<string>(() => {
    switch (reportType) {
      case "Subscription Control List":
        return "Task";
      case "Renewal Fees Due":
        return "Amount";
      case "Certificate Run":
        return "Total";
      case "Compliance Report":
        return "TBC";
      default:
        return "";
    }
  }, [reportType]);

  const [getCustomReport] = useLazyQuery(GET_CUSTOM_REPORTS, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getCustomReport }) => {
      const { total, companyList, userListString, auditList } = getCustomReport;
      const _reports = userListString
        ? JSON.parse(userListString)
        : companyList ?? auditList;

      setReportList(_reports);
      setListTotal(total);
      setLoading(false);
      setInitialising(false);
      setShowSpin(false);
    },
    onError: handleError,
  });

  const [getFutureControlList] = useLazyQuery(GET_FUTURE_CONTROL_LIST_REPORT, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getFutureControlListReport }) => {
      const _reports = getFutureControlListReport
        ? JSON.parse(getFutureControlListReport)
        : null;

      if (_reports) setReportList(_reports);
      if (_reports?.length) {
        setListTotal(_reports.length);
      } else {
        notifyInfo(
          "There are no certificates expiring on the specified months."
        );
      }
      setLoading(false);
      setInitialising(false);
      setShowSpin(false);
    },
    onError: handleError,
  });

  const { refetch: downloadCustomReport } = useQuery(GET_CUSTOM_REPORTS, {
    fetchPolicy: "no-cache",
    skip: true,
    onError: notifyError,
  });

  const [getComplianceReport] = useLazyQuery(GET_COMPLIANCE_REPORTS, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getComplianceReport }) => {
      setReportList(getComplianceReport);
      setLoading(false);
      setInitialising(false);
      setShowSpin(false);
    },
    onError: handleError,
  });

  const [getReports] = useLazyQuery(GET_REPORTS, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getReports }) => {
      if (getReports?.reports) setReportList(getReports.reports);
      if (getReports?.total) setListTotal(getReports.total);
      if (getReports?.reportString) {
        const data = JSON.parse(getReports.reportString);
        setReportList(data);
      }

      setLoading(false);
      setInitialising(false);
      setShowSpin(false);
    },
    onError: handleError,
  });

  const [downloadReports] = useLazyQuery(DOWNLOAD_REPORTS, {
    onCompleted: ({ getReports }) => {
      const data = getReports.reports.map((report, i) => {
        return {
          "#": i + 1,
          Company: report.company.registeredName,
          "#Employees": report.employees,
          "#Related Parties": report.relatedParties,
          "#Entity": report.entity,
          [title]:
            reportType === "Subscription Control List"
              ? report.renewalTask?.id
              : report.total,
        };
      });

      writeCSV(
        reportType,
        `${reportType.replace(/ /g, "_")}_ ${reportMonth}`,
        data
      );
      setDownloading(false);
    },
    onError: handleError,
  });

  const runMultiFilterCompanyQuery = (
    useMultiFilter,
    useSkip,
    skipCache = false
  ) => {
    setShowSpin(true);
    setLoading(true);
    let postFilters: any = {};

    for (let [key, value] of Object.entries(useMultiFilter)) {
      if (value) {
        postFilters[key] = value;
      }
    }

    if (reportType == "Compliance Report") {
      const to = postFilters.complianceReportTo;
      const from = postFilters.complianceReportFrom;
      const complianceReportType = postFilters.complianceReportType;

      if (!complianceReportType) {
        setShowSpin(false);
        setLoading(false);
        return openNotificationWithIcon(
          "error",
          "Error",
          "Please select the compliance report type"
        );
      }

      if (!to || !from) {
        setShowSpin(false);
        setLoading(false);
        return openNotificationWithIcon(
          "error",
          "Error",
          "Please select the date range"
        );
      }

      getComplianceReport({
        variables: {
          input: {
            type: postFilters.type,
            complianceReportType,
            to,
            from,
          },
        },
      });
    } else if (reportType == "Future Subscription Control List") {
      if (!postFilters?.company || !postFilters?.company?.length) {
        setShowSpin(false);
        setLoading(false);
        return notifyError("Please select the company");
      }

      if (!postFilters?.month || !postFilters?.month?.length) {
        setShowSpin(false);
        setLoading(false);
        return notifyError("Please select the month(s)");
      }

      getFutureControlList({
        variables: {
          input: {
            company: postFilters.company,
            months: postFilters.month,
          },
        },
      });
    } else if (reportType == "Custom Report") {
      if (!postFilters.customReportType) {
        setShowSpin(false);
        setLoading(false);
        return openNotificationWithIcon(
          "error",
          "Error",
          "Please select the custom report type"
        );
      }

      getCustomReport({
        variables: {
          input: {
            type: postFilters.customReportType,
            filters: customReportFilter,
            skip: useSkip,
          },
        },
      });
    } else {
      getReports({
        variables: {
          input: {
            ...postFilters,
            includeTotal: useSkip === 0 ? true : false,
            skip: useSkip,
            take: pageSize,
          },
        },
      });
    }
  };

  const handleFilterChange = (value, filterType, reset) => {
    if (filterType === "from") setFrom(Number(value));
    if (filterType === "to") setTo(Number(value));
    if (filterType === "type") setReportType(value);
    if (filterType === "month") setReportMonth(value);
    if (filterType === "company") setCompanyId(value[0]);
    if (filterType === "complianceReportType") setComplianceReportType(value);
    if (filterType === "employee") setEmployeeId(value);

    const newMultiFilter =
      filterType === "dateRange"
        ? {
            ...multiFilter,
            complianceReportFrom: value[0],
            complianceReportTo: value[1],
          }
        : { ...multiFilter, [filterType]: value };

    setMultiFilter(newMultiFilter);
    if (newMultiFilter && isActiveMultiFilter(newMultiFilter)) {
      setSkip(0);
      setCurrentPage(1);
      setReportList([]);
    }
  };

  const handleDownloadReport = async () => {
    try {
      setDownloading(true);
      let postFilters: any = {};
      for (let [key, value] of Object.entries(multiFilter)) {
        if (value) {
          postFilters[key] = value;
        }
      }

      if (reportType === "Compliance Report") {
        return writeCSV(reportType, reportType, reportList);
      } else if (reportType === "Custom Report") {
        let reportData = [];
        let skip = 0;
        while (listTotal !== reportData.length) {
          const { data, errors } = await downloadCustomReport({
            input: {
              type: postFilters.customReportType,
              filters: customReportFilter,
              skip,
              take: Math.min(listTotal, 2000),
            },
          });

          if (errors && errors.length) {
            setDownloading(false);
            return openNotificationWithIcon(
              "error",
              "Error",
              errors[0].message
            );
          }

          switch (postFilters.customReportType) {
            case "Member":
              reportData.push(
                ...JSON.parse(data.getCustomReport?.userListString)
              );
              break;
            case "Company":
              reportData.push(...data.getCustomReport?.companyList);
              break;
            case "Audit":
              reportData.push(...data.getCustomReport?.auditList);
              break;
            default:
          }

          skip += 2000;
        }

        let csvData: any;

        if (!reportData.length) throw new Error("Report download failed.");
        if (postFilters.customReportType === "Member") {
          csvData = reportData.map((i) => {
            return {
              "ID Number": i.idNumber,
              "First Name": i.firstName?.toUpperCase(),
              "Last Name": i.lastName?.toUpperCase(),
              Status: (i.userStatus || "")?.toUpperCase(),
              Capacity: i.employment
                .map((i) => i?.capacity?.name || "")
                .join(" | "),
              Company: i.employment
                ?.map(
                  (i) => i?.company?.tradingName || i?.company?.registeredName
                )
                .join(" | ")
                ?.toUpperCase(),
              "Membership Number": i.employment
                .map((i) => i.councilMembershipNumber)
                .join(" | "),
              "Valid Until": i.certificates?.length
                ? format(
                    i.certificates
                      .map((i) => new Date(i.toDate))
                      .sort(compareDesc)[0],
                    "dd MMM yyyy"
                  )
                : "",
            };
          });
        } else if (postFilters.customReportType === "Company") {
          csvData = reportData.map((i) => {
            return {
              "Registered Name": i.registeredName,
              "Trading Name": i.tradingName,
              "Membership Number": i.membershipNumber,
              Status: i.status,
              "Nature Of Business": i.natureOfBusiness,
              "Employee Count": i.employmentCount,
              "Valid Until": i.certificates?.length
                ? format(
                    i.certificates
                      .map((i) => new Date(i.toDate))
                      .sort(compareDesc)[0],
                    "dd MMM yyyy"
                  )
                : "",
            };
          });
        } else if (postFilters.customReportType === "Audit") {
          csvData = reportData.map((i) => {
            return {
              ID: i.id,
              "Date Created": format(new Date(i.dateCreated), "dd MMM yyy"),
              Company:
                i?.task?.company?.registeredName ||
                i?.task?.company?.tradingName ||
                "",
              "BBEEE Level": i.bbeeeLevel || "",
              Industry: i?.industry || "",
              "Geographical Area": i?.geographicalArea || "",
              "Value of Books": i?.valueOfBooks || "",
              "Amount Recovered": i?.amountRecovered || "",
              "Financial Year": i?.financialYear
                ? format(new Date(i.financialYear), "MMMM yyyy")
                : "",
              "Task ID": i?.task?.id || "",
              "Task Status": i?.task?.taskStatus || "",
            };
          });
        }

        setDownloading(false);
        const fileName = `${reportType} - ${postFilters.customReportType}`;
        return writeCSV(fileName, fileName, csvData);
      } else if (reportType === "Audit Certificate Submissions") {
        const csvData = reportList.map((r) => {
          const x = {};

          x["Company ID"] = r.company.id;
          x["Company"] = r.company.registeredName || r.company.registeredName;

          for (let i = from; i <= to; i++) {
            x[i] = r[i] ?? "NO";
          }

          return x;
        });

        setDownloading(false);
        const fileName = `${reportType} ${from} - ${to}`;
        return writeCSV(fileName, fileName, csvData);
      }

      downloadReports({
        variables: {
          input: {
            ...postFilters,
            skip: 0,
            take: Math.min(listTotal, 500),
          },
        },
      });
    } catch (err) {
      setDownloading(false);
      notifyError(err);
    }
  };

  useEffect(() => {
    if (multiFilter && reportList.length)
      runMultiFilterCompanyQuery(multiFilter, skip);
  }, [currentPage, skip]);

  const modalProps = {
    ref: null,
    visible: modalVisible,
    reportType,
    reportMonth,
    companies: reportList?.map(({ company }) => company),
    setModalVisible,
    companyDetails: selectedCompany,
    onCancel: () => setModalVisible(false),
    refreshList: clearFilters,
    employeeId,
  };

  const columns = useMemo<any[]>(() => {
    let reportColumns: any[];

    if (reportType === "Future Subscription Control List") {
      reportColumns = [];
    } else if (reportType === "Compliance Report") {
      reportColumns = [
        {
          title:
            complianceReportType === "debtCollector"
              ? "Debt Collector Type"
              : "Business Type",
          key: "type",
          render: ({ capacity }) => (
            <span className={capacity === "Total" ? "bold" : ""}>
              {capacity?.toUpperCase()}
            </span>
          ),
        },
        {
          title: true ? "Received" : "Registered",
          key: "received_registered",
          render: ({ capacity, received }) => (
            <span className={capacity === "Total" ? "bold" : ""}>
              {received}
            </span>
          ),
        },
        {
          title: "Approved",
          key: "approved",
          render: ({ capacity, approved }) => (
            <span className={capacity === "Total" ? "bold" : ""}>
              {approved}
            </span>
          ),
        },
        {
          title: "Active",
          key: "active",
          render: ({ capacity, active }) => (
            <span className={capacity === "Total" ? "bold" : ""}>{active}</span>
          ),
        },
        ...(complianceReportType === "debtCollector"
          ? [
              {
                title: "Unemployed",
                key: "unemployed",
                render: ({ capacity, unemployed }) => (
                  <span className={capacity === "Total" ? "bold" : ""}>
                    {unemployed}
                  </span>
                ),
              },
            ]
          : []),
        {
          title: "Suspended",
          key: "suspended",
          render: ({ capacity, suspended }) => (
            <span className={capacity === "Total" ? "bold" : ""}>
              {suspended}
            </span>
          ),
        },
        {
          title: "Deregistered",
          key: "deregistered",
          render: ({ capacity, deregistered }) => (
            <span className={capacity === "Total" ? "bold" : ""}>
              {deregistered}
            </span>
          ),
        },
        {
          title: "Refused",
          key: "refused",
          render: ({ capacity, refused }) => (
            <span className={capacity === "Total" ? "bold" : ""}>
              {refused}
            </span>
          ),
        },
      ];
    } else {
      reportColumns = [
        {
          title: "Company",
          key: "company",
          render: ({ company }) =>
            (company?.registeredName || company?.tradingName).toUpperCase(),
        },
        ...(reportType === "Renewal Fees Due"
          ? [
              {
                title: "Trn ID",
                key: "TransactionId",
                render: ({ renewalTask }) => renewalTask?.transactions?.[0]?.id,
              },
            ]
          : []),
        {
          title: "#Employees",
          render: ({ employees }) =>
            reportType === "Renewal Fees Due"
              ? `R ${formatCurrency(employees)}`
              : employees,
        },
        {
          title: "#Related Parties",
          render: ({ relatedParties }) =>
            reportType === "Renewal Fees Due"
              ? `R ${formatCurrency(relatedParties)}`
              : relatedParties,
        },
        {
          title: "#Entity",
          render: ({ entity }) =>
            reportType === "Renewal Fees Due"
              ? `R ${formatCurrency(entity)}`
              : entity,
        },
        {
          title,
          render: ({ renewalTask, total }) => {
            const val =
              reportType === "Subscription Control List"
                ? renewalTask?.id
                : total;

            return reportType === "Renewal Fees Due"
              ? `R ${formatCurrency(val)}`
              : val;
          },
        },
      ];

      if (reportType === "Renewal Fees Due") {
        reportColumns.push({
          title: "Trn Status",
          key: "transactionStatus",
          render: ({ renewalTask }) =>
            renewalTask?.transactions?.[0]?.status?.name,
        });
      }

      if (reportType === "Subscription Control List") {
        reportColumns.push(
          {
            title: <span>Task Status</span>,
            key: "taskStatus",
            render: ({ renewalTask }) => {
              const taskStatus = renewalTask?.taskStatus;
              if (!taskStatus) return "";

              let status;

              if (["Resolved", "Complete"].includes(taskStatus))
                status = "success";
              else if (
                ["Awaiting verification", "Awaiting Payment"].includes(
                  taskStatus
                )
              )
                status = "warning";
              else if (taskStatus === "Failed") status = "error";

              return (
                <span>
                  <Badge status={status} /> {taskStatus}
                </span>
              );
            },
          },
          {
            title: <span>Action</span>,
            key: "action",
            render: ({ renewalTask, company }) => (
              <a
                className="purple-link"
                onClick={(e) => {
                  e.preventDefault();
                  if (renewalTask) {
                    navigate("/admin/tasks/review/renewal", {
                      state: { ...renewalTask, company },
                    });
                  }
                }}
              >
                View
              </a>
            ),
          }
        );
      }
    }

    return reportColumns;
  }, [reportType]);

  if (error) {
    console.error({ error });
    return <InternalErrorPage />;
  }

  return (
    <>
      <Spin tip="Loading..." className="loader" spinning={showSpin}>
        <div className="col-sm-12 col-md-12 placeholder-table-card">
          <Card>
            <div className="card-header">
              <h3>Reports</h3>
              <div>
                <ActiveMemberReportButton disabled={showSpin} />
                <Button
                  className="purple-button"
                  disabled={showSpin}
                  onClick={() => runMultiFilterCompanyQuery(multiFilter, 0)}
                >
                  Run
                </Button>
                <Button
                  className="purple-button"
                  disabled={reportList?.length !== 1 || showSpin}
                  onClick={() => setModalVisible(true)}
                >
                  Populate
                </Button>
                {reportType !== "Future Subscription Control List" && (
                  <Button
                    className="purple-button"
                    loading={downloading}
                    disabled={!reportList?.length || showSpin}
                    onClick={handleDownloadReport}
                  >
                    Download
                  </Button>
                )}
                <Button
                  className="purple-button"
                  disabled={showSpin}
                  onClick={clearFilters}
                >
                  Clear filters
                </Button>
              </div>
            </div>
            {initialising && <TableLoader />}
            <div className={loading ? "no-interact" : null}>
              {!initialising && (
                <>
                  <CompanyFilters
                    ref={filtersRef}
                    setError={setError}
                    handleFilterChange={handleFilterChange}
                    defaultMultiFilter={defaultMultiFilter}
                    type={reportType}
                    companyId={companyId}
                  />
                  {reportType === "Custom Report" &&
                    multiFilter.customReportType && (
                      <Filters
                        handleFilterChange={setCustomReportFilter}
                        defaultMultiFilter={defaultMultiFilter}
                        type={
                          // @ts-ignore
                          multiFilter.customReportType as "Member" | "Company"
                        }
                        companyId={companyId}
                      />
                    )}
                </>
              )}
              {reportList && !isEmpty(reportList) && (
                <>
                  <Divider />
                  {reportType === "Audit Certificate Submissions" && (
                    <AuditCertSubmissionTable
                      yearRange={[from, to]}
                      auditSubmissionList={reportList}
                      currentPage={currentPage}
                      pageSize={pageSize}
                      listTotal={listTotal}
                      handlePagination={handlePagination}
                    />
                  )}

                  {reportType === "Future Subscription Control List" && (
                    <FutureControlListTable
                      currentPage={currentPage}
                      pageSize={pageSize}
                      listTotal={listTotal}
                      reportList={reportList}
                    />
                  )}

                  {reportType === "Custom Report" && (
                    <>
                      {typeof multiFilter.customReportType === "string" &&
                        multiFilter.customReportType === "Member" && (
                          <UserTable
                            navigate={navigate}
                            handlePagination={handlePagination}
                            userList={reportList}
                            pageSize={pageSize}
                            currentPage={currentPage}
                            listTotal={listTotal}
                          />
                        )}

                      {typeof multiFilter.customReportType === "string" &&
                        multiFilter.customReportType === "Company" && (
                          <CompanyTable
                            navigate={navigate}
                            handlePagination={handlePagination}
                            companyList={reportList}
                            pageSize={pageSize}
                            currentPage={currentPage}
                            listTotal={listTotal}
                          />
                        )}

                      {typeof multiFilter.customReportType === "string" &&
                        multiFilter.customReportType === "Audit" && (
                          <AuditTable
                            handlePagination={handlePagination}
                            auditList={reportList}
                            pageSize={pageSize}
                            currentPage={currentPage}
                            listTotal={listTotal}
                          />
                        )}
                    </>
                  )}

                  {![
                    "Custom Report",
                    "Audit Certificate Submissions",
                    "Future Subscription Control List",
                  ].includes(reportType) && (
                    <Table
                      rowKey="id"
                      columns={columns}
                      pagination={{
                        current: currentPage,
                        showSizeChanger: false,
                        pageSize,
                        onChange: (page) => handlePagination(page),
                        total:
                          reportType === "Compliance Report"
                            ? reportList.length
                            : listTotal,
                        showTotal: () => (
                          <h3>
                            Total:{" "}
                            {reportType === "Compliance Report"
                              ? reportList.length
                              : listTotal}
                          </h3>
                        ),
                      }}
                      dataSource={reportList}
                    />
                  )}
                </>
              )}
            </div>
            {!initialising && reportList && isEmpty(reportList) && (
              <>
                <Divider />
                <Table />
              </>
            )}
          </Card>
        </div>
      </Spin>
      <ChangeCompanyModal {...modalProps} />
    </>
  );
}

export default ReportList;
