import { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Table, Spin, Card, Button, Form } from "antd";
import InternalErrorPage from "../InternalErrorPage";
import PermissionsGuard from "../../components/Auth/can";
import {
  UPDATE_BUSINESS_UNIT,
  DELETE_BUSINESS_UNIT,
  CREATE_BUSINESS_UNIT,
} from "../Admin/graphql/mutations";
import { isEmpty } from "lodash";
import { GET_BUSINESS_UNITS } from "../Admin/graphql/queries";
import AccessDenied from "../../components/Auth/accessDenied";
import { openNotificationWithIcon } from "@/utils/notification";
import EditBusinessUnitForm from "./editBusinessUnit";
import { TableLoader } from "../../components/UserManagement/TableContentPlaceholder";

function BusinessUnit() {
  const [showSpin, setShowSpin] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();
  const permissions = localStorage.getItem("userPermissions");
  const [businessUnitList, setBusinessUnitList] = useState([]);
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState();
  const [updateBusinessUnit] = useMutation(UPDATE_BUSINESS_UNIT);
  const [deleteBusinessUnit] = useMutation(DELETE_BUSINESS_UNIT);
  const [createBusinessUnit] = useMutation(CREATE_BUSINESS_UNIT);

  const columns = [
    {
      title: "Name",
      render: (record) => record.name,
      key: "name",
    },
    {
      title: "Description",
      render: (record) => record.description,
      key: "description",
    },
    {
      title: <span>Action</span>,
      key: "id",
      render: (record) => (
        <div>
          <EditOutlined title="Edit" onClick={() => showModal(record)} />
          &nbsp;&nbsp;
          <DeleteOutlined
            title="Delete"
            onClick={() => _deleteBusinessUnit(record.id)}
          />
        </div>
      ),
    },
  ];

  const showModal = (e) => {
    setSelectedBusinessUnit(e);
    setModalVisible(true);
  };

  const _deleteBusinessUnit = async (id) => {
    try {
      setShowSpin(true);
      const resp = await deleteBusinessUnit({ variables: { id } });

      if (resp.data) {
        const res = await refetch();
        if (res.data) {
          setBusinessUnitList(res.data.buList);
          setShowSpin(false);
          openNotificationWithIcon(
            "success",
            "Delete operation success ",
            "Business unit deleted successfully"
          );
        }
      }
    } catch (error) {
      setShowSpin(false);
      openNotificationWithIcon(
        "error",
        "Delete operation error ",
        error.message
      );
    }
  };

  const handleCreate = async () => {
    const values = await form.validateFields().catch(() => {});
    if (!values) {
      return;
    }

    setModalVisible(false);
    setShowSpin(true);

    const createUpdateMutation = values.id
      ? updateBusinessUnit
      : createBusinessUnit;

    const resp = await createUpdateMutation({
      variables: {
        id: values.id,
        name: values.name,
        description: values.description,
      },
    });

    if (resp.data) {
      const res = await refetch();
      if (res.data) {
        setBusinessUnitList(res.data.buList); // refetch updated project list
        setShowSpin(false);
        openNotificationWithIcon(
          "success",
          "Update success ",
          "Role updated successfully"
        );
      }

      form.resetFields();
    }
  };

  const ModalProps = {
    form,
    visible: modalVisible,
    onCancel: () => setModalVisible(false),
    onCreate: () => handleCreate(),
    initialData: selectedBusinessUnit,
  };

  const { loading, error, data, refetch } = useQuery(GET_BUSINESS_UNITS, {
    pollInterval: 600000,
    onCompleted: (data) => {
      setBusinessUnitList(data.buList);
    },
  });

  if (loading) return <TableLoader />;
  if (error) return <InternalErrorPage />;

  if (isEmpty(data.buList))
    return (
      <div className="col-sm-12 col-md-12 placeholder-table-card">
        <Table />
      </div>
    );
  return (
    <PermissionsGuard
      permissions={permissions}
      perform={"bu:list"}
      yes={() => (
        <>
          <Spin
            tip="Loading..."
            className="loader"
            style={{ display: showSpin ? "block" : "none" }}
          />
          <div className="col-sm-12 col-md-12 placeholder-table-card">
            <Card style={{ height: "14vh" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Card.Meta
                  title={"Platform Business Unit Management"}
                  description={
                    <p style={{ height: "fit-content" }}>
                      Manage SovTech Business Unts
                    </p>
                  }
                />
                <PermissionsGuard
                  permissions={permissions}
                  perform={"bu:create"}
                  yes={() => (
                    <Button
                      type="primary"
                      style={{ alignSelf: "auto" }}
                      onClick={() => showModal(undefined)}
                    >
                      Add a Bussiness Unit
                    </Button>
                  )}
                />
              </div>
            </Card>
          </div>

          <div className="col-sm-12 col-md-12 placeholder-table-card">
            <Card>
              <Table
                columns={columns}
                pagination={{ pageSize: 10, showSizeChanger: false }}
                dataSource={businessUnitList}
                rowKey="id"
              />
            </Card>
          </div>
          <EditBusinessUnitForm {...ModalProps} />
        </>
      )}
      no={() => <AccessDenied permission="bu:list" />}
    />
  );
}

export default BusinessUnit;
