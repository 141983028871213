import { useCallback, useEffect, useMemo, useState } from "react";
import { Row, Col, Select, Button, DatePicker } from "antd";
import debounce from "lodash/debounce";
import { useLazyQuery } from "@apollo/client";
import { SEARCH_COMPANIES, SEARCH_USERS } from "../graphql/queries";
import { notifyError } from "@/utils/notification";
import { useReportingFilters } from "./useReportingFilters";
import {
  ClearOutlined,
  DownloadOutlined,
  FilePdfOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { If } from "@/components/If";
import { format, isFuture } from "date-fns";
import moment from "moment";
import { generateMonths } from "@/utils";
import { months as monthList } from "@/constants";
import { useAppState } from "@/stores/app";
import { CustomReportingFilters } from "./CustomReportingFilters";

export type FilterKey =
  | "dateRange"
  | "companyIds"
  | "employeeIds"
  | "type"
  | "months"
  | "sort"
  | "from"
  | "to"
  | "status"
  | "pageNumber"
  | "pageSize"
  | "reportType"
  | "custom";

export type Filters = Record<
  FilterKey,
  string | number | string[] | Record<string, any>
>;

type ReportingFilterProps = {
  loading?: boolean;
  downloading?: boolean;
  handleGenerate: () => void;
  handleDownloadCsv: () => void;
  handleDownloadPdf: () => void;
  resetData: () => void;
};

const empty = [{ id: "empty", name: "No results found" }];

export function ReportingFilters({
  loading = false,
  downloading = false,
  handleGenerate,
  handleDownloadCsv,
  handleDownloadPdf,
  resetData,
}: ReportingFilterProps) {
  const [companySearchText, setCompanySearchText] = useState("");
  const [userSearchText, setUserSearchText] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [userList, setUserList] = useState([]);

  const {
    reportType,
    type,
    employeeIds,
    companyIds,
    setFilterState,
    clearFilters,
    from,
    to,
    dateRange,
    months,
  } = useReportingFilters();

  const renewalMonths = useAppState((state) => state.lookups.renewalMonths);

  const handleCompanySearch = (txt: string) => {
    setCompanySearchText(txt);
  };

  const handleUserFilterUpdate = (txt: string) => {
    setUserSearchText(txt);
  };

  const debouncedSearchCompanies = useCallback(
    debounce(handleCompanySearch, 250),
    []
  );

  const debouncedSearchUser = useCallback(
    debounce(handleUserFilterUpdate, 250),
    []
  );

  const [getCompanies] = useLazyQuery(SEARCH_COMPANIES, {
    fetchPolicy: "cache-first",
    onCompleted: (data) => {
      if (
        data &&
        data.companiesByFilter &&
        data.companiesByFilter.companyList
      ) {
        if (data.companiesByFilter.companyList.length > 0) {
          setCompanyList(
            data.companiesByFilter.companyList.map(
              ({ id, registeredName }) => ({ name: registeredName, id })
            )
          );
        } else {
          setCompanyList(empty);
        }
      }
    },
    onError: (error) => {
      notifyError(error);
    },
  });

  const [getUsers] = useLazyQuery(SEARCH_USERS, {
    fetchPolicy: "cache-first",
    onCompleted: ({ searchUsers }) => {
      if (searchUsers) {
        if (searchUsers.length > 0) {
          setUserList(
            searchUsers.map(({ id, firstName, lastName }) => ({
              name: `${lastName} ${firstName}`,
              id,
            }))
          );
        } else {
          setCompanyList(empty);
        }
      }
    },
    onError: (error) => {
      notifyError(error);
    },
  });

  useEffect(() => {
    if (companySearchText?.length > 2) {
      setCompanyList([{ id: "loading", name: "Loading..." }]);
      getCompanies({
        variables: { filter: companySearchText, criteria: "searchName" },
      });
    } else {
      setCompanyList([
        { id: "3char", name: "Please enter at least 3 characters" },
      ]);
    }
  }, [companySearchText]);

  useEffect(() => {
    if (userSearchText?.length > 2) {
      setUserList([{ id: "loading", name: "Loading..." }]);
      getUsers({
        variables: { name: userSearchText },
      });
    } else {
      setUserList([
        { id: "3char", name: "Please enter at least 3 characters" },
      ]);
    }
  }, [userSearchText]);

  const showEmployeeFilter = useMemo(() => {
    return reportType === "member" && !["compliance-member"].includes(type);
  }, [reportType]);

  const showCompanyFilter = useMemo(() => {
    return (
      (reportType === "company" &&
        !["compliance-business", "compliance-member"].includes(String(type))) ||
      [
        "renewal-fees-due",
        "subscription-control-list",
        "future-subscription-control-list",
      ].includes(String(type))
    );
  }, [reportType]);

  const showFromFilter = useMemo(() => {
    return type === "audit-certificate-submission";
  }, [type]);

  const showToFilter = useMemo(() => {
    return type === "audit-certificate-submission";
  }, [type]);

  const showDateRangeFilter = useMemo(() => {
    return ["compliance-business", "compliance-member"].includes(String(type));
  }, [type]);

  const showMonthsFilter = useMemo(() => {
    return [
      "renewal-fees-due",
      "subscription-control-list",
      "future-subscription-control-list",
    ].includes(String(type));
  }, [type]);

  const showGenerate = useMemo(() => {
    return type !== "employment-history";
  }, [type]);

  const showDownload = useMemo(() => {
    return type !== "employment-history";
  }, [type]);

  const showDownloadPdf = useMemo(() => {
    return ["employment-history", "top-20-collectors"].includes(type);
  }, [type]);

  const years = useMemo<number[]>(() => {
    const _years = [];
    for (let i = 2010; i <= 2030; i++) {
      _years.push(i);
    }
    return _years;
  }, []);

  const formatMonth = (m: string) =>
    `${monthList[+m.split("-")[0] - 1]} ${m.split("-")[1]}`.toUpperCase();

  return (
    <div>
      <Row gutter={[24, 24]} className={loading ? "no-interact" : ""}>
        <If condition={showCompanyFilter}>
          <Col span={6}>
            <div className="flex-column gap-2">
              <label>Company:</label>
              <Select
                showSearch
                value={companyIds?.[0] ? companyIds[0] : undefined}
                showArrow
                defaultActiveFirstOption={false}
                filterOption={false}
                notFoundContent={null}
                loading={false}
                onSearch={debouncedSearchCompanies}
                onSelect={(company) =>
                  setFilterState({ companyIds: [String(company)] })
                }
                placeholder="Search company"
                defaultValue={companyIds?.[0]}
              >
                {companyList.map((company) => (
                  <Select.Option key={company.id}>{company.name}</Select.Option>
                ))}
              </Select>
            </div>
          </Col>
        </If>

        <If condition={showEmployeeFilter}>
          <Col span={type === "employment-history" ? 12 : 6}>
            <div className="flex-column gap-2">
              <label>Employee:</label>
              <Select
                showSearch
                showArrow
                defaultActiveFirstOption={false}
                filterOption={false}
                notFoundContent={null}
                loading={false}
                onSearch={debouncedSearchUser}
                onSelect={(user) =>
                  setFilterState({ employeeIds: [String(user)] })
                }
                placeholder="Search user"
                value={employeeIds?.[0] ?? ""}
                className="w-100"
              >
                {userList.map((user) => (
                  <Select.Option key={user.id}>{user.name}</Select.Option>
                ))}
              </Select>
            </div>
          </Col>
        </If>

        <If condition={showFromFilter}>
          <Col span={6}>
            <div className="flex-column gap-2">
              <label>From:</label>
              <Select
                showArrow
                defaultActiveFirstOption={false}
                filterOption={false}
                notFoundContent={null}
                onSelect={(from) => {
                  if (+from < +to) {
                    setFilterState({ from });
                  }
                }}
                placeholder="From"
                value={from}
                className="w-100"
              >
                {years.map((y) => (
                  <Select.Option value={y}>{y}</Select.Option>
                ))}
              </Select>
            </div>
          </Col>
        </If>

        <If condition={showToFilter}>
          <Col span={6}>
            <div className="flex-column gap-2">
              <label>To:</label>
              <Select
                showArrow
                defaultActiveFirstOption={false}
                filterOption={false}
                notFoundContent={null}
                onSelect={(to) => {
                  if (+to > +from) {
                    setFilterState({ to });
                  }
                }}
                placeholder="To"
                value={to}
                className="w-100"
              >
                {years.map((y) => (
                  <Select.Option value={y}>{y}</Select.Option>
                ))}
              </Select>
            </div>
          </Col>
        </If>

        <If condition={showDateRangeFilter}>
          <Col span={6}>
            <div className="flex-column gap-2">
              <label>Date Range:</label>
              <DatePicker.RangePicker
                disabledDate={(current) => {
                  return isFuture(current.toDate());
                }}
                value={[
                  dateRange?.[0] ? moment(dateRange[0]) : undefined,
                  dateRange?.[1] ? moment(dateRange[1]) : undefined,
                ]}
                onChange={([from, to]) => {
                  setFilterState({
                    dateRange: [
                      from.format("YYYY-MM-DD"),
                      to.format("YYYY-MM-DD"),
                    ],
                  });
                }}
              />
            </div>
          </Col>
        </If>

        <If condition={showMonthsFilter}>
          <Col span={6}>
            <div className="flex-column gap-2">
              <label>Month(s): </label>
              <Select
                placeholder="Select the month(s)"
                value={months?.length > 0 ? months[0] : undefined}
                onChange={(value) => setFilterState({ months: value })}
                // mode={
                //   type === "Future Subscription Control List"
                //     ? "multiple"
                //     : undefined
                // }
              >
                {(type === "future-subscription-control-list"
                  ? generateMonths()
                  : renewalMonths || []
                ).map((m, i) => (
                  <Select.Option key={`month-${i}`} value={m}>
                    {type === "future-subscription-control-list"
                      ? format(new Date(m), "MMMM yyyy")
                      : formatMonth(m)}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </Col>
        </If>
      </Row>

      <If condition={reportType === "custom"}>
        <CustomReportingFilters />
      </If>

      <div className="flex-row gap-2 mt-3 justify-end">
        <If condition={showGenerate}>
          <Button
            disabled={loading || downloading}
            loading={loading}
            icon={<SendOutlined />}
            className="purple-button m-0"
            onClick={handleGenerate}
          >
            Generate
          </Button>
        </If>
        <Button
          disabled={loading || downloading}
          icon={<ClearOutlined />}
          type="primary"
          danger
          onClick={() => {
            clearFilters();
            resetData();
          }}
        >
          Clear
        </Button>
        <If condition={showDownload}>
          <Button
            disabled={loading || downloading}
            loading={downloading}
            icon={<DownloadOutlined />}
            type="primary"
            onClick={handleDownloadCsv}
          >
            Download CSV
          </Button>
        </If>
        <If condition={showDownloadPdf}>
          <Button
            disabled={loading || downloading}
            loading={downloading}
            icon={<FilePdfOutlined />}
            type="primary"
            onClick={handleDownloadPdf}
          >
            Download PDF
          </Button>
        </If>
      </div>
    </div>
  );
}
