import FinancialInformationCompany from "../RegisterCompany/financialInformation";
import EmploymentInformation from "../RegisterEmployee/employmentInformation";
import { useRegistrationState } from "@/stores/registration";

type Props = {
  employeeInvitationCode?: string;
  nextStep: () => void;
};

function FinancialInfo(props: Props) {
  const { registrationCapacity } = useRegistrationState();
  return (
    <>
      {registrationCapacity === "Business" && (
        <FinancialInformationCompany nextStep={props.nextStep} />
      )}
      {registrationCapacity === "Individual" && (
        <EmploymentInformation
          employeeInvitationCode={props?.employeeInvitationCode}
          nextStep={props.nextStep}
        />
      )}
    </>
  );
}
export default FinancialInfo;
