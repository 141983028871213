import { useState, useEffect, useRef } from "react";
import { Card, Table, Button, Spin, Divider } from "antd";
import moment from "moment";
import { useLazyQuery } from "@apollo/client";
import { GET_FILTERED_TRANSACTION_LIST } from "../../../graphql/queries";
import TransactionFilters from "./filters";
import { TableLoader } from "../../../components/UserManagement/TableContentPlaceholder";
import InternalErrorPage from "../../InternalErrorPage";
import EmptyState from "../../../components/Styled/EmptyState";
import { useNavigate } from "react-router-dom";

const isActiveMultiFilter = (multiFilter) => {
  let activeStatus = false;
  for (let filterKey of Object.keys(multiFilter)) {
    if (multiFilter[filterKey]) {
      activeStatus = true;
    }
  }
  return activeStatus;
};

const defaultMultiFilter = {
  id: false,
  typeId: false,
  transactionStatusId: false,
  assignedTo: false,
  companyId: false,
  sort: "DESC",
  invoiceNumber: false,
};

type Props = {
  memberId?: number | false;
  companyId?: number | false;
  mode: string;
};

function MemberTransactionsList({
  memberId = false,
  companyId = false,
  mode,
}: Props) {
  console.log({ mode });
  const navigate = useNavigate();
  const [transactionList, setTransactionList] = useState([]);
  const [listTotal, setListTotal] = useState(0);
  const [showEmptyState, setShowEmptyState] = useState(true);
  const [skip, setSkip] = useState(0);
  const [initialising, setInitialising] = useState(true);
  const [showSpin, setShowSpin] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const prePopulatedFilter =
    mode === "member-overview-transactions" && memberId
      ? {
          assignedTo: memberId.toString(),
        }
      : mode === "company-overview-transactions" && companyId
      ? {
          companyId: +companyId,
        }
      : {};
  const [multiFilter, setMultiFilter] = useState({
    ...defaultMultiFilter,
    ...prePopulatedFilter,
  });
  const [useMode] = useState(mode);
  const pageSize = 10;

  const handlePagination = (page) => {
    setSkip((page - 1) * pageSize);
    setCurrentPage(page);
  };

  const filtersRef = useRef();

  const clearFilters = () => {
    if (filtersRef && filtersRef.current) {
      // @ts-ignore: Object is possibly undefined
      filtersRef.current.resetFilters();
      let newMultiFilter = Object.assign(
        {},
        defaultMultiFilter,
        prePopulatedFilter
      );
      setMultiFilter(newMultiFilter);
      setSkip(0);
      setCurrentPage(1);
      runMultiFilterTransactionQuery(newMultiFilter, 0);
    }
  };

  const [getFilteredTransactionList] = useLazyQuery(
    GET_FILTERED_TRANSACTION_LIST,
    {
      onCompleted: (data) => {
        setTransactionList(data.transactions.transactionList);
        if (data.transactions.total) {
          setListTotal(data.transactions.total);
          if (showEmptyState) setShowEmptyState(false);
        }
        setLoading(false);
        setInitialising(false);
        setShowSpin(false);
      },
      onError: (error) => {
        setError(error.message);
      },
      fetchPolicy: "network-only",
    }
  );

  const runMultiFilterTransactionQuery = (useMultiFilter, useSkip) => {
    setShowSpin(true);
    setLoading(true);

    let postFilters = {};
    for (let [key, value] of Object.entries(useMultiFilter)) {
      if (value) {
        postFilters[key] = value;
      }
    }

    getFilteredTransactionList({
      variables: {
        input: {
          ...postFilters,
          includeTotal: useSkip === 0 ? true : false,
          skip: useSkip,
          take: pageSize,
        },
      },
    });
  };

  const handleTransactionFilterChange = (value, filterType, reset) => {
    const newMultiFilter = Object.assign(multiFilter, { [filterType]: value });
    setMultiFilter(newMultiFilter);
    if (newMultiFilter && isActiveMultiFilter(newMultiFilter)) {
      setSkip(0);
      setCurrentPage(1);
      runMultiFilterTransactionQuery(newMultiFilter, 0);
    }
  };

  useEffect(() => {
    runMultiFilterTransactionQuery(multiFilter, skip);
  }, [currentPage, skip]);

  const columns = [
    {
      title: "Trn #",
      render: (record) => record.id,
      key: "id",
    },
    ...(window.location.pathname === "/admin/transactions"
      ? [
          {
            title: "Company Name",
            render: (record) => record.company.registeredName,
            key: "company.registeredName",
          },
        ]
      : [
          {
            title: "Ref #",
            render: (record) => record.directDepositInvoiceNumber ?? "",
            key: "directDepositInvoiceNumber",
          },
        ]),
    {
      title: "Applicant Name",
      key: "task",
      render: ({ task: record }) =>
        record?.employee
          ? record.employee?.firstName + " " + record.employee?.lastName
          : "",
    },

    { title: "Type", render: (record) => record.type?.name ?? "", key: "type" },
    {
      title: "Linked Task",
      render: (record) => record.task?.id || "",
      key: "task",
    },
    {
      title: "Date Created",
      key: "createdDate",
      render: (record) =>
        record.createdDate
          ? moment(record.createdDate).format("YYYY-MM-DD")
          : "",
    },
    {
      title: "Invoice Amount",
      key: "task",
      render: ({ task: record }) => {
        if (record?.invoice?.amount && !isNaN(record?.invoice?.amount)) {
          return parseFloat(record.invoice.amount).toFixed(2);
        } else {
          return "";
        }
      },
    },
    {
      title: <span>Action</span>,
      render: (record) => {
        return (
          <a
            className="ant-dropdown-link purple-link"
            onClick={() => {
              navigate(`/admin/transaction/${record.id}`, {
                state: { transaction: record },
              });
            }}
          >
            View
          </a>
        );
      },
    },
  ];

  if (error) {
    console.error("transactions_error", error);
    return (
      <>
        <InternalErrorPage />
      </>
    );
  }

  return (
    <Spin tip="Loading..." className="loader" spinning={showSpin}>
      {initialising && <TableLoader />}
      {!initialising && showEmptyState && (
        <EmptyState
          location="transactions"
          headingText="Nothing to see here"
          bodyText="You currently have no transaction history. You can create a ticket to our support team to help with any problems you may be facing."
        />
      )}
      {!showEmptyState && (
        <>
          <div className="col-sm-12 col-md-12 placeholder-table-card">
            <Card>
              <div className="card-header">
                {useMode === "member-overview-transactions" ? (
                  <h3>Transactions</h3>
                ) : (
                  <h3>All Transactions</h3>
                )}
                <div>
                  <Button
                    style={{ marginRight: "20px" }}
                    className="purple-button"
                    onClick={clearFilters}
                  >
                    Clear filters
                  </Button>
                </div>
              </div>
              <div className={loading ? "no-interact" : null}>
                {!initialising && (
                  <TransactionFilters
                    ref={filtersRef}
                    setError={setError}
                    handleTransactionFilterChange={
                      handleTransactionFilterChange
                    }
                    defaultMultiFilter={defaultMultiFilter}
                    mode={useMode}
                  />
                )}
                <Divider />
                <Table
                  rowKey="id"
                  columns={columns}
                  pagination={{
                    current: currentPage,
                    showSizeChanger: false,
                    pageSize,
                    onChange: (page) => handlePagination(page),
                    total: listTotal,
                    showTotal: () => <h3>Total: {listTotal}</h3>,
                  }}
                  dataSource={transactionList}
                />
              </div>
            </Card>
          </div>
        </>
      )}
    </Spin>
  );
}

export default MemberTransactionsList;
