import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import AWaitingConfirmation from "./awaitingCofirmation";
import MemberProfile from "../Admin/Members/MemberOverview/profile";
import { APPLICATION_VERIFIED } from "../../constants";
import { GET_MEMBER_OVERVIEW_INFORMATION } from "../Admin/graphql/queries";
import { useUserState } from "@/stores/user";

function Profile() {
  const [useMemberOverviewData, setMemberOverviewData] = useState<any>();
  const [useCompanyInformation, setCompanyInformation] = useState();

  const { activeUserRole, userRoles, id } = useUserState();
  const role = userRoles.find((ur) => ur.id === activeUserRole);

  const isApplicationVerified =
    role?.applicationStatus === APPLICATION_VERIFIED;

  const [fetchMemberOverviewInformation] = useLazyQuery(
    GET_MEMBER_OVERVIEW_INFORMATION,
    {
      variables: {
        userId: id,
      },
      onCompleted: (data) => {
        setMemberOverviewData(data);
        setCompanyInformation(
          data.userData.employment.find(
            (emp: any) => emp.company.id === role.companyId
          )
        );
      },
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    fetchMemberOverviewInformation();
  }, []);

  return (
    <>
      {!isApplicationVerified ? (
        <AWaitingConfirmation />
      ) : (
        <MemberProfile
          companyId={role.companyId}
          profileData={useMemberOverviewData}
          companyInformation={useCompanyInformation}
          refetchProfile={fetchMemberOverviewInformation}
          employments={[useCompanyInformation]}
        />
      )}
    </>
  );
}

export default Profile;
