import { useQuery } from "@apollo/client";
import { GET_BACKGROUND_JOBS } from "../graphql/queries";
import { notifyError } from "@/utils/notification";
import { Card, Skeleton, Table, Input, Tag } from "antd";
import { format } from "date-fns";
import { If } from "@/components/If";

function BackgroundJobs() {
  const { loading, data } = useQuery(GET_BACKGROUND_JOBS, {
    fetchPolicy: "network-only",
    onError: (error) => {
      notifyError(error);
    },
  });

  return (
    <div className="col-sm-12 col-md-12 placeholder-table-card">
      <Card>
        <If condition={loading || !data}>
          <Skeleton active title paragraph={{ rows: 5 }} />
        </If>
        <If condition={!loading && data}>
          <h3>Background Jobs</h3>
          <br />
          <Table
            columns={[
              {
                title: "StartedAt",
                key: "startedAt",
                render: ({ startedAt }) => {
                  return format(new Date(startedAt), "dd/MM/yyyy");
                },
              },
              {
                title: "LastUpdateAt",
                key: "endedAt",
                render: ({ endedAt }) => {
                  return format(new Date(endedAt), "dd/MM/yyyy");
                },
              },
              {
                title: "Status",
                render: ({ status }) => {
                  const color =
                    status === "finished"
                      ? "green"
                      : status === "started"
                      ? "processing"
                      : "red";
                  return <Tag color={color}>{status.toUpperCase()}</Tag>;
                },
                key: "status",
              },
              {
                title: "Job Name",
                render: ({ functionName }) => functionName,
                key: "functionName",
              },
              {
                title: "Job Description",
                render: ({ functionDescription }) => functionDescription,
                key: "functionDescription",
              },
              {
                title: "Details",
                render: ({ details }) => (
                  <Input.TextArea value={details} disabled autoSize />
                ),
                key: "details",
              },
            ]}
            rowKey="id"
            size="small"
            dataSource={data?.getLambdaExecutionLogs || []}
            pagination={{ pageSize: 50, hideOnSinglePage: true }}
          />
        </If>
      </Card>
    </div>
  );
}

export default BackgroundJobs;
