import { useState, useEffect, useRef } from "react";
import { DownOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Card,
  Table,
  Button,
  Spin,
  Divider,
  Badge,
  Menu,
  Dropdown,
} from "antd";
import moment from "moment";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_DOCUMENTS, GET_DOWNLOAD_URL } from "../../../graphql/queries";
import { ARCHIVE_DOCUMENT } from "../../../graphql/mutations";
import DocumentFilters from "./filters";
import { TableLoader } from "../../../components/UserManagement/TableContentPlaceholder";
import InternalErrorPage from "../../InternalErrorPage";
import CreateDocumentForm from "./createDocument";
import EmptyState from "../../../components/Styled/EmptyState";
import { openNotificationWithIcon } from "@/utils/notification";
import { ADMIN_STREAMS } from "../../../constants";

const isActiveMultiFilter = (multiFilter) => {
  let activeStatus = false;
  for (let filterKey of Object.keys(multiFilter)) {
    if (multiFilter[filterKey]) {
      activeStatus = true;
    }
  }
  return activeStatus;
};

const defaultMultiFilter = {
  documentTypeId: false,
  documentStatusId: 2,
  documentName: false,
  userId: false,
  companyId: false,
  sort: "DESC",
};

interface IProps {
  companyName?: string;
  companyId?: number;
  memberId?: number;
  memberIdNumber?: string;
  mode: string;
}

function MemberDocumentsList({
  memberId,
  companyId,
  companyName,
  memberIdNumber,
  mode,
}: IProps) {
  const [documentList, setDocumentList] = useState([]);
  const [listTotal, setListTotal] = useState(0);
  const [presignedDownloadUrl, setPresignedDownloadUrl] = useState("");
  const [showEmptyState, setShowEmptyState] = useState(true);
  const [skip, setSkip] = useState(0);
  const [initialising, setInitialising] = useState(true);
  const [showSpin, setShowSpin] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [createDocumentModalVisible, setCreateDocumentModalVisible] =
    useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const prePopulatedFilter =
    (mode === "member-overview-documents" || mode === "my-documents") &&
    memberId
      ? {
          userId: memberId,
        }
      : ["company-overview-documents", "soleprop-overview-documents"].indexOf(
          mode
        ) > -1 && !isNaN(companyId)
      ? {
          companyId: companyId,
        }
      : {};
  const [multiFilter, setMultiFilter] = useState<{}>(
    Object.assign({}, defaultMultiFilter, prePopulatedFilter)
  );

  useEffect(() => {
    if (
      (mode === "member-overview-documents" || mode === "my-documents") &&
      memberId
    ) {
      setMultiFilter({ ...multiFilter, userId: memberId });
    }
    if (
      ["company-overview-documents", "soleprop-overview-documents"].indexOf(
        mode
      ) > -1 &&
      !isNaN(companyId)
    ) {
      setMultiFilter({ ...multiFilter, companyId: companyId });
    }
  }, [mode, companyId, memberId]);

  const [useMode] = useState(mode);
  const pageSize = 10;

  const handlePagination = (page) => {
    setSkip((page - 1) * pageSize);
    setCurrentPage(page);
  };

  const filtersRef = useRef();

  const [getDocumentLink] = useLazyQuery(GET_DOWNLOAD_URL, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setPresignedDownloadUrl(data.preSignedDownloadUrl);
      window.open(data.preSignedDownloadUrl);
      setShowSpin(false);
      setLoading(false);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const clearFilters = () => {
    if (filtersRef && filtersRef.current) {
      // @ts-ignore: Object is possibly undefined
      filtersRef.current.resetFilters();
      let newMultiFilter = Object.assign(
        {},
        defaultMultiFilter,
        prePopulatedFilter
      );
      setMultiFilter(newMultiFilter);
      setSkip(0);
      setCurrentPage(1);
      runMultiFilterDocumentQuery(newMultiFilter, 0, true);
    }
  };

  const [getFilteredDocumentList] = useLazyQuery(GET_DOCUMENTS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setDocumentList(data.documents.documentList);
      if (data.documents.total) {
        setListTotal(data.documents.total);
        if (showEmptyState) setShowEmptyState(false);
      }
      setLoading(false);
      setInitialising(false);
      setShowSpin(false);
    },
    onError: (error) => {
      setError(error.message);
    },
  });

  const [getFilteredDocumentListNoCache] = useLazyQuery(GET_DOCUMENTS, {
    onCompleted: (data) => {
      setDocumentList(data.documents.documentList);
      if (data.documents.total) {
        setListTotal(data.documents.total);
        if (showEmptyState) setShowEmptyState(false);
      }
      setLoading(false);
      setInitialising(false);
      setShowSpin(false);
    },
    onError: (error) => {
      setError(error.message);
    },
    fetchPolicy: "network-only",
  });

  const runMultiFilterDocumentQuery = (
    useMultiFilter,
    useSkip,
    skipCache = false
  ) => {
    setShowSpin(true);
    setLoading(true);
    let postFilters = {};
    for (let [key, value] of Object.entries(useMultiFilter)) {
      if (value) {
        postFilters[key] = value;
      }
    }
    if (!skipCache) {
      getFilteredDocumentList({
        variables: {
          input: {
            ...postFilters,
            includeTotal: useSkip === 0 ? true : false,
            skip: useSkip,
            take: pageSize,
          },
        },
      });
    } else {
      getFilteredDocumentListNoCache({
        variables: {
          input: {
            ...postFilters,
            includeTotal: useSkip === 0 ? true : false,
            skip: useSkip,
            take: pageSize,
          },
        },
      });
    }
  };

  const handleDocumentFilterChange = (value, filterType, reset) => {
    let newMultiFilter = Object.assign(multiFilter, { [filterType]: value });
    setMultiFilter(newMultiFilter);
    if (newMultiFilter && isActiveMultiFilter(newMultiFilter)) {
      setSkip(0);
      setCurrentPage(1);
      runMultiFilterDocumentQuery(newMultiFilter, 0);
    }
  };

  const handleViewTemplate = (filePath) => {
    if (presignedDownloadUrl && presignedDownloadUrl.indexOf(filePath) > -1)
      window.open(presignedDownloadUrl);
    else {
      setShowSpin(true);
      setLoading(true);
      getDocumentLink({
        variables: {
          input: {
            filePath,
            forCompany:
              [
                "company-overview-documents",
                "soleprop-overview-documents",
              ].indexOf(mode) > -1 && !isNaN(companyId)
                ? true
                : false,
          },
        },
      });
    }
  };

  const [archiveDocument] = useMutation(ARCHIVE_DOCUMENT, {
    onCompleted: () => {
      openNotificationWithIcon("success", "Success", "Successfully Archived");
      clearFilters();
    },
    onError: (err) => {
      console.error("archive_document_error", err);
      openNotificationWithIcon("error", "Error", "Successfully Archived");
    },
  });

  const handleArchiveDocument = async ({ id }) => {
    setShowSpin(true);
    await archiveDocument({
      variables: {
        input: {
          id,
          documentStatusId: 1,
        },
      },
    });
    setShowSpin(false);
  };

  useEffect(() => {
    runMultiFilterDocumentQuery(multiFilter, skip);
  }, [currentPage, skip, multiFilter]);

  const columns = [
    {
      title: "Document Type",
      render: (record) => record.documentType?.extendedName || "",
      key: "id",
    },
    {
      title: "Company Name",
      render: (record) => record.company?.registeredName || "",
      key: "company.registeredName",
    },
    {
      title: "Document Name",
      render: (record) => record.name || "",
      key: "name",
    },
    {
      title: "Date Created",
      key: "createDate",
      render: (record) =>
        record ? moment(record.createDate).format("YYYY-MM-DD HH:mm") : "",
    },
    {
      title: "Status",
      key: "status",
      render: (record) => {
        const status = record.status?.name;
        let useStatus:
          | "success"
          | "processing"
          | "default"
          | "error"
          | "warning" = "warning";

        if (["Uploaded", "Active"].includes(status)) {
          useStatus = "success";
        } else if (status === "Required") {
          useStatus = "error";
        } else if (status === "Archived") {
          useStatus = "processing";
        }
        return status ? (
          <span style={{ display: "flex" }}>
            <Badge status={useStatus} /> {status}
          </span>
        ) : (
          ""
        );
      },
    },
    {
      title: <span>Action</span>,
      render: (record) => {
        const menu = (
          <Menu>
            <Menu.Item onClick={() => handleViewTemplate(record.documentPath)}>
              View Document
            </Menu.Item>
            {ADMIN_STREAMS.includes(localStorage.getItem("userRoles")) && (
              <Menu.Item onClick={() => handleArchiveDocument(record)}>
                Archive Document
              </Menu.Item>
            )}
          </Menu>
        );
        return (
          <>
            <Dropdown
              overlay={menu}
              placement="bottomRight"
              trigger={["click"]}
            >
              <a
                className="ant-dropdown-link purple-link"
                onClick={(e) => e.preventDefault()}
              >
                More
                <DownOutlined title="member actions dropdown" />
              </a>
            </Dropdown>
          </>
        );
      },
    },
  ];

  const CreateDocumentModalProps = {
    visible: createDocumentModalVisible,
    memberId: memberId,
    memberIdNumber: memberIdNumber,
    companyId: companyId,
    companyName: companyName,
    onCancel: () => setCreateDocumentModalVisible(false),
    clearFilters,
    initialData: false,
  };

  if (error) {
    return <InternalErrorPage error={error} />;
  }

  return (
    <>
      <Spin tip="Loading..." className="loader" spinning={showSpin} />
      {initialising && <TableLoader />}
      {!initialising && showEmptyState && (
        <EmptyState
          location="docs"
          headingText="Nothing to see here"
          bodyText="You currently have no documents. You can create a ticket to our support team to help with any problems you may be facing."
        >
          <p>
            <Button
              className="purple-button"
              onClick={() => setCreateDocumentModalVisible(true)}
            >
              <PlusOutlined title="Create new document" />
              Upload new document
            </Button>
          </p>
        </EmptyState>
      )}
      <CreateDocumentForm {...CreateDocumentModalProps} />
      {!showEmptyState && (
        <>
          <div className="col-sm-12 col-md-12 placeholder-table-card">
            <Card>
              <div className="card-header">
                {useMode === "my-documents" ? (
                  <h3>My Documents</h3>
                ) : (
                  <h3>Documents</h3>
                )}
                <div>
                  <Button
                    style={{ marginRight: "20px" }}
                    className="purple-button"
                    onClick={clearFilters}
                  >
                    Clear filters
                  </Button>
                  {[
                    "my-documents",
                    "member-overview-documents",
                    "soleprop-overview-documents",
                    "company-overview-documents",
                  ].indexOf(mode) > -1 && (
                    <Button
                      className="purple-button"
                      onClick={() => setCreateDocumentModalVisible(true)}
                    >
                      <PlusOutlined title="Create new document" />
                      Upload new document
                    </Button>
                  )}
                </div>
              </div>
              <div className={loading ? "no-interact" : null}>
                {!initialising && (
                  <DocumentFilters
                    ref={filtersRef}
                    setError={setError}
                    handleDocumentFilterChange={handleDocumentFilterChange}
                    defaultMultiFilter={defaultMultiFilter}
                    mode={useMode}
                  />
                )}
                <Divider />
                <Table
                  rowKey="id"
                  columns={columns}
                  pagination={{
                    current: currentPage,
                    showSizeChanger: false,
                    pageSize,
                    onChange: (page) => handlePagination(page),
                    total: listTotal,
                    showTotal: () => <h3>Total: {listTotal}</h3>,
                  }}
                  dataSource={documentList}
                />
              </div>
            </Card>
          </div>
        </>
      )}
    </>
  );
}

export default MemberDocumentsList;
