import { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_PENDING_APPLICATIONS_BY_FILTER } from "../graphql/queries";
import { notifyError } from "@/utils/notification";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Card, Divider, Skeleton, Table } from "antd";
import { format } from "date-fns";
import { If } from "@/components/If";
import { PendingApplicationsFilters } from "./PendingApplicationsFilters";
import { MARK_APPLICATION_AS_VERIFIED } from "../graphql/mutations";
import { usePendingApplicationsFilters } from "@/hooks/UsePendingApplicationsFilters";

function PendingApplications() {
  const [total, setTotal] = useState(0);
  const [applications, setApplications] = useState([]);
  const {
    companyId,
    userId,
    pageNumber,
    createdById,
    take,
    skip,
    setFilterState,
    clearFilters,
  } = usePendingApplicationsFilters();

  const [getPendingApplications, { loading }] = useLazyQuery(
    GET_PENDING_APPLICATIONS_BY_FILTER,
    {
      fetchPolicy: "network-only",
      onCompleted: ({ getPendingApplicationsByFilter }) => {
        setApplications(getPendingApplicationsByFilter.result);
        setTotal(getPendingApplicationsByFilter.count);
      },
      onError: (error) => {
        notifyError(error);
      },
    }
  );

  const [markVerified, { loading: marking }] = useMutation(
    MARK_APPLICATION_AS_VERIFIED,
    {
      onCompleted: () => {
        handleSearch();
      },
      onError: (error) => {
        notifyError(error);
      },
    }
  );

  const handleSearch = () => {
    getPendingApplications({
      variables: {
        input: {
          take,
          skip,
          companyId: companyId ? +companyId : undefined,
          userId: userId ? +userId : undefined,
          createdById: createdById ? +createdById : undefined,
        },
      },
    });
  };

  useEffect(() => {
    handleSearch();
  }, [pageNumber, take, skip, companyId, userId, createdById]);

  return (
    <div className="col-sm-12 col-md-12 placeholder-table-card">
      <Card>
        <div className="card-header">
          <h3>Pending Applications</h3>
          <div>
            {/* <Button
              icon="download"
              className="purple-button"
              onClick={handleDownload}
              loading={downloading}
            >
              Download
            </Button> */}
            <Button className="purple-button" onClick={clearFilters}>
              Clear filters
            </Button>
          </div>
        </div>
        <If condition={loading || marking}>
          <Skeleton active title paragraph={{ rows: 5 }} />
        </If>
        <If condition={!loading && !marking}>
          <PendingApplicationsFilters />
          <Divider />
          <Table
            rowKey="id"
            columns={[
              {
                title: "Date",
                render: ({ dateCreated }) =>
                  format(new Date(dateCreated), "dd/MM/yyyy"),
                key: "dateCreated",
              },
              {
                title: "Type",
                render: (record) => record.applicantionType,
                key: "applicationType",
              },
              {
                title: "Employee",
                key: "employee",
                render: (record) => {
                  if (!record?.employee) {
                    return "<Unspecified>";
                  }

                  return `${record?.employee?.lastName || ""} ${
                    record?.employee?.firstName || ""
                  }`.toUpperCase();
                },
              },
              {
                title: "Employee Status",
                key: "status",
                render: (record) => {
                  if (!record?.employee) {
                    return "<Unspecified>";
                  }

                  return `${record?.employee?.userStatus || ""}`.toUpperCase();
                },
              },
              {
                title: "Company",
                key: "company",
                render: (record) => {
                  if (!record?.employer) {
                    return "<Unspecified>";
                  }

                  const { registeredName, tradingName } = record.employer;

                  return (registeredName || tradingName).toUpperCase() || "";
                },
              },
              {
                title: "Created By",
                key: "createdBy",
                render: (record) => {
                  if (!record?.createdBy) {
                    return "<Unspecified>";
                  }

                  return `${record?.createdBy?.lastName || ""} ${
                    record?.createdBy?.firstName || ""
                  }`.toUpperCase();
                },
              },
              {
                title: "",
                key: "action",
                render: (record) => (
                  <Button
                    type="link"
                    icon={<CheckCircleOutlined />}
                    disabled={marking}
                    onClick={() => {
                      markVerified({
                        variables: {
                          id: record.id,
                        },
                      });
                    }}
                  >
                    Complete
                  </Button>
                ),
              },
            ]}
            size="small"
            pagination={{
              showSizeChanger: false,
              current: pageNumber,
              pageSize: take,
              onChange: (page) => {
                setFilterState({ pageNumber: page.toString() });
              },
              total: total,
              showTotal: () => <h3>Total: {total}</h3>,
            }}
            dataSource={applications}
          />
        </If>
      </Card>
    </div>
  );
}

export default PendingApplications;
