import { Result } from "antd";

export default function OfflinePage() {
  return (
    <Result
      status="500"
      title="You are Offline"
      subTitle="You are currently offline. Please check your network connection and try again"
    />
  );
}
