import { useState, useEffect } from "react";
import {
  Button,
  Radio,
  Input,
  Select,
  Col,
  Row,
  Divider,
  Skeleton,
  Form,
} from "antd";
//
import {
  GET_COMPANIES_BY_FILTER,
  GET_CAPACITY_LIST,
  GET_ADDRESS_LIST,
  GET_USER_ACTIVE_APPLICATIONS,
  GET_COMPANY_FROM_EMPLOYEE_INVITATION,
} from "../../graphql/queries";
import { GET_USER_TYPES, GET_USER } from "../Admin/graphql/queries";
import { isEmpty } from "lodash";
import {
  UPSERT_USER_EMPLOYMENT,
  CREATE_APPLICATION,
  UPDATE_APPLICATION,
  UPDATE_USER,
} from "../../graphql/mutations";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { notifyError, openNotificationWithIcon } from "@/utils/notification";
import { SOLE_PROPRIETOR_USER_ROLE, ADMIN_STREAMS } from "../../constants";
import { If } from "@/components/If";
import { useRegistrationState } from "@/stores/registration";
import { useUserState } from "@/stores/user";

interface IProps {
  employeeInvitationCode?: string;
  nextStep: () => void;
}

function EmploymentInformationForm(props: IProps) {
  const [form] = Form.useForm();
  const {
    setRegistrationState,
    manualRegisterId,
    manualRegistrationType,
    manualRegisterCompanyId,
    isMultipleEmployment,
    registeringAs,
    registrationCapacity,
    registrationType,
    batchRegistrationId,
    companyId: cid,
  } = useRegistrationState();
  const { id: uid } = useUserState();
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [roles, setRoles] = useState<any>();
  const [selectedCapacity, setSelectedCapacity] = useState("");
  const [selectedCapacityId, setSelectedCapacityId] = useState<number>();
  const [employmentInfo, setEmploymentInfo] = useState<any>();
  const [allEmployments, setAllEmployments] = useState([]);
  const [applicationId, setApplicationId] = useState<number>();
  const [companiesList, setCompaniesList] = useState([]);
  const [previousCompanyList, setPreviousCompanyList] = useState([]);
  const [capacityList, setCapacityList] = useState([]);
  const [loadingEmployments, setLoadingEmployments] = useState(false);
  const [useEmployeeInvitationCode, setUseEmployeeInvitationCode] =
    useState("");
  const [companyAddressInfo, setCompanyAddressInfo] = useState<any>();
  const [userAddressType, setUserAddressType] = useState("Head/Main Office");
  const [councilRegistrationNumber, setCouncilRegistrationNumber] =
    useState("");

  const userRole = localStorage.getItem("userRoles");
  const companyId = !!manualRegistrationType ? manualRegisterCompanyId : cid;
  const userId = manualRegistrationType ? manualRegisterId : uid;

  const isAdmin = ADMIN_STREAMS.includes(userRole);

  const [updateUserRole] = useMutation(UPDATE_USER);

  const [fetchApplicationInfo] = useLazyQuery(GET_USER_ACTIVE_APPLICATIONS, {
    variables: { userId },
    onCompleted: ({ userActiveApplication }) => {
      setLoadingEmployments(false);
      if (!userActiveApplication) return;
      setApplicationId(userActiveApplication.id);

      const employments = userActiveApplication?.employee?.employment;
      setAllEmployments(employments);

      if (manualRegistrationType === "transfer") {
        const prevCompanies = employments?.map(({ company }) => ({
          id: company?.id,
          registeredName: company?.registeredName,
          tradingName: company?.tradingName,
        }));

        setPreviousCompanyList(prevCompanies);
      }

      if (!isMultipleEmployment && manualRegistrationType !== "transfer") {
        const currentCompanyEmployments = employments?.filter(
          ({ company }) => company?.id === companyId
        );

        const employment =
          currentCompanyEmployments.length > 0 &&
          currentCompanyEmployments[currentCompanyEmployments.length - 1];

        if (employment) {
          setEmploymentInfo(employment);
          setCompaniesList([employment.company]);
          form.setFieldsValue({
            councilRegistrationNumber: employment?.company?.membershipNumber,
            registrationNumber: employment?.company?.registeredNumber,
          });

          // fetch selected company address list
          const companyId = employment.company?.id;
          if (companyId) {
            setRegistrationState("companyId", companyId);
          }
          setUserAddressType(employment.employerAddressType);
        }
      }
    },
    onError: () => {
      setLoadingEmployments(false);
      openNotificationWithIcon("error", "Error", "Error loading user data");
    },
  });

  const [upsertUserApplication] = useMutation(
    applicationId ? UPDATE_APPLICATION : CREATE_APPLICATION,
    {
      onError: () => {
        setIsSubmitting(false);
        openNotificationWithIcon(
          "error",
          "Save Error",
          "Error when saving employment information. Please try again"
        );
      },
      onCompleted: async () => {
        // update user role to Sole Proprietor - if selected capacity is sole prop
        if (selectedCapacity === SOLE_PROPRIETOR_USER_ROLE) {
          await updateUserRole({
            variables: {
              input: {
                id: userId,
                userRoles: [roles],
                capacityId: selectedCapacityId,
                representsId: companyId,
              },
            },
          });
        }
        setIsSubmitting(false);
        setRegistrationState("step4Saved", true);
        openNotificationWithIcon(
          "success",
          "Save Success",
          "Employment information saved successfully"
        );
      },
    }
  );

  const [upsertEmploymentInfo] = useMutation(UPSERT_USER_EMPLOYMENT, {
    onError: (error) => {
      setIsSubmitting(false);
      console.error("upsert_employment_info_error", error);
      return openNotificationWithIcon(
        "error",
        "Save Error",
        "Error when saving employment information. Please ensure that your invitation code is correct and that it has not already been accepted. Otherwise, please confirm with your employer that your ID number matches your invitation."
      );
    },
    onCompleted: (data) => {
      if (data?.upsertUserEmployment) {
        upsertUserApplication({
          variables: {
            input: {
              id: applicationId,
              registrationType:
                manualRegistrationType === "multiple-employments"
                  ? "Multiple Employments"
                  : registrationType,
              registrationCapacity,
              registeredAs: registeringAs,
              applicantType: "Online Registration",
              applicantionType: registeringAs,
              employeeId: userId,
              employerId: +companyId,
              employmentInvitationCode: useEmployeeInvitationCode,
            },
          },
        });
      }
    },
  });

  const [getUser] = useLazyQuery(GET_USER, {
    variables: {
      input: { id: userId },
    },
    onCompleted: ({ userData }) => {
      const employment = userData?.employment?.find(
        ({ company }) => company?.id === companyId
      );

      form.setFieldsValue({
        employer: employment?.company?.registeredName,
        councilRegistrationNumber: employment?.company?.membershipNumber,
        registrationNumber: employment?.company?.registeredNumber,
      });
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    setLoadingEmployments(true);
    fetchApplicationInfo();
    if (!isAdmin) getUser();
  }, []);

  useEffect(() => {
    getCompanyAddress({
      variables: {
        companyId,
      },
    });
  }, [companyId]);

  const step = {
    title: "Step 4: Employment information",
    content: "Enter your employment information",
  };

  const { loading: loadingCapacity, data: dataCapacity } = useQuery(
    GET_CAPACITY_LIST,
    {
      onError: () =>
        openNotificationWithIcon(
          "error",
          "Error",
          "Error loading capacity list"
        ),
      onCompleted: () => {
        if (
          !dataCapacity?.capacityList ||
          !Array.isArray(dataCapacity?.capacityList) ||
          dataCapacity?.capacityList.length === 0
        ) {
          return;
        }

        // filter out duplicate capabilities
        const capabilities: any[] = dataCapacity.capacityList.filter(
          (cap: any, i: number, arr: any) => {
            const index = arr.findIndex((c: any) => c.name === cap.name);
            return index === i;
          }
        );

        if (manualRegistrationType) {
          const caps = capabilities.filter((cap: any) => {
            let _caps = ["Director", "Member", "Officer", "Employee"];

            if (batchRegistrationId) {
              _caps = ["Employee", "Officer"];
            }

            return _caps.includes(cap.name);
          });

          setCapacityList(caps);

          return;
        }

        if (
          registrationCapacity === "Business" &&
          registeringAs === "A Sole Proprietor"
        ) {
          setCapacityList(
            capabilities.filter(
              (x) => !["Trustee", "Partner", "Director"].includes(x.name)
            )
          );
        } else if (registrationCapacity === "Individual")
          setCapacityList(capabilities.filter((x) => x.name === "Employee"));
      },
    }
  );

  const [
    getCompanyAddress,
    {
      loading: loadingAddress,
      //refetch: refetchCompanyAddress,
    },
  ] = useLazyQuery(GET_ADDRESS_LIST, {
    variables: { companyId },
    onError: () =>
      openNotificationWithIcon(
        "error",
        "Error",
        "Error loading company address list"
      ),
    onCompleted: ({ addresses }) => {
      // set company address info from query result
      setCompanyAddressInfo(addresses);
    },
  });

  const [searchCompanies] = useLazyQuery(GET_COMPANIES_BY_FILTER, {
    fetchPolicy: "network-only",
    variables: {},
    onError: () =>
      openNotificationWithIcon(
        "error",
        "Error",
        "Error loading employer information"
      ),
    onCompleted: (data) =>
      setCompaniesList(data.companiesByFilter?.companyList),
  });

  const [getCompanyDataFromInvitationCode] = useLazyQuery(
    GET_COMPANY_FROM_EMPLOYEE_INVITATION,
    {
      variables: {},
      fetchPolicy: "network-only",
      onError: () =>
        openNotificationWithIcon(
          "error",
          "Error",
          "Error with provided employee invitation code"
        ),
      onCompleted: (data) => {
        if (data?.companyFromEmployeeInvitation) {
          setRegistrationNumber(
            data?.companyFromEmployeeInvitation?.registeredNumber
          );
          setCouncilRegistrationNumber(
            data?.companyFromEmployeeInvitation?.membershipNumber
          );
          form.setFieldsValue({
            councilRegistrationNumber:
              data?.companyFromEmployeeInvitation?.membershipNumber,
            registrationNumber:
              data?.companyFromEmployeeInvitation?.registeredNumber,
          });
          setCompaniesList([data?.companyFromEmployeeInvitation]);
          //fetch selected company address list
          getCompanyAddress({
            variables: {
              companyId: Number.parseInt(
                data?.companyFromEmployeeInvitation?.id
              ),
            },
          });

          setRegistrationState(
            "companyId",
            data?.companyFromEmployeeInvitation?.id
          );
        } else {
          openNotificationWithIcon(
            "error",
            "Error",
            "Error with provided employee invitation code"
          );
        }
      },
    }
  );

  useEffect(() => {
    if (
      props.employeeInvitationCode &&
      props.employeeInvitationCode.length > 0
    ) {
      fetchCompanyFromInvitation(props.employeeInvitationCode);
    }
  }, [props.employeeInvitationCode]);

  const fetchCompanyFromInvitation = (invitationCode) => {
    if (invitationCode && invitationCode.length === 32) {
      getCompanyDataFromInvitationCode({
        variables: {
          code: invitationCode,
        },
      });

      setUseEmployeeInvitationCode(props.employeeInvitationCode);
    }
  };

  const setRegistrationNumberField = (e) => {
    const company = companiesList.find((x) => x.id === Number.parseInt(e));
    setRegistrationNumber(company?.registeredNumber);
    setCouncilRegistrationNumber(company?.membershipNumber);

    form.setFieldsValue({
      councilRegistrationNumber: company?.registeredNumber,
      registrationNumber: company?.membershipNumber,
    });

    //fetch selected company address list
    getCompanyAddress({
      variables: {
        companyId: Number.parseInt(e),
      },
    });
  };

  const handleCompaniesSearch = (value) => {
    if (value.length > 2) {
      searchCompanies({ variables: { criteria: "searchText", filter: value } });
    }
  };

  const [getUserRoles] = useLazyQuery(GET_USER_TYPES, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const role = data.userTypesList.filter(
        (x) => x.name === SOLE_PROPRIETOR_USER_ROLE
      );
      setRoles(role[0]?.id);
    },
  });

  const handleCapacityChange = (e) => {
    setSelectedCapacityId(e);
    const capacity = capacityList.filter((x) => x.id === e);
    if (capacity[0] && capacity[0].name === SOLE_PROPRIETOR_USER_ROLE) {
      setSelectedCapacity(SOLE_PROPRIETOR_USER_ROLE);
      getUserRoles();
    }
  };

  const handlePreviousCompanyChange = (id: string) =>
    setRegistrationState("previousCompanyId", +id);

  const handleSubmitEmploymentInformation = async (values: any) => {
    setIsSubmitting(true);
    if (isAdmin) {
      setRegistrationState("companyId", values.employer);
    }
    setUseEmployeeInvitationCode(values.employmentInvitationCode);

    if (!isMultipleEmployment) {
      setRegistrationState("employmentId", employmentInfo?.id);
    }

    upsertEmploymentInfo({
      variables: {
        input: {
          id:
            manualRegistrationType === "transfer"
              ? allEmployments.find(
                  ({ company }) => company?.id === +values.previousEmployer
                )?.id
              : isMultipleEmployment
              ? undefined
              : employmentInfo && employmentInfo.id,
          userId,
          employerId: +values.employer,
          capacityId: values.capacity,
          councilRegistrationNumber: values.councilRegistrationNumber,
          employerAddressType: values.addressType,
          employerBranchLocation:
            values.addressType === "Head/Main Office"
              ? undefined
              : values.branch,
          employmentInvitationCode: values.employmentInvitationCode,
          isManualRegistration: !!manualRegistrationType,
          isTransfer: manualRegistrationType === "transfer",
        },
      },
    });
  };

  //selectable options of capacity
  const capacityOptions: any = capacityList
    ? capacityList.map((type) => {
        return (
          <Select.Option key={type.id} value={type.id}>
            {type.name}
          </Select.Option>
        );
      })
    : [];

  //selectable address types options
  const addressBranchOptions: any = companyAddressInfo
    ? companyAddressInfo.map((type) => {
        return (
          <Select.Option key={type.id} value={type.suburb}>
            {type.suburb}
          </Select.Option>
        );
      })
    : [];

  const companiesOptions = companiesList.map((d) => (
    <Select.Option key={d.id.toString()}>
      {d.registeredName || d.tradingName}
    </Select.Option>
  ));

  const previousCompaniesOptions = previousCompanyList.map((d) => (
    <Select.Option key={d.id}>
      {d.registeredName || d.tradingName}
    </Select.Option>
  ));

  const handleInvitationCodeChange = (e) => {
    if (e.target.value && e.target.value.length === 32) {
      fetchCompanyFromInvitation(e.target.value);
    }
  };

  if (loadingAddress || loadingCapacity || loadingEmployments) {
    return <Skeleton active paragraph={{ rows: 6 }} />;
  }

  return (
    <div className="" style={{ paddingBottom: "25px" }}>
      <div>
        <span className="step-title">{step.title}</span>
        <p>{step.content}</p>
      </div>
      <Divider />
      <Form
        layout="vertical"
        form={form}
        onFinish={handleSubmitEmploymentInformation}
        onFinishFailed={(errors) => {
          console.log(errors);
          notifyError("Please fill in all required fields");
        }}
      >
        <div className="">
          {!manualRegistrationType && (
            <div className="flex-column-input-block">
              <label>Employment Invitation Code</label>
              <Form.Item
                label="Employment Invitation Code"
                name="employmentInvitationCode"
                {...{
                  initialValue:
                    useEmployeeInvitationCode &&
                    useEmployeeInvitationCode.length > 0
                      ? useEmployeeInvitationCode
                      : "",
                  rules: [
                    {
                      required: false,
                      message: "Please enter an employee invitation code",
                    },
                    {
                      len: 32,
                      message: "Invitation code should be 32 characters long",
                    },
                  ],
                }}
              >
                <Input
                  onChange={(e) => handleInvitationCodeChange(e)}
                  placeholder="Enter Employee Invitation code as stated in your Company Invite email"
                />
              </Form.Item>
            </div>
          )}
          {manualRegistrationType === "transfer" && (
            <div className="flex-column input-block">
              <Form.Item
                label="Previous Employer Name"
                name="previousEmployer"
                rules={[
                  {
                    required: true,
                    message: "Please select previous company",
                  },
                ]}
              >
                <Select
                  placeholder="Please select company"
                  defaultActiveFirstOption={false}
                  showArrow
                  filterOption={false}
                  notFoundContent={null}
                  onSelect={handlePreviousCompanyChange}
                  loading={loadingEmployments}
                >
                  {previousCompaniesOptions}
                </Select>
              </Form.Item>
            </div>
          )}
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                label={
                  manualRegistrationType === "transfer"
                    ? "New Employer Name"
                    : "Employer Name"
                }
                name="employer"
                rules={[
                  {
                    required: true,
                    message: "Please enter new company name",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Please enter company name"
                  defaultActiveFirstOption={!isAdmin}
                  showArrow={false}
                  filterOption={false}
                  notFoundContent={null}
                  onSearch={handleCompaniesSearch}
                  onSelect={setRegistrationNumberField}
                  defaultValue={companiesList[0]?.id.toString()}
                >
                  {companiesOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Registration Number"
                {...{
                  name: "registrationNumber",
                  initialValue: !isEmpty(employmentInfo?.company)
                    ? employmentInfo.company?.registeredNumber
                    : registrationNumber,
                  rules: [
                    {
                      required: false,
                      message: "Please enter business registration number",
                    },
                  ],
                }}
              >
                <Input
                  disabled
                  placeholder="Please enter business registration number"
                  value={registrationNumber}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col span={12} className="">
              <Form.Item
                label="Council Registration Number"
                {...{
                  name: "councilRegistrationNumber",
                  initialValue: employmentInfo
                    ? employmentInfo?.company?.councilRegistrationNumber
                    : councilRegistrationNumber,
                  rules: [
                    {
                      required: false,
                      message: "Please enter council registration number",
                    },
                  ],
                }}
              >
                <Input
                  disabled
                  placeholder="Please enter council registration number"
                  value={councilRegistrationNumber}
                />
              </Form.Item>
            </Col>
            <Col span={12} className="">
              <Form.Item
                label="Capacity of employment"
                {...{
                  name: "capacity",
                  initialValue: employmentInfo?.capacity?.id,
                  rules: [
                    {
                      required: true,
                      message: "Please select your employment capacity",
                    },
                  ],
                }}
              >
                <Select
                  className="input-height input-select"
                  onChange={(e) => handleCapacityChange(e)}
                >
                  {capacityOptions}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={12} className="">
              <Form.Item
                label="Employer address type"
                {...{
                  name: "addressType",
                  initialValue: employmentInfo?.employerAddressType,
                  rules: [
                    {
                      required: true,
                      message: "Please indicate",
                    },
                  ],
                }}
              >
                <Radio.Group
                  className="input-select input-spacer"
                  onChange={(e) => setUserAddressType(e.target.value)}
                >
                  <Radio value={"Head/Main Office"}>Head/Main Office</Radio>
                  <Radio value={"Branch"}>Branch</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <If
              condition={
                userAddressType !== "Head/Main Office" ||
                employmentInfo?.employerBranchLocation === "Branch"
              }
            >
              <Col span={12}>
                <Form.Item
                  label="Branch"
                  {...{
                    name: "branch",
                    initialValue: employmentInfo?.employerBranchLocation,
                    rules: [
                      {
                        required: false,
                        message: "Please select",
                      },
                    ],
                  }}
                >
                  <Select
                    className="full-width"
                    placeholder="Please select your branch"
                  >
                    {addressBranchOptions}
                  </Select>
                </Form.Item>
              </Col>
            </If>
          </Row>

          <Row className="">
            <Col span={16} />
            <Col span={8}>
              <Button
                htmlType="submit"
                className="btn-registration-capacity-selected full-width"
                loading={isSubmitting}
              >
                Save Information
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
}

export default EmploymentInformationForm;
