import { useRef, useEffect } from "react";
import { Steps, Button, Typography } from "antd";
import "./index.css";
import { COMPANY_TYPES } from "../../constants";
import { notifyError, openNotificationWithIcon } from "@/utils/notification";
import Registration from "./registration";
import BusinessInfo from "./businessInformation";
import PersonalInfo from "./personalInformation";
import Prequalification from "./prequalification";
import FinancialInformation from "./finacialInformation";
import AuditorAccountantInfo from "./auditorAccountantInfo";
import EducationInfo from "./educationInformation";
import Authentification from "./authentication";
import Documentation from "./documentantion";
import RegistrationFeePayment from "./feePayments";
import { useRegistrationState } from "@/stores/registration";
import { useParams } from "react-router-dom";
import { RedirectComponent } from "@/components/RedirectComponent";

export default function RegisterCompany() {
  const { invitationCode } = useParams();

  const {
    batchRegistrationId,
    manualRegistrationApplicationId: applicationId,
    registeringAs,
    registrationCapacity,
    currentStep,
    cipcDocumentUploaded,
    setRegistrationState,
    ...rest
  } = useRegistrationState();

  const isSP = registeringAs === "A Sole Proprietor";
  const isCompany = COMPANY_TYPES.includes(registeringAs);
  const isEmployee = registeringAs === "Employee of a Business";
  const contentSectionRef = useRef(null);

  function setStep(step: number) {
    setRegistrationState("currentStep", step);
  }

  useEffect(() => {
    // automatically take the user to their last saved step
    const stepObj = [1, 2, 3, 4, 5, 6, 7, 8]
      .map((i) => ({ key: i, val: rest[`step${i}Saved`] }))
      .filter((i) => Boolean(i.val))
      .reverse()[0];
    setStep(stepObj ? stepObj.key - 1 : 0);
  }, []);

  useEffect(() => {
    if (contentSectionRef && contentSectionRef.current) {
      contentSectionRef.current.scrollTop = 0;
      setTimeout(() => {
        // Fail safe scroll to top for if component update takes longer than usual
        if (contentSectionRef && contentSectionRef.current) {
          contentSectionRef.current.scrollTop = 0;
        }
      }, 500);
    }
  }, [currentStep]);

  const next = () => {
    stepCheckAndSet(currentStep);
  };

  const stepCheckAndSet = (current: number) => {
    const currentStepSaved = rest[`step${current + 1}Saved`];

    if (registeringAs === "Company" && !cipcDocumentUploaded) {
      return notifyError("Please upload the CIPC document before continuing.");
    } else if (currentStepSaved) {
      setStep(current + 1);
    } else if (registrationCapacity === "Business") {
      if (currentStep === 1) {
        return openNotificationWithIcon(
          "error",
          "Error",
          <div>
            <span>
              Please make sure you have saved the following information:
            </span>
            <br />
            <span>
              1. At least 3 Business Units contact details: Legal, Finance and
              Administration
            </span>
            <br />
            <span>2. At least one Related Party's information </span>
            <br />
            <span>
              3. At least 2 Address types: Work Physical and Work Postal
            </span>
          </div>
        );
      } else if ((isSP && currentStep === 5) || (!isSP && currentStep === 3)) {
        return notifyError(
          "Please make sure that you provide both Trust and Business Accounts information "
        );
      }
    } else if (registrationCapacity === "Individual") {
      if (currentStep === 1) {
        return openNotificationWithIcon(
          "error",
          "Error",
          <div>
            <span>
              Please make sure you have saved the following information:
            </span>
            <br />
            <span>1. Additional personal information</span>
            <br />
            <span>
              2. At least 2 Address types: Home Physical and Home Postal
            </span>
          </div>
        );
      }
    } else {
      return notifyError("Please save the above data before moving on");
    }
  };

  const previous = () => {
    setStep(currentStep - 1);
  };

  let steps: any = [];
  if (isEmployee) {
    steps = [
      {
        title: "Step: Registration",
        content:
          "Select the type of registration and thereafter type of applicant",
      },
      {
        title: "Step: Personal information",
        content: "Complete your basic personal information",
      },
      {
        title: "Step: Employment information",
        content: "Enter your employment information",
      },
      {
        title: "Step: Education & Languages",
        content:
          "Complete the form stating your credentials and work experience",
      },
      {
        title: "Step: Authentication",
        content: "Confirm your user profile ",
      },
      {
        title: "Step: Documentation",
        content: "Upload required documents",
      },
      {
        title: "Step: Registration fee and payment",
        content: "Complete your payment for the registration fee",
      },
    ];
  } else if (isSP) {
    steps = [
      {
        title: "Step: Registration",
        content:
          "Select the type of registration and thereafter type of applicant",
      },
      {
        title: "Step: Business information",
        content: "Complete your business basic  information",
      },
      {
        title: "Step: Personal information",
        content: "Complete your basic personal information",
      },
      {
        title: "Step: Prequalification",
        content:
          "Complete the short form that enables you for prequalification",
      },
      {
        title: "Step: Education & Experience Information",
        content:
          "Complete the form stating your credentials and work experience",
      },
      {
        title: "Step: Financial information",
        content: "Enter your financial information",
      },
      {
        title: "Step: Auditor/Accountant information",
        content: "Complete the auditor/accountant information",
      },
      {
        title: "Step: Authentication",
        content: "Confirm your user profile ",
      },
      {
        title: "Step: Documentation",
        content: "Upload required documents",
      },
    ];
  } else {
    steps = [
      {
        title: "Step: Registration",
        content:
          "Select the type of registration and thereafter type of applicant",
      },
      {
        title: "Step: Business and personal information",
        content: "Complete your basic personal information",
      },
      {
        title: "Step: Prequalification",
        content:
          "Complete the short form that enables you for prequalification",
      },
      {
        title: "Step: Financial information",
        content: "Enter your financial information",
      },
      {
        title: "Step: Auditor/Accountant information",
        content: "Complete the auditor/accountant information",
      },
      {
        title: "Step: Authentication",
        content: "Confirm your user profile ",
      },
      {
        title: "Step: Documentation",
        content: "Upload required documents",
      },
    ];
  }

  return (
    <>
      <div className="register-form-wrapper">
        <div className="sider">
          <div className="sider-title">
            <Typography.Title level={3}>Register your profile</Typography.Title>
            <Typography.Paragraph>
              Register your profile to become a member as a debt collector and
              gain access to our advanced features
            </Typography.Paragraph>
          </div>
          <Steps
            progressDot
            direction="vertical"
            current={currentStep}
            items={steps
              .filter((value: any) => Object.keys(value).length !== 0)
              .map((step: any, index: number) => {
                const title = step.title.replace("Step:", `Step ${index + 1}:`);
                return { title, content: step.content };
              })
              .map((item: any) => ({
                key: item.title,
                title: item.title,
                description: item.content,
              }))}
          />
        </div>
        <div ref={contentSectionRef} className="content-section">
          <div className="step-content">
            {isSP &&
              (currentStep === 0 ? (
                <Registration nextStep={next} />
              ) : currentStep === 1 ? (
                <BusinessInfo nextStep={next} />
              ) : currentStep === 2 ? (
                <PersonalInfo nextStep={next} />
              ) : currentStep === 3 ? (
                <Prequalification nextStep={next} />
              ) : currentStep === 4 ? (
                <EducationInfo nextStep={next} />
              ) : currentStep === 5 ? (
                <FinancialInformation nextStep={next} />
              ) : currentStep === 6 ? (
                <AuditorAccountantInfo nextStep={next} />
              ) : currentStep === 7 ? (
                <Authentification nextStep={next} />
              ) : currentStep === 8 ? (
                <Documentation nextStep={next} />
              ) : (
                <RegistrationFeePayment />
              ))}
            {(isCompany || !registeringAs) &&
              (currentStep === 0 ? (
                <Registration nextStep={next} />
              ) : currentStep === 1 ? (
                <BusinessInfo nextStep={next} />
              ) : currentStep === 2 ? (
                <Prequalification nextStep={next} />
              ) : currentStep === 3 ? (
                <FinancialInformation nextStep={next} />
              ) : currentStep === 4 ? (
                <AuditorAccountantInfo nextStep={next} />
              ) : currentStep === 5 ? (
                <Authentification nextStep={next} />
              ) : currentStep === 6 ? (
                <Documentation nextStep={next} />
              ) : (
                <RegistrationFeePayment />
              ))}
            {isEmployee &&
              (currentStep === 0 ? (
                <Registration nextStep={next} />
              ) : currentStep === 1 ? (
                <BusinessInfo nextStep={next} />
              ) : currentStep === 2 ? (
                <FinancialInformation
                  nextStep={next}
                  employeeInvitationCode={invitationCode}
                />
              ) : currentStep === 3 ? (
                <EducationInfo nextStep={next} />
              ) : currentStep === 4 ? (
                <Authentification nextStep={next} />
              ) : currentStep === 5 ? (
                <Documentation nextStep={next} />
              ) : (
                <RedirectComponent
                  applicationId={applicationId}
                  batchRegistrationId={batchRegistrationId}
                />
              ))}
            <div className="stepper-btn-container">
              <Button
                onClick={previous}
                style={{ display: currentStep === 0 ? "none" : "inline" }}
              >
                Previous
              </Button>{" "}
              <Button
                type="primary"
                onClick={next}
                className="btn-registration-capacity-selected"
                style={{
                  display:
                    isSP && currentStep === 9
                      ? "none"
                      : !isSP && currentStep === 7
                      ? "none"
                      : "inline",
                }}
              >
                Next Step
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
