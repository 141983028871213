import { useQuery } from "@apollo/client";
import {
  Button,
  Card,
  Col,
  Descriptions,
  Row,
  Skeleton,
  Space,
  Table,
} from "antd";
import { GET_USER_EMPLOYMENT_HISTORY } from "../graphql/queries";
import { format } from "date-fns";
import {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { notifyError } from "@/utils/notification";
import { FilePdfOutlined } from "@ant-design/icons";
import { jsPDF } from "jspdf";
import { ReportHandle } from "../Reporting/ReportingTypes";

const A4_WIDTH = 794;

function _EmploymentHistory(
  { userId }: { userId: number },
  ref: Ref<ReportHandle>
) {
  const [_reportData, setReportData] = useState<any>();
  const pageRef = useRef(null);
  const [downloading, setDownloading] = useState(false);
  const { loading, error, data } = useQuery(GET_USER_EMPLOYMENT_HISTORY, {
    variables: { userId },
    skip: !userId,
  });

  useEffect(() => {
    if (error) notifyError(error);
  }, [error]);

  const runQuery = async () => {};

  const downloadCsv = async () => {};

  const downloadPdf = async () => {};

  useImperativeHandle(ref, () => ({
    run: runQuery,
    download: downloadCsv,
    downloadPdf,
    running: loading,
    downloading,
    resetData: () => {
      setReportData(undefined);
    },
  }));

  if (loading || !data) {
    return (
      <Card>
        <Skeleton active />
        <Space>
          <Skeleton active />
          <Skeleton active />{" "}
        </Space>
      </Card>
    );
  }

  return (
    <Card>
      <div ref={pageRef} className="mb-16" style={{ maxWidth: A4_WIDTH }}>
        <Row>
          <Col span={24}>
            <h2>COUNCIL FOR DEBT COLLECTORS</h2>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col span={12}>
            <Descriptions column={1}>
              <Descriptions.Item label="">
                <b>Debt Collector Activity History Report</b>
              </Descriptions.Item>
              <Descriptions.Item label="Date of Issue" span={2}>
                {format(new Date(), "dd MMMM yyyy")}
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Col span={12}>
            <Descriptions column={1}>
              <Descriptions.Item label="Name">
                {data.getUserEmploymentHistory.firstName}
              </Descriptions.Item>
              <Descriptions.Item label="Surname">
                {data.getUserEmploymentHistory.lastName}
              </Descriptions.Item>
              <Descriptions.Item label="Identity Number">
                {data.getUserEmploymentHistory.idNumber}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>

        <Table
          dataSource={data.getUserEmploymentHistory.employmentHistory || []}
          rowKey={(record) => record.id}
          columns={[
            {
              title: "Company Name",
              dataIndex: "company",
              key: "companyName",
              render: (record) => record.name,
            },
            {
              title: "Capacity",
              dataIndex: "capacity",
              key: "capacity",
            },
            {
              title: "Membership Number",
              dataIndex: "membershipNumber",
              key: "membershipNumber",
            },
            {
              title: "Employment Date",
              dataIndex: "employmentDate",
              key: "employmentDate",
            },
            {
              title: "From Date",
              dataIndex: "certificates",
              key: "from",
              render: (record) => {
                return <pre>{record.map((r) => r.from).join("\n")}</pre>;
              },
            },
            {
              title: "To Date",
              dataIndex: "certificates",
              key: "to",
              render: (record) => {
                return <pre>{record.map((r) => r.to).join("\n")}</pre>;
              },
            },
          ]}
          pagination={false}
        />
      </div>
      <Row justify={"start"}>
        <Col>
          <Button
            icon={<FilePdfOutlined />}
            className="purple-button"
            loading={downloading}
            disabled={downloading}
            onClick={() => {
              setDownloading(true);
              if (pageRef.current) {
                const pdf = new jsPDF({
                  orientation: "p",
                  unit: "pt",
                  format: "a4",
                });

                pdf.html(pageRef.current, {
                  callback: async () => {
                    pdf
                      .save(
                        `${data.getUserEmploymentHistory.idNumber}-Employment-History.pdf`,
                        { returnPromise: true }
                      )
                      .catch(notifyError)
                      .finally(() => {
                        setDownloading(false);
                      });
                  },
                  x: 10,
                  y: 10,
                  html2canvas: {
                    scale: 0.7,
                  },
                });
              }
            }}
          >
            Download
          </Button>
        </Col>
      </Row>
    </Card>
  );
}

export const EmploymentHistory = forwardRef(_EmploymentHistory);
