import { useState } from "react";
import { Modal, Button } from "antd";
import { useMutation } from "@apollo/client";
import moment from "moment";
import {
  UPDATE_TASK,
  UPDATE_COMPLAINT,
  UPDATE_PROSECUTION_APPEARANCE,
} from "../../../../graphql/mutations";
import { openNotificationWithIcon } from "@/utils/notification";
import { useNavigate } from "react-router-dom";
import { TASK_STATUSES } from "../../../../constants";

const ResolveComplaint: React.FC<any> = ({
  show = false,
  updateVisible,
  complaint,
  taskId,
  appearanceId,
  verdict,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(show);
  const [updateTask] = useMutation(UPDATE_TASK);
  const [updateComplaint] = useMutation(UPDATE_COMPLAINT);
  const [updateProsecutionAppearance] = useMutation(
    UPDATE_PROSECUTION_APPEARANCE,
    {
      onCompleted: (data) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Appearance information updated successfully"
        );
      },
      onError: () => {
        openNotificationWithIcon(
          "error",
          "Error",
          "The was an error updating prosecution"
        );
      },
    }
  );
  const handleOk = async () => {
    try {
      setLoading(true);

      const resp = await updateTask({
        variables: {
          input: {
            id: taskId,
            taskStatus:
              verdict && appearanceId
                ? TASK_STATUSES.prosecutionResolved
                : TASK_STATUSES.resolved,
          },
        },
      });
      await updateComplaint({
        variables: {
          input: { id: complaint?.id, complaintStatus: "Resolved" },
        },
      });

      if (resp.data.updateTask) {
        openNotificationWithIcon(
          "success",
          "Success",
          "Task resolved successfully"
        );
        if (verdict === "Not Guilty") {
          await updateProsecutionAppearance({
            variables: {
              input: {
                id: appearanceId,
                taskId,
                hearingVerdict: verdict,
              },
            },
          });
        }
        setTimeout(() => navigate("/admin/tasks"), 2000);
      } else
        openNotificationWithIcon(
          "error",
          "Error",
          "Task could not be not resolved. Please try again"
        );
      setLoading(false);
    } catch (err) {
      setLoading(false);
      openNotificationWithIcon(
        "error",
        "Error",
        "Task could not be not resolved. Please try again"
      );
    }
  };

  const handleCancel = () => {
    setVisible(false);
    updateVisible(false);
  };

  return (
    <>
      <Modal
        open={visible}
        title="Resolve Complaint"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            No, Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            className="purple-button"
            loading={loading}
            onClick={handleOk}
          >
            Yes, Resolve
          </Button>,
        ]}
      >
        <h3>Are you sure you want to resolve this task?</h3>
        <p>
          TaskID: <strong>{taskId}</strong>
        </p>
        <p>
          Task type: <strong>Complaint</strong>
        </p>
        <p>
          Date Created:{" "}
          <strong>{moment(complaint?.dateCreated).format("YYYY-MM-DD")}</strong>
        </p>
        <p>
          Created By:{" "}
          <strong>
            {complaint?.firstName} {complaint?.lastName}
          </strong>
        </p>
        <p>
          Last Modified:{" "}
          <strong>
            {moment(complaint?.dateModified).format("YYYY-MM-DD")}
          </strong>
        </p>
        <p>
          Complaint Against Member:{" "}
          <strong>
            {complaint?.debtCollector?.firstName}{" "}
            {complaint?.debtCollector?.lastName}
          </strong>
        </p>
        <br />
      </Modal>
    </>
  );
};

export default ResolveComplaint;
