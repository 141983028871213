import { ExportOutlined } from '@ant-design/icons';
import { Layout } from "antd";
import logo from "../../assets/images/layout-logo.png";

const Header: React.FC<any> = () => {
  return (
    <Layout>
      <Layout.Header
        className="complaint-header"
        style={{
          background: "#fff",
          boxShadow: "3px 3px 3px lightgrey",
          height: "70px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "1vw",
          }}
        >
          <img
            src={logo}
            alt="CFDC Logo"
            style={{
              marginTop: 0,
              height: "50px",
              width: "100px",
            }}
          />

          <span
            className="top-right-menu"
            style={{ color: "grey", lineHeight: "50px" }}
            onClick={() => window.location.replace("/")}
          >
            <ExportOutlined />{" "}
            <a href="https://www.cfdc.org.za/" style={{ color: "grey" }}>
              Back to website
            </a>
          </span>
        </div>
      </Layout.Header>
    </Layout>
  );
};

export default Header;
