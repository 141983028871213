import { useState, useEffect } from "react";
import {
  ClockCircleOutlined,
  FileOutlined,
  MailOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import { Button, Card, Spin } from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
import { useMutation, useLazyQuery } from "@apollo/client";
import { UPDATE_TASK } from "@/graphql/mutations";
import {
  GET_TASK_ACTIVITIES,
  GET_TASK,
  GET_DOWNLOAD_URL,
} from "@/graphql/queries";
import { openNotificationWithIcon } from "@/utils/notification";
import PermissionsGuard from "../../../components/Auth/can";
import ComplaintLoader from "../Complaint/SubComponents/complaintLoader";
import { useNavigate, useParams } from "react-router-dom";

function TaskReview() {
  const { id } = useParams();
  const [taskId, setTaskId] = useState<any>(id);
  const navigate = useNavigate();
  const [activityList, setActivityList] = useState([]);
  //@ts-ignore
  const [proofOfPaymentDocs, setProofOfPaymentDocs] = useState([]);
  const [task, setTask] = useState<any>({});
  const [loadingTask, setLoadingTask] = useState(true);
  const [taskStatus, setTaskStatus] = useState<string | undefined>(undefined);

  useEffect(() => {
    setTaskId(+id);
  }, [id]);

  const [getTask] = useLazyQuery(GET_TASK, {
    fetchPolicy: "network-only",
    onError: (err) => {
      if (
        err.message === "Unauthorized" ||
        err.message?.includes("token has expired")
      )
        navigate("/login");

      openNotificationWithIcon(
        "error",
        "Load Error",
        "There was an error loading activities"
      );
    },
    onCompleted: (data) => {
      console.log(data);
      if (!isEmpty(data?.task)) {
        setTask(data.task);
        setLoadingTask(false);
      }
    },
  });

  //@ts-ignore
  const [getDownloadUrl] = useLazyQuery(GET_DOWNLOAD_URL, {
    onCompleted: (data) => {
      window.open(data.preSignedDownloadUrl);
    },
  });

  const [getActivityList, { loading: loadingActivity }] = useLazyQuery(
    GET_TASK_ACTIVITIES,
    {
      fetchPolicy: "network-only",
      onError: (err) => {
        if (
          err.message === "Unauthorized" ||
          err.message?.includes("token has expired")
        )
          navigate("/login");

        openNotificationWithIcon(
          "error",
          "Load Error",
          "There was an error loading activities"
        );
      },
      onCompleted: (data) => {
        if (!isEmpty(data.activities?.activityList)) {
          setActivityList(data.activities?.activityList);
        }
      },
    }
  );

  useEffect(() => {
    getTask({ variables: { taskId } });
    getActivityList({ variables: { input: { taskId } } });
  }, []);

  const [updateTask] = useMutation(UPDATE_TASK, {
    onCompleted: (data) => {
      if (!isEmpty(data.updateTask)) {
        setTaskStatus(data.updateTask.taskStatus);
      }
    },
  });

  const handleTaskUpdate = (status) => {
    updateTask({
      variables: {
        input: {
          id: taskId,
          taskStatus: status,
          assignedToId: Number.parseInt(localStorage.getItem("userId")),
        },
      },
    });
  };

  const handleViewDocument = async (filePath) => {
    try {
      // request download url
      await getDownloadUrl({
        variables: {
          input: {
            filePath,
            companyName: "",
            forCompany: true,
            userId: "",
          },
        },
      });
    } catch {
      openNotificationWithIcon(
        "error",
        "Upload Error",
        "Error occurred during upload"
      );
    }
  };

  return (
    <>
      {loadingTask && <ComplaintLoader />}
      {!loadingTask && (
        <div style={{ margin: "1vw" }}>
          <Card className="information-card" key={`Address ${1}`}>
            <div className="flex-column information-block">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <h2>Task {task?.id}</h2>
                <h3>
                  Status: <a>{taskStatus || task?.taskStatus}</a>
                </h3>
              </div>

              <br />
              <span>
                Task Type: <label>{task?.type?.name}</label>
                <br />
                Date Created:{" "}
                <label>{moment(task?.createdDate).format("DD-MM-YYYY")}</label>
                <br />
                Last Modified:{" "}
                <label>{moment(task?.createdDate).format("DD-MM-YYYY")}</label>
                <div className="flex-row">
                  <div></div>
                  <PermissionsGuard
                    perform={"task:resolve"}
                    yes={() => (
                      <div>
                        <Button
                          className="red-button"
                          style={{ marginRight: "8px" }}
                          onClick={() => handleTaskUpdate("Urgent")}
                        >
                          Urgent
                        </Button>
                        <Button
                          className="purple-button"
                          onClick={() => handleTaskUpdate("Complete")}
                        >
                          Resolve Task
                        </Button>
                      </div>
                    )}
                  />
                </div>
              </span>
            </div>
          </Card>
          <br />
          <div className="flex-row">
            <div className="flex-column" style={{ width: "63%" }}>
              {task && task.type.name === "Financial Ticket" && (
                <Card>
                  <h3>Finance Ticket Details</h3>
                  <div className="inline">
                    <span>Company Name</span>
                    <br />
                    <span>
                      {task.company.registeredName} ( {task.company.tradingName}{" "}
                      )
                    </span>
                    <br />
                    <span>Payment Method</span>
                    <br />
                    <span>Direct Deposit</span>
                    <br />
                    <span>Proof of payments</span>
                    <br />
                    {proofOfPaymentDocs.map((doc) => {
                      return (
                        <div key={doc.name}>
                          <span>
                            <img style={{ height: "25px", width: "25px" }} />
                            &nbsp; &nbsp;
                            {doc.name}
                            <Button
                              type="link"
                              onClick={() =>
                                handleViewDocument(doc.documentPath)
                              }
                            >
                              View
                            </Button>
                          </span>
                          <br />
                        </div>
                      );
                    })}
                  </div>
                </Card>
              )}
            </div>
            <div style={{ width: "35%" }}>
              <Card
                title={
                  <div className="flex-row">
                    <h4>Latest Activity</h4>
                    <span>
                      Message{" "}
                      <span className="icon-dot">
                        <MessageOutlined />
                      </span>
                    </span>
                  </div>
                }
              >
                {loadingActivity && <Spin />}
                {activityList.map((act) => {
                  return (
                    <>
                      <div className="inline">
                        <div>
                          <span className="activity-icon-dot">
                            {act.description.includes("Email") ? (
                              <MailOutlined />
                            ) : act.description.includes("template") ? (
                              <FileOutlined />
                            ) : (
                              <ClockCircleOutlined />
                            )}
                          </span>
                        </div>
                        <div>
                          <span>{act.description}</span>&nbsp;
                          <span>
                            {"by"} {act?.user?.firstName} {act?.user?.lastName}
                          </span>
                          <br />
                          <div style={{ color: "#CCC" }}>
                            <span>
                              {moment(act?.dateCreated).format("YYYY-MM-DD")}
                            </span>{" "}
                            <span className="dot"></span>{" "}
                            <span>
                              {moment(act?.dateCreated).format("hh:mm a")}
                            </span>
                          </div>
                          <hr />
                        </div>
                      </div>
                    </>
                  );
                })}
              </Card>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default TaskReview;
