import "./index.css";
import RegisterForm from "./registerForm";
import Registration from "../../../Register";
import { useRegistrationState } from "@/stores/registration";

function Register() {
  const { manualRegistrationType } = useRegistrationState();

  if (manualRegistrationType) {
    return <Registration />;
  }

  return (
    <div className="register-form-wrapper">
      <RegisterForm />
    </div>
  );
}

export default Register;
