import { Card, Collapse } from "antd";

export function NewMemberRegistration() {
  const text = "This is a new member registration form";

  return (
    <div className="m-2 m-2">
      <Card title="New Member Registration">
        <Collapse defaultActiveKey={["1"]} onChange={(key) => {}}>
          <Collapse.Panel header="Personal Information" key="1">
            <p>{text}</p>
          </Collapse.Panel>
          <Collapse.Panel header="This is panel header 2" key="2">
            <p>{text}</p>
          </Collapse.Panel>
          <Collapse.Panel header="This is panel header 3" key="3">
            <p>{text}</p>
          </Collapse.Panel>
        </Collapse>
      </Card>
    </div>
  );
}
