import { useState } from "react";
import { Card, Divider, Button } from "antd";
import { useQuery } from "@apollo/client";
import {
  GET_USER_LANGUAGE_PROFICIENCY_LIST,
  GET_USER_QUALIFICATIONS,
} from "../../../graphql/queries";
import { isEmpty, isInteger } from "lodash";
import ResultComment from "./resultComment";
import InformationLoader from "./informationLoader";
import "./index.css";

const ReviewEducationExperienceInformation: React.FC<any> = ({
  transaction,
  reviewInfo,
  registrationTaskId,
}) => {
  const { employee, company, type } = transaction;

  const [userInfo, setUserInfo] = useState<any>();
  const [userQualificationList, setUserQualificationsList] = useState([]);
  const [languageProficiencyList, setLanguageProficiencyList] = useState([]);
  const userId = type.name === "Employee" ? employee.id : company.createdBy?.id;

  const { loading: loadingProficiencies } = useQuery(
    GET_USER_LANGUAGE_PROFICIENCY_LIST,
    {
      skip: !isInteger(userId),
      variables: {
        userId,
      },
      onCompleted: (data) =>
        setLanguageProficiencyList(data.languageProficiencyList),
    }
  );

  const { loading: loadingQualifications } = useQuery(GET_USER_QUALIFICATIONS, {
    skip: !isInteger(userId),
    variables: {
      userId: +userId,
    },
    onCompleted: (data) => {
      setUserQualificationsList(data.qualificationsList);
      setUserInfo(data.userData);
    },
  });
  const ResultComponentsProps = {
    ...transaction,
    step: "EducationExperience",
    reviewInfo,
    registrationTaskId,
  };

  if (loadingQualifications || loadingProficiencies)
    return <InformationLoader />;
  return (
    <>
      <div>
        <br />
        <label>Language Proficiency:</label>
        {!isEmpty(languageProficiencyList) ? (
          languageProficiencyList.map((proficiency, index) => {
            return (
              <Card className="flex-coulumn input-select" key={`lp-${index}`}>
                <div
                  className="information-block-wrapper input-select"
                  style={{ marginTop: 0 }}
                >
                  <div className="flex-column information-block">
                    <Button
                      shape="round"
                      className="btn-registration-capacity-selected"
                    >
                      {`Proficiency ${index + 1}`}
                    </Button>
                    <br />
                    <br />
                    <span>
                      Language: <label>{proficiency.language?.name}</label>
                      <br />
                      Proficiency Level:{" "}
                      <label>{proficiency.proficiencyLevel.name}</label>
                      <br />
                      Abilities:{" "}
                      <label>
                        {proficiency.abilities.map((a) => ` ${a.name}`)}
                      </label>
                      <br />
                    </span>
                  </div>

                  {index + 1 !== languageProficiencyList.length && <Divider />}
                </div>
              </Card>
            );
          })
        ) : (
          <span> No information was entered</span>
        )}
      </div>
      {userInfo && (
        <div className="flex-column information-block">
          <br />
          <span>
            Highest Grade Obtained: <label>{userInfo?.highestGrade}</label>
          </span>
          <span>
            Year Highest Grade Obtained:{" "}
            <label>{userInfo?.highestGradeYear}</label>
          </span>
          <br />
        </div>
      )}
      <div>
        <br />
        <label>Qualifications:</label>

        {!isEmpty(userQualificationList) ? (
          userQualificationList.map((qualification, index) => {
            return (
              <Card className=" flex-column input-select">
                <div
                  className="information-block-wrapper input-select"
                  style={{ marginTop: 0 }}
                  key={index}
                >
                  <div className="flex-column information-block">
                    <Button
                      shape="round"
                      className="btn-registration-capacity-selected"
                    >
                      {`Qualification ${index + 1}`}
                    </Button>
                    <br />
                    <br />
                    <span>
                      Qualification Type:{" "}
                      <label>{qualification.type?.name}</label>
                      <br />
                      Qualification:{" "}
                      <label>{qualification.qualification}</label>
                      <br />
                      Year Obtained <label>{qualification.year}</label>
                      <br />
                      Name of institution{" "}
                      <label>{qualification.institutionName}</label>
                      <br />
                    </span>
                  </div>

                  {index + 1 !== userQualificationList.length && <Divider />}
                </div>
              </Card>
            );
          })
        ) : (
          <span> No information was entered</span>
        )}
      </div>
      <ResultComment {...ResultComponentsProps} />
    </>
  );
};
export default ReviewEducationExperienceInformation;
