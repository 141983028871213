import { Empty, Table } from "antd";

type Props = {
  yearRange: [number, number];
  auditSubmissionList: any[];
  currentPage: number;
  pageSize: number;
  listTotal: number;
  handlePagination: (page: number) => void;
};

export default ({
  yearRange,
  auditSubmissionList,
  currentPage,
  pageSize,
  listTotal,
  handlePagination,
}: Props) => {
  const columns = [
    {
      title: "Company ID",
      key: "company",
      render: (record) => record.company.id,
    },
    {
      title: "Company",
      key: "company",
      render: ({ company: record }) =>
        (record.tradingName || record.registeredName || "").toUpperCase(),
    },
  ];

  for (let i = yearRange[0]; i <= yearRange[1]; i++) {
    columns.push({
      title: String(i),
      key: String(i),
      render: (record) => (record[String(i)] ? "YES" : "NO"),
    });
  }

  if (auditSubmissionList.length === 0) {
    return <Empty />;
  }

  return (
    <Table
      rowKey="id"
      columns={columns}
      size="small"
      pagination={{
        hideOnSinglePage: true,
        current: currentPage,
        showSizeChanger: false,
        pageSize,
        onChange: (page) => handlePagination(page),
        total: listTotal,
      }}
      dataSource={auditSubmissionList}
    />
  );
};
