import { notifyError } from "@/utils/notification";
import { Modal, Input, Form } from "antd";

function ConfirmCodeForm({ visible, onCancel, onCreate, initialData }) {
  const [form] = Form.useForm();

  async function onSubmit() {
    form
      .validateFields()
      .then((values) => {
        onCreate(values);
      })
      .catch(() => {
        notifyError("Please enter a valid confirmation code");
      });
  }

  return (
    <Modal
      open={visible}
      title="Confirm Email with Code"
      okText="Confirm"
      onCancel={onCancel}
      onOk={onSubmit}
    >
      <Form form={form} layout="vertical" initialValues={initialData}>
        <Form.Item
          label="Confirmation code"
          name={"code"}
          rules={[
            {
              required: true,
              message: "Please input your confirmation code!",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ConfirmCodeForm;
