import { Button, Table } from "antd";
import { Address } from "@/typings/types";
import { CheckSquareOutlined } from "@ant-design/icons";

type Props = { addresses: Address[]; onEdit?: (address: any) => void };

function AddressTable({ addresses, onEdit }: Props) {
  return (
    <div>
      <Table
        size="small"
        dataSource={addresses.map((address, index) => ({
          ...address,
          key: index + 1,
        }))}
        rowKey={(record) => String(record.id)}
        columns={[
          {
            title: "#",
            render: (record: any) => {
              return `${record.key}`;
            },
          },
          {
            title: "Type",
            render: (record: any) => String(record.addressType?.name || ""),
            key: "type",
          },
          {
            title: "Complex",
            render: (record: any) => String(record.addressName || ""),
            key: "name",
          },
          {
            title: "Street/Postal",
            render: (record: any) =>
              String(record.streetAddress || record.postalAddress || ""),
            key: "street",
          },

          {
            title: "Suburb",
            render: (record: any) => String(record.suburb || ""),
            key: "suburb",
          },
          {
            title: "City",
            render: (record: any) => String(record.city || ""),
            key: "City",
          },
          {
            title: "Postal Code",
            render: (record: any) => String(record.postalCode || ""),
            key: "postalCode",
          },
          {
            title: "Domicillium",
            render: (_, record: any) => {
              return record.domicilliumAddress ? <CheckSquareOutlined /> : "";
            },
            key: "domicile",
          },
          ...(Boolean(onEdit)
            ? [
                {
                  title: "",
                  render: (record: any) => (
                    <Button type="link" onClick={() => onEdit(record)}>
                      Edit
                    </Button>
                  ),
                  key: "action",
                },
              ]
            : []),
        ]}
        pagination={false}
      />
    </div>
  );
}

export default AddressTable;
