import { useState } from "react";
import { Button, Input, Checkbox, Select, Spin, Form } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_ADDRESS, UPDATE_ADDRESS } from "../../graphql/mutations";
import { GET_ADDRESS_TYPES, GET_ADDRESS_LIST } from "@/graphql/queries";
import { isEmpty } from "lodash";

import InternalErrorPage from "@/containers/InternalErrorPage";
import { notifyError, notifySuccess } from "@/utils/notification";
import { useUserState } from "@/stores/user";
import AddressTable from "@/components/AddressTable";

const { Option } = Select;

interface IProps {
  editProfileMode?: boolean;
  userId?: number;
  sendAddressInfo?: (postalPhysicalSaved: boolean) => void;
}

function AddressForm({
  sendAddressInfo,
  editProfileMode = false,
  userId: providedUid,
}: IProps) {
  const [form] = Form.useForm();
  const { id: uid } = useUserState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDomicillium, setIsDomicillium] = useState(false);
  const [domicilliumAddressExists, setDomicilliumAddressExists] =
    useState(false);
  const [addressTypeList, setAddressTypeList] = useState<any>();
  const [addingAddress, setAddingAddress] = useState(false);
  const [editingAddress, setEditingAddress] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<any>();
  const [selectedAddressType, setSelectedAddressType] = useState<any>();
  const [companyAddressInfo, setCompanyAddressInfo] = useState([]);
  const [createUserContact] = useMutation(CREATE_ADDRESS);
  const [updateUserAddres] = useMutation(UPDATE_ADDRESS);

  const userId = providedUid ? +providedUid : uid;

  const {
    loading: loadingAddressTypes,
    error: errorAddressTypes,
    data: dataAddressTypes,
  } = useQuery(GET_ADDRESS_TYPES, {
    onCompleted: () => setAddressTypeList(dataAddressTypes.addressTypeList),
  });

  const {
    loading: loadingAddress,
    error: errorAddress,
    data: dataAddress,
    refetch: refetchCompanyAddress,
  } = useQuery(GET_ADDRESS_LIST, {
    variables: {
      userId,
    },
    onCompleted: () => {
      // set company address info from query result
      setCompanyAddressInfo(dataAddress.addresses);
      if (!isEmpty(dataAddress.addresses)) {
        const postalPhysicalAddressesSaved =
          dataAddress.addresses.filter((x) =>
            ["Home Physical", "Home Postal"].includes(x.addressType?.name)
          ).length >= 2;
        if (sendAddressInfo) {
          sendAddressInfo(postalPhysicalAddressesSaved);
        }
      }
    },
  });

  if (errorAddress || errorAddressTypes) return <InternalErrorPage />;

  //selectable address types options
  const addressTypeOptions: any =
    !loadingAddress && addressTypeList
      ? addressTypeList
          .filter((x) => {
            return editingAddress
              ? x.name.includes("Home")
              : x.name.includes("Home") &&
                  x.name !== companyAddressInfo[0]?.addressType?.name;
          })
          .map((type) => {
            return (
              <Option key={type.id} value={type.id}>
                {type.name}
              </Option>
            );
          })
      : [];

  const handleSubmitAddressInfo = async (values: any) => {
    try {
      setIsSubmitting(true);
      const createUpdatemutation = selectedAddress?.id
        ? updateUserAddres
        : createUserContact;

      await createUpdatemutation({
        variables: {
          input: {
            id: selectedAddress?.id,
            addressName: values.physicalComplexName,
            addressTypeId: values.addressType,
            city: values.physicalCity,
            suburb: values.physicalSuburb,
            streetAddress: values.physicalStreetAddress,
            postalCode: values.physicalPostalCode,
            postalAddress: values.physicalPostalAddress,
            domicilliumAddress: values.domicilliumAddress,
            location: values.location,
            userId: !selectedAddress?.id ? userId : undefined,
          },
        },
      });

      const contactsResp = await refetchCompanyAddress({
        userId,
      });

      if (contactsResp.errors) {
        setIsSubmitting(false);
        return;
      }

      setAddingAddress(false);
      setEditingAddress(false);
      setIsSubmitting(false);
      // clear selected address
      setSelectedAddress({});
      if (!isEmpty(contactsResp.data.addresses)) {
        ///check if both postal and physical address information has been saved
        setCompanyAddressInfo(contactsResp.data.addresses);
        const postalPhysicalAddressesSaved =
          contactsResp.data.addresses.filter((x) =>
            ["Home Physical", "Home Postal"].includes(x.addressType?.name)
          ).length >= 2;
        if (postalPhysicalAddressesSaved && sendAddressInfo) {
          sendAddressInfo(true);
        }
      }
      if (values.domicilliumAddress) {
        setDomicilliumAddressExists(true);
      }
      form.resetFields();
      return notifySuccess("Address saved successfully");
    } catch (error) {
      setIsSubmitting(false);
      return notifyError(error);
    }
  };

  const checkIfDomicilliumAddressExists = (e) => {
    // if selected is home physical check domicillium address
    const addressType = addressTypeList.filter((a) => a.id === e);
    setSelectedAddressType(addressType[0]);

    let setLocation = {};
    let setDomicilliumAddress = {
      ["domicilliumAddress"]: false,
    };
    if (
      selectedAddressType &&
      selectedAddressType.name === "Home Physical" &&
      companyAddressInfo.filter((item) => item.location === "Head Office")
        .length > 0
    ) {
      setLocation = {
        ["location"]: "Branch",
      };
    } else if (
      selectedAddressType &&
      selectedAddressType.name === "Home Postal"
    ) {
      setLocation = {
        ["location"]: "Head Office",
      };
    }

    if (
      selectedAddressType &&
      selectedAddressType.name === "Home Physical" &&
      !domicilliumAddressExists
    ) {
      form.setFieldsValue({
        domicilliumAddress: true,
      });
    }
    form.setFieldsValue({
      ...setDomicilliumAddress,
      ...setLocation,
    });
  };

  const isPhysicalAddressSelected = () => {
    if (!selectedAddressType) return true;
    if (
      selectedAddressType &&
      selectedAddressType.name.toLowerCase().includes("physical")
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      {!editProfileMode && (
        <div
          className="flex-column input-block"
          style={{ width: "100%", paddingTop: "40px" }}
        >
          <label>Address information:</label>
        </div>
      )}
      {(loadingAddress || loadingAddressTypes) && <Spin />}
      {!isEmpty(companyAddressInfo) && !isEmpty(addressTypeList) && (
        <AddressTable
          addresses={companyAddressInfo}
          onEdit={(address: any) => {
            setSelectedAddress(address);
            setSelectedAddressType(address.addressType);
            setAddingAddress(true);
            setEditingAddress(true);
          }}
        />
      )}

      <Form
        form={form}
        onFinish={handleSubmitAddressInfo}
        onFieldsChange={(changes) => {
          const domicile = changes.find(
            (x) => x.name[0] === "domicilliumAddress"
          );
          if (domicile) {
            setIsDomicillium(domicile.value);
          }
        }}
      >
        <div
          className="input-block-wrapper"
          style={{ display: addingAddress ? "flex" : "none" }}
        >
          <div className="flex-column input-block">
            <label>Address type</label>
            <Form.Item
              {...{
                name: "addressType",
                initialValue: selectedAddress?.addressType?.id,
                rules: [
                  {
                    required: true,
                    message: "Please select address type",
                  },
                ],
              }}
            >
              <Select
                className="input-select"
                onChange={checkIfDomicilliumAddressExists}
              >
                {addressTypeOptions}
              </Select>
            </Form.Item>
          </div>
          <div className="flex-column input-block">
            <label>Location</label>
            <Form.Item
              {...{
                name: "location",
                initialValue: selectedAddress?.location,
                rules: [
                  {
                    required: true,
                    message: "Please select address location",
                  },
                ],
              }}
            >
              <Select className="input-select">
                <Select.Option value={"Head Office"}>Head Office</Select.Option>
                <Select.Option value={"Branch"}>Branch</Select.Option>
              </Select>
            </Form.Item>
          </div>
          {isPhysicalAddressSelected() ? (
            <>
              <div className="flex-column input-block">
                <label>Complex/Building/Apartment Name</label>
                <Form.Item
                  {...{
                    name: "physicalComplexName",
                    initialValue: selectedAddress?.addressName,
                    rules: [
                      {
                        required: true,
                        message: "Please input Complex/Building/Apartment Name",
                      },
                    ],
                  }}
                >
                  <Input placeholder="Enter Complex/Building/Apartment Name" />
                </Form.Item>
              </div>
              <div className="flex-column input-block">
                <label>Street Address</label>

                <Form.Item
                  {...{
                    name: "physicalStreetAddress",
                    initialValue: selectedAddress?.streetAddress,
                    rules: [
                      {
                        required: true,
                        message: "Please input street address",
                      },
                    ],
                  }}
                >
                  <Input placeholder="Enter street address" />
                </Form.Item>
              </div>
              <div className="flex-column input-block">
                <label>Town/Suburb</label>
                <Form.Item
                  {...{
                    name: "physicalSuburb",
                    initialValue: selectedAddress?.suburb,
                    rules: [
                      {
                        required: true,
                        message: "Please input suburb",
                      },
                    ],
                  }}
                >
                  <Input placeholder="Enter suburb" />
                </Form.Item>
              </div>
              <div className="flex-column input-block">
                <label>City</label>
                <Form.Item
                  {...{
                    name: "physicalCity",
                    initialValue: selectedAddress?.city,
                    rules: [
                      {
                        required: true,
                        message: "Please input city",
                      },
                    ],
                  }}
                >
                  <Input placeholder="Enter city" />
                </Form.Item>
              </div>
            </>
          ) : (
            <>
              <div className="flex-column input-block">
                <label>Postal Address</label>
                <Form.Item
                  {...{
                    name: "physicalPostalAddress",
                    initialValue: selectedAddress?.postalAddress,
                    rules: [
                      {
                        required: true,
                        message: "Please input postal address",
                      },
                    ],
                  }}
                >
                  <Input placeholder="Enter postal address" />
                </Form.Item>
              </div>
              <div className="flex-column input-block">
                <label>Postal Code</label>
                <Form.Item
                  {...{
                    name: "physicalPostalCode",
                    initialValue: selectedAddress?.postalCode,
                    rules: [
                      {
                        required: true,
                        message: "Please input postal code",
                      },
                    ],
                  }}
                >
                  <Input placeholder="Enter postal code" />
                </Form.Item>
              </div>
            </>
          )}
          <div className="flex-column input-block input-select">
            <Form.Item
              {...{
                name: "domicilliumAddress",
                initialValue: !isEmpty(selectedAddress)
                  ? selectedAddress.domicilliumAddress
                  : isDomicillium,
                valuePropName: "checked",
                rules: [
                  {
                    required: true,
                    message: "Please input address",
                  },
                ],
              }}
            >
              <Checkbox disabled={domicilliumAddressExists}>
                Domicillium Address
              </Checkbox>
            </Form.Item>
          </div>
          <div className="flex-column input-block input-select">
            <Button
              className="btn-registration-capacity-selected"
              loading={isSubmitting}
              htmlType="submit"
            >
              Save address
            </Button>
          </div>
        </div>
        {!loadingAddress &&
          !loadingAddressTypes &&
          addressTypeOptions &&
          companyAddressInfo && (
            <div className="flex-column input-block input-select input-spacer">
              <Button
                type="default"
                className="purple-button"
                style={{ width: "100%", height: "40px" }}
                onClick={() => setAddingAddress(true)}
                disabled={addingAddress || companyAddressInfo.length === 2}
              >
                + Add another address
              </Button>
            </div>
          )}
      </Form>
    </>
  );
}

export default AddressForm;
