import { useState, useMemo } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { UploadOutlined } from "@ant-design/icons";
import { Modal, Spin, Select, Input, Card, Button, Upload, Form } from "antd";
import { GET_PRESIGNED_URL } from "../../graphql/queries";
import { CREATE_DOCUMENT } from "../../graphql/mutations";
import { filterUnemployed } from "@/utils/filterUnemployed";
import { uploadToS3 } from "@/utils/uploadToS3";
import { notifyError } from "@/utils/notification";

type Props = {
  employeeDetails: any;
  typeOfChange: "status" | "capacity" | "delete" | "membership";
  loading: boolean;
  visible: boolean;
  onCancel: () => void;
  changeCapacity: (arg: any) => void;
  changeMembership?: (arg: any) => void;
  changeStatus: (arg: any) => void;
  deleteUser?: (arg: any) => void;
  setLoading: (arg: boolean) => void;
};

export default function ChangeMemberForm(props: Props) {
  const {
    onCancel,
    changeCapacity,
    changeMembership,
    changeStatus,
    deleteUser,
    setLoading,
    typeOfChange,
    employeeDetails,
    loading,
    visible,
  } = props;
  const [form] = Form.useForm();
  const [status, setStatus] = useState("");
  const [createCertificate, setCreateCertificate] = useState(false);
  const [capacity, setCapacity] = useState("");
  const [reason, setReason] = useState("");
  const [membershipNumber, setMembershipNumber] = useState("");
  const [employmentNumber, setEmploymentNumber] = useState("");
  const [filePath, setFilePath] = useState<{ path: string; name: string }>();
  const [uploading, setUploading] = useState(false);
  const [file, setFile] = useState<any>();
  const [company, setCompany] = useState<number>();

  const reasons = useMemo<string[]>(() => {
    if (typeOfChange === "status") {
      switch (status) {
        case "active":
          return [
            "Database Error",
            "Misallocation of Payment",
            "Termination of Suspension",
          ];
        case "deactivated":
          return ["Outcome of Hearing"];
        case "suspended":
          return [
            "Under Investigation",
            "Outstamding Fees",
            "Outcome of Hearing",
            "Outstanding Audit Certificate",
          ];
        default:
          return [""];
      }
    }

    return ["Database Error", "Promotion", "Change of Employment Capacity"];
  }, [status, typeOfChange]);

  const employments = useMemo<any[]>(() => {
    if (!employeeDetails?.employment?.length) return [];
    return filterUnemployed(employeeDetails.employment);
  }, [employeeDetails]);

  const title = useMemo(() => {
    switch (typeOfChange) {
      case "capacity":
        return "Change Member Capacity";
      case "delete":
        return `Delete Member - ${
          employeeDetails
            ? employeeDetails?.lastName + "" + employeeDetails?.firstName
            : ""
        }`;
      case "membership":
        return "Change Membership Number";
      case "status":
        return "Change Member Status";
      default:
        return "";
    }
  }, [typeOfChange, employeeDetails]);

  const entityType = useMemo<string>(() => {
    if (!employments.length) return "";
    const _company = employments.find(
      ({ company: c }) => c?.id === company
    )?.company;
    if (!_company) return "";
    const { tradingAs, registeredNumber } = _company;
    const regNo = registeredNumber?.split("/")?.pop();
    const ta = tradingAs?.toLowerCase();
    if (regNo === "07" || ta === "company") {
      return "Company";
    } else if (regNo === "23" || ta === "Closed Corporation") {
      return "Closed Corporation";
    } else if (
      [null, "sole proprietor"].includes(ta) &&
      registeredNumber?.length === "13"
    ) {
      return "Sole Proprietor";
    } else {
      return "Other";
    }
  }, [employments, company]);

  const capacities = useMemo<string[]>(() => {
    const items = ["Officer", "Employee"];
    switch (entityType) {
      case "Company":
        items.push("Director");
        break;
      case "Closed Corporation":
        items.push("Member of CC");
        break;
      case "Partnership":
        items.push("Partner");
        break;
      case "Trust":
        items.push("Trustee");
        break;
      case "Sole Proprietor":
        items.push("Owner (Sole Proprietor)");
        break;
    }
    return items;
  }, [entityType]);

  const statuses = ["Active", "Suspended", "Deregistered"];

  const handleChangeStatus = () => {
    setLoading(true);
    switch (typeOfChange) {
      case "membership":
        if (!membershipNumber || !employmentNumber) {
          setLoading(false);
          return notifyError(
            "Please specify both the company and membership number"
          );
        }

        return changeMembership({
          variables: {
            input: {
              membershipNumber,
              employmentNumber,
            },
          },
        });
      case "capacity":
        setCreateCertificate(true);
        return changeCapacity({
          variables: {
            input: {
              capacity,
              reason,
              companyId: company,
              userId: employeeDetails.id,
            },
          },
        });
      case "status":
        return changeStatus({
          variables: {
            input: {
              status,
              reason,
              createCertificate,
              userId: employeeDetails.id,
              ...(company && { companyId: company }),
            },
          },
        });
      case "delete":
        return deleteUser({
          variables: { id: employeeDetails.id },
        });
    }
  };

  const [createDocument] = useMutation(CREATE_DOCUMENT);

  const [getPresignedUrl] = useLazyQuery(GET_PRESIGNED_URL, {
    fetchPolicy: "network-only",
    onCompleted: async ({ preSignedUrl }) => {
      if (preSignedUrl) {
        await uploadToS3(preSignedUrl, file.type, file);
        await createDocument({
          variables: {
            input: {
              documentName: filePath?.name,
              documentPath: filePath?.path,
              documentTypeId: 27,
              documentStatusId: 1,
              documentFormat: file.type,
              userId: employeeDetails.id,
              forCompany: true,
              ...(company && { companyId: company }),
            },
          },
        });
      }
    },
    onError: (err) => {
      console.error({ err });
    },
  });

  const handleUpload = async ({ file, onSuccess }: any) => {
    setUploading(true);
    setFile(file);

    const ext = file.type.split("/").pop();
    const name = `change_${typeOfChange}_${Date.now()}`;
    const path = `${employeeDetails.idNumber}/admin-uploads/${name}.${ext}`;
    setFilePath({ path, name });

    getPresignedUrl({
      variables: {
        input: {
          filePath: path,
          contentType: file.type,
          forCompany: false,
          userId: employeeDetails.id,
          companyName: "Admin",
        },
      },
    });

    setTimeout(() => {
      setUploading(false);
      onSuccess();
    }, 3500);
  };

  return (
    <Modal
      open={visible}
      title={title}
      okText={typeOfChange === "delete" ? "Delete" : "Change"}
      onCancel={onCancel}
      onOk={handleChangeStatus}
      okButtonProps={{
        className: "purple-button",
        disabled: loading || uploading,
      }}
      cancelButtonProps={{
        disabled: loading || uploading,
      }}
      okType="default"
    >
      <Spin tip="Loading..." spinning={loading}>
        {typeOfChange === "delete" ? (
          <div style={{ textAlign: "center" }}>
            <h3>
              You are about to delete a user and all its associated data. <br />
              Are you sure you want to continue with this irreversible action?
            </h3>
            <br />
          </div>
        ) : (
          <Form form={form} layout="vertical">
            {["capacity", "membership"].includes(typeOfChange) && (
              <Form.Item label="Company" name="company" required>
                <Select
                  className="input-select"
                  onChange={(value) => {
                    const companyId = Number(value);
                    setCompany(companyId);
                    const eid = employments.find(
                      (e) => e.company?.id === companyId
                    )?.id;

                    setEmploymentNumber(eid);
                  }}
                >
                  {employments.map((item, index) => (
                    <Select.Option
                      key={`company-${index}`}
                      value={item?.company?.id?.toString()}
                    >
                      {item?.company?.tradingName ||
                        item?.company?.registeredName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {typeOfChange === "membership" && (
              <Form.Item
                label="New Membership Number"
                name="membershipNumber"
                required
              >
                <Input
                  onChange={(e) => {
                    setMembershipNumber(e.target.value);
                  }}
                  value={employmentNumber}
                  placeholder="Enter the new employment number here"
                />
              </Form.Item>
            )}

            {["status", "capacity"].includes(typeOfChange) && (
              <Form.Item
                label={`New ${
                  typeOfChange === "status" ? "Status" : "Capacity"
                }`}
                name={typeOfChange}
                required
              >
                <Select
                  className="input-select"
                  onChange={(value) => {
                    const val = value.toString();
                    typeOfChange === "status"
                      ? setStatus(val)
                      : setCapacity(val);
                  }}
                >
                  {(typeOfChange === "status" ? statuses : capacities).map(
                    (item, index) => (
                      <Select.Option
                        key={`item-${index}`}
                        value={item.toLowerCase().replace(/ /g, "_")}
                      >
                        {item}
                      </Select.Option>
                    )
                  )}
                </Select>
              </Form.Item>
            )}

            {["status", "capacity"].includes(typeOfChange) && (
              <Form.Item
                label={`Reason for change of ${typeOfChange}`}
                name="reason"
                required
              >
                <Select
                  className="input-select"
                  onChange={(value) => setReason(value.toString())}
                >
                  {reasons.map((r) => (
                    <Select.Option
                      key={r}
                      value={r.toLowerCase().replace(/ /g, "_")}
                    >
                      {r}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}

            {["status", "capacity"].includes(typeOfChange) && (
              <>
                <h4>Add a comment</h4>
                <Card
                  className="comment-card"
                  cover={
                    <Input.TextArea
                      style={{ resize: "none" }}
                      rows={3}
                      placeholder="Start typing..."
                      disabled
                    />
                  }
                >
                  <Card.Meta
                    title={
                      <div className="comment-action">
                        <div />
                        <div>
                          <Button className="btn-add-comment" disabled>
                            Save
                          </Button>
                        </div>
                      </div>
                    }
                  />
                </Card>
                <Upload
                  disabled={loading || uploading}
                  customRequest={handleUpload}
                >
                  <Button disabled={!!!company}>
                    <UploadOutlined /> Upload
                  </Button>
                </Upload>
              </>
            )}
          </Form>
        )}
      </Spin>
    </Modal>
  );
}
