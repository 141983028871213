import { Card, Tabs } from "antd";
import HireIcon from "../../../assets/images/hire.svg";
import ProfileManager from "./profile";
import ProfileSecurity from "./security";

function Profile() {
  const tabItems = [
    {
      label: "Basic Settings",
      key: "1",
      children: <ProfileManager />,
    },
    {
      label: "Security Settings",
      key: "2",
      children: <ProfileSecurity />,
    },
  ];

  return (
    <>
      <Card className="header-card">
        <div>
          <img src={HireIcon} alt="" className="header-card-img" />
          <span>
            Please complete the information below to set up your profile and get
            started
          </span>
        </div>
      </Card>
      <Card className="body-card">
        <Tabs tabPosition="left" items={tabItems} />
      </Card>
    </>
  );
}

export default Profile;
