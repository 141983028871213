import { useState, useEffect } from "react";
import {
  Card,
  Spin,
  Row,
  Col,
  Divider,
  Button,
  Popconfirm,
  Typography,
} from "antd";
import ProfileInformationLoader from "./profileInformationLoader";
import {
  ADMIN_RESET_USER_PASSWORD,
  GENERATE_MEMBERSHIP_NUMBER,
  UPDATE_USER,
} from "../../graphql/mutations";
import { UPDATE_USER_EMAIL } from "../../../../graphql/mutations";
import ProfileEditable from "./profileEditable";
import { useLazyQuery, useMutation } from "@apollo/client";
import { notifyError, openNotificationWithIcon } from "@/utils/notification";
import UpdateEmailForm from "./updateEmail";
import { Auth } from "aws-amplify";
import ConfirmCodeForm from "./confirmCode";
import { signOut } from "@/utils/auth/signOut";
import { GET_DOWNLOAD_URL } from "@/graphql/queries";
import BlacklistStatusAlert from "@/components/BlacklistStatusAlert";
import { GET_BLACKLIST_STATUS } from "@/containers/Admin/graphql/queries";
import AddressTable from "@/components/AddressTable";

Auth.configure({
  Auth: {
    identityPoolId: import.meta.env.VITE_USER_POOL_CLIENT_ID,
    region: import.meta.env.VITE_REGION,
    userPoolId: import.meta.env.VITE_USER_POOL_ID,
    UserPoolId: import.meta.env.VITE_USER_POOL_ID,
    ClientId: import.meta.env.VITE_USER_POOL_CLIENT_ID,
    userPoolWebClientId: import.meta.env.VITE_USER_POOL_CLIENT_ID,
  },
});

function MemberProfile({
  profileData,
  companyId,
  companyInformation,
  refetchProfile,
  employments,
}: any) {
  const userRole = localStorage.getItem("userRoles");
  const [loading, setLoading] = useState(true);
  const [languageProficiencyList, setLanguageProficiencyList] = useState([]);
  const [qualificationsList, setQualificationsList] = useState([]);
  const [userData, setUserData] = useState(null);
  const [usePersonalAddresses, setPersonalAddresses] = useState(null);
  const [useCompanyInformation, setCompanyInformation] =
    useState(companyInformation);
  const [useEditMode, setEditMode] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [updatingEmail, setUpdatingEmail] = useState(false);

  const [adminResetUserPassword] = useMutation(ADMIN_RESET_USER_PASSWORD);
  const [updateUser] = useMutation(UPDATE_USER);
  const [generateNumber] = useMutation(GENERATE_MEMBERSHIP_NUMBER);
  const [updateEmail] = useMutation(UPDATE_USER_EMAIL);

  const [getBlacklistStatus, { data: blacklistData }] = useLazyQuery(
    GET_BLACKLIST_STATUS,
    {
      variables: {
        id: userData?.id,
        type: "user",
      },
      onError: notifyError,
    }
  );

  const [getDownloadUrl, { loading: loadingDoc }] = useLazyQuery(
    GET_DOWNLOAD_URL,
    {
      onCompleted: (data) => {
        if (data) {
          window.open(data.preSignedDownloadUrl);
        }
      },
      onError: (error) => {
        notifyError(error);
      },
    }
  );

  useEffect(() => {
    if (userData?.id) {
      getBlacklistStatus();
    }
  }, [userData?.id]);

  useEffect(() => {
    if (profileData) {
      if (profileData.languageProficiencyList) {
        setLanguageProficiencyList(profileData.languageProficiencyList);
      }
      if (profileData.qualificationsList) {
        setQualificationsList(profileData.qualificationsList);
      }
      if (profileData.userData) {
        setUserData(profileData.userData);
      }
      if (profileData.personalLocationData) {
        setPersonalAddresses(profileData.personalLocationData);
      }
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [profileData]);

  useEffect(() => {
    setCompanyInformation(companyInformation);
  }, [companyInformation]);

  const disableEditAndRefreshInfo = () => {
    setEditMode(false);
    refetchProfile();
  };

  const confirmResetPassword = async () => {
    await adminResetUserPassword({
      variables: {
        username: userData.email,
      },
    });
    openNotificationWithIcon(
      "success",
      "Password reset",
      "The user password has been reset to the default password"
    );
  };

  const handleUpdateEmail = async (values) => {
    try {
      setUpdatingEmail(true);
      const { errors, data } = await updateEmail({
        variables: { input: { userId: userData.id, newEmail: values.email } },
      });

      setUpdatingEmail(false);

      if (data.updateEmail.success) {
        setModalVisible(false);
        openNotificationWithIcon(
          "success",
          "Success",
          `Email successfully updated.`
        );
      } else {
        console.error(errors);
        notifyError(errors[0]);
      }
    } catch (error) {
      notifyError(error);
      setUpdatingEmail(false);
    }
  };

  const handleConfirmCode = async (values: any) => {
    try {
      await Auth.verifyCurrentUserAttributeSubmit("email", values.code);
      const email = localStorage.getItem("newEmail");

      updateUser({
        variables: {
          input: {
            id: userData.id,
            email,
          },
        },
      }).then((res) => {
        if (res.data.updateUser) {
          localStorage.removeItem("newEmail");
          openNotificationWithIcon(
            "success",
            "Code confirmed",
            "Email has been successfully updated."
          );
          signOut({
            email: userData.username,
            idNumber: userData.idNumber,
          });
        } else {
          openNotificationWithIcon(
            "error",
            "Email update Failed",
            "Please try again or contact support."
          );
        }
      });
    } catch (error) {
      openNotificationWithIcon(
        "error",
        "Email update Failed",
        "Please try again or contact support."
      );
    }
    setConfirmModalVisible(false);
  };

  function viewReport() {
    getDownloadUrl({
      variables: {
        input: {
          filePath: userData.prequalification.reportUrl,
          forCompany: false,
        },
      },
    });
  }

  const handleGenerateMembershipNumber = async () => {
    try {
      generateNumber({
        variables: {
          input: {
            id: useCompanyInformation.id,
            userId: userData.id,
            employerId: useCompanyInformation?.company?.id,
          },
        },
      }).then((res) => {
        if (res.data.generateCouncilMembershipNumber) {
          openNotificationWithIcon(
            "success",
            "Membership number success",
            "Membership number has been successfully updated."
          );
        } else {
          openNotificationWithIcon(
            "error",
            "Membership number update Failed",
            "Please try again or contact support."
          );
        }
      });
    } catch (error) {
      openNotificationWithIcon(
        "error",
        "Membership number update Failed",
        "Please try again or contact support."
      );
    }
  };

  const ModalProps = {
    visible: modalVisible,
    onCancel: () => setModalVisible(false),
    onCreate: (values) => handleUpdateEmail(values),
    initialData: userData,
    updatingEmail,
  };

  const ConfirmModalProps = {
    visible: confirmModalVisible,
    onCancel: () => setConfirmModalVisible(false),
    onCreate: (values) => handleConfirmCode(values),
    initialData: userData,
  };

  useEffect(() => {
    localStorage.setItem("companyId", useCompanyInformation?.company.id);
  }, [useCompanyInformation]);

  return (
    <Spin tip="Loading..." className="loader" spinning={loading}>
      <div className="col-sm-12 col-md-12 card-page-border">
        <Card>
          {(!profileData ||
            !profileData.userData ||
            !profileData.qualificationsList ||
            !profileData.languageProficiencyList ||
            !profileData.personalLocationData) && <ProfileInformationLoader />}
          {profileData && useEditMode && (
            <>
              <div className="card-header">
                <h3>Personal information</h3>
              </div>

              <ProfileEditable userData={userData} />
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <Button
                    onClick={() => disableEditAndRefreshInfo()}
                    className="purple-button"
                    style={{ marginRight: "20px" }}
                  >
                    View Profile
                  </Button>
                </Col>
              </Row>
            </>
          )}
          {profileData &&
            !useEditMode &&
            userData &&
            qualificationsList &&
            languageProficiencyList &&
            usePersonalAddresses && (
              <>
                <div className="card-header">
                  <h3>Personal information</h3>
                  <h3>
                    {"Membership number(s): "}
                    {employments?.length ? (
                      employments
                        .map((e) => e.councilMembershipNumber || "")
                        .join(", ")
                    ) : userRole === "Super User" ? (
                      <Button
                        onClick={handleGenerateMembershipNumber}
                        className="purple-button"
                        style={{ marginRight: "20px" }}
                      >
                        Generate Number
                      </Button>
                    ) : (
                      "N/A"
                    )}
                  </h3>
                </div>
                {!!blacklistData?.getEntityBlacklistStatus && (
                  <BlacklistStatusAlert
                    type="user"
                    blacklistData={blacklistData.getEntityBlacklistStatus}
                  />
                )}
                <Row gutter={[24, 24]}>
                  <Col span={6} className="flex-row gap-1">
                    <span className="bold">ID number: </span>
                    <Typography.Paragraph copyable>
                      {userData.idNumber ? userData.idNumber : "N/A"}
                    </Typography.Paragraph>
                  </Col>
                  <Col span={6}>
                    <span className="bold">First name: </span>
                    {userData.firstName ? userData.firstName : "N/A"}
                  </Col>
                  <Col span={6}>
                    <span className="bold">Last name: </span>
                    {userData.lastName ? userData.lastName : "N/A"}
                  </Col>
                  <Col span={6}>
                    <span className="bold">Phone number: </span>
                    {userData.phone ? userData.phone : "N/A"}
                  </Col>
                  <Col span={6} className="flex-row gap-1">
                    <span className="bold">Email: </span>
                    <Typography.Paragraph copyable>
                      {userData.email ? userData.email : "N/A"}
                    </Typography.Paragraph>
                  </Col>
                  <Col span={6}>
                    <span className="bold">Capacity: </span>
                    {useCompanyInformation && useCompanyInformation.capacity
                      ? useCompanyInformation?.capacity?.name
                      : "N/A"}
                  </Col>
                  <Col span={6}>
                    <span className="bold">Business unit: </span>
                    {useCompanyInformation && useCompanyInformation.businessUnit
                      ? useCompanyInformation?.businessUnit?.name
                      : "N/A"}
                  </Col>
                  <Col span={6}>
                    <span className="bold">Phone number 2: </span>
                    {userData.secondaryPhone ? userData.secondaryPhone : "N/A"}
                  </Col>
                  <Col span={6}>
                    <span className="bold">Work number: </span>
                    {userData.workPhone ? userData.workPhone : "N/A"}
                  </Col>
                  <Col span={6}>
                    <span className="bold">Home number: </span>
                    {userData.homePhone ? userData.homePhone : "N/A"}
                  </Col>
                  <Col span={6}>
                    <span className="bold">Work fax: </span>
                    {userData.fax ? userData.fax : "N/A"}
                  </Col>
                  <Col span={6}>
                    <span className="bold">Email private: </span>
                    {userData.email ? userData.email : "N/A"}
                  </Col>
                  <Col span={6}>
                    <span className="bold">Marital status: </span>
                    {userData.maritalStatus ? userData.maritalStatus : "N/A"}
                  </Col>
                  <Col span={6}>
                    <span className="bold">Ethnicity: </span>
                    {userData.ethnicity ? userData.ethnicity : "N/A"}
                  </Col>
                  <Col span={6}>
                    <span className="bold">Age: </span>
                    {userData.age ? userData.age : "N/A"}
                  </Col>
                  <Col span={6}>
                    {userData?.prequalification?.reportUrl ? (
                      <Button
                        loading={loadingDoc}
                        onClick={viewReport}
                        className="purple-button"
                      >
                        View Eagle Eye Report
                      </Button>
                    ) : (
                      "Eagle Eye Report Not Available"
                    )}
                  </Col>
                </Row>
                <Divider />
                <div className={loading ? "no-interact" : null}>
                  <h3>Location information</h3>
                  <AddressTable addresses={usePersonalAddresses || []} />
                  <Divider />
                  <h3>Language proficiency</h3>
                  {languageProficiencyList &&
                    languageProficiencyList.map((item, index) => (
                      <Row
                        gutter={[24, 24]}
                        key={`Language Proficiency ${index + 1}`}
                      >
                        <Col span={4}>
                          <span className="bold">Language: </span>
                          {item.language?.name}
                        </Col>
                        <Col span={4}>
                          <span className="bold">Proficiency: </span>
                          {item.proficiencyLevel?.name}
                        </Col>
                        <Col span={16}>
                          <span className="bold">Ability: </span>
                          {item.abilities.map((item) => item.name).join(", ")}.
                        </Col>
                      </Row>
                    ))}
                  <Divider />
                  <h3>Educational qualifications</h3>
                  <Row gutter={[24, 24]}>
                    <Col span={8}>
                      <span className="bold">Highest grade:</span>{" "}
                      {userData.highestGrade ? userData.highestGrade : "N/A"}
                    </Col>
                    <Col span={8}>
                      <span className="bold">Year obtained:</span>{" "}
                      {userData.highestGradeYear
                        ? userData.highestGradeYear
                        : "N/A"}
                    </Col>
                    <Col span={8}></Col>
                    {qualificationsList &&
                      qualificationsList.map((item, index) => (
                        <Col key={`Qualification ${index + 1}`} span={16}>
                          <Card>
                            <div className="flex-column-record-block">
                              <Button
                                shape="round"
                                className="btn-purple-non-interactive"
                              >
                                {`Qualification ${index + 1}`}
                              </Button>
                              <br />
                              <span>
                                Type: <label>{item.type?.name}</label>
                                <br />
                                Name of institution:{" "}
                                <label>{item.institutionName}</label>
                                <br />
                                Qualification:{" "}
                                <label>{item.qualification}</label>
                                <br />
                                Year: <label>{item.year}</label>
                                <br />
                              </span>
                            </div>
                          </Card>
                          <br />
                        </Col>
                      ))}
                  </Row>
                  <Divider />
                  <h3>Employment information</h3>
                  {employments &&
                    employments.length &&
                    employments.map((employment) => (
                      <Row gutter={[24, 24]} key={employment?.id.toString()}>
                        <Col span={8}>
                          <span className="bold">Employer name: </span>
                          {employment?.company?.registeredName ||
                            employment?.company?.tradingName}
                        </Col>
                        <Col span={8}>
                          <span className="bold">Registration number: </span>
                          {employment?.company?.registeredNumber}
                        </Col>
                        <Col span={8}>
                          <span className="bold">
                            Council registration number:{" "}
                          </span>
                          {employment?.councilMembershipNumber}
                        </Col>
                        <Col span={8}>
                          <span className="bold">Capacity of employment: </span>
                          {employment?.capacity?.name}
                        </Col>
                        <Col span={8}>
                          <span className="bold">Employer address: </span>
                          {employment?.employerAddressType} -{" "}
                          {employment?.employerBranchLocation}
                        </Col>
                        <Divider />
                      </Row>
                    ))}
                  <Row gutter={[24, 24]}>
                    <Col span={24}>
                      <label>Change account</label>
                    </Col>
                  </Row>
                  <Row gutter={[24, 24]}>
                    <Col span={24}>
                      <Button
                        onClick={() => setEditMode(true)}
                        className="purple-button"
                        style={{ marginRight: "20px" }}
                        disabled={userRole !== "Super User"}
                      >
                        Edit Profile
                      </Button>
                      {userRole === "Super User" && (
                        <Popconfirm
                          title="Are you sure to reset this users password?"
                          onConfirm={confirmResetPassword}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            onClick={() => {}}
                            style={{ marginRight: "20px" }}
                          >
                            Reset Password
                          </Button>
                        </Popconfirm>
                      )}
                      {userRole === "Super User" && (
                        <Popconfirm
                          title="Are you sure to reset this users email?"
                          onConfirm={() => setModalVisible(true)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            onClick={() => {}}
                            style={{ marginRight: "20px" }}
                          >
                            Change Email
                          </Button>
                        </Popconfirm>
                      )}
                    </Col>
                  </Row>
                </div>
              </>
            )}
        </Card>
      </div>
      <UpdateEmailForm {...ModalProps} />
      <ConfirmCodeForm {...ConfirmModalProps} />
    </Spin>
  );
}

export default MemberProfile;
