import { useState, useEffect } from "react";
import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  Select,
  DatePicker,
  TimePicker,
  Upload,
  FormInstance,
  Form,
} from "antd";
import fileIcon from "../../../../assets/images/pdf_icon.png";
import { notifyError, openNotificationWithIcon } from "@/utils/notification";
import {
  ADDRESS_DIRECTIONS_TEMPLATE,
  REGULATION_7_8_a_NOTICE,
  REGULATION_7_12_NOTICE,
} from "../../../../constants";
import { useMutation, useLazyQuery } from "@apollo/client";
import {
  CREATE_COMPLAINT_PROSECUTION,
  UPDATE_COMPLAINT_PROSECUTION,
} from "@/graphql/mutations";
import { GET_PROSECUTION_BY_COMPLAINT } from "@/graphql/queries";
import { isEmpty } from "lodash";
import moment from "moment";
import "../index.css";
import SubmitToClient from "./submitToClient";

type Props = {
  taskId: number;
  companyId: number;
  companyName: string;
  complaint: any;
  companyContacts: any[];
  getDownloadUrl: (args: any) => void;
  updateActivity: () => void;
  form: FormInstance;
  documentTypes: { id: number; name: string; umbrella: string }[];
  handleUpdateContact: (field: string, value: string, id: number) => void;
  uploadDocument: (
    file: File,
    filePath: string,
    docTypeId: number,
    companyName: string,
    companyId: number,
    taskId?: number
  ) => Promise<void>;
  refreshComplaint: () => void;
};

export default ({
  taskId,
  companyId,
  complaint,
  companyContacts,
  getDownloadUrl,
  updateActivity,
  refreshComplaint,
  form,
  handleUpdateContact,
  uploadDocument,
  documentTypes,
  companyName,
}: Props) => {
  const isComplaintAdmin = localStorage.getItem("roleStream") === "Legal";
  const [chargeSheetGenerated, setChargeSheetGenerated] = useState(false);
  const [noticeTemplateGenerated, setNoticeTemplateGenerated] = useState(false);
  const [generatedTemplate, setGeneratedTemplate] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [noticeSent, setNoticeSent] = useState(false);
  const [prosecution, setProsecution] = useState<any>();
  const [appearances, setAppearances] = useState([]);
  const [numberOfCharges, setNumberOfCharges] = useState(1);

  const contraventionsList = [
    "Reg Sec 1A - Code",
    "Reg Sec 1B - Code",
    "Act Sec 8 Non Register",
    "Act Sec 19 Fees + Costs",
    "Act Sec 15 Improper Conduct/ Disqualification",
    "Act Sec 20 Trust Account/ Audit (Non Compliance)",
  ];

  const [getProsecution] = useLazyQuery(GET_PROSECUTION_BY_COMPLAINT, {
    fetchPolicy: "no-cache",
    onCompleted: ({ complaintProsecutionByComplaint }) =>
      updateState(complaintProsecutionByComplaint),
    onError: () => {
      openNotificationWithIcon(
        "error",
        "Error",
        "The was an error fetching prosecution record"
      );
    },
  });

  const [createProsecution] = useMutation(CREATE_COMPLAINT_PROSECUTION, {
    onCompleted: (data) => updateState(data.createComplaintProsecution),
    onError: () => {
      openNotificationWithIcon(
        "error",
        "Error",
        "The was an error creating prosecution record"
      );
    },
  });

  const [updateProsecution] = useMutation(UPDATE_COMPLAINT_PROSECUTION, {
    onCompleted: (data) => updateState(data.updateComplaintProsecution),
    onError: () => {
      openNotificationWithIcon(
        "error",
        "Error",
        "The was an error updating prosecution"
      );
    },
  });

  // const [generateTemplate] = useMutation(GENERATE_NOTICE_FILE, {
  //   onError: () => {
  //     setSubmitting(false);
  //     openNotificationWithIcon(
  //       "error",
  //       "Error",
  //       "The was an error generating document"
  //     );
  //   }
  // });

  useEffect(() => {
    if (complaint?.prosecution) updateState(complaint?.prosecution);
  }, []);

  const updateState = (prosecution) => {
    if (!isEmpty(prosecution)) {
      setProsecution(prosecution);
      setChargeSheetGenerated(prosecution.chargeSheetGenerated);
      setNoticeTemplateGenerated(prosecution.noticeTemplateGenerated);
      setNoticeSent(prosecution.noticeEmailSent);
      setAppearances(prosecution.prosecutionAppearances);
      if (prosecution.numberOfCharges)
        setNumberOfCharges(prosecution.numberOfCharges);
    }
  };

  const handleViewDocument = async (fileName) => {
    try {
      setSubmitting(true);
      await getDownloadUrl({
        filePath: `${companyId}/Complaints/Complaint_${complaint.id}/${fileName}.pdf`,
        forCompany: true,
      });
      setSubmitting(false);
    } catch {
      openNotificationWithIcon(
        "error",
        "Upload Error",
        "Error occurred during upload"
      );
    }
  };

  const handleGenerateTemplate = async (templateName: string): Promise<any> => {
    try {
      const values = await form.validateFields();

      let input = prosecution
        ? {
            id: prosecution.id,
            numberOfCharges: Number(values.numberOfCharges),
            charges: Array.from(
              Array(Number(values.numberOfCharges)),
              (e, i) => {
                return {
                  description: values[`chargeDescription${i}`],
                  alternativeCharge: values[`alternativeCharge${i}`],
                };
              }
            ),
          }
        : {
            reason: values.prosecutionReason.join(","),
            dateOfAppearance: values.dateOfAppearance,
            timeOfAppearance: values.timeOfAppearance,
            placeOfAppearance: values.address,
            complaintId: complaint.id,
            numberOfCharges: Number(values.numberOfCharges),
            charges: Array.from(
              Array(Number(values.numberOfCharges)),
              (e, i) => {
                return {
                  description: values[`chargeDescription${i}`],
                  alternativeCharge: values[`alternativeCharge${i}`],
                };
              }
            ),
          };

      let updateInput;
      const upsertProsecution = prosecution
        ? updateProsecution
        : createProsecution;

      switch (templateName) {
        case REGULATION_7_8_a_NOTICE:
          setChargeSheetGenerated(true);
          updateInput = {
            ...input,
            chargeSheetGenerated: true,
          };

          break;
        case REGULATION_7_12_NOTICE:
          setNoticeTemplateGenerated(true);
          updateInput = {
            ...input,
            noticeTemplateGenerated: true,
          };
          break;
        case ADDRESS_DIRECTIONS_TEMPLATE:
          updateInput = {
            ...input,
            appearanceAddressGenerated: true,
          };
          break;
      }

      const resp = await upsertProsecution({
        variables: { input: updateInput },
      });

      // await generateTemplate({
      //   variables: {
      //     companyId: companyId,
      //     complaintId: complaint.id,
      //     template: templateName
      //   }
      // });

      return resp;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const handleUploadTemplate = async (
    { file, onSuccess }: any,
    template: string
  ) => {
    try {
      setSubmitting(true);
      await handleGenerateTemplate(template);
      await uploadDocument(
        file,
        `${companyId}/Complaints/Complaint_${complaint.id}/${template}.pdf`,
        documentTypes.find((t) => t.name === template)?.id,
        companyName,
        companyId,
        taskId
      );

      onSuccess();
      setGeneratedTemplate(template);
      updateActivity();
      openNotificationWithIcon(
        "success",
        "Success",
        `${generatedTemplate} successfully uploaded`
      );
    } catch (err) {
      notifyError(err);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      {isComplaintAdmin && (
        <div>
          <Form>
            <div className="form-item-block">
              <Form.Item
                label="Reason for prosecution"
                className="input-block"
                {...{
                  name: "prosecutionReason",
                  initialValue: prosecution?.reason.split(","),
                  rules: [
                    { required: true, message: "Please enter company name" },
                  ],
                }}
              >
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  showSearch
                  placeholder="Select reason"
                >
                  {contraventionsList?.map((type) => {
                    return (
                      <Select.Option key={type} value={type}>
                        {type}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label={"Date of appearance"}
                className="input-block"
                {...{
                  name: "dateOfAppearance",
                  initialValue: !isEmpty(appearances)
                    ? moment(
                        appearances[appearances.length - 1]?.dateOfAppearance
                      )
                    : moment(),
                  rules: [
                    {
                      required: true,
                      message: `Please select date of appearance`,
                    },
                  ],
                }}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  className="input-height input-select"
                />
              </Form.Item>
            </div>
            <div className="form-item-block">
              <Form.Item
                label="Time of hearing"
                className="input-block"
                {...{
                  name: "timeOfAppearance",
                  initialValue: !isEmpty(appearances)
                    ? moment(
                        appearances[appearances.length - 1]?.timeOfAppearance,
                        "HH:mm"
                      )
                    : moment("00:00:00", "HH:mm"),
                  rules: [
                    {
                      required: true,
                      message: "Please enter time of appearance",
                    },
                  ],
                }}
              >
                <TimePicker
                  format="HH:mm"
                  style={{ width: "100%" }}
                  placeholder="Enter time of appearance"
                />
              </Form.Item>
              <Form.Item
                label={"Place of appearance"}
                className="input-block"
                {...{
                  name: "address",
                  initialValue: !isEmpty(appearances)
                    ? appearances[appearances.length - 1]?.placeOfAppearance
                    : "",
                  rules: [
                    {
                      required: true,
                      message: `Please enter address for appearance`,
                    },
                  ],
                }}
              >
                <Input placeholder="Please enter address" />
              </Form.Item>
            </div>
            <div className="form-item-block">
              <Form.Item
                label={"Number of Charges"}
                className="input-block"
                {...{
                  name: "numberOfCharges",
                  initialValue: numberOfCharges,
                  rules: [
                    {
                      required: true,
                      message: `Please enter the number of charges`,
                    },
                  ],
                }}
              >
                <Input
                  placeholder="Please enter the number of charges"
                  onChange={(e) => setNumberOfCharges(Number(e.target.value))}
                  min={1}
                  type="number"
                />
              </Form.Item>
            </div>
            {Array.from(Array(numberOfCharges), (e, i) => {
              return (
                <div key={`charge${i}`}>
                  <div className="form-item-block">
                    <Form.Item
                      label={`Charge ${i + 1} Description`}
                      style={{ width: "100%" }}
                      {...{
                        name: `chargeDescription${i}`,
                        initialValue: !isEmpty(prosecution?.prosecutionCharges)
                          ? prosecution?.prosecutionCharges[i]?.description
                          : "",
                        rules: [
                          {
                            required: true,
                            message: `Please enter the charge description`,
                          },
                        ],
                      }}
                    >
                      <Input.TextArea
                        rows={5}
                        placeholder="Please enter the charge descriptions"
                      />
                    </Form.Item>
                  </div>

                  <div className="form-item-block">
                    <Form.Item
                      label={`Alternative Charge ${i + 1}`}
                      style={{ width: "100%" }}
                      {...{
                        name: `alternativeCharge${i}`,
                        initialValue: !isEmpty(prosecution?.prosecutionCharges)
                          ? prosecution?.prosecutionCharges[i]
                              ?.alternativeCharge
                          : "",
                        rules: [
                          {
                            required: true,
                            message: `Please enter the alternative charge description`,
                          },
                        ],
                      }}
                    >
                      <Input.TextArea
                        rows={5}
                        placeholder="Please enter the alternative charge descriptions"
                      />
                    </Form.Item>
                  </div>
                </div>
              );
            })}
          </Form>

          <br />
          <div>
            {!chargeSheetGenerated && (
              <>
                <h4>Regulation 7 Sub-Section 8(A) Charge Sheet</h4>
                {/* <Button
                className="purple-button"
                loading={submitting}
                onClick={() =>
                  handleGenerateTemplate(REGULATION_7_8_a_NOTICE)
                }
              >
                Generate PDF
              </Button> */}
                <Upload
                  accept=".pdf,.PDF"
                  showUploadList={false}
                  customRequest={(opts) =>
                    handleUploadTemplate(opts, REGULATION_7_8_a_NOTICE)
                  }
                  disabled={submitting}
                >
                  <Button loading={submitting} className="purple-button">
                    <UploadOutlined /> Upload Charge Sheet
                  </Button>
                </Upload>

                <br />
                <br />
              </>
            )}

            {!noticeTemplateGenerated && (
              <>
                <h4>Regulation 7(12) - Notice to appear</h4>
                {/* <Button
                className="purple-button"
                loading={submitting}
                onClick={() => handleGenerateTemplate(REGULATION_7_12_NOTICE)}
              >
                Generate PDF
              </Button> */}
                <Upload
                  accept=".pdf,.PDF"
                  showUploadList={false}
                  customRequest={(opts) =>
                    handleUploadTemplate(opts, REGULATION_7_12_NOTICE)
                  }
                  disabled={submitting}
                >
                  <Button loading={submitting} className="purple-button">
                    <UploadOutlined /> Upload Charge Sheet
                  </Button>
                </Upload>
                <br />
                <br />
              </>
            )}
          </div>
        </div>
      )}

      <div>
        {noticeSent && !isComplaintAdmin && (
          <>
            <h3>Please view the prosecution details: </h3>
            <span>
              Reason for prosecution: <strong>{prosecution?.reason}</strong>
            </span>
            <br />
            <span>
              Date of appearance:{" "}
              <strong>
                {!isEmpty(appearances)
                  ? appearances[appearances.length - 1]?.dateOfAppearance
                  : ""}
              </strong>
            </span>
            <br />
            <span>
              Time of appearance:{" "}
              <strong>
                {!isEmpty(appearances)
                  ? appearances[appearances.length - 1]?.timeOfAppearance
                  : ""}
              </strong>
            </span>
            <br />
            <span>
              Place of appearance:{" "}
              <strong>
                {!isEmpty(appearances)
                  ? appearances[appearances.length - 1]?.placeOfAppearance
                  : ""}
              </strong>
            </span>
            <br />
            <br />
          </>
        )}

        {chargeSheetGenerated &&
          (isComplaintAdmin || (!isComplaintAdmin && noticeSent)) && (
            <div>
              <h4>Regulation 7 Sub-Section 8(A) - Charge Sheet</h4>
              <span>
                <img style={{ height: "17px", width: "17px" }} src={fileIcon} />
                &nbsp; &nbsp;
                {REGULATION_7_8_a_NOTICE}
                <Button
                  type="link"
                  loading={submitting}
                  onClick={() => handleViewDocument(REGULATION_7_8_a_NOTICE)}
                >
                  View
                </Button>
              </span>
              <br />
              <br />
            </div>
          )}

        {noticeTemplateGenerated &&
          (isComplaintAdmin || (!isComplaintAdmin && noticeSent)) && (
            <div>
              <h4>Regulation 7(12) - Notice to appear</h4>
              <span>
                <img style={{ height: "17px", width: "17px" }} src={fileIcon} />
                &nbsp; &nbsp;
                {REGULATION_7_12_NOTICE}
                <Button
                  type="link"
                  loading={submitting}
                  onClick={() => handleViewDocument(REGULATION_7_12_NOTICE)}
                >
                  View
                </Button>
              </span>
            </div>
          )}
      </div>
      <br />
      <br />
      {!noticeSent &&
        chargeSheetGenerated &&
        noticeTemplateGenerated &&
        isComplaintAdmin && (
          <SubmitToClient
            companyContacts={companyContacts}
            taskId={taskId}
            complaint={complaint}
            setNoticeSent={(val: boolean) =>
              getProsecution({ variables: { complaintId: complaint?.id } })
            }
            refreshComplaint={refreshComplaint}
            updateActivity={updateActivity}
            mode={"notice-to-appear"}
            handleUpdateContact={handleUpdateContact}
          />
        )}
    </>
  );
};
