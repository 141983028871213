import { useState, useEffect } from "react";
import { Card, Descriptions } from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
import { useLazyQuery } from "@apollo/client";
import { GET_TASK } from "@/graphql/queries";
import { Link } from "react-router-dom";
import { openNotificationWithIcon } from "@/utils/notification";
import ComplaintLoader from "../Admin/Complaint/SubComponents/complaintLoader";
import { useNavigate, useParams } from "react-router-dom";
import RegistrationFeePayment from "../Register/feePayments";
import { If } from "@/components/If";

function TaskReview() {
  const navigate = useNavigate();
  const { id } = useParams<Record<string, string>>();
  const taskId = +id;
  const [task, setTask] = useState<any>({});
  const [loadingTask, setLoadingTask] = useState(true);

  useEffect(() => {
    localStorage.setItem("employerFinanceTaskId", id);
  }, [id]);

  const [getTask] = useLazyQuery(GET_TASK, {
    fetchPolicy: "no-cache",
    onError: (err) => {
      if (
        err.message === "Unauthorized" ||
        err.message?.includes("token has expired")
      ) {
        navigate("/login");
      }

      openNotificationWithIcon(
        "error",
        "Load Error",
        "There was an error loading your task"
      );
    },
    onCompleted: (data) => {
      if (!isEmpty(data?.task)) {
        setTask(data.task);
        setLoadingTask(false);

        localStorage.setItem("registrationCapacity", "Individual");
        localStorage.setItem("companyId", data?.task?.company?.id.toString());
        if (
          data?.task?.employeeInvitation?.application?.id &&
          data?.task?.employeeInvitation?.application?.task?.id
        ) {
          localStorage.setItem(
            "employeePaymentApplicationId",
            data?.task?.employeeInvitation?.application?.id
          );
          localStorage.setItem(
            "employeePaymentApplicationTaskId",
            data?.task?.employeeInvitation?.application?.task?.id
          );
        } else {
          localStorage.removeItem("employeePaymentApplicationId");
          localStorage.removeItem("employeePaymentApplicationTaskId");
        }
      }
    },
  });

  useEffect(() => {
    getTask({ variables: { taskId } });
  }, []);

  if (loadingTask) {
    return <ComplaintLoader />;
  }

  return (
    <div style={{ margin: "1vw" }}>
      <Card className="information-card">
        <Descriptions column={4} title={`Payment Task - ${task?.id}`}>
          <Descriptions.Item label="Task Type">
            {task?.type?.name}
          </Descriptions.Item>
          <Descriptions.Item label="Task Status">
            {task?.taskStatus}
          </Descriptions.Item>
          <Descriptions.Item label="Date Created">
            {moment(task?.createdDate).format("DD-MM-YYYY")}
          </Descriptions.Item>
          <Descriptions.Item label="Date Modified">
            {moment(task?.createdDate).format("DD-MM-YYYY")}
          </Descriptions.Item>
        </Descriptions>
      </Card>
      <br />

      <If
        condition={
          !!task?.company?.id &&
          !["complete", "resolved"].includes(task.taskStatus.toLowerCase())
        }
      >
        <div className="flex-row">
          <div className="flex-column">
            <Card>
              <RegistrationFeePayment
                paymentMethodRequired="Direct"
                registrationCapacity="Individual"
                registeringAs="Employee of a Business"
                companyId={task?.company?.id}
                task={task}
              />
            </Card>
          </div>
        </div>
      </If>

      {["complete", "resolved"].includes(task.taskStatus.toLowerCase()) &&
        task?.employeeInvitation?.transaction?.id && (
          <div className="flex-row">
            <div className="flex-column" style={{ width: "50%" }}>
              <Card>
                Payment Transaction Complete:{" "}
                <Link
                  to={`/admin/transaction/${task.employeeInvitation.transaction.id}`}
                >
                  #{task.employeeInvitation.transaction.id}
                </Link>
              </Card>
            </div>
          </div>
        )}
    </div>
  );
}

export default TaskReview;
