import { Modal, Input, Form, FormInstance } from "antd";

type Props = {
  visible: boolean;
  onCancel: () => void;
  onCreate: () => Promise<void>;
  initialData?: any;
  form: FormInstance;
};

function InviteUserForm({
  visible,
  onCancel,
  onCreate,
  initialData,
  form,
}: Props) {
  return (
    <Modal
      open={visible}
      title="Invite a member"
      okText="Invite"
      onCancel={onCancel}
      onOk={onCreate}
      okButtonProps={{
        className: "purple-button",
      }}
      okType="default"
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Email"
          name={"email"}
          {...{
            initialValue: initialData ? initialData.email : "",
            rules: [
              {
                required: true,
                message: "Please input user's email!",
              },
            ],
          }}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default InviteUserForm;
