import {
  useCallback,
  Fragment,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { Input, Row, Col, Select } from "antd";
import debounce from "lodash/debounce";
const { Option } = Select;

export const EmployeeFilters = (
  { handleEmployeeFilterChange, defaultMultiFilter, setError, mode },
  ref
) => {
  const capacityFilterRef = useRef();
  const removalReasonFilterRef = useRef();
  const identityFilterRef = useRef();
  const sortFilterRef = useRef();

  useImperativeHandle(ref, () => ({
    // @ts-ignore: function called from parent
    resetFilters() {
      resetFilterCollection([
        {
          ref: capacityFilterRef,
          defaultValue: defaultMultiFilter["capacityId"],
        },
        {
          ref: removalReasonFilterRef,
          defaultValue: defaultMultiFilter["removalReason"],
        },
        {
          ref: identityFilterRef,
          defaultValue: defaultMultiFilter["identity"],
        },
        {
          ref: sortFilterRef,
          defaultValue: defaultMultiFilter["sort"],
        },
      ]);
    },
  }));

  const resetFilterCollection = (refMetaCollection = []) => {
    for (let refMeta of refMetaCollection) {
      if (refMeta && refMeta.ref && refMeta.ref.current) {
        let currentRef = refMeta.ref.current;
        let useDefaultValue = refMeta.defaultValue
          ? refMeta.defaultValue
          : null;
        if (currentRef.rcSelect) {
          currentRef.rcSelect.setState({ value: [useDefaultValue] });
        } else if (currentRef.picker) {
          currentRef.picker.setState({
            value: useDefaultValue,
            showDate: useDefaultValue,
          });
        } else if (currentRef.input) {
          currentRef.input.value = useDefaultValue;
        }
      }
    }
  };

  const handleEmployeeFilterUpdate = (data) => {
    handleEmployeeFilterChange(data, "identity");
  };

  const debouncedEmployeeEmailFilterUpdate = useCallback(
    debounce(handleEmployeeFilterUpdate, 250),
    []
  );

  return (
    <Fragment>
      <Row gutter={[24, 24]}>
        <Col span={8}>
          <div className="flex-center-space-between">
            <label>ID/Passport:</label>
            <Input
              ref={identityFilterRef}
              placeholder="Search employee by user ID"
              style={{ width: "calc(100% - 75px)" }}
              onChange={(event) =>
                debouncedEmployeeEmailFilterUpdate(event.target.value)
              }
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="flex-center-space-between">
            <label>Removal Reason:</label>
            <Select
              ref={removalReasonFilterRef}
              placeholder="Select removal reason"
              onChange={(value) =>
                handleEmployeeFilterChange(value, "removalReason")
              }
              style={{ width: "calc(100% - 75px)" }}
            >
              <Option value="deceased">Deceased</Option>
              <Option value="left-employ">Left Employment</Option>
              <Option value="hearing-outcome">Outcome of hearing</Option>
            </Select>
          </div>
        </Col>
        <Col span={8}>
          <div className="flex-center-space-between">
            <label>Capacity:</label>
            <Select
              ref={capacityFilterRef}
              placeholder="Select Capacity"
              onChange={(value) =>
                handleEmployeeFilterChange(value, "capacityId")
              }
              style={{ width: "calc(100% - 75px)" }}
            >
              <Option value={1}>Director</Option>
              <Option value={2}>Employee</Option>
            </Select>
          </div>
        </Col>
        <Col span={8}>
          <div className="flex-center-space-between">
            <label>Sort:</label>
            <Select
              ref={sortFilterRef}
              placeholder="sort by..."
              onChange={(value) => handleEmployeeFilterChange(value, "sort")}
              style={{ width: "calc(100% - 75px)" }}
              defaultValue={defaultMultiFilter["sort"]}
            >
              <Option value="DESC">Newest</Option>
              <Option value="ASC">Oldest</Option>
            </Select>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

export default forwardRef(EmployeeFilters);
