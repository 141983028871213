import { useState, useEffect } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { LinkOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Modal,
  Spin,
  Select,
  Input,
  Card,
  Menu,
  Button,
  Upload,
  Form,
} from "antd";
import { GET_USER } from "../graphql/queries";
import { GET_PRESIGNED_URL } from "../../../graphql/queries";
import { CREATE_DOCUMENT } from "../../../graphql/mutations";
import { POPULATE_REPORT } from "../graphql/mutations";
import { uploadToS3 } from "@/utils/uploadToS3";
import { openNotificationWithIcon } from "@/utils/notification";

type Props = {
  reportType: string;
  reportMonth: string;
  companies: any[];
  visible: boolean;
  employeeId: number;
  onCancel: () => void;
  refreshList: () => void;
  setModalVisible: (arg: boolean) => void;
};

export default function PopulateReportModal(props: Props) {
  const {
    reportType,
    reportMonth,
    companies,
    onCancel,
    setModalVisible,
    visible,
    employeeId,
  } = props;
  const [form] = Form.useForm();
  const [createRenewalTask, setCreateRenewalTask] = useState<"yes" | "no">(
    "no"
  );
  const [createTaxInvoice, setCreateTaxInvoice] = useState<"yes" | "no">("no");
  const [createCertificates, setCreateCertificates] = useState<"yes" | "no">(
    "no"
  );
  const [filePath, setFilePath] = useState<{ path: string; name: string }>();
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<any>();
  const [user, setUser] = useState<any>();

  const toBoolean = (x: "yes" | "no") => (x === "yes" ? true : false);

  const handleChangeStatus = async () => {
    try {
      if (
        [createCertificates, createRenewalTask, createCertificates].every(
          (i) => i === "no"
        )
      ) {
        return setModalVisible(false);
      }

      setLoading(true);
      await populateReport({
        variables: {
          input: {
            company: companies.map(({ id }) => id),
            createCertificate: toBoolean(createCertificates),
            createRenewalTask: toBoolean(createRenewalTask),
            createTaxInvoice: toBoolean(createTaxInvoice),
            month: reportMonth,
            employeeId,
          },
        },
      });
      setModalVisible(false);
      openNotificationWithIcon(
        "success",
        "Success",
        "Report populated successfully"
      );
    } catch (error) {
      console.error(error);
      if (error.message.includes("Received status code 504")) {
        setModalVisible(false);
        openNotificationWithIcon(
          "success",
          "Success",
          "Populating in the background, please check again in a few minutes"
        );
      } else {
        openNotificationWithIcon(
          "error",
          "Change Company Error",
          error.message.replace("GraphQL error: ", "")
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const [getUser] = useLazyQuery(GET_USER, {
    onCompleted: (data) => {
      setLoading(false);
      setUser(data?.userData);
    },
    onError: (err) => {
      setLoading(false);
      console.error({ err });
    },
  });

  const [createDocument] = useMutation(CREATE_DOCUMENT);

  const [populateReport] = useMutation(POPULATE_REPORT);

  const [getPresignedUrl] = useLazyQuery(GET_PRESIGNED_URL, {
    fetchPolicy: "network-only",
    onCompleted: async ({ preSignedUrl }) => {
      if (preSignedUrl) {
        await uploadToS3(preSignedUrl, file.type, file);
        await createDocument({
          variables: {
            input: {
              documentName: filePath?.name,
              documentPath: filePath?.path,
              documentTypeId: 27,
              documentStatusId: 1,
              documentFormat: file.type,
              forCompany: true,
            },
          },
        });
      }
    },
    onError: (err) => {
      console.error({ err });
    },
  });

  const handleUpload = async ({ file, onSuccess }: any) => {
    setUploading(true);
    setFile(file);

    const ext = file.type.split("/").pop();
    const name = `change_status_${Date.now()}`;
    const path = `admin-uploads/${name}.${ext}`;
    setFilePath({ path, name });

    getPresignedUrl({
      variables: {
        input: {
          filePath: path,
          contentType: file.type,
          forCompany: false,
          companyName: "Admin",
        },
      },
    });

    setTimeout(() => {
      setUploading(false);
      onSuccess();
    }, 3500);
  };

  useEffect(() => {
    if (employeeId && visible) {
      setLoading(true);
      getUser({ variables: { input: { id: employeeId } } });
    }
  }, [employeeId, visible]);

  return (
    <Modal
      open={visible}
      title="Populate Report"
      okText="Run"
      onCancel={onCancel}
      onOk={handleChangeStatus}
      okButtonProps={{
        className: "purple-button",
        disabled: loading || uploading,
      }}
      cancelButtonProps={{
        disabled: loading || uploading,
      }}
      okType="default"
    >
      <Spin tip="Loading..." spinning={loading}>
        <Form form={form} layout="vertical">
          {reportType === "Certificate Run" ? (
            <Form.Item
              label={`Create Certificate(s) (${
                employeeId && user
                  ? `${user.firstName} ${user.firstName}`
                  : "All Company"
              })`}
              initialValue="no"
              required
              name="certificates"
            >
              <Select
                className="input-select"
                onChange={(value: "yes" | "no") => setCreateCertificates(value)}
              >
                <Select.Option value="yes">Yes</Select.Option>
                <Select.Option value="no">No</Select.Option>
              </Select>
            </Form.Item>
          ) : (
            <>
              <Form.Item
                label="Create Renewal Task"
                name="renewalTask"
                initialValue={"no"}
                required
              >
                <Select
                  className="input-select"
                  onChange={(value: "yes" | "no") =>
                    setCreateRenewalTask(value)
                  }
                >
                  <Select.Option value="yes">Yes</Select.Option>
                  <Select.Option value="no">No</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Create Tax Invoice"
                name="taxInvoice"
                required
                initialValue={"no"}
              >
                <Select
                  className="input-select"
                  onChange={(value: "yes" | "no") => setCreateTaxInvoice(value)}
                >
                  <Select.Option value="yes">Yes</Select.Option>
                  <Select.Option value="no">No</Select.Option>
                </Select>
              </Form.Item>
            </>
          )}
          <h4>Add a comment</h4>
          <Card
            className="comment-card"
            cover={
              <Input.TextArea
                style={{ resize: "none" }}
                rows={3}
                placeholder="Start typing..."
                disabled
              />
            }
          >
            <Card.Meta
              title={
                <div className="comment-action">
                  <Menu
                    mode="vertical"
                    theme="light"
                    className="link-user-menu"
                    style={{ visibility: "hidden" }}
                  >
                    <Menu.SubMenu
                      title={
                        <span>
                          <LinkOutlined
                            style={{ fontSize: "26px", color: "darkslategray" }}
                          />
                          <span>Link</span>
                        </span>
                      }
                    >
                      <Menu.Item key={"y"} id={"1"} onClick={(e) => {}}>
                        Hello
                      </Menu.Item>
                      <Menu>
                        {[].map((u) => (
                          <Menu.Item
                            key={u.id}
                            id={`${u.firstName} ${u.lastName}`}
                            onClick={(e) => {}}
                          >
                            {u.firstName} {u.lastName}(Admin)
                          </Menu.Item>
                        ))}
                      </Menu>
                    </Menu.SubMenu>
                  </Menu>
                  <div>
                    <Button className="btn-add-comment" disabled>
                      Save
                    </Button>
                  </div>
                </div>
              }
            />
          </Card>
          <Upload disabled={loading || uploading} customRequest={handleUpload}>
            <Button>
              <UploadOutlined /> Upload
            </Button>
          </Upload>
        </Form>
      </Spin>
    </Modal>
  );
}
