import { useMemo, useState } from "react";
import {
  Avatar,
  Button,
  Divider,
  Form,
  Input,
  List,
  Radio,
  Select,
  Spin,
} from "antd";
import Header from "./header";
import { notifyError } from "@/utils/notification";
import InformationLoader from "./informationLoader";
import { BASE_ENDPOINT } from "../../constants";
import { useNavigate } from "react-router-dom";
import { validateMobilePhoneNumber } from "@/utils/validatePhoneNumber";
import { LinkOutlined, HomeOutlined } from "@ant-design/icons";

export default function CreateComplaint() {
  const [complaintAgainst, setComplaintAgainst] = useState("Debt Collector");
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showComplaintForm, setShowComplaintForm] = useState(true);

  const handleSubmit = async (values: any) => {
    setLoading(true);

    if (
      values.contactNumber.substring(0, 3) == "027" ||
      values.contactNumber.substring(0, 3) == "+27"
    ) {
      values.contactNumber = "+27" + values.contactNumber.slice(3);
    } else if (values.contactNumber.substring(0, 1) == "0") {
      values.contactNumber = "+27" + values.contactNumber.slice(1);
    } else if (values.contactNumber.substring(0, 3) != "+27") {
      values.contactNumber = "+27" + values.contactNumber;
    }

    try {
      const response = await fetch(`${BASE_ENDPOINT}/complaint`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          input: { ...values, complaintAgainst: "Debt Collector" },
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const { result } = data;

      if (result?.id) {
        setLoading(false);
        form.resetFields();
        localStorage.clear();
        navigate(`/create-complaint/${result.id}`);
        setShowComplaintForm(false);
      }
    } catch (error) {
      setLoading(false);
      return notifyError(error);
    }
  };

  const complaintTypes = useMemo<{ id: number; name: string }[]>(
    () => [
      {
        id: 2,
        name: "Credit Provider",
      },
      {
        id: 3,
        name: "Debt Counsellor",
      },
      {
        id: 4,
        name: "Debt Collector",
      },
    ],
    []
  );

  const BackToHome = (
    <Button
      type="default"
      onClick={() => window.location.replace("/")}
      className="purple-button"
    >
      Back to website
    </Button>
  );

  return (
    <>
      <Header />
      <div className="base-form m-5">
        <h3>Create a Complaint</h3>
        <Divider />
        <div className="d-flex items-center justify-center mt-5">
          <div className="flex-column ">
            {loading && <InformationLoader />}

            {showComplaintForm && !loading && (
              <>
                <div className="flex-column self-start">
                  <h3 className="">Select who the complaint is against:</h3>
                  <Select
                    placeholder="Select who complaint is against"
                    onSelect={(value) =>
                      localStorage.setItem("complaintAgainst", `${value}`)
                    }
                    onChange={setComplaintAgainst}
                    defaultValue="Debt Collector"
                    className="form-input-width"
                  >
                    {complaintTypes.map((type) => {
                      return (
                        <Select.Option
                          key={"user-type-" + type.id}
                          value={type.name}
                        >
                          {type.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </div>
                <br />
                <br />
                {complaintAgainst === "Debt Collector" && (
                  <>
                    <h3 className="mt-4">
                      Please fill out your details below:
                    </h3>
                    <Form
                      form={form}
                      onFinish={handleSubmit}
                      layout="vertical"
                      onFinishFailed={(errors) => {
                        notifyError("Please fill out the required fields");
                      }}
                      initialValues={{
                        identificationType: "RSA ID",
                      }}
                    >
                      <Spin
                        style={{ display: loading ? "block" : "none" }}
                        className="loader"
                      />
                      <div className="flex-row">
                        <Form.Item
                          label="First Name"
                          className="new-form-item mr-5"
                          {...{
                            name: "firstName",
                            rules: [
                              {
                                required: true,
                                message: "Please enter your first name!",
                              },
                            ],
                          }}
                        >
                          <Input
                            placeholder="Please input first name!"
                            className="form-input-width"
                          />
                        </Form.Item>

                        <Form.Item
                          label="Last Name"
                          className="new-form-item"
                          {...{
                            name: "lastName",
                            rules: [
                              {
                                required: true,
                                message: "Please enter your last name",
                              },
                            ],
                          }}
                        >
                          <Input
                            placeholder="Please input last name"
                            className="form-input-width"
                          />
                        </Form.Item>
                      </div>

                      <div className="flex-row">
                        <Form.Item
                          label="Email"
                          className="new-form-item mr-5"
                          {...{
                            name: "email",
                            rules: [
                              {
                                required: true,
                                type: "email",
                                message: "Please enter a valid email address",
                              },
                            ],
                          }}
                        >
                          <Input
                            placeholder="Please input email!"
                            className="form-input-width"
                          />
                        </Form.Item>

                        <Form.Item
                          label="Contact Number"
                          {...{
                            name: "contactNumber",
                            rules: [
                              {
                                required: true,
                                message: "Please enter contact number",
                              },
                              {
                                min: 9,
                                message:
                                  "The number must be minimum 9 digits long.",
                              },
                              {
                                max: 10,
                                message:
                                  "The number must not be more than 10 digits long.",
                              },
                              {
                                validator: validateMobilePhoneNumber,
                              },
                            ],
                          }}
                        >
                          <Input
                            type="number"
                            placeholder="Please input number"
                            maxLength={10}
                            minLength={9}
                            className="form-input-width"
                          />
                        </Form.Item>
                      </div>

                      <div className="flex-row">
                        <div className="form-input-width mr-5">
                          <Form.Item
                            label="Identification Type"
                            {...{
                              name: "identificationType",
                              rules: [
                                {
                                  required: true,
                                  message:
                                    "Please choose your Identification Type",
                                },
                              ],
                            }}
                          >
                            <Radio.Group buttonStyle="solid">
                              <Radio.Button className="" value={"RSA ID"}>
                                RSA ID
                              </Radio.Button>
                              <Radio.Button value={"Passport"}>
                                Passport
                              </Radio.Button>
                            </Radio.Group>
                          </Form.Item>
                        </div>

                        <Form.Item
                          label="ID Number"
                          className="form-input-width"
                          {...{
                            name: "identificationNumber",
                            rules: [
                              {
                                required: true,
                                message: "Please enter id number",
                              },
                            ],
                          }}
                        >
                          <Input placeholder="Please input ID!" />
                        </Form.Item>
                      </div>

                      <br />

                      <Button
                        type="default"
                        htmlType="submit"
                        className="btn-add-comment"
                      >
                        Next Step
                      </Button>
                    </Form>
                  </>
                )}

                {complaintAgainst === "Credit Provider" && (
                  <>
                    <h3>Credit Providers:</h3>
                    <p>
                      The council for debt collectors only deals with complaints
                      pertaining to debt collectors. For complaints regarding
                      CREDIT PROVIDERS please refer such complaints to the NCR
                      using the link provided:
                    </p>
                    <br />

                    <List
                      split={false}
                      itemLayout="horizontal"
                      dataSource={[
                        {
                          icon: <LinkOutlined />,
                          description: (
                            <a href="https://ncr.org.za">https://ncr.org.za</a>
                          ),
                        },
                        {
                          icon: <HomeOutlined />,
                          description: "complaints@ncr.org.za",
                        },
                        {
                          icon: <HomeOutlined />,
                          description: "Fax: (087) 234 7822",
                        },
                      ]}
                      renderItem={(item) => (
                        <List.Item>
                          <List.Item.Meta
                            avatar={<Avatar icon={item.icon} />}
                            description={item.description}
                          />
                        </List.Item>
                      )}
                    />
                    <br />
                    {BackToHome}
                  </>
                )}

                {complaintAgainst === "Debt Counsellor" && (
                  <>
                    <h3>Debt Counsellor:</h3>
                    <p>
                      The council for debt collectors only deals with complaints
                      pertaining to debt collectors. For complaints regarding
                      DEBT COUNSELLORS please refer such complaints to the NCR
                      using the link provided:
                    </p>
                    <br />
                    <List
                      split={false}
                      itemLayout="horizontal"
                      dataSource={[
                        {
                          icon: <LinkOutlined />,
                          description: (
                            <a href="https://ncr.org.za">https://ncr.org.za</a>
                          ),
                        },
                        {
                          icon: <HomeOutlined />,
                          description: "dccomplaints@ncr.org.za",
                        },
                        {
                          icon: <HomeOutlined />,
                          description: "Fax: (087) 234 7789",
                        },
                      ]}
                      renderItem={(item) => (
                        <List.Item>
                          <List.Item.Meta
                            avatar={<Avatar icon={item.icon} />}
                            description={item.description}
                          />
                        </List.Item>
                      )}
                    />
                    <br />
                    {BackToHome}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
