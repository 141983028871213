import { useState, useEffect } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Input, Button, Tooltip, Form } from "antd";
import { openNotificationWithIcon } from "@/utils/notification";
import { Auth } from "aws-amplify";
import { PASSWORD_REGEX, PASSWORD_REGEX_MESSAGE } from "../../../constants";

Auth.configure({
  Auth: {
    identityPoolId: import.meta.env.VITE_USER_POOL_CLIENT_ID,
    region: import.meta.env.VITE_REGION,
    userPoolId: import.meta.env.VITE_USER_POOL_ID,
    UserPoolId: import.meta.env.VITE_USER_POOL_ID,
    ClientId: import.meta.env.VITE_USER_POOL_CLIENT_ID,
    userPoolWebClientId: import.meta.env.VITE_USER_POOL_CLIENT_ID,
  },
});

function ProfileSecurityForm() {
  const [form] = Form.useForm();

  const [email, setEmail] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [codeSent, setCodeSent] = useState(false);

  useEffect(() => {
    setEmail(localStorage.getItem("username"));
  }, []);

  const sendConfirmationCode = async () => {
    await Auth.forgotPassword(email);
    setCodeSent(true);
  };

  const handleChangePassword = async (values) => {
    try {
      setSubmitting(true);
      await Auth.forgotPasswordSubmit(
        email,
        values.confirmationCode,
        values.newPassword
      );

      form.resetFields();
      setCodeSent(false);
      setSubmitting(false);

      openNotificationWithIcon(
        "success",
        "Success",
        "Your password has been reset successfully"
      );
    } catch (e) {
      openNotificationWithIcon("error", "Validation Error", e.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <div className="tab-content">
        <div>
          <h3>Security Settings</h3>
          <br />
          <br />
          <h4>Account Password</h4>
          <span>Current password strength</span>
        </div>
        <Button type="link" onClick={sendConfirmationCode} className="btn-link">
          Modify
        </Button>
      </div>
      <hr />
      <br />
      <br />

      {codeSent && (
        <div>
          <Form form={form} layout="vertical" onFinish={handleChangePassword}>
            <Form.Item
              label="New Password"
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: "Please enter new password",
                },
                {
                  min: 8,
                  message: "Password has to be longer than 8 characters",
                },
                {
                  pattern: PASSWORD_REGEX,
                  message: PASSWORD_REGEX_MESSAGE,
                },
              ]}
            >
              <Input.Password placeholder="Enter new password" />
            </Form.Item>
            <Form.Item
              label="Confirm Password"
              name={"confirmPassword"}
              rules={[
                {
                  required: true,
                  message: "Confirm password",
                },
                {
                  min: 8,
                  message: "Password has to be longer than 8 characters",
                },
                {
                  pattern: PASSWORD_REGEX,
                  message: PASSWORD_REGEX_MESSAGE,
                },
              ]}
            >
              <Input.Password placeholder="Confirm password" />
            </Form.Item>
            <Form.Item
              name="confirmationCode"
              rules={[
                {
                  required: true,
                  message: "Enter confirmation code",
                },
              ]}
              label={
                <span>
                  Confirm Code:&nbsp;
                  <Tooltip title="The code has been sent to your email">
                    <InfoCircleOutlined />
                  </Tooltip>
                </span>
              }
            >
              <Input placeholder="Enter confirmation code" />
            </Form.Item>

            <br />
            <Button
              className="btn-upload"
              htmlType="submit"
              loading={submitting}
            >
              Change Password
            </Button>
          </Form>
        </div>
      )}
    </>
  );
}

export default ProfileSecurityForm;
