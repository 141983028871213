import { useState } from "react";
import { Button, Input, Select, Divider, Form } from "antd";
import { validateMobilePhoneNumber } from "@/utils/validatePhoneNumber";
import { openNotificationWithIcon, notifyError } from "@/utils/notification";
import {
  BASE_ENDPOINT,
  COMPLAINT_SUPPORTING_DOCUMENT_TYPE_ID,
  UNREGISTERED_COMPANY_ID,
} from "../../constants";
import { uploadToS3 } from "@/utils/uploadToS3";
import DocumentUploader from "./uploadDocuments";
import { Success } from "./success";
import OTPForm from "./complantsOTP";
import Header from "./header";
import { useParams } from "react-router-dom";

function DebtCollectorForm() {
  const [form] = Form.useForm();
  const { complaintId } = useParams();
  const [submitting, setSubmitting] = useState(false);
  const [isOtherCompany, setIsOtherCompany] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [companiesList, setCompaniesList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState<any>();
  const [values, setValues] = useState<any>();
  const [loadingCompany, setLoadingCompany] = useState<any>();

  const [documentsToUpload, setDocumentsToUpload] = useState([]);

  const handleCompaniesSearch = async (value) => {
    if (value.length > 3) {
      setLoadingCompany(true);
      const resp = await fetch(`${BASE_ENDPOINT}/companies`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          input: { criteria: "searchName", filter: value },
        }),
      });
      const data = await resp.json();
      setCompaniesList(data.result);
      setLoadingCompany(false);
    }
  };

  const setCompanyDetails = (e: string) => {
    setIsOtherCompany(e === "Other");
    setSelectedCompany(companiesList.find((x) => x.id === +e));
  };

  const submit = async (values: any) => {
    setSubmitting(true);

    if (
      values.debtCollectorContactNumber?.substring(0, 3) === "027" ||
      values.debtCollectorContactNumber?.substring(0, 3) === "+27"
    ) {
      values.debtCollectorContactNumber =
        "+27" + values.debtCollectorContactNumber?.slice(3);
    } else if (values.debtCollectorContactNumber?.substring(0, 1) === "0") {
      values.debtCollectorContactNumber =
        "+27" + values.debtCollectorContactNumber?.slice(1);
    } else if (values.debtCollectorContactNumber?.substring(0, 3) !== "+27") {
      values.debtCollectorContactNumber =
        "+27" + values.debtCollectorContactNumber;
    }

    setValues(values);

    // trigger otp
    const res = await fetch(`${BASE_ENDPOINT}/complaint/otp/${complaintId}`, {
      method: "GET",
    });

    const data = await res.json();

    if (!data) {
      setSubmitting(false);
      return openNotificationWithIcon(
        "error",
        "Error",
        "Error submitting information. Please try again"
      );
    }
    if (data?.result === "success") setShowOTP(true);
  };

  const updateComplaint = async () => {
    const resp = await fetch(`${BASE_ENDPOINT}/complaint`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        input: {
          id: +complaintId,
          companyId: isOtherCompany
            ? UNREGISTERED_COMPANY_ID
            : selectedCompany?.id,
          complaintAgainst: "Debt Collector",
          debtCollectorCompany: isOtherCompany
            ? values.otherCompanyName?.trim()
            : selectedCompany?.registeredName?.trim() ||
              selectedCompany?.tradingName?.trim(),
          debtCollectorName: values.debtCollectorName,
          debtCollectorEmail: values.debtCollectorEmail,
          debtCollectorContactNumber: values.debtCollectorContactNumber,
          description: values.description,
        },
      }),
    });

    const data = await resp.json();

    // create documents
    if (documentsToUpload.length) await uploadDocuments();

    setSubmitting(false);
    setSubmitted(true);
    if (data?.result?.id) {
      setSuccessful(true);
    }
  };

  const uploadDocuments = async () => {
    try {
      let documentRecords = [];
      await Promise.all(
        documentsToUpload.map(async (doc) => {
          await uploadToS3(doc.preSignedUrl, doc.contentType, doc.file);

          documentRecords.push({
            documentName: doc.documentName,
            documentPath: `${
              isOtherCompany ? UNREGISTERED_COMPANY_ID : selectedCompany?.id
            }/Complaints/Complaint_${complaintId}/Supporting_Documents/${
              doc.documentName
            }`,
            documentTypeId: COMPLAINT_SUPPORTING_DOCUMENT_TYPE_ID,
            documentStatusId: 1,
            standardDescription: doc.documentName,
            altDescription: "",
            documentFormat: doc.contentType,
            complaintId: +complaintId,
            companyId: isOtherCompany
              ? UNREGISTERED_COMPANY_ID
              : selectedCompany?.id,
            forCompany: true,
          });
        })
      );
      const res = await fetch(`${BASE_ENDPOINT}/createDocument`, {
        method: "POST",
        body: JSON.stringify({
          input: documentRecords,
        }),
      });

      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
    } catch {
      notifyError("Error occurred during upload");
    }
  };

  return (
    <div>
      <Header />
      {!successful && (
        <div className="m-5">
          <div className="">
            <h3>Create a Complaint</h3>
            <Divider />

            <div className="flex-column items-center justify-center mt-5">
              <div>
                <h3>Please fill out the details on debt collector below: </h3>
                <br />
                <Form
                  form={form}
                  onFinish={submit}
                  className=""
                  layout="vertical"
                >
                  <div className="form-item-block  gap-3">
                    <Form.Item
                      label="Company name"
                      className="input-block"
                      {...{
                        name: "debtCollectorCompany",
                        initialValue:
                          selectedCompany?.registeredName?.trim() ||
                          selectedCompany?.tradingName?.trim(),

                        rules: [
                          {
                            required: true,
                            message: "Please enter company name",
                          },
                        ],
                      }}
                    >
                      <Select
                        showSearch
                        placeholder="Enter company's name and select"
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        notFoundContent={null}
                        onSearch={handleCompaniesSearch}
                        onChange={setCompanyDetails}
                        loading={loadingCompany}
                        className="form-input-width"
                      >
                        {companiesList?.map((type) => {
                          return (
                            <Select.Option key={type.id} value={type.id}>
                              {type.registeredName || type.tradingName.trim()}
                            </Select.Option>
                          );
                        })}
                        <Select.Option key="Other" value="Other">
                          Other
                        </Select.Option>
                      </Select>
                    </Form.Item>
                    {isOtherCompany && (
                      <Form.Item
                        label={"Company Name"}
                        className="input-block"
                        {...{
                          name: "otherCompanyName",
                          rules: [
                            {
                              required: true,
                              message: "Please enter the company name",
                            },
                          ],
                        }}
                      >
                        <Input
                          placeholder="Please enter the company name"
                          className="form-input-width"
                        />
                      </Form.Item>
                    )}
                  </div>
                  <div className="form-item-block gap-3">
                    <Form.Item
                      label={"Debt Collector's Name"}
                      className="input-block"
                      {...{
                        name: "debtCollectorName",
                        rules: [
                          {
                            required: false,
                            message: `Please enter debt collector's name`,
                          },
                        ],
                      }}
                    >
                      <Input
                        placeholder="Please input Name"
                        className="form-input-width"
                      />
                    </Form.Item>
                    <Form.Item
                      label={`Debt Collector's Email`}
                      className="input-block"
                      {...{
                        name: "debtCollectorEmail",
                        rules: [
                          {
                            required: false,
                            type: "email",
                            message: `Please enter valid email address`,
                          },
                        ],
                      }}
                    >
                      <Input
                        placeholder="Please input email address"
                        className="form-input-width"
                      />
                    </Form.Item>
                  </div>

                  <div className="form-item-block gap-3">
                    <Form.Item
                      label="Debt Collector Contact Number"
                      className="input-block"
                      {...{
                        name: "debtCollectorContactNumber",
                        rules: [
                          { required: false },
                          { validator: validateMobilePhoneNumber },
                        ],
                      }}
                    >
                      <Input
                        type="number"
                        placeholder="Please input contact number"
                        className="form-input-width"
                      />
                    </Form.Item>
                  </div>

                  <h3>
                    Please upload all supporting documentation/communication
                  </h3>

                  <DocumentUploader
                    updateDocumentList={(list) => setDocumentsToUpload(list)}
                    complaintId={complaintId}
                    companyId={
                      isOtherCompany
                        ? UNREGISTERED_COMPANY_ID
                        : selectedCompany?.id
                    }
                    companyName={
                      isOtherCompany
                        ? values?.otherCompanyName
                        : selectedCompany?.registeredName.trim() ||
                          selectedCompany?.tradingName.trim()
                    }
                  />

                  <Form.Item
                    label="Describe your complaint: (Max: 1000 characters)"
                    {...{
                      name: "description",
                      rules: [
                        {
                          required: true,
                          message:
                            "Please enter a description for the complaint",
                        },
                      ],
                    }}
                  >
                    <Input.TextArea rows={5} maxLength={1000} />
                  </Form.Item>

                  {!submitted && (
                    <Button
                      htmlType="submit"
                      className="purple-button"
                      loading={submitting}
                    >
                      Submit
                    </Button>
                  )}
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}

      {submitted && successful && <Success />}

      {showOTP && (
        <OTPForm
          show={showOTP}
          complaintId={complaintId}
          updateComplaint={updateComplaint}
          cancelSumbit={(val: boolean) => setSubmitting(val)}
        />
      )}
    </div>
  );
}

export default DebtCollectorForm;
