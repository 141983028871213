import { useState, useEffect } from "react";
import { Tabs, Breadcrumb } from "antd";
import { useLazyQuery } from "@apollo/client";
import BusinessProfile from "../../Companies/CompanyOverview/companyProfile";
import CompanyTasks from "../../Tasks/memberTasks";
import MemberTransactions from "../../Transactions/memberTransactions";
import MemberEmployees from "../../Employees/companyEmployees";
import Representatives from "../../Representatives/companyRepresentatives";
import CompanyDocuments from "../../Documents/memberDocuments";
import { GET_COMPANY_OVERVIEW_INFORMATION } from "../../graphql/queries";
import { useParams } from "react-router-dom";

function MemberOverview() {
  const { companyId } = useParams();
  const [_isLoading, setIsLoading] = useState(true);
  const [useCompanyId, setCompanyId] = useState(null);
  const [useCompanyName, setCompanyName] = useState(null);
  const [fetchCompanyOverviewInformation] = useLazyQuery(
    GET_COMPANY_OVERVIEW_INFORMATION,
    {
      variables: {
        companyId: +companyId,
      },
      onCompleted: (data) => {
        setIsLoading(false);
        if (typeof data?.company?.id === "number") {
          setCompanyId(data?.company.id);
          setCompanyName(
            data?.company?.registeredName
              ? data?.company?.registeredName
              : data?.company?.tradingName
          );
        }
      },
      fetchPolicy: "network-only",
    }
  );
  useEffect(() => {
    fetchCompanyOverviewInformation();
  }, []);

  const tabItems = [
    {
      label: "Business",
      key: "1",
      children: <BusinessProfile companyId={useCompanyId} />,
    },
    {
      label: "Related Parties",
      key: "2",
      children: <Representatives companyId={Number.parseInt(useCompanyId)} />,
    },
    {
      label: "Employees",
      key: "3",
      children: (
        <MemberEmployees
          companyId={Number.parseInt(useCompanyId)}
          mode="admin"
        />
      ),
    },
    {
      label: "Tasks",
      key: "4",
      children: (
        <CompanyTasks companyId={useCompanyId} mode="company-overview-tasks" />
      ),
    },
    {
      label: "Transactions",
      key: "5",
      children: (
        <MemberTransactions
          companyId={useCompanyId}
          mode="company-overview-transactions"
        />
      ),
    },
    {
      label: "Documents",
      key: "6",
      children: (
        <CompanyDocuments
          companyId={useCompanyId}
          companyName={useCompanyName}
          mode="company-overview-documents"
        />
      ),
    },
  ];

  return (
    <>
      <div className="member-profile-header-div">
        <Breadcrumb>
          <Breadcrumb.Item>
            <a href="/admin/Companies">Companies</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <a href={`/admin/company/${companyId}`}>
              {useCompanyName ? useCompanyName : companyId}
            </a>
          </Breadcrumb.Item>
        </Breadcrumb>
        {useCompanyName && <h2>{useCompanyName}</h2>}
      </div>
      <Tabs tabPosition="top" items={tabItems} defaultActiveKey="1" />
    </>
  );
}

export default MemberOverview;
