import { useState, useEffect } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import { Input } from "antd";
import Comment from "./Comment";
import { GET_PROSECUTION_RECOMMENDATION } from "../../../../graphql/queries";
import { UPDATE_PROSECUTION_RECOMMENDATION } from "../../../../graphql/mutations";
import { UpdateRecommendationInput } from "@/typings";
import { notifyError } from "@/utils/notification";
import { isBoolean } from "lodash";

type Props = {
  recommendationId: number;
  complaintId: number;
  userId: number;
  refreshActivities: () => void;
  refreshComplaint: () => void;
  taskId: number;
  companyId: number;
};

export default ({
  recommendationId,
  complaintId,
  userId,
  refreshActivities,
  refreshComplaint,
  taskId,
  companyId,
}: Props) => {
  const [recommendation, setRecommendation] = useState<any>();

  const [updateRecommendation, { loading: updating }] = useMutation(
    UPDATE_PROSECUTION_RECOMMENDATION
  );

  const [getRecommendation] = useLazyQuery(GET_PROSECUTION_RECOMMENDATION, {
    fetchPolicy: "no-cache",
    variables: { id: recommendationId },
    onCompleted: ({ getProsecutionRecommendation }) => {
      setRecommendation(getProsecutionRecommendation);
    },
    onError: notifyError,
  });

  const handleUpdateRecommendation = async ({
    id,
    comment,
    approved,
  }: UpdateRecommendationInput) => {
    try {
      await updateRecommendation({
        variables: {
          input: { id, comment, approved, taskId },
        },
      });
      getRecommendation();

      if (isBoolean(approved)) {
        refreshActivities();
        refreshComplaint();
      }
    } catch (err) {
      notifyError(err);
    }
  };

  useEffect(() => {
    if (recommendationId) getRecommendation();
  }, [recommendationId]);

  return (
    <>
      <h4>Reason for Prosecution</h4>
      <Input.TextArea rows={5} readOnly value={recommendation?.reason || ""} />
      <Comment
        mode="recommendation"
        complaintId={complaintId}
        comments={recommendation}
        userId={userId}
        loading={updating}
        handleSubmitRecommendation={handleUpdateRecommendation}
        companyId={companyId}
      />
    </>
  );
};
