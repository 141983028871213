import { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { Modal, Input, Checkbox, Button, Form, FormInstance } from "antd";
import { useMutation } from "@apollo/client";
import { UPDATE_USER } from "../../graphql/mutations";
import { openNotificationWithIcon } from "@/utils/notification";
import { PASSWORD_REGEX, PASSWORD_REGEX_MESSAGE } from "../../constants";

Auth.configure({
  Auth: {
    identityPoolId: import.meta.env.VITE_USER_POOL_CLIENT_ID,
    region: import.meta.env.VITE_REGION,
    userPoolId: import.meta.env.VITE_USER_POOL_ID,
    UserPoolId: import.meta.env.VITE_USER_POOL_ID,
    ClientId: import.meta.env.VITE_USER_POOL_CLIENT_ID,
    userPoolWebClientId: import.meta.env.VITE_USER_POOL_CLIENT_ID,
  },
});

const ConfirmAccountForm = ({
  form,
  visible,
  onCancel,
  onCreate,
  mode = "aws-email-confirmation",
  emailPrefill = "",
  okText = "Save",
  modalHeading = "Account Confirmation",
  cancelText = "Cancel",
  hideOkButton = false,
}: {
  form: FormInstance;
  visible: boolean;
  onCancel: () => void;
  onCreate: (x: any) => any;
  mode?: string;
  emailPrefill?: string;
  okText?: string;
  modalHeading?: string;
  cancelText?: string;
  hideOkButton?: boolean;
}) => {
  const [_code, setCode] = useState("");
  const [_password, setPassword] = useState("");
  const [confirmed, setConfirmed] = useState(false);
  const [_confirmPassword, setConfirmPassword] = useState("");
  const [isConfirming, setIsConfirming] = useState(false);
  const [updateUser] = useMutation(UPDATE_USER);

  useEffect(() => {
    setConfirmed(false);
    setPassword("");
    setConfirmPassword("");
    setIsConfirming(false);
    setCode("");
  }, [visible]);

  const submitForm = () => {
    form
      .validateFields()
      .then(onCreate)
      .then((err) => {});
  };

  const handlePasswordUpdate = async (values: any) => {
    setIsConfirming(true);

    try {
      await Auth.forgotPasswordSubmit(
        localStorage.getItem("idNumber"),
        values.code,
        values.password
      );

      await updateUser({
        variables: {
          input: {
            id: Number(localStorage.getItem("userId")),
            requirePasswordReset: false,
          },
        },
      });

      setConfirmed(true);
    } catch (e) {
      setConfirmed(false);
      setIsConfirming(false);
      openNotificationWithIcon("error", "Validation Error", e.message);
    }
  };

  return (
    <Modal
      open={visible}
      title={modalHeading}
      okText={okText}
      onCancel={onCancel}
      onOk={submitForm}
      cancelText={cancelText}
      okButtonProps={hideOkButton ? { style: { display: "none" } } : {}}
    >
      <Form form={form} layout="vertical" onFinish={handlePasswordUpdate}>
        {mode === "aws-email-confirmation" && (
          <>
            <span>
              It appears your registration has not been confirmed yet. Please
              capture your email address and the confirmation code that has been
              sent to your email.
            </span>
            <br />
            <Form.Item
              label="ID/Passport Number"
              name={"idNumber"}
              rules={[
                {
                  required: true,
                  message: "Please input your ID/Passport number!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Confirmation code"
              name="code"
              rules={[
                {
                  required: true,
                  message: "Please input the confirmation code!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </>
        )}

        {mode === "update-email-confirmation" && (
          <>
            <span>
              It appears your registration has not been confirmed yet. Please
              confirm your email address below and click on the verification
              link which is emailed to you.
            </span>
            <br />
            <br />
            <Form.Item
              label="Email address"
              name={"email"}
              initialValue={emailPrefill}
              rules={[
                {
                  required: true,
                  message: "Please input your email address!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={"confirm"}
              valuePropName="checked"
              rules={[
                {
                  required: true,
                  message: "Please confirm that you understand this.",
                },
              ]}
            >
              <Checkbox>
                I understand that any updates made to this email address will
                adjust the email address I use to log in
              </Checkbox>
            </Form.Item>
          </>
        )}
        {mode === "update-password-confirmation" && (
          <>
            {!confirmed && (
              <>
                <span>
                  We require you to set a new password, this is to ensure that
                  your account is secure and can not be accessed by anyone other
                  than you.
                </span>
                <br />
                <Form.Item
                  label="Confirmation Code: (Sent via email)"
                  style={{ paddingTop: "2em" }}
                  name={"code"}
                  rules={[
                    {
                      required: true,
                      message: "Please your confirmation code",
                    },
                  ]}
                >
                  <Input autoFocus />
                  <span>
                    Please check your email for the confirmation code.
                  </span>
                </Form.Item>
                <hr />
                <br />
                <Form.Item
                  label="New Password"
                  name={"password"}
                  rules={[
                    {
                      required: true,
                      message: "Please enter new password",
                    },
                    {
                      min: 8,
                      message: "Password has to be longer than 8 characters",
                    },
                    {
                      pattern: PASSWORD_REGEX,
                      message: PASSWORD_REGEX_MESSAGE,
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  label="Confirm Password"
                  name={"confirmPassword"}
                  rules={[
                    {
                      required: true,
                      message: "Please enter new password",
                    },
                    {
                      min: 8,
                      message: "Password has to be longer than 8 characters",
                    },
                    {
                      pattern: PASSWORD_REGEX,
                      message: PASSWORD_REGEX_MESSAGE,
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item {...{ name: "" }}>
                  <Button
                    block
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                    loading={isConfirming}
                    className="btn-account-confirmation"
                  >
                    Save new password
                  </Button>
                </Form.Item>
              </>
            )}
            {confirmed && (
              <>
                <span>
                  You have successfully updated your password. You may now log
                  in.
                </span>
              </>
            )}
          </>
        )}
      </Form>
    </Modal>
  );
};

export default ConfirmAccountForm;
