import { useState, useMemo } from "react";
import { Modal, Spin, DatePicker, Input } from "antd";
import { useMutation } from "@apollo/client";
import { notifyError, notifySuccess } from "@/utils/notification";
import { BLACKLIST_ENTITY } from "../graphql/mutations";
import { Company, User } from "@/typings/types";

type Props = {
  type: string;
  entity: Company | User;
  onCancel: () => void;
  refreshList: () => void;
};

export default function BlacklistEntityModal({
  onCancel,
  entity,
  type = "company",
}: Props) {
  const [reason, setReason] = useState<string>();
  const [listedUntil, setListedUntil] = useState<any>();

  const [blacklistEntity, { loading }] = useMutation(BLACKLIST_ENTITY, {
    onCompleted: () => {
      close();
      notifySuccess(
        `${type[0].toUpperCase()}${type.slice(1)} blacklisted successfully`
      );
    },
    onError: notifyError,
  });

  const entityName = useMemo(() => {
    return (
      type === "company"
        ? (entity as Company).registeredName
        : `${(entity as User).firstName} ${(entity as User).lastName}`
    ).toUpperCase();
  }, [entity, type]);

  function close() {
    setReason("");
    setListedUntil(undefined);
    onCancel();
  }

  async function submit() {
    if (!reason) {
      notifyError("Please provide a reason for blacklisting");
      return;
    }

    const input: any = {
      id: entity.id,
      reason,
      type,
    };

    if (listedUntil) {
      input.listedUntil = listedUntil.toDate();
    }

    await blacklistEntity({
      variables: {
        input,
      },
    });
  }

  return (
    <Modal
      open={true}
      title={`Blacklist Entity - ${entityName}`}
      okText="Save"
      onCancel={close}
      onOk={submit}
      cancelButtonProps={{
        disabled: loading,
      }}
      okButtonProps={{
        className: "purple-button",
        disabled: loading,
      }}
      okType="default"
    >
      <Spin spinning={loading}>
        <div className="mb-3">
          <h4 className="">Reason</h4>
          <Input.TextArea
            rows={4}
            className="input-select"
            placeholder="Type in the reason for blacklisting"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </div>
        <div style={{ marginTop: "20px" }}>
          <h4 className="mt-3">Listed Until</h4>
          <DatePicker
            placeholder="Select date - optional"
            className="input-select"
            value={listedUntil}
            onChange={(date) => setListedUntil(date)}
          />
        </div>
      </Spin>
    </Modal>
  );
}
