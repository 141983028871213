import { Skeleton, Card } from "antd";
import "../index.css";

const ComplaintLoader: React.FC<any> = () => {
  return (
    <>
      <Card className="information-card card">
        <Skeleton loading active />
      </Card>
      <div className="flex-row card">
        <div className="flex-column" style={{ width: "63%" }}>
          <Card>
            <Skeleton loading active />
          </Card>
          <br />
          <Card>
            <Skeleton loading active />
          </Card>
        </div>
        <div style={{ width: "35%" }}>
          <Card>
            <Skeleton loading active />
          </Card>
        </div>
      </div>
    </>
  );
};
export default ComplaintLoader;
