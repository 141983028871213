import { notifyError } from "@/utils/notification";
import { Modal, Input, Form } from "antd";

type Props = {
  visible: boolean;
  onCancel: () => void;
  onCreate: (x: any) => void;
  initialData: Record<string, any>;
  updatingEmail: boolean;
};

function UpdateEmailForm({
  visible,
  onCancel,
  onCreate,
  initialData,
  updatingEmail,
}: Props) {
  const [form] = Form.useForm();

  function onSubmit() {
    form
      .validateFields()
      .then((values) => {
        onCreate(values);
      })
      .catch(() => {
        notifyError("Please fill in the email");
      });
  }

  return (
    <Modal
      open={visible}
      title="Update Email"
      okText="Save"
      okButtonProps={{ loading: updatingEmail }}
      onCancel={onCancel}
      cancelButtonProps={{ disabled: updatingEmail }}
      onOk={onSubmit}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="New Email"
          name={"email"}
          {...{
            initialValue: initialData ? initialData.email : "",
            rules: [
              {
                required: true,
                message: "Please input user's email!",
              },
            ],
          }}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default UpdateEmailForm;
