import { Button, Card, Divider } from "antd";
import { useReportingFilters } from "./useReportingFilters";
import { useMemo, useRef } from "react";
import { ReportingFilters } from "./ReportingFilters";
import { EmploymentHistory } from "../EmploymentHistory/EmploymentHistory";
import { If, IfNot } from "@/components/If";
import { useUserState } from "@/stores/user";
import { isSuperAdmin } from "@/utils";
import { Top20Collectors } from "./Reports/Top20Collectors";
import { SubscriptionControlList } from "./Reports/SubscriptionControlList";
import { RenewalFeesDue } from "./Reports/RenewalFeesDue";
import { CustomReport } from "./Reports/CustomReport";
import { ComplianceReport } from "./Reports/ComplianceReport";
import { AuditCertificateSubmission } from "./Reports/AuditCertificateSubmission";
import { CertificateRun } from "./Reports/CertificateRun";
import { ReportHandle } from "./ReportingTypes";
import { useNavigate } from "react-router";
import { ArrowLeftOutlined } from "@ant-design/icons";

export function Reporting() {
  const navigate = useNavigate();
  const { activeUserRole, userRoles } = useUserState();
  const filters = useReportingFilters();

  const { reportType, type, employeeIds } = filters;

  const activeRole = userRoles.find((role) => role.id === activeUserRole);

  const employmentHistoryRef = useRef<ReportHandle>(null);
  const top20CollectorsRef = useRef<ReportHandle>(null);
  const subscriptionControlListRef = useRef<ReportHandle>(null);
  const renewalFeesDueRef = useRef<ReportHandle>(null);
  const customReportRef = useRef<ReportHandle>(null);
  const complianceReportRef = useRef<ReportHandle>(null);
  const auditCertificateSubmissionRef = useRef<ReportHandle>(null);
  const certificateRunRef = useRef<ReportHandle>(null);

  const reportName = useMemo(() => {
    return `${reportType.charAt(0).toUpperCase() + reportType.slice(1)} / ${type
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")}`;
  }, [reportType, type]);

  const showEmploymentHistory = useMemo(() => {
    return (
      reportType === "member" &&
      type === "employment-history" &&
      !!employeeIds?.[0]
    );
  }, [reportType, type, employeeIds]);

  const showTop20Collectors = useMemo(() => {
    return reportType === "company" && type === "top-20-collectors";
  }, [reportType, type]);

  const showAuditCertificateSubmission = useMemo(() => {
    return reportType === "company" && type === "audit-certificate-submission";
  }, [reportType, type]);

  const showSubscriptionControlList = useMemo(() => {
    return [
      "subscription-control-list",
      "future-subscription-control-list",
    ].includes(type);
  }, [, type]);

  const showRenewalFeesDue = useMemo(() => {
    return reportType === "renewals" && type === "renewal-fees-due";
  }, [reportType, type]);

  const showCustomReport = useMemo(() => {
    return (
      reportType === "custom" && ["member", "company", "audit"].includes(type)
    );
  }, [reportType, type]);

  const showComplianceReport = useMemo(() => {
    return ["compliance-business", "compliance-member"].includes(type);
  }, [type]);

  const showCertificateRun = useMemo(() => {
    return reportType === "company" && type === "certificate-run";
  }, [reportType, type]);

  const isDownloading = useMemo(() => {
    const downloadingStatus = {
      employmentHistory: employmentHistoryRef.current?.downloading,
      top20Collectors: top20CollectorsRef.current?.downloading,
      subscriptionControlList: subscriptionControlListRef.current?.downloading,
      renewalFeesDue: renewalFeesDueRef.current?.downloading,
      customReport: customReportRef.current?.downloading,
      complianceReport: complianceReportRef.current?.downloading,
      certificateRun: certificateRunRef.current?.downloading,
      auditCertificateSubmission:
        auditCertificateSubmissionRef.current?.downloading,
    };

    return Object.values(downloadingStatus).some((status) => status);
  }, [
    employmentHistoryRef.current?.downloading,
    top20CollectorsRef.current?.downloading,
    subscriptionControlListRef.current?.downloading,
    renewalFeesDueRef.current?.downloading,
    customReportRef.current?.downloading,
    complianceReportRef.current?.downloading,
    certificateRunRef.current?.downloading,
    auditCertificateSubmissionRef.current?.downloading,
  ]);

  const isLoading = useMemo(() => {
    const downloadingStatus = {
      employmentHistory: employmentHistoryRef.current?.running,
      top20Collectors: top20CollectorsRef.current?.running,
      subscriptionControlList: subscriptionControlListRef.current?.running,
      renewalFeesDue: renewalFeesDueRef.current?.running,
      customReport: customReportRef.current?.running,
      complianceReport: complianceReportRef.current?.running,
      certificateRun: certificateRunRef.current?.running,
      auditCertificateSubmission:
        auditCertificateSubmissionRef.current?.running,
    };

    return Object.values(downloadingStatus).some((status) => status);
  }, [
    employmentHistoryRef.current?.running,
    top20CollectorsRef.current?.running,
    subscriptionControlListRef.current?.running,
    renewalFeesDueRef.current?.running,
    customReportRef.current?.running,
    complianceReportRef.current?.running,
    certificateRunRef.current?.running,
    auditCertificateSubmissionRef.current?.running,
  ]);

  const runIfShown = (action: (ref: any) => void) => {
    if (showEmploymentHistory) action(employmentHistoryRef.current);
    if (showTop20Collectors) action(top20CollectorsRef.current);
    if (showSubscriptionControlList) action(subscriptionControlListRef.current);
    if (showRenewalFeesDue) action(renewalFeesDueRef.current);
    if (showCustomReport) action(customReportRef.current);
    if (showComplianceReport) action(complianceReportRef.current);
    if (showCertificateRun) action(certificateRunRef.current);
    if (showAuditCertificateSubmission)
      action(auditCertificateSubmissionRef.current);
  };

  const handleGenerate = () => {
    runIfShown((ref) => ref?.run?.());
  };

  const handleResetData = () => {
    runIfShown((ref) => ref?.resetData?.());
  };

  const handleDownloadCsv = () => {
    runIfShown((ref) => ref?.download?.());
  };

  const handleDownloadPdf = () => {
    runIfShown((ref) => ref?.download?.());
  };

  if (!isSuperAdmin(activeRole?.stream)) {
    return (
      <div className="d-flex m-5 h-100 justify-center">
        You do not have access to this page
      </div>
    );
  }

  return (
    <div className="d-flex m-2">
      <Card
        title={`Reporting: ${reportName}`}
        className="m-0"
        extra={
          <Button
            icon={<ArrowLeftOutlined />}
            onClick={() => navigate("/admin/dashboard")}
          >
            Go to Dashboard
          </Button>
        }
      >
        <IfNot condition={showTop20Collectors}>
          <ReportingFilters
            handleGenerate={handleGenerate}
            handleDownloadCsv={handleDownloadCsv}
            handleDownloadPdf={handleDownloadPdf}
            loading={isLoading}
            resetData={handleResetData}
            downloading={isDownloading}
          />
          <Divider />
        </IfNot>

        <If condition={showEmploymentHistory}>
          <EmploymentHistory
            ref={employmentHistoryRef}
            userId={+employeeIds?.[0]}
          />
        </If>

        <If condition={showTop20Collectors}>
          <Top20Collectors ref={top20CollectorsRef} />
        </If>

        <If condition={showSubscriptionControlList}>
          <SubscriptionControlList ref={subscriptionControlListRef} />
        </If>

        <If condition={showRenewalFeesDue}>
          <RenewalFeesDue ref={renewalFeesDueRef} />
        </If>

        <If condition={showCustomReport}>
          <CustomReport ref={customReportRef} />
        </If>

        <If condition={showComplianceReport}>
          <ComplianceReport ref={complianceReportRef} />
        </If>

        <If condition={showAuditCertificateSubmission}>
          <AuditCertificateSubmission ref={auditCertificateSubmissionRef} />
        </If>

        <If condition={showCertificateRun}>
          <CertificateRun ref={certificateRunRef} />
        </If>
      </Card>
    </div>
  );
}
