import {
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Button,
  Divider,
  Input,
  Popover,
  Row,
  Select,
  Skeleton,
  Table,
  Tooltip,
  Form,
  Space,
} from "antd";
import { isEmpty, isNumber } from "lodash";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  notifyError,
  notifySuccess,
  openNotificationWithIcon,
} from "@/utils/notification";
import {
  CREATE_USER_LANGUAGE_PROFICIENCY,
  CREATE_USER_QUALIFICATION,
  DELETE_USER_LANGUAGE_PROFICIENCY,
  DELETE_USER_QUALIFICATION,
  REQUEST_OTP,
  UPDATE_USER,
  UPDATE_USER_LANGUAGE_PROFICIENCY,
  UPDATE_USER_QUALIFICATION,
} from "../../graphql/mutations";
import {
  GET_LANGUAGES_LIST,
  GET_LANGUAGE_ABILITIES_LIST,
  GET_PROFICIENCY_LEVELS,
  GET_QUALIFICATIONS_TYPES,
  GET_USER_LANGUAGE_PROFICIENCY_LIST,
  GET_USER_QUALIFICATIONS,
} from "../../graphql/queries";
import { If, IfNot } from "@/components/If";
import { useRegistrationState } from "@/stores/registration";
import { useUserState } from "@/stores/user";

interface IProps {
  editProfileMode?: boolean;
  userId?: number;
  nextStep?: () => void;
}

export default function EducationExperience({
  editProfileMode = false,
  userId,
}: IProps) {
  const [qualificationForm] = Form.useForm();
  const [schoolForm] = Form.useForm();

  const { id: uid } = useUserState();
  const { setRegistrationState, manualRegistrationType, manualRegisterId } =
    useRegistrationState();
  const [addingLanguage, setAddingLanguage] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<any>();
  const [selectedQualification, setSelectedQualification] = useState<any>();
  const [userInfo, setUserInfo] = useState<any>();
  const [useUserId, setUserId] = useState<any>(
    manualRegistrationType ? manualRegisterId : userId || uid
  );
  const [userQualificationList, setUserQualificationsList] = useState([]);
  const [addingQualification, setAddingQualification] = useState(false);
  const [languageProficiencySaved, setLanguageProficiencySaved] =
    useState(false);
  const [gradeInfoSaved, setGradeInfoSaved] = useState(false);
  const [languageProficiencyList, setLanguageProficiencyList] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const maxYear = new Date().getFullYear();
  const minYear = maxYear - 49;
  const [deleteQualification] = useMutation(DELETE_USER_QUALIFICATION);
  const [deleteLanguageProficiency] = useMutation(
    DELETE_USER_LANGUAGE_PROFICIENCY
  );

  useEffect(() => {
    if (isNumber(userId)) {
      setUserId(userId);
    } else {
      setUserId(manualRegistrationType ? manualRegisterId : uid);
    }
  }, [userId]);

  const [upsertLanguageProficiency] = useMutation(
    isEmpty(selectedLanguage)
      ? CREATE_USER_LANGUAGE_PROFICIENCY
      : UPDATE_USER_LANGUAGE_PROFICIENCY,
    {
      onError: (err) => {
        console.log(err);
        setIsSubmitting(false);
        openNotificationWithIcon(
          "error",
          "Save Error",
          "Error when saving language information. Please try again"
        );
      },
      onCompleted: (data) => {
        setProficiencyMutationResult(data);
      },
    }
  );

  const [updateUserGradeInformation] = useMutation(UPDATE_USER, {
    onError: () => {
      setIsSubmitting(false);
      openNotificationWithIcon(
        "error",
        "Save Error",
        "Error when saving grade information. Please try again"
      );
    },
    onCompleted: () => {
      setIsSubmitting(false);
      setGradeInfoSaved(true);
      if (languageProficiencySaved && !editProfileMode) {
        setRegistrationState("step5Saved", true);
      }
      openNotificationWithIcon(
        "success",
        "Save Success",
        "Grade information saved successfully"
      );
    },
  });

  const [upsertUserQualification] = useMutation(
    isEmpty(selectedQualification)
      ? CREATE_USER_QUALIFICATION
      : UPDATE_USER_QUALIFICATION,
    {
      onCompleted: (data) => {
        setQualificationMutationResult(data);
        setIsSubmitting(false);
      },
      onError: (err) => {
        console.log(err);
        setIsSubmitting(false);
        notifyError(
          "Error when saving qualification information. Please try again"
        );
      },
    }
  );

  const [requestOTP] = useMutation(REQUEST_OTP, {
    variables: {
      input: {
        userId: useUserId,
      },
    },
  });

  useEffect(() => {
    if (
      !isEmpty(languageProficiencyList) &&
      !isEmpty(userInfo?.highestGrade) &&
      !editProfileMode
    ) {
      setRegistrationState("step5Saved", true);
    }
  }, [languageProficiencyList, userQualificationList, userInfo]);

  const {
    loading: loadingLanguages,
    //error: errorLanguages,
    data: dataLanguages,
  } = useQuery(GET_LANGUAGES_LIST);
  const {
    loading: loadingLanguageAbilities,
    //error: LanguageAbilities,
    data: dataLanguageAbilities,
  } = useQuery(GET_LANGUAGE_ABILITIES_LIST);
  const {
    loading: loadingProficiencyLevels,
    //error: errorAddressTypes,
    data: dataLanguageProficiencyLevels,
  } = useQuery(GET_PROFICIENCY_LEVELS);
  const {
    loading: loadingProficiencies,
    //error: errorAddressTypes,
  } = useQuery(GET_USER_LANGUAGE_PROFICIENCY_LIST, {
    variables: { userId: +useUserId },
    onCompleted: (data) =>
      setLanguageProficiencyList(data.languageProficiencyList),
  });
  const {
    loading: loadingQualificationTypes,
    //error: errorQualificationTypes,
    data: dataQualificationTypes,
  } = useQuery(GET_QUALIFICATIONS_TYPES);
  const {
    loading: loadingUserQualifications,
    //error: errorAddressTypes,
  } = useQuery(GET_USER_QUALIFICATIONS, {
    variables: { userId: +useUserId },
    onCompleted: (data) => {
      setUserQualificationsList(data.qualificationsList);
      setUserInfo(data.userData);
    },
    fetchPolicy: "network-only",
  });

  if (
    loadingLanguages ||
    loadingProficiencies ||
    loadingLanguageAbilities ||
    loadingProficiencyLevels ||
    loadingQualificationTypes ||
    loadingUserQualifications
  )
    return (
      <>
        <Skeleton active paragraph={{ rows: 4, width: "100%" }} />
        <Skeleton active paragraph={{ rows: 4, width: "100%" }} />
      </>
    );

  const { proficiencyLevels } =
    !loadingProficiencyLevels && dataLanguageProficiencyLevels;
  const { languagesList } = !loadingLanguages && dataLanguages;
  const { languageAbilitiesList } =
    !loadingLanguageAbilities && dataLanguageAbilities;
  const { qualificationsTypes } =
    !loadingQualificationTypes && dataQualificationTypes;

  //selectable language options
  const languageOptions: any = languagesList
    ? languagesList.map((type) => {
        return (
          <Select.Option key={type.id} value={type.id}>
            {type.name}
          </Select.Option>
        );
      })
    : [];

  //selectable language options
  const proficiencyLevelOptions: any = proficiencyLevels
    ? proficiencyLevels.map((type) => {
        return (
          <Select.Option key={type.id} value={type.id}>
            {type.name}
          </Select.Option>
        );
      })
    : [];

  //selectable language ability options
  const languageAbilityOptions: any = languageAbilitiesList
    ? languageAbilitiesList.map((type) => {
        return (
          <Select.Option key={type.id} value={type.id}>
            {type.name}
          </Select.Option>
        );
      })
    : [];

  //selectable address types options
  const qualificationTypesOptions: any = qualificationsTypes
    ? qualificationsTypes.map((type) => {
        return (
          <Select.Option key={type.id} value={type.id}>
            {type.name}
          </Select.Option>
        );
      })
    : [];

  const yearOptions = [];
  const gradeOptions = [];

  for (let i = minYear; i < maxYear; i++) {
    yearOptions.push(
      <Select.Option key={i} value={i}>
        {i}
      </Select.Option>
    );
  }
  for (let i = 7; i <= 12; i++) {
    gradeOptions.push(
      <Select.Option key={i} value={i.toString()}>
        {i}
      </Select.Option>
    );
  }
  const step = {
    title: "Step 4: Education &  Languages",
    content: "Complete the form stating your credentials and work experience",
  };

  const handleSubmitLanguage = async (values: any) => {
    setIsSubmitting(true);
    upsertLanguageProficiency({
      variables: {
        input: {
          id: isEmpty(selectedLanguage) ? undefined : selectedLanguage.id,
          proficiencyLevelId: values.languageProficiency,
          abilities: values.languageAbility,
          languageId: values.language,
          userId: isEmpty(selectedLanguage) ? useUserId : undefined,
        },
      },
    });
    schoolForm.resetFields();
    setSelectedLanguage({});
  };

  const handleSubmitGradeInfo = async (values: any) => {
    setIsSubmitting(true);
    updateUserGradeInformation({
      variables: {
        input: {
          highestGrade: values.highestGrade,
          highestGradeYear: values.yearGradeObtained.toString(),
          id: useUserId,
        },
      },
    });
  };

  const handleSubmitQualificationInfo = async (values: any) => {
    setIsSubmitting(true);
    upsertUserQualification({
      variables: {
        input: {
          id: isEmpty(selectedQualification)
            ? undefined
            : selectedQualification.id,
          institutionName: values.nameOfInstitution,
          year: values.yearQualificationObtained,
          qualification: values.qualification,
          typeId: values.qualificationType,
          userId: isEmpty(selectedQualification) ? useUserId : undefined,
        },
      },
    });
    qualificationForm.resetFields();
    setSelectedQualification({});
  };

  const setProficiencyMutationResult = (data) => {
    setAddingLanguage(false);
    let proficiencyList = [...languageProficiencyList]; // Ensure it's a mutable array
    if (data.createLanguageProficiency) {
      proficiencyList.push(data.createLanguageProficiency);
    }
    if (data.updateLanguageProficiency) {
      proficiencyList = proficiencyList.filter(
        (x) => x.id !== data.updateLanguageProficiency.id
      );
      proficiencyList.push(data.updateLanguageProficiency);
    }
    setLanguageProficiencyList(proficiencyList);
    setLanguageProficiencySaved(true);
    setIsSubmitting(false);
    if (gradeInfoSaved && !editProfileMode) {
      setRegistrationState("step5Saved", true);
    }
    notifySuccess("Language information saved successfully");
  };

  const setQualificationMutationResult = (data: any) => {
    let qualificationList = [...(userQualificationList ?? [])];

    if (data.createEducationalQualification) {
      qualificationList.push(data.createEducationalQualification);

      setUserInfo(data.createEducationalQualification?.user);
    }

    if (data.updateEducationalQualification) {
      qualificationList = qualificationList.filter(
        (x) => x.id !== data.updateEducationalQualification.id
      );
      qualificationList.push(data.updateEducationalQualification);

      setUserInfo(data.updateEducationalQualification?.user);
    }

    setUserQualificationsList(qualificationList);
    setAddingQualification(false);
    setIsSubmitting(false);

    if (!isEmpty(userInfo?.highestGrade) && !isEmpty(languageProficiencyList)) {
      if (!editProfileMode) {
        requestOTP()
          .then(() => {
            setRegistrationState("step5Saved", true);
            openNotificationWithIcon(
              "success",
              "Save Success",
              "Qualification information saved successfully"
            );
          })
          .catch(() => {
            openNotificationWithIcon(
              "error",
              "OTP Service Error",
              "Information saved, but could not send the OTP"
            );
          });
      } else {
        openNotificationWithIcon(
          "success",
          "Save Success",
          "Qualification information saved successfully"
        );
      }
    }
  };

  const handleDeleteQualification = async (qualification) => {
    setIsDeleting(true);
    if (qualification.id) {
      await deleteQualification({ variables: { id: qualification.id } });

      let qualificationList = userQualificationList;
      qualificationList = qualificationList.filter(
        (x) => x.id !== qualification.id
      );
      setUserQualificationsList(qualificationList);
    }
    setIsDeleting(false);
    openNotificationWithIcon(
      "success",
      "Deletion Success",
      "Qualification information has been deleted successfully "
    );
  };

  const handleDeleteProficiency = async (proficiency) => {
    setIsDeleting(true);
    if (proficiency.id) {
      await deleteLanguageProficiency({ variables: { id: proficiency.id } });
      let proficiencyList = languageProficiencyList;
      proficiencyList = languageProficiencyList.filter(
        (x) => x.id !== proficiency.id
      );
      setLanguageProficiencyList(proficiencyList);
    }
    setIsDeleting(false);
    openNotificationWithIcon(
      "success",
      "Deletion Success",
      "Language information has been deleted successfully "
    );
  };

  return (
    <div className="" style={{ marginBottom: "30px" }}>
      <IfNot condition={!!editProfileMode}>
        <div>
          <span className="step-title">{step.title}</span>
          <p>{step.content}</p>
          <Divider />
        </div>

        <Row justify="space-between">
          <h3>Language Proficiency</h3>
          <Button
            type="link"
            className="purple-text"
            onClick={() => setAddingLanguage(true)}
            disabled={addingLanguage}
            icon={<PlusOutlined />}
          >
            Add Language
          </Button>
        </Row>
        <Row>
          <p style={{ marginBottom: "20px" }}>
            Please indicate languages in which you are proficient from the
            dropdown headers, as well as level of proficiency below each
            language selected.
          </p>
        </Row>
      </IfNot>

      <If condition={isEmpty(languageAbilitiesList) && !addingLanguage}>
        <Row justify="center" style={{ marginBottom: "20px" }}>
          <p>
            You haven't added any languages. Use the button above to add
            languages
          </p>
        </Row>
      </If>

      <If condition={!isEmpty(languageProficiencyList)}>
        <Table
          pagination={false}
          dataSource={languageProficiencyList}
          columns={[
            {
              title: "Language",
              key: "language",
              render: (record) => {
                return record.language?.name || "";
              },
            },
            {
              title: "Proficiency Level",
              key: "proficiencyLevel",
              render: (record) => {
                return record?.proficiencyLevel?.name || "";
              },
            },
            {
              title: "Abilities",
              key: "abilities",
              render: (record) => {
                return record.abilities?.map((a) => a.name)?.join(", ") || "";
              },
            },
            {
              title: "Action",
              key: "type",
              render: (record) => {
                return (
                  <div style={{ display: "flex", gap: "10px" }}>
                    <Popover content="Edit/Update language">
                      <Button
                        type="link"
                        icon={<EditOutlined />}
                        onClick={() => {
                          setSelectedLanguage(record);
                          setAddingLanguage(true);
                        }}
                        style={{ color: "orange" }}
                      />
                    </Popover>
                    <Popover content="Delete language">
                      <Button
                        type="link"
                        icon={<DeleteOutlined />}
                        disabled={isDeleting}
                        onClick={() => {
                          handleDeleteProficiency(record);
                        }}
                        style={{ color: "red" }}
                      />
                    </Popover>
                  </div>
                );
              },
            },
          ]}
        />
      </If>

      <If condition={addingLanguage}>
        <Form
          layout="vertical"
          className="mt-4"
          onFinish={handleSubmitLanguage}
        >
          <div className="flex-row gap-4">
            <Form.Item
              className="flex-1"
              label="Language"
              {...{
                name: "language",
                initialValue: selectedLanguage?.language?.id,
                rules: [
                  {
                    required: true,
                    message: "Please select language",
                  },
                ],
              }}
            >
              <Select className="input-select" placeholder="Select...">
                {languageOptions}
              </Select>
            </Form.Item>

            <Form.Item
              className="flex-1"
              label="Proficiency"
              {...{
                name: "languageProficiency",
                initialValue: selectedLanguage?.proficiencyLevel?.id,
                rules: [
                  {
                    required: true,
                    message: "Please select language proficiency",
                  },
                ],
              }}
            >
              <Select className="input-select" placeholder="Select...">
                {proficiencyLevelOptions}
              </Select>
            </Form.Item>

            <Form.Item
              className="flex-1"
              label={
                <Tooltip title="You may select multiple">
                  Ability&nbsp;
                  <InfoCircleOutlined />
                </Tooltip>
              }
              {...{
                name: "languageAbility",
                initialValue: selectedLanguage?.abilities?.map((a) => a.id),
                rules: [
                  {
                    required: true,
                    message: "Please select language ability",
                  },
                ],
              }}
            >
              <Select
                className="input-select"
                mode="multiple"
                placeholder="Select..."
              >
                {languageAbilityOptions}
              </Select>
            </Form.Item>
          </div>

          <Row justify="end">
            <Space size={"middle"}>
              <Button
                className="btn-registration-capacity-selected"
                htmlType="submit"
                loading={isSubmitting}
                icon={<SaveOutlined />}
              >
                Save
              </Button>
              <Button
                icon={<CloseCircleOutlined />}
                onClick={() => setAddingLanguage(false)}
              >
                Cancel
              </Button>
            </Space>
          </Row>
        </Form>
      </If>

      <Row className="mt-5 flex-column">
        <h3 className="mb-1">High School Grade</h3>
        <p className="mb-2">
          Please indicate the highest grade you completed in school.
        </p>

        <If condition={loadingUserQualifications}>
          <Skeleton active />
        </If>

        <Form
          form={schoolForm}
          onFinish={handleSubmitGradeInfo}
          layout="vertical"
        >
          <div className="flex-row gap-4">
            <Form.Item
              className="flex-1"
              label="Highest grade obtained"
              {...{
                name: "highestGrade",
                initialValue: userInfo?.highestGrade,
                rules: [
                  {
                    required: true,
                    message: "Please enter highest grade obtained",
                  },
                ],
              }}
            >
              <Select placeholder="Select..." className="input-select">
                {gradeOptions}
              </Select>
            </Form.Item>

            <Form.Item
              label="Year obtained"
              className="flex-1"
              {...{
                name: "yearGradeObtained",
                initialValue: userInfo?.highestGradeYear,
                rules: [
                  {
                    required: true,
                    message: "Please select the year the grade was obtained",
                  },
                ],
              }}
            >
              <Select placeholder="Select" className="input-select">
                {yearOptions}
              </Select>
            </Form.Item>
          </div>

          <Row justify="end">
            <Button
              htmlType="submit"
              className="purple-button"
              loading={isSubmitting}
              icon={<SaveOutlined />}
            >
              Save
            </Button>
          </Row>
        </Form>
      </Row>

      <Row className="flex-column">
        <IfNot condition={!!editProfileMode}>
          <Row justify="space-between" className="mt-5">
            <h3>Qualifications</h3>
            <Button
              type="link"
              className="purple-text"
              onClick={() => setAddingQualification(true)}
              disabled={addingQualification}
              icon={<PlusOutlined />}
            >
              Add Qualification
            </Button>
          </Row>

          <p className="mb-2">
            Please complete below, all qualifications (certificate, diplomas,
            degrees) obtained:
          </p>
        </IfNot>

        <If condition={addingQualification}>
          <Form
            form={qualificationForm}
            onFinish={handleSubmitQualificationInfo}
            className="mt-4"
            layout="vertical"
          >
            <div className="flex-row gap-4">
              <Form.Item
                className="flex-1"
                label="Type"
                {...{
                  name: "qualificationType",
                  initialValue: selectedQualification?.type?.id,
                  rules: [
                    {
                      required: true,
                      message: "Please select the type of qualification",
                    },
                  ],
                }}
              >
                <Select className="input-select" placeholder="Select...">
                  {qualificationTypesOptions}
                </Select>
              </Form.Item>
              <Form.Item
                label="Institution"
                className="flex-1"
                {...{
                  name: "nameOfInstitution",
                  initialValue: selectedQualification?.institutionName,
                  rules: [
                    {
                      required: true,
                      message: "Please enter the name of your institution",
                    },
                  ],
                }}
              >
                <Input placeholder="Please enter the name of your institution" />
              </Form.Item>
              <Form.Item
                className="flex-1"
                label="Qualification"
                {...{
                  name: "qualification",
                  initialValue: selectedQualification?.qualification,
                  rules: [
                    {
                      required: true,
                      message: "Please enter your qualification",
                    },
                  ],
                }}
              >
                <Input placeholder="Please enter your qualification" />
              </Form.Item>
              <Form.Item
                className="flex-1"
                label="Year"
                {...{
                  name: "yearQualificationObtained",
                  initialValue: selectedQualification?.year,
                  rules: [
                    {
                      required: true,
                      message: "Please select year",
                    },
                  ],
                }}
              >
                <Select className="input-select" placeholder="Select...">
                  {yearOptions}
                </Select>
              </Form.Item>
            </div>
            <Row align="bottom" justify="end">
              <Space>
                <Button
                  htmlType="submit"
                  loading={isSubmitting}
                  icon={<SaveOutlined />}
                  className="purple-button"
                >
                  Save
                </Button>
                <Button
                  icon={<CloseCircleOutlined />}
                  onClick={() => setAddingQualification(false)}
                  className="full-width"
                >
                  Cancel
                </Button>
              </Space>
            </Row>
          </Form>
        </If>

        <If condition={!isEmpty(userQualificationList)}>
          <Table
            className="mt-2"
            pagination={false}
            dataSource={userQualificationList}
            columns={[
              {
                title: "type",
                key: "type",
                render: (record) => {
                  return record.type?.name;
                },
              },
              {
                title: "Institution",
                key: "institutionName",
                render: (record) => {
                  return record.institutionName;
                },
              },
              {
                title: "Qualification",
                key: "qualification",
                render: (record) => {
                  return record.qualification;
                },
              },
              {
                title: "Year",
                key: "year",
                render: (record) => {
                  return record.year;
                },
              },
              {
                title: "Action",
                key: "type",
                render: (record) => {
                  return (
                    <div style={{ display: "flex", gap: "10px" }}>
                      <Popover content="Edit/Update qualification">
                        <Button
                          type="link"
                          icon={<EditOutlined />}
                          onClick={() => {
                            setSelectedQualification(record);
                            setAddingQualification(true);
                          }}
                          style={{ color: "orange" }}
                        />
                      </Popover>
                      <Popover content="Delete qualification">
                        <Button
                          type="link"
                          icon={<DeleteOutlined />}
                          onClick={() => {
                            handleDeleteQualification(record);
                          }}
                          style={{ color: "red" }}
                        />
                      </Popover>
                    </div>
                  );
                },
              },
            ]}
          />
        </If>

        <If condition={isEmpty(userQualificationList) && !addingQualification}>
          <Row justify="center" style={{ margin: "10px 0 10px 0" }}>
            <p>
              You haven't added any qualifications. Use the button above to add
              qualifications
            </p>
          </Row>
        </If>
      </Row>
    </div>
  );
}
