import { useState, useEffect } from "react";
import { Input, Button, Row, Col } from "antd";

import { isEmpty } from "lodash";
import { useMutation } from "@apollo/client";
import { openNotificationWithIcon } from "@/utils/notification";
import { VALIDATE_OTP, REQUEST_OTP } from "../../graphql/mutations";
import AuthenticationCompany from "../RegisterCompany/authentication";
import AuthenticationEmployee from "../RegisterEmployee/authentification";
import { If } from "@/components/If";
import { useRegistrationState } from "@/stores/registration";
import { useUserState } from "@/stores/user";
import { COMPANY_TYPES } from "../../constants";

type Props = { nextStep: () => void };

const OTPAuthentication = (props: Props) => {
  const {
    setRegistrationStateObject: setRegistrationState,
    registeringAs,
    manualRegistrationType,
    manualRegisterId,
    registrationCapacity,
  } = useRegistrationState();

  const { id: userId } = useUserState();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [OTP, setOTP] = useState("");
  const [currentStep, setCurrentStep] = useState("step5Saved");

  const [otpMode, setOtpMode] = useState("SMS");
  const [employerNumber, setEmployerNumber] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [sendingOtp, setSendingOtp] = useState(false);

  const isSoleProp = registeringAs === "A Sole Proprietor";
  const isCompany = COMPANY_TYPES.includes(registeringAs);

  useEffect(() => {
    if (isSoleProp) {
      setCurrentStep("step8Saved");
    } else if (isCompany) {
      setCurrentStep("step6Saved");
    } else {
      setCurrentStep("step5Saved");
    }
  }, [registeringAs, isSoleProp, isCompany]);

  const [requestOTP] = useMutation(REQUEST_OTP);
  const [validateOTP] = useMutation(VALIDATE_OTP, {
    onError: () => {
      setIsSubmitting(false);
      openNotificationWithIcon(
        "error",
        "Authentication Error",
        "Invalid OTP. Please try again"
      );
    },
    onCompleted: () => {
      setRegistrationState({ [currentStep]: true });

      openNotificationWithIcon(
        "success",
        "Authentication Success",
        "Your registration has been authenticated successfully "
      );
    },
  });

  const sendOTP = async () => {
    if (isAllZeros(employerNumber) && registrationCapacity !== "Business") {
      setOtpSent(true);
      openNotificationWithIcon(
        "success",
        "OTP Success",
        `Your OTP has been bypassed. Please use the default OTP`
      );
    } else {
      let formattedNumber = employerNumber;
      if (
        formattedNumber.substring(0, 3) == "027" ||
        formattedNumber.substring(0, 3) == "+27"
      ) {
        formattedNumber = "+27" + formattedNumber.slice(3);
      } else if (formattedNumber.substring(0, 1) == "0") {
        formattedNumber = "+27" + formattedNumber.slice(1);
      } else if (formattedNumber.substring(0, 3) != "+27") {
        formattedNumber = "+27" + formattedNumber;
      }

      try {
        if (!!manualRegisterId) {
          setSendingOtp(true);
          await requestOTP({
            variables: {
              input: {
                userId: manualRegisterId,
                employerNumber: formattedNumber,
              },
            },
          });

          setEmployerNumber(formattedNumber);

          setSendingOtp(false);
          setOtpSent(true);
        }
      } catch (err) {
        // @ts-ignore
        console.error("OTP_SEND::", err.message);
        openNotificationWithIcon(
          "Otp send error",
          "Unable to send the otp",
          "Please try sending it again"
        );
      }
    }
  };

  const submitOTP = () => {
    if (isEmpty(OTP)) {
      return openNotificationWithIcon(
        "error",
        "Validation Error",
        `Please Enter OTP sent to via ${otpMode}`
      );
    }
    setIsSubmitting(true);

    validateOTP({
      variables: {
        userId: manualRegistrationType ? manualRegisterId : userId,
        otp: parseInt(OTP),
      },
    });
  };

  function isAllZeros(str: string) {
    return str
      .trim()
      .split("")
      .every((c) => c === "0");
  }

  function handleRequestOTP() {
    if (isAllZeros(employerNumber) && registrationCapacity !== "Business") {
      setOtpSent(true);
      openNotificationWithIcon(
        "success",
        "OTP Success",
        `Your OTP has been bypassed. Please use the default OTP`
      );
    } else {
      requestOTP({
        variables: {
          input: {
            userId,
            employerNumber: manualRegistrationType ? employerNumber : "",
          },
        },
      })
        .then((resp) => {
          const mode =
            resp.data.requestOTP === "SMS OTP Limit reached!"
              ? "Email"
              : otpMode;
          setOtpMode(mode);
          openNotificationWithIcon(
            "success",
            "OTP Success",
            `Your OTP has been sent. Please check your ${mode} for OTP`
          );
        })
        .catch(() =>
          openNotificationWithIcon(
            "error",
            "OTP Service Error",
            "Could not send OTP this time"
          )
        );
    }
  }

  if (!!manualRegistrationType && !otpSent) {
    return (
      <Row>
        <h4>Please enter the number that will receive the otp:</h4>
        <Input
          addonBefore={<span style={{ color: "black" }}>🇿🇦 +27</span>}
          placeholder="Please enter the number that will receive the otp"
          onChange={(e) => setEmployerNumber(e.target.value)}
        />
        <Row justify="end" className="full-width">
          <Button
            className="purple-button"
            onClick={sendOTP}
            disabled={isSubmitting || ![9, 10].includes(employerNumber.length)}
            loading={sendingOtp || isSubmitting}
          >
            {sendingOtp ? "Sending OTP..." : "Send OTP"}
          </Button>
        </Row>
      </Row>
    );
  }

  return (
    <>
      <If condition={registrationCapacity === "Business"}>
        <AuthenticationCompany />
      </If>
      <If condition={registrationCapacity === "Individual"}>
        <AuthenticationEmployee />
      </If>

      <div className="flex-column">
        <h4>Please enter OTP received:</h4>
        <Input
          placeholder="Please enter OTP"
          value={OTP}
          onChange={(e) => setOTP(e.target.value.trim())}
        />
        <small>
          * If you have not received the OTP within 2 minutes or OTP entered in
          invalid, click here to{" "}
          <span
            className="purple-text"
            onClick={isSubmitting || sendingOtp ? undefined : handleRequestOTP}
          >
            resend OTP
          </span>
          .
        </small>
      </div>
      <Row justify="end">
        <Col span={8}>
          <Button
            type="default"
            className="purple-button"
            style={{ width: "100%", marginTop: "20px" }}
            onClick={submitOTP}
            disabled={isSubmitting}
          >
            Submit
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default OTPAuthentication;
