import { useState, useEffect } from "react";
import { Button, Card, Divider, Skeleton } from "antd";
import { useQuery } from "@apollo/client";
import { GET_USER } from "../Admin/graphql/queries";
import { isEmpty } from "lodash";

import InternalErrorPage from "../InternalErrorPage";
import ContactForm from "./contactDetailsForm";
import AddressInfo from "./addressForm";
import RelatedPartyForm from "./relatedPartyForm";
import { useUserState } from "@/stores/user";
import { useRegistrationState } from "@/stores/registration";

interface IProps {
  nextStep: () => void;
}

function BusinessInfoForm(props: IProps) {
  const { email } = useUserState();
  const { setRegistrationState } = useRegistrationState();
  const [representativeSaved, setRepresentativeSaved] = useState(false);
  const [userInfo, setUserInfo] = useState<any>();
  const [companyContactsInfo, setCompanyContactsInfo] = useState([]);
  const [isContactInfoSaved, setIsContactInfoSaved] = useState(false);
  const [postalPhysicalAddressesSaved, setPostalPhysicalAddressesSaved] =
    useState(false);
  const [allRequiredContactsSaved, setAllRequiredContactsSaved] =
    useState(false);

  useEffect(() => {
    checkRequiredFormsFilled();
  }, [
    postalPhysicalAddressesSaved,
    allRequiredContactsSaved,
    representativeSaved,
  ]);

  const {
    loading: loadingUser,
    error: errorUser,
    data: dataUser,
    //refetch: refetchUser,
  } = useQuery(GET_USER, {
    variables: { input: { email } },
    skip: isEmpty(email),
    onCompleted: () => {
      // set user info from query result
      setUserInfo(dataUser.userData);
    },
  });

  const checkRequiredFormsFilled = () => {
    if (
      postalPhysicalAddressesSaved &&
      allRequiredContactsSaved &&
      representativeSaved
    ) {
      setRegistrationState("step2Saved", true);
    }
  };

  const ContactFormProps = {
    onDone: (
      contactSaved: boolean,
      allRequireContactsSaved: boolean,
      contacts: any
    ) => {
      setIsContactInfoSaved(contactSaved);
      setCompanyContactsInfo(contacts);
      setAllRequiredContactsSaved(allRequireContactsSaved);
      checkRequiredFormsFilled();
    },
  };

  const AddressFormProps = {
    sendAddressInfo: (postalPhysicalSaved: boolean) => {
      setPostalPhysicalAddressesSaved(postalPhysicalSaved);
      checkRequiredFormsFilled();
    },
  };

  const RelatedPartyFormProps = {
    sendRelatedPartyInfo: (relatedPartySaved: boolean) => {
      setRepresentativeSaved(relatedPartySaved);
      checkRequiredFormsFilled();
    },
  };

  if (errorUser) {
    return <InternalErrorPage />;
  }

  const step = {
    title: "Step 2: Business and personal information",
    content: "Complete your basic personal information",
  };

  return (
    <>
      <div>
        <span className="step-title">{step.title}</span>
        <p>{step.content}</p>
        <Divider />
      </div>
      <span>
        <label>Personal information:</label>
      </span>
      <br />
      <br />
      <span>
        <label>Representative Information</label>
      </span>
      {loadingUser ? (
        <Skeleton active paragraph={{ rows: 7 }} />
      ) : (
        <Card className="input-block-wrapper input-select">
          <div className="flex-column input-block">
            <Button
              shape="round"
              className="btn-registration-capacity-selected"
            >
              Your Information
            </Button>
            <br />
            <br />
            <span>
              ID/PassportNumber: <label>{userInfo?.idNumber}</label>
              <br />
              First Name: <label>{userInfo?.firstName}</label>
              <br />
              Last Name: <label>{userInfo?.lastName}</label>
              <br />
            </span>
          </div>
          <div className="flex-column input-block">
            <span />
            <br />
            <br />
            <span>
              Phone Number: <label>{userInfo?.phone}</label>
              <br />
              Email: <label>{userInfo?.email}</label>
              <br />
              Capacity: <label>{userInfo?.capacity?.name}</label>
              <br />
            </span>
          </div>
        </Card>
      )}

      <br />
      <br />
      <span>
        <label>Business Information</label>
      </span>
      <br />
      <br />
      <span>
        <label>Contact Information</label>
      </span>
      <br />
      <span>
        To add contact information in the table below, select type from the
        dropdown:
      </span>
      <br />
      <span>
        Mandatory Business Unit Contact to be added included Legal, Finance,
        Administration{" "}
      </span>
      <br />
      <br />

      <ContactForm {...ContactFormProps} />

      {(isContactInfoSaved || !isEmpty(companyContactsInfo)) && (
        <div className="flex-column input-select">
          <span>
            <label>Related Party information</label>
          </span>
          <br />
          <span>Please indicate all related parties in the table below</span>
          <br />
          <br />
          <RelatedPartyForm {...RelatedPartyFormProps} />
        </div>
      )}

      <AddressInfo {...AddressFormProps} />
    </>
  );
}

export default BusinessInfoForm;
