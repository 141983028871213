import { Tabs } from "antd";
import EmployeesComponent from "../Admin/Employees/companyEmployees";
import RemovedEmployees from "./RemovedEmployees";
import { useUserState } from "@/stores/user";

function Employees() {
  const role = useUserState((s) => s.getActiveRole)();

  const companyStatus = localStorage.getItem("companyStatus");

  const showTab = !companyStatus || companyStatus !== "Deregistered";

  const tabItems = [
    {
      label: "Employees",
      key: "1",
      children: <EmployeesComponent companyId={role.companyId} mode="member" />,
    },
    ...(showTab
      ? [
          {
            label: "Removed Employees",
            key: "3",
            children: <RemovedEmployees companyId={role.companyId} />,
          },
        ]
      : []),
  ];

  return (
    <>
      <div className="employees-header-div">
        <h2>Employee Management</h2>
      </div>

      <Tabs tabPosition="top" items={tabItems} />
    </>
  );
}

export default Employees;
