import { useLazyQuery, useMutation } from "@apollo/client";
import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Descriptions,
  Divider,
  Form,
  Row,
  Spin,
  Typography,
  Upload,
} from "antd";
import { CREATE_DOCUMENT, PROCESS_DD_PAYMENT } from "@/graphql/mutations";
import { GET_PRESIGNED_URL, GENERATE_SAGE_DOC } from "@/graphql/queries";
import { isEmpty } from "lodash";
import { useState } from "react";
import { COMPANY_TYPES, EMPLOYEE_OF_A_BUSINESS } from "../../constants";
import { notifyError, openNotificationWithIcon } from "@/utils/notification";
import { uploadToS3 } from "@/utils/uploadToS3";
import RegistrationFeeCompany from "../RegisterCompany/registrationFeePayment";
import RegistrationFeeEmployee from "../RegisterEmployee/registrationFeePayment";
import RegistrationFeeSoleProprietor from "../RegisterSoleProprietor/registrationFeePayment";
import { useRegistrationState } from "@/stores/registration";
import { useUserState } from "@/stores/user";
import { useNavigate } from "react-router-dom";
import { If } from "@/components/If";

interface Props {
  paymentMethodRequired?: string;
  registrationCapacity?: string;
  registeringAs?: string;
  companyId?: number;
  taskId?: number;
  task?: any;
  isRenewal?: boolean;
  isPenalty?: boolean;
}

function RegistrationFeePaymentForm(props: Props) {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { task, isRenewal, paymentMethodRequired } = props;
  let { registrationCapacity, companyId, registeringAs } = props;

  const {
    setRegistrationStateObject: setRegistrationState,
    companyName,
    applicationId,
    step1Saved,
    step2Saved,
    manualRegistrationType,
    manualRegisterId,
    manualRegisterCompanyId,
    registrationCapacity: regCapacity,
    registeringAs: regAs,
    companyId: cid,
  } = useRegistrationState();
  const { id: uid } = useUserState();

  const sagePayload =
    task?.invoice?.sagePayload && JSON.parse(task.invoice.sagePayload);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [directDepositRef] = useState<string>(
    sagePayload?.Reference || `INV${(+new Date()).toString()}`
  );
  const [isUploading, setIsUploading] = useState(false);
  const [documentName, setDocumentName] = useState("");
  const [documentPath, setDocumentPath] = useState("");
  const [fileType, setFileType] = useState("");
  const [documentList, setDocumentList] = useState([]);
  const [popFile, setPopFile] = useState<any>();
  const [loading, setLoading] = useState(false);

  const userId = manualRegistrationType ? manualRegisterId : uid;
  const paymentMethod = paymentMethodRequired || "Direct";
  const companyTypes = [...COMPANY_TYPES, "A Sole Proprietor"];
  const isCurrentlyRegistering = step1Saved || step2Saved;

  registrationCapacity = registrationCapacity || regCapacity;

  if (!companyId) {
    companyId = manualRegistrationType ? manualRegisterCompanyId : cid;
  }

  if (!registeringAs) {
    registeringAs = regAs;
  }

  const isBusinessRegistration =
    registrationCapacity === "Business" && companyTypes.includes(registeringAs);

  const isCompany =
    registrationCapacity === "Business" &&
    COMPANY_TYPES.includes(registeringAs);
  const isSoleProprietor = registeringAs === "A Sole Proprietor";
  const isEmployee =
    registrationCapacity === "Individual" &&
    registeringAs === EMPLOYEE_OF_A_BUSINESS;

  let nextPath: string;
  if (isCurrentlyRegistering) {
    nextPath = isBusinessRegistration
      ? "/register/results/business"
      : "/register/results";
  } else {
    nextPath = "/payment-task-verification";
  }

  const [createDocument] = useMutation(CREATE_DOCUMENT);

  const [processDDPayment] = useMutation(PROCESS_DD_PAYMENT, {
    onCompleted: (data) => {
      if (!isEmpty(data)) {
        setRegistrationState({
          directDepositTransactionId: data.processDDPayment.transaction.id,
          paymentTaskId: data.processDDPayment.paymentTask.id,
        });
      }

      setIsSubmitting(false);
    },
    onError: (error) => {
      setIsSubmitting(false);
      notifyError(error);
    },
  });

  const [getPresignedUrl] = useLazyQuery(GET_PRESIGNED_URL, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (!isEmpty(data.preSignedUrl)) {
        let currentList = documentList;
        currentList.push({
          fileName: documentName,
          presignedUrl: data.preSignedUrl,
          type: fileType,
          popFile,
          path: documentPath,
        });
        setDocumentList(currentList);
        setIsUploading(false);
      }
    },
    onError: (err) => {
      setIsUploading(false);
      notifyError(err);
    },
  });

  const uploadDocument = async () => {
    const uploadedDocs = [];
    try {
      if (documentList.length === 0) {
        return notifyError(
          "Please upload proof of payment/deposit slip to submit for review"
        );
      }

      setIsSubmitting(true);

      for (let {
        presignedUrl,
        type,
        popFile,
        path,
        fileName,
      } of documentList) {
        await uploadToS3(presignedUrl, type, popFile);
        const { data } = await createDocument({
          variables: {
            input: {
              documentName: fileName,
              documentPath: path,
              documentTypeId: 20,
              documentStatusId: 1,
              documentFormat: type,
              companyId,
              userId: +userId,
              forCompany: true,
            },
          },
        });

        uploadedDocs.push(data.createDocument.id);
      }

      await processDDPayment({
        variables: {
          input: {
            userId,
            companyId,
            directDepositInvoiceNumber: props.isRenewal
              ? props.task.company.sageTaxInvoiceRef
              : directDepositRef,
            paymentMethod,
            isBusinessRegistration,
            registeringAs: isBusinessRegistration ? registeringAs : undefined,
            applicationId: isBusinessRegistration ? applicationId : undefined,
            isRenewal: props.isRenewal,
            ...(task?.id ? { paymentTaskId: task.id } : {}),
            documents: uploadedDocs,
          },
        },
      });

      navigate(nextPath);
    } catch (error) {
      // @ts-ignore
      if (error.message.includes("504")) {
        return navigate(nextPath);
      }
      notifyError(error);
    }
  };

  const generatePresignedUrl = async (option) => {
    try {
      const { file, onSuccess } = option;

      const fileExt: string = file.name.split(".").pop();
      const docNo = documentList.length;
      const invoiceSagePayload =
        task?.invoice?.sagePayload && JSON.parse(task.invoice.sagePayload);
      const renewalRef = invoiceSagePayload?.Reference?.replace("-", "_");
      const docName = `Direct_Deposit_Payment_${companyId}_${
        isRenewal ? renewalRef : directDepositRef
      }_${docNo}.${fileExt}`;
      const path = `${companyId}/Proof_of_Payments/Direct_Deposits/${docName}`;

      setIsUploading(true);
      setPopFile(file);
      setFileType(file.type);
      setDocumentName(docName);
      setDocumentPath(path);

      await getPresignedUrl({
        variables: {
          input: {
            filePath: path,
            contentType: file.type,
            forCompany: true,
          },
        },
      });

      setTimeout(() => {
        onSuccess();
      }, 2500);
    } catch (error) {
      notifyError(error);
    }
  };

  const [getSageDocument] = useLazyQuery(GENERATE_SAGE_DOC, {
    fetchPolicy: "network-only",
    onError: (err) => {
      setLoading(false);

      if (
        err.message === "Unauthorized" ||
        err.message?.includes("token has expired")
      )
        navigate("/login");

      openNotificationWithIcon(
        "error",
        "Load Error",
        "There was an error retrieving the document"
      );
    },
    onCompleted: (data) => {
      window.open(data.generateSageDocument);
      setLoading(false);
    },
  });

  const handleViewTemplate = (type: string, companyId: number, id: number) => {
    setLoading(true);
    getSageDocument({
      variables: {
        input: {
          docType: type,
          companyId,
          sageInvoiceId: id,
        },
      },
    });
  };

  return (
    <>
      <If condition={isCompany && !!companyId}>
        <RegistrationFeeCompany />
      </If>

      <If condition={isEmployee && !!companyId}>
        <RegistrationFeeEmployee
          companyId={companyId}
          registeringAs={registeringAs}
          isRenewal={props.isRenewal}
          sagePayload={task?.invoice?.sagePayload}
        />
      </If>

      <If condition={isSoleProprietor && !!companyId}>
        <RegistrationFeeSoleProprietor />
      </If>

      <Divider />

      <div>
        {isSubmitting && <Spin tip="Submitting..." className="loader" />}
        <Form form={form} className={isSubmitting ? "no-interact " : ""}>
          {paymentMethod === "Direct" && (
            <>
              <Row className="">
                <If condition={props.isRenewal}>
                  <Typography.Paragraph>
                    Make payments as per payment on subscription list that was
                    sent through email or can be found in documents list.
                  </Typography.Paragraph>
                </If>
                <If condition={!props.isRenewal}>
                  <Typography.Paragraph>
                    Please deposit your registration amount into the account
                    below.
                  </Typography.Paragraph>
                </If>
                <Typography.Text>
                  Once you have made the payment, you will need to upload your
                  proof of payment or deposit slip in order to proceed.
                </Typography.Text>

                <Card style={{ marginTop: "10px" }}>
                  <Descriptions
                    column={5}
                    title="CFDC Banking Details"
                    layout="vertical"
                  >
                    <Descriptions.Item label="Bank">ABSA</Descriptions.Item>
                    <Descriptions.Item label="Account name">
                      CFDC Account
                    </Descriptions.Item>
                    <Descriptions.Item label="Account number">
                      4055948699
                    </Descriptions.Item>
                    <Descriptions.Item label="Account type">
                      Cheque
                    </Descriptions.Item>
                    <Descriptions.Item label="Branch code">
                      633505
                    </Descriptions.Item>
                    <Descriptions.Item span={3} label="Payment Reference">
                      {isBusinessRegistration
                        ? (companyName || "").toUpperCase()
                        : props.isRenewal
                        ? props.task.company.tradingName
                        : directDepositRef}
                    </Descriptions.Item>
                    <Descriptions.Item span={2} label="Comment">
                      {task?.comment}
                    </Descriptions.Item>
                  </Descriptions>

                  <div className="flex-column input-block">
                    {false && props.isRenewal && (
                      <Button
                        loading={loading}
                        className="btn-registration-capacity-selected"
                        onClick={() => {
                          handleViewTemplate(
                            "invoice",
                            props.task.company.id,
                            props.task.company.sageRecurringInvoiceId
                          );
                        }}
                      >
                        View Invoice
                      </Button>
                    )}
                  </div>
                </Card>
                <br />
                <br />
                <Typography.Paragraph>
                  Please upload your proof of payment or deposit slip indicating
                  that you have processed the above payment.
                </Typography.Paragraph>
              </Row>
              <div className="flex-col">
                <h4>Proof of Payment/Deposit Slip</h4>
                <Upload
                  customRequest={generatePresignedUrl}
                  style={{ display: "flex" }}
                  disabled={isUploading}
                >
                  <Button>
                    <UploadOutlined />
                    <span>Upload</span>
                  </Button>
                </Upload>
                <Row style={{ marginTop: "20px" }}>
                  <Button
                    disabled={isUploading}
                    loading={isSubmitting}
                    className="btn-registration-capacity-selected"
                    onClick={() => uploadDocument()}
                  >
                    Submit for Review
                  </Button>
                </Row>
              </div>
            </>
          )}
        </Form>
      </div>
    </>
  );
}

export default RegistrationFeePaymentForm;
