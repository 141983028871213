import "./index.css";
import { useState, useEffect } from "react";
import { Card, Input, Button } from "antd";
import { isEmpty } from "lodash";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { format } from "date-fns";
import {
  GET_TASK,
  GET_APPLICATION_REVIEW_INFO,
  GET_DOWNLOAD_URL,
} from "@/graphql/queries";
import {
  CREATE_DOCUMENT,
  UPDATE_REVIEW_STEP,
  UPDATE_TASK,
} from "@/graphql/mutations";
import { openNotificationWithIcon } from "@/utils/notification";
import ComplaintLoader from "../Admin/Complaint/SubComponents/complaintLoader";
import { useNavigate, useParams } from "react-router-dom";

import fileIcon from "../../assets/images/pdf_icon.png";
import Uploader from "../../components/Uploader";
import { uploadToS3 } from "@/utils/uploadToS3";
import { ADMIN_STREAM, SUPER_USER_STREAM } from "../../constants";

interface IDocumentList {
  fileName: string;
  presignedUrl: string;
  type: string;
  popFile: any;
  path: string;
}

function TaskReview() {
  const { id } = useParams();
  const [taskId, setTaskId] = useState<any>(id);
  const navigate = useNavigate();
  //@ts-ignore
  const [proofOfPaymentDocs, setProofOfPaymentDocs] = useState([]);
  const [task, setTask] = useState<any>({});
  const [loadingTask, setLoadingTask] = useState(true);
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState("");
  const [downloadUrl, setDownloadUrl] = useState("");
  const [loadingDoc, setLoadingDoc] = useState(false);
  const [documentList, setDocumentList] = useState<IDocumentList[]>([]);
  const [reviewStepId, setReviewStepId] = useState<number>();

  useEffect(() => {
    setTaskId(+id);
  }, [id]);

  const {} = useQuery(GET_TASK, {
    variables: { taskId },
    onError: (err) => {
      if (
        err.message === "Unauthorized" ||
        err.message?.includes("token has expired")
      )
        navigate("/login");

      openNotificationWithIcon(
        "error",
        "Load Error",
        "There was an error loading activities"
      );
    },
    onCompleted: (data) => {
      if (!isEmpty(data?.task)) {
        getCommentsById({ variables: { taskId: data?.task?.id } });
        setTask(data.task);
        setLoadingTask(false);
        setLoadingDoc(false);
        setComment("");
      }
    },
  });

  const [getDownloadUrl] = useLazyQuery(GET_DOWNLOAD_URL, {
    fetchPolicy: "no-cache",
    onError: (err) => {
      setLoadingDoc(false);
      openNotificationWithIcon(
        "error",
        "Load Error",
        "There was an error retrieving the document"
      );
    },
    onCompleted: (data) => {
      setLoadingDoc(false);
      setDownloadUrl(data.preSignedDownloadUrl);
    },
  });

  const [createDocument] = useMutation(CREATE_DOCUMENT);
  const [updateReviewStep] = useMutation(UPDATE_REVIEW_STEP);
  const [updateTask] = useMutation(UPDATE_TASK);

  const [getCommentsById] = useLazyQuery(GET_APPLICATION_REVIEW_INFO, {
    onCompleted: ({ reviewByTask }) => {
      const mainThread = reviewByTask?.steps[0];

      setComments(mainThread?.comments.reverse());
      setReviewStepId(mainThread?.id);
    },
  });

  const resolveTask = async () => {
    setLoadingDoc(true);
    await updateTask({
      variables: {
        input: {
          id: task?.id,
          taskStatus: "Complete",
        },
      },
    });

    navigate("/tasks");
  };

  const handleMessageSubmit = async () => {
    if (!comment) {
      openNotificationWithIcon(
        "error",
        "Empty comment",
        "Please input comment"
      );
    }

    setLoadingDoc(true);
    const documents = await uploadDocument(documentList);

    await updateReviewStep({
      variables: {
        input: {
          id: reviewStepId,
          creatorId: +localStorage.getItem("userId"),
          comment,
          documents,
        },
      },
    });

    openNotificationWithIcon(
      "success",
      "Task updated",
      `${task?.id} has been updated`
    );

    window.location.reload();
  };

  const uploadDocument = async (documentList: IDocumentList[]) => {
    const documents: number[] = [];

    for (let { presignedUrl, type, popFile, path, fileName } of documentList) {
      uploadToS3(presignedUrl, type, popFile);
      const { data } = await createDocument({
        variables: {
          input: {
            documentName: fileName,
            documentPath: path,
            documentStatusId: 1,
            documentFormat: type,
            companyId: task?.company?.id,
            forCompany: true,
            documentTypeId: 10,
          },
        },
      });

      documents.push(data?.createDocument?.id);
    }

    return documents;
  };

  const handleViewDocument = (filePath) => {
    setLoadingDoc(true);
    getDownloadUrl({
      variables: {
        input: {
          filePath,
          forCompany: true,
        },
      },
    });
  };

  useEffect(() => {
    if (downloadUrl) window.open(downloadUrl);
  }, [downloadUrl]);

  return (
    <>
      {loadingTask && <ComplaintLoader />}
      {!loadingTask && (
        <div style={{ margin: "1vw" }}>
          <Card className="information-card" key={`Address ${1}`}>
            <div className="flex-column information-block">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <h2>Payment Task {task?.id}</h2>
                <h3>
                  Status: <a>{task?.taskStatus}</a>
                </h3>
              </div>
              <br />
              {task?.employeeInvitation?.acceptedBy && (
                <span>
                  Payment for registration of invited employee:{" "}
                  <label>
                    {task?.employeeInvitation?.acceptedBy?.firstName}{" "}
                    {task?.employeeInvitation?.acceptedBy?.lastName} (
                    {task?.employeeInvitation?.acceptedBy?.idNumber})
                  </label>
                  <br />
                </span>
              )}
              <br />
              <span>
                Task Type: <label>{task?.type?.name}</label>
                <br />
                Date Created:{" "}
                <label>
                  {format(new Date(task?.createdDate), "dd-MM-yyyy")}
                </label>
                <br />
                Last Modified:{" "}
                <label>
                  {format(new Date(task?.createdDate), "dd-MM-yyyy")}
                </label>
              </span>
              <div style={{ textAlign: "right" }}>
                {[ADMIN_STREAM, SUPER_USER_STREAM].includes(
                  localStorage.getItem("roleStream")
                ) && (
                  <Button
                    className="btn-add-comment"
                    onClick={resolveTask}
                    loading={loadingDoc}
                  >
                    Resolve
                  </Button>
                )}
              </div>
            </div>
          </Card>
          <br />
          <Card
            className="comment-card"
            cover={
              <div style={{ padding: "2.5% 15% 2.5% 2.5%" }}>
                <Card
                  style={{ marginBottom: "30px" }}
                  title={
                    <p style={{ margin: 0 }}>
                      To:{" "}
                      <span
                        style={{
                          background: "#ccc",
                          width: "100%",
                          padding: "5px 15px",
                          borderRadius: "10px",
                          color: "#121212",
                        }}
                      >
                        {[ADMIN_STREAM, SUPER_USER_STREAM].includes(
                          localStorage.getItem("roleStream")
                        )
                          ? `${task?.employee?.firstName}${task?.employee?.lastName}`
                          : "CFDC Admin"}
                      </span>
                    </p>
                  }
                >
                  <Input.TextArea
                    style={{ marginBottom: "10px" }}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    rows={8}
                  />
                  <Uploader
                    companyId={task?.company?.id}
                    defaults={{ directory: "Task", filename: "Direct_Task" }}
                    onPresigned={(list: any) => setDocumentList(list)}
                  />
                  <Card.Meta
                    className="comment-action"
                    title={
                      <>
                        <div>
                          <Button
                            className="btn-add-comment"
                            style={{ marginBottom: "15px" }}
                            onClick={handleMessageSubmit}
                            loading={loadingDoc}
                          >
                            Send message
                          </Button>
                        </div>
                      </>
                    }
                  />
                </Card>

                {comments?.map((comment) => (
                  <div key={comment.id} className="message-box">
                    <div
                      className="flex-column"
                      style={{ flexDirection: "row" }}
                    >
                      <div style={{ flex: 1 }}>
                        <p>
                          {comment?.author?.firstName}{" "}
                          {comment?.author?.lastName}
                        </p>
                      </div>
                      <div style={{ flex: 1 }}>
                        <p style={{ textAlign: "right" }}>
                          {format(
                            new Date(comment?.createdDate),
                            "dd/MM/yyyy hh:mm"
                          )}
                        </p>
                      </div>
                    </div>

                    <p>{comment.body}</p>

                    {comment.documents?.map(({ documentPath }) => (
                      <div style={{ marginBottom: "30px" }}>
                        <span>
                          <img
                            style={{ height: "25px", width: "25px" }}
                            src={fileIcon}
                          />
                          &nbsp; &nbsp;
                          {documentPath}
                          <Button
                            type="link"
                            onClick={() => handleViewDocument(documentPath)}
                            loading={loadingDoc}
                          >
                            View
                          </Button>
                        </span>
                        <br />
                      </div>
                    ))}
                    <hr />
                  </div>
                ))}
              </div>
            }
          />
        </div>
      )}
    </>
  );
}

export default TaskReview;
