import { Button } from "antd";
import { REFRESH_SUBLIST } from "@/containers/Admin/graphql/mutations";
import { GET_CONTROL_LIST } from "@/graphql/queries";
import { useEffect } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { notifyError } from "@/utils/notification";
import fileIcon from "../../../../assets/images/pdf_icon.png";

type Props = {
  taskId: number;
  loadingDoc: boolean;
  handleViewDocument: (path: string) => void;
  companyId: number;
  createdDate: string;
  onRefreshComplete: () => void;
};

function SubscriptionControlList({
  taskId,
  loadingDoc,
  handleViewDocument,
  companyId,
  createdDate,
  onRefreshComplete,
}: Props) {
  const [
    getControlList,
    {
      loading: loadingControlList,
      data: controlListData,
      refetch: refetchControlList,
    },
  ] = useLazyQuery(GET_CONTROL_LIST, {
    variables: {
      renewalTaskId: taskId,
    },
    onError: (err) => {
      notifyError(err);
    },
  });

  const [refreshControlList, { loading: refreshing }] = useMutation(
    REFRESH_SUBLIST,
    {
      fetchPolicy: "no-cache",
      variables: {
        input: {
          companyId,
          taskId,
          date: new Date(createdDate).toISOString(),
        },
      },
      onCompleted: () => {
        refetchControlList();
        if (onRefreshComplete) {
          onRefreshComplete();
        }
      },
      onError: (err) => {
        console.error("`refresh sublist error", err);
        notifyError(err);
      },
    }
  );

  useEffect(() => {
    getControlList();
  }, [taskId]);

  return (
    <div className="flex-column information-block">
      <span>Pro forma Invoice:</span>
      {loadingControlList ? (
        <div>Loading...</div>
      ) : (
        <div style={{ paddingLeft: "15px" }}>
          {controlListData?.getSubscriptionControlList ? (
            <div key={controlListData.getSubscriptionControlList.documentPath}>
              <span>
                <img style={{ height: "25px", width: "25px" }} src={fileIcon} />
                &nbsp; &nbsp;
                {controlListData.getSubscriptionControlList.name.toUpperCase()}
                <Button
                  type="link"
                  loading={loadingDoc}
                  onClick={() =>
                    handleViewDocument(
                      controlListData.getSubscriptionControlList.documentPath
                    )
                  }
                >
                  Download
                </Button>
              </span>
              <br />
            </div>
          ) : (
            <p>Subscription control list not found</p>
          )}
          <Button
            loading={refreshing}
            disabled={loadingControlList || loadingDoc}
            onClick={() => {
              refreshControlList();
            }}
            style={{ marginTop: "10px" }}
          >
            Refresh Pro Forma
          </Button>
        </div>
      )}
    </div>
  );
}

export default SubscriptionControlList;
