import { useEffect, useMemo } from "react";
import { List } from "antd";
import { useQuery } from "@apollo/client";
import { GET_BATCH_REGISTRATION_EMPLOYEES } from "@/graphql/queries";
import { notifyError } from "@/utils/notification";

type Props = {
  batchRegistrationId: number;
  loading?: boolean;
};

const style = {
  margin: "30px",
};

function BatchRegistrationEmployees({
  batchRegistrationId,
  loading: parentLoading,
}: Props) {
  const { loading, error, data, refetch } = useQuery(
    GET_BATCH_REGISTRATION_EMPLOYEES,
    {
      variables: { input: { id: batchRegistrationId } },
    }
  );

  const employees = useMemo(
    () =>
      data?.getEmployeesInBatchRegistration?.map((emp: any, i: number) => ({
        ...emp,
        index: i + 1,
      })),
    [data]
  );

  useEffect(() => {
    if (error) {
      notifyError(error);
    }
  }, [error]);

  useEffect(() => {
    if (!parentLoading) {
      refetch();
    }
  }, [parentLoading]);

  return (
    <List
      header={<h3>Employees in this Batch</h3>}
      bordered={false}
      size="small"
      dataSource={employees}
      rowKey={(item: any) => String(item.id)}
      renderItem={(item: any) => (
        <List.Item>{`${item.index}. ${item.lastName} ${item.firstName} (${item.idNumber})`}</List.Item>
      )}
      loading={loading}
      style={style}
    />
  );
}

export default BatchRegistrationEmployees;
