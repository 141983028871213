import { useState, useEffect } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload, message, Divider, Skeleton, Form } from "antd";
import { useMutation, useLazyQuery } from "@apollo/client";
import {
  GET_PRESIGNED_URL,
  GET_CREDIT_BUREAU_RESULTS,
} from "../../graphql/queries";
import { CREATE_DOCUMENT } from "../../graphql/mutations";
import { uploadToS3 } from "@/utils/uploadToS3";
import { isEmpty } from "lodash";

import { IUserData, IUserDataVars } from "../../interfaces/user";
import { GET_USER } from "../../containers/Admin/graphql/queries";
import { Company } from "@/typings/types";
import { useRegistrationState } from "../../stores/registration";
import { If } from "../../components/If";
import { useUserState } from "@/stores/user";

import { COMPANY_TYPES } from "../../constants";

function DocumentationForm(props: any) {
  const [form] = Form.useForm();
  const {
    setRegistrationState,
    registeringAs,
    registrationCapacity,
    manualRegistrationType,
    registrationType,
    manualRegisterCompanyId,
    companyId: cid,
    manualRegisterId,
    appliedForExemption,
    companyName: cname,
    ...rest
  } = useRegistrationState();

  const { id: uid, idNumber } = useUserState();

  const [file, setFile] = useState<any>();
  const [userCompany, setUserCompany] = useState<Company | undefined>(
    undefined
  );
  const [contentType, setContentType] = useState("");
  const [documentName, setDocumentName] = useState("");
  const [uploading, setUploading] = useState(false);
  const [creditBureauResults, setCreditBureauResults] = useState([]);
  const [documentsToUpload, setDocumentsToUpload] = useState([]);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [submitDocuments] = useMutation(CREATE_DOCUMENT);

  const [currentStep, setCurrentStep] = useState("");

  const isSP = registeringAs === "A Sole Proprietor";
  const isCompany = COMPANY_TYPES.includes(registeringAs);

  const companyId = manualRegistrationType ? manualRegisterCompanyId : cid;

  const userId = manualRegistrationType ? manualRegisterId : uid;

  const _step = isSP ? "step9Saved" : isCompany ? "step7Saved" : "step6Saved";

  const stepSaved = !!rest[_step];

  const [getUser] = useLazyQuery<IUserData, IUserDataVars>(GET_USER, {
    variables: {
      input: {
        id: userId,
      },
    },
    onCompleted: (data) => {
      // set user info from query result
      const employment = data.userData.employment.find(
        ({ company }) => company.id === companyId
      );

      if (employment) {
        setUserCompany(employment.company);
      }
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    setCurrentStep(_step);

    if (registrationCapacity === "Business" && !appliedForExemption) {
      setRegistrationState(_step, true);
    }

    if (registrationCapacity === "Individual" && !stepSaved) {
      getCreditBureauResults();
    }

    if (
      // if company registration and not convicted or applied for exemption
      // no documents are required, set step saved
      (registrationCapacity === "Business" && appliedForExemption) ||
      !isEmpty(creditBureauResults)
    ) {
      setShowSaveButton(true);
    }

    if (isEmpty(creditBureauResults)) {
      setRegistrationState(_step, true);
      setShowSaveButton(false);
    }

    // creditBureauResults.map(([indicator, value]) => {
    //   if (indicator === "isDeceased") {
    //     window.location.replace("/register/fail?origin=eagleEye");
    //   }
    // });
  }, []);

  const step = {
    title: `Step ${isSP ? 9 : isCompany ? 6 : 5}: Documentation`,
    content: "Upload required documents",
  };

  const [getPresignedUrl] = useLazyQuery(GET_PRESIGNED_URL, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (!isEmpty(data.preSignedUrl)) {
        const docList = documentsToUpload;
        docList.push({
          documentName,
          preSignedUrl: data.preSignedUrl,
          file,
          contentType,
        });
        setDocumentsToUpload(docList);
      }
    },
  });

  const [getCreditBureauResults, { loading }] = useLazyQuery(
    GET_CREDIT_BUREAU_RESULTS,
    {
      fetchPolicy: "network-only",
      variables: {
        userId,
      },
      onError: () => {
        if (!appliedForExemption) {
          setRegistrationState(currentStep as any, true);
        }
      },
      onCompleted: (data) => {
        if (data.eagleEyeValidation) {
          setShowSaveButton(true);
          setCreditBureauResults(Object.entries(data.eagleEyeValidation));
        } else {
          if (!appliedForExemption) {
            setRegistrationState(currentStep as any, true);
          }
        }
      },
    }
  );

  const customRequest = async (option) => {
    const { file, onSuccess } = option;
    const companyName =
      registrationCapacity === "Individual"
        ? userCompany?.registeredName || userCompany?.tradingName
        : cname;

    setFile(file);
    setContentType(file.type);
    getPresignedUrl({
      variables: {
        input: {
          filePath:
            registrationCapacity === "Business"
              ? `${companyId}/${documentName}`
              : `${idNumber}/${documentName}`,
          companyName,
          contentType: file.type,
          forCompany: registrationCapacity === "Business",
          userId,
        },
      },
    });

    setTimeout(() => {
      onSuccess();
      setShowSaveButton(true);
    }, 3500);
  };

  const uploadDocuments = async (values: any) => {
    try {
      // upload to s3
      setUploading(true);
      await Promise.all(
        documentsToUpload.map(async (doc) => {
          await uploadToS3(doc.preSignedUrl, doc.contentType, doc.file);

          await submitDocuments({
            variables: {
              input: {
                documentName: doc.documentName,
                documentTypeId: 1,
                documentStatusId: 1,
                standardDescription: doc.documentName,
                altDescription: "",
                documentFormat: doc.contentType,
                companyId: companyId,
                userId: userId,
                forCompany: registrationCapacity === "Business",
              },
            },
          });
          message.success(`${file.name} file uploaded successfully`);
        })
      );
      setUploading(false);
      setRegistrationState(currentStep as any, true);
    } catch (error) {
      console.log(error);
      setUploading(false);
      message.error(`${file.name} file upload failed.`);
    }
  };

  if (loading) {
    return (
      <>
        <Skeleton active paragraph={{ rows: 3 }} />
        <Skeleton active paragraph={{ rows: 3 }} />
      </>
    );
  }

  return (
    <div className="flex-column">
      <div>
        <span className="step-title">{step.title}</span>
        <p>{step.content}</p>
        <Divider />
      </div>

      <Form form={form}>
        {appliedForExemption && !stepSaved && (
          <div className="flex-column input-block input-select">
            <span>
              <label>
                We require the following documents from you based on the
                prequalification question:{" "}
              </label>
            </span>
            <br />
            <br />
            <span>
              1.1 Has application for exemption in terms of section 10(2) been
              lodged with Ministry of Justice and Constitutional Development
            </span>

            <br />
            <br />
            <label>Proof of Exemption</label>
            <Form.Item
              {...{
                name: "proofOfExemption",
                rules: [
                  {
                    required: true,
                    message: "Please indicate",
                  },
                ],
              }}
            >
              <Upload
                customRequest={customRequest}
                style={{ display: "flex" }}
                disabled={uploading}
              >
                <Button onClick={() => setDocumentName(`Proof_of_Exemption`)}>
                  <UploadOutlined />
                  <span>Upload</span>
                </Button>
              </Upload>
            </Form.Item>
          </div>
        )}
        {(stepSaved || !showSaveButton) && (
          <div className="flex-column input-block input-select">
            <span>
              <label>
                No documents are required this time. You may continue to the
                next step
              </label>
            </span>
          </div>
        )}
        {registrationCapacity !== "Business" &&
          !isEmpty(creditBureauResults) && (
            <div className="flex-column input-block input-select">
              <span>
                <label>
                  Based on Credit Bureau results, the following indicators are
                  reflected, please provide proof where applicable
                </label>
                <br />
                <br />
              </span>
              {!isEmpty(creditBureauResults) &&
                creditBureauResults.map(([indicator, value]) => {
                  if (
                    (value &&
                      ![
                        "__typename",
                        "isDeceased",
                        "idPassesCDV",
                        "nameMatches",
                        "hasSAFPS",
                        "hasSAFPSPR",
                      ].includes(indicator)) ||
                    (!value &&
                      ["idPassesCDV", "nameMatches"].includes(indicator))
                  )
                    return (
                      <div className="flex-column input-block input-select">
                        <label>
                          {indicator === "underDebtReview"
                            ? "Debt Review"
                            : indicator === "hasDefault"
                            ? "Arrears registered"
                            : indicator === "hasJudgment"
                            ? "Judgement by Court Order"
                            : indicator === "isIndigent"
                            ? "isIndigent"
                            : indicator === "hasTraceLocator"
                            ? "hasTraceLocator"
                            : indicator}
                        </label>
                        <Form.Item
                          {...{
                            name: indicator,
                            rules: [
                              {
                                required: true,
                                message: "Please upload",
                              },
                            ],
                          }}
                        >
                          <Upload
                            customRequest={customRequest}
                            style={{ display: "flex" }}
                            disabled={uploading}
                          >
                            <Button
                              onClick={() =>
                                setDocumentName(`${indicator}_Proof`)
                              }
                            >
                              <UploadOutlined />
                              <span>Upload</span>
                            </Button>
                          </Upload>
                        </Form.Item>
                      </div>
                    );
                  return <></>;
                })}
            </div>
          )}

        <If condition={showSaveButton}>
          <div className="flex-column input-block">
            <Button
              className="btn-registration-capacity-selected"
              onClick={uploadDocuments}
              loading={uploading}
            >
              Save documents
            </Button>
          </div>
        </If>
      </Form>
    </div>
  );
}

export default DocumentationForm;
