import { isLoggedIn } from "@/utils";
import { LoaderFunctionArgs, redirect } from "react-router-dom";

export function protectedLoader({ request }: LoaderFunctionArgs) {
  const loggedIn = isLoggedIn();

  if (!loggedIn) {
    const pathname = new URL(request.url).pathname;

    if (pathname === "/") {
      return redirect("/login");
    }

    let params = new URLSearchParams();
    params.set("from", pathname);

    return redirect("/login?" + params.toString());
  }

  return null;
}

export function loginLoader() {
  if (isLoggedIn()) {
    return redirect("/");
  }

  return null;
}
