import {
  useState,
  useMemo,
  useImperativeHandle,
  forwardRef,
  Ref,
  useEffect,
} from "react";
import { Card, Table, Empty, Tag } from "antd";
import { useLazyQuery } from "@apollo/client";
import {
  GET_REPORTS,
  DOWNLOAD_REPORTS,
  GET_FUTURE_CONTROL_LIST_REPORT,
} from "../../graphql/queries";
import { TableLoader } from "../../../../components/UserManagement/TableContentPlaceholder";
import { notifyError, notifyInfo } from "@/utils/notification";
import { writeCSV } from "@/utils";
import { useNavigate } from "react-router-dom";
import { useReportingFilters } from "../useReportingFilters";
import { ReportHandle } from "../ReportingTypes";
import { format } from "date-fns";
import FutureControlListTable from "../../Reports/FutureControlListTable";

function _SubscriptionControlList(_: unknown, ref: Ref<ReportHandle>) {
  const navigate = useNavigate();
  const [reportList, setReportList] = useState([]);
  const [listTotal, setListTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const {
    pageNumber,
    pageSize,
    skip,
    type,
    companyIds,
    months,
    setFilterState,
  } = useReportingFilters();

  const handleError = (error: any) => {
    setLoading(false);
    setDownloading(false);
    notifyError(error);
  };

  const isCurrent = useMemo(() => type === "subscription-control-list", [type]);

  const [getReports] = useLazyQuery(GET_REPORTS, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getReports }) => {
      if (getReports?.reports) setReportList(getReports.reports);
      if (getReports?.total) setListTotal(getReports.total);
      if (getReports?.reportString) {
        const data = JSON.parse(getReports.reportString);
        setReportList(data);
      }
      setLoading(false);
    },
    onError: handleError,
  });

  const [getFutureControlList] = useLazyQuery(GET_FUTURE_CONTROL_LIST_REPORT, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getFutureControlListReport }) => {
      const _reports = getFutureControlListReport
        ? JSON.parse(getFutureControlListReport)
        : null;

      if (_reports) setReportList(_reports);
      if (_reports?.length) {
        setListTotal(_reports.length);
      } else {
        notifyInfo(
          "There are no certificates expiring on the specified months."
        );
      }
      setLoading(false);
    },
    onError: handleError,
  });

  const [downloadReports] = useLazyQuery(DOWNLOAD_REPORTS, {
    onCompleted: ({ getReports }) => {
      const data = getReports.reports.map((report, i) => {
        return {
          "#": i + 1,
          Company: report.company.registeredName,
          "#Employees": report.employees,
          "#Related Parties": report.relatedParties,
          "#Entity": report.entity,
          [isCurrent ? "Task ID" : "Amount"]: isCurrent
            ? report.renewalTask?.id
            : report.total,
        };
      });

      writeCSV(
        type
          .split("-")
          .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
          .join(" "),
        type,
        data
      );
      setDownloading(false);
    },
    onError: handleError,
  });

  const runQuery = async () => {
    setLoading(true);

    if (isCurrent) {
      getReports({
        variables: {
          input: {
            company: companyIds?.map((id) => +id),
            type: "Subscription Control List",
            month: months?.[0],
            includeTotal: true,
            sort: "DESC",
            skip,
            take: pageSize,
          },
        },
      });
    } else {
      if (!months?.length || !companyIds?.length) {
        setLoading(false);
        return;
      }

      getFutureControlList({
        variables: {
          input: {
            company: companyIds?.map((id) => +id),
            months,
          },
        },
      });
    }
  };

  const downloadCsv = async () => {
    setDownloading(true);
    if (isCurrent) {
      downloadReports({
        variables: {
          input: {
            company: companyIds?.map((id) => +id),
            month: months?.[0],
            includeTotal: true,
            sort: "DESC",
            take: Math.min(listTotal, 500),
            type: "Subscription Control List",
          },
        },
      });
    } else {
      writeCSV(
        "Future Subscription Control List",
        "future-subscription-control-list",
        reportList.map((r, i) => {
          return {
            Company: r.company.registeredName,
            "#Employees": r.members,
            "#Related Parties": r.directors,
            "#Entity": r.entity,
          };
        })
      );
      setDownloading(false);
    }
  };

  const columns = useMemo<any[]>(() => {
    let reportColumns: any[] = [
      {
        title: "Month",
        key: "month",
        render: ({ date }) =>
          date ? format(new Date(date), "MMM yyyy").toUpperCase() : "",
      },
      {
        title: "Company",
        key: "company",
        render: ({ company }) =>
          (company?.registeredName || company?.tradingName).toUpperCase(),
      },
      {
        title: "#Employees",
        render: ({ employees }) => employees,
      },
      {
        title: "#Related Parties",
        render: ({ relatedParties }) => relatedParties,
      },
      {
        title: "#Entity",
        render: ({ entity }) => entity,
      },
      {
        title: isCurrent ? "Task ID" : "Amount",
        render: ({ renewalTask, total }) => {
          return isCurrent ? renewalTask?.id : total;
        },
      },
      {
        title: <span>Task Status</span>,
        key: "taskStatus",
        render: ({ renewalTask }) => {
          const taskStatus = renewalTask?.taskStatus;
          if (!taskStatus) return "";

          let status;

          if (["Resolved", "Complete"].includes(taskStatus)) status = "success";
          else if (
            ["Awaiting verification", "Awaiting Payment"].includes(taskStatus)
          )
            status = "warning";
          else if (taskStatus === "Failed") status = "error";

          return (
            <span>
              <Tag color={status}> {taskStatus}</Tag>
            </span>
          );
        },
      },
      {
        title: <span>Action</span>,
        key: "action",
        render: ({ renewalTask, company }) => (
          <a
            className="purple-link"
            onClick={(e) => {
              e.preventDefault();
              if (renewalTask) {
                navigate("/admin/tasks/review/renewal", {
                  state: { ...renewalTask, company },
                });
              }
            }}
          >
            View
          </a>
        ),
      },
    ];

    return reportColumns;
  }, []);

  useImperativeHandle(ref, () => ({
    run: runQuery,
    download: downloadCsv,
    running: loading,
    downloading,
    resetData: () => {
      setReportList([]);
      setListTotal(0);
    },
  }));

  useEffect(() => {
    runQuery();
  }, [pageNumber]);

  if (loading) {
    return <TableLoader />;
  }

  if (reportList.length === 0) {
    return <Empty description="Use the filters above to generate a report." />;
  }

  return (
    <div className="">
      <Card>
        {isCurrent ? (
          <Table
            size="small"
            rowKey="id"
            columns={columns}
            pagination={{
              current: pageNumber,
              showSizeChanger: false,
              hideOnSinglePage: true,
              pageSize,
              onChange: (page) => setFilterState({ pageNumber: page }),
              total: listTotal,
              showTotal: () => <h3>Total: {listTotal}</h3>,
            }}
            dataSource={reportList || []}
          />
        ) : (
          <FutureControlListTable
            reportList={reportList}
            currentPage={pageNumber}
            pageSize={pageSize}
            listTotal={listTotal}
          />
        )}
      </Card>
    </div>
  );
}

export const SubscriptionControlList = forwardRef(_SubscriptionControlList);
