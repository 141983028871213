import { useState, useEffect, useRef } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Card, Table, Button, Spin, Divider, Form } from "antd";
import moment from "moment";
import { useLazyQuery, useMutation } from "@apollo/client";
import { openNotificationWithIcon } from "@/utils/notification";
import { GET_MY_FILTERED_TASK_LIST } from "../../../graphql/queries";
import { GET_USER } from "../graphql/queries";
import { CREATE_TASK } from "../../../graphql/mutations";
import TaskFilters from "./filters";
import { TableLoader } from "../../../components/UserManagement/TableContentPlaceholder";
import InternalErrorPage from "../../InternalErrorPage";
import CreateTaskForm from "./createTask";
import PermissionsGuard from "../../../components/Auth/can";
import EmptyState from "../../../components/Styled/EmptyState";
import {
  ADMIN_STREAM,
  FINANCE_STREAM,
  LEGAL_STREAM,
  MEMBER_STREAM,
  SUPER_USER_STREAM,
  TASK_PAGE_SIZE,
} from "../../../constants";
import { useFilterState } from "@/contexts/AdminFilterContext";
import { formatFilters } from "../../../utils";
import { useNavigate } from "react-router-dom";

interface IProps {
  memberId?: number;
  companyId?: number;
  mode: string;
}

function ComplaintTasksList({ memberId, companyId, mode }: IProps) {
  const navigate = useNavigate();
  const [taskList, setTaskList] = useState([]);
  const [listTotal, setListTotal] = useState(0);
  const [showEmptyState, setShowEmptyState] = useState(true);
  const [initialising, setInitialising] = useState(true);
  const [showSpin, setShowSpin] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [createTaskModalVisible, setCreateTaskModalVisible] = useState(false);
  const [createTaskForm] = Form.useForm();
  const [roleStream, setRoleStream] = useState("");

  const {
    filters: { filter, savedSkip, savedCurrentPage },
    savePageNumber,
    filterReset,
  } = useFilterState();

  const filterData = { ...filter, taskTypeId: 4 };

  const [useMode] = useState(mode);
  const [createTask] = useMutation(CREATE_TASK);
  const pageSize = 10;

  const filtersRef = useRef();

  const clearFilters = () => {
    if (filtersRef && filtersRef.current) {
      // @ts-ignore: Object is possibly undefined
      filtersRef.current.resetFilters();
      filterReset();
      runMultiFilterTaskQuery();
    }
  };

  const [getUserData] = useLazyQuery(GET_USER);

  const [getFilteredTaskList] = useLazyQuery(GET_MY_FILTERED_TASK_LIST, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setTaskList(data.tasks.taskList);
      if (data.tasks.total) {
        setListTotal(data.tasks.total);
        if (showEmptyState) setShowEmptyState(false);
      }
      setLoading(false);
      setInitialising(false);
      setShowSpin(false);
    },
    onError: (error) => {
      setError(error.message);
    },
  });

  const runMultiFilterTaskQuery = () => {
    setShowSpin(true);
    setLoading(true);

    getFilteredTaskList({
      variables: {
        input: {
          ...formatFilters(filterData),
          includeTotal: true,
          skip: savedSkip,
          take: TASK_PAGE_SIZE,
        },
      },
    });
  };

  useEffect(() => {
    setRoleStream(localStorage.getItem("roleStream"));
  }, []);

  useEffect(() => {
    runMultiFilterTaskQuery();
  }, []);

  useEffect(() => {
    runMultiFilterTaskQuery();
  }, [savedCurrentPage, savedSkip, JSON.stringify(filter)]);

  const columns = [
    {
      title: "Task ID",
      render: (record) => record.id,
      key: "id",
    },
    {
      title: "Complainant",
      key: "complainant",
      render: ({ complaint }: any) => {
        if (complaint) {
          const { firstName, lastName } = complaint;
          return `${lastName.toUpperCase()} ${firstName.toUpperCase()}`;
        } else {
          return "";
        }
      },
    },
    {
      title: "Company",
      key: "company",
      render: (record: any) => {
        return record?.company?.registeredName || "";
      },
    },
    {
      title: "Created",
      key: "createdDate",
      render: (record) =>
        record.createdDate
          ? moment(record.createdDate).format("YYYY-MM-DD")
          : "",
    },
    {
      title: "Reminder",
      key: "reminderDate",
      render: (record) =>
        record ? moment(record.reminderDate).format("YYYY-MM-DD") : "N/A",
    },
    {
      title: "Status",
      key: "status",
      render: (record) => {
        return record.taskStatus ? (
          <span style={{ display: "flex" }}>{record.taskStatus}</span>
        ) : (
          ""
        );
      },
    },
    {
      title: <span>Action</span>,
      key: "action",
      render: (record) => {
        if (useMode === "my-tasks") {
          return (
            <PermissionsGuard
              perform={"complaint:review"}
              yes={() => (
                <a
                  className="ant-dropdown-link purple-link"
                  onClick={() => {
                    navigate(`/admin/tasks/${record.id}`, {
                      state: { ...record },
                    });
                  }}
                >
                  View
                </a>
              )}
            />
          );
        } else {
          return (
            <a
              className="ant-dropdown-link purple-link"
              onClick={(e) => e.preventDefault()}
            >
              View
            </a>
          );
        }
      },
    },
  ];

  const handleCreateTask = async () => {
    const values = await createTaskForm.validateFields().catch(() => {});
    if (!values) return;

    if (values.userIdNumber) {
      const userData = await getUserData({
        query: GET_USER,
        variables: { input: { idNumber: values.userIdNumber } },
      });
      if (
        !userData ||
        !userData.data ||
        !userData.data.userData.registrationCapacity
      ) {
        return openNotificationWithIcon(
          "error",
          "Task creation error",
          "Incorrect User ID Provided!"
        );
      }
      let { registrationCapacity, registeredAs, id, employedBy } =
        userData.data.userData;
      setShowSpin(true);
      const isIndividual =
        registrationCapacity === "Individual" &&
        registeredAs === "Employee of a Business";
      const isCompany =
        registrationCapacity === "Business" && registeredAs === "Company";
      //submit data
      createTask({
        variables: {
          input: {
            taskType: values.taskType,
            priorityStatus: "",
            companyId: isCompany ? employedBy.id : undefined,
            employeeId: isIndividual ? id : undefined,
          },
        },
      })
        .then(() => {
          setShowSpin(false);
          openNotificationWithIcon(
            "success",
            "Task creation success",
            "Task created successfully"
          );

          createTaskForm.resetFields();
          setCreateTaskModalVisible(false);
        })
        .catch((error) => {
          setShowSpin(false);
          openNotificationWithIcon(
            "error",
            "Task creation error",
            error.message
          );
        });
    }
  };

  const CreateTaskModalProps = {
    form: createTaskForm,
    visible: createTaskModalVisible,
    onCancel: () => setCreateTaskModalVisible(false),
    onCreate: () => handleCreateTask(),
    initialData: false,
  };

  if (error) {
    return <InternalErrorPage />;
  }

  return (
    <Spin tip="Loading..." className="loader" spinning={showSpin}>
      {initialising && <TableLoader />}
      {!initialising && showEmptyState && (
        <EmptyState
          location="tasks"
          headingText="Nothing to see here"
          bodyText="You currently have no incoming tickets. You can create a ticket to our support team to help with any problems you may be facing."
        >
          {![
            ADMIN_STREAM,
            FINANCE_STREAM,
            LEGAL_STREAM,
            MEMBER_STREAM,
            SUPER_USER_STREAM,
          ].includes(roleStream) && (
            <p>
              <Button
                className="purple-button"
                onClick={() => setCreateTaskModalVisible(true)}
              >
                <PlusOutlined title="Create new task" />
                Create new task
              </Button>
            </p>
          )}
        </EmptyState>
      )}
      <CreateTaskForm {...CreateTaskModalProps} />
      {!showEmptyState && (
        <>
          <div className="col-sm-12 col-md-12 placeholder-table-card">
            <Card>
              <div className="card-header">
                {useMode === "my-tasks" ? (
                  <h3>Complaint Tasks</h3>
                ) : (
                  <h3>Tasks</h3>
                )}
                <div>
                  <Button
                    style={{ marginRight: "20px" }}
                    className="purple-button"
                    onClick={clearFilters}
                  >
                    Clear filters
                  </Button>
                  {["my-tasks", "member-overview-tasks"].indexOf(mode) > -1 &&
                    ![
                      ADMIN_STREAM,
                      FINANCE_STREAM,
                      LEGAL_STREAM,
                      MEMBER_STREAM,
                      SUPER_USER_STREAM,
                    ].includes(roleStream) && (
                      <Button
                        className="purple-button"
                        onClick={() => setCreateTaskModalVisible(true)}
                      >
                        <PlusOutlined title="Create new task" />
                        Create new task
                      </Button>
                    )}
                </div>
              </div>
              <div className={loading ? "no-interact" : null}>
                {!initialising && (
                  <TaskFilters
                    ref={filtersRef}
                    defaultMultiFilter={filterData}
                    mode={useMode}
                  />
                )}
                <Divider />
                <Table
                  rowKey={(record) => record.id}
                  columns={columns}
                  size="small"
                  pagination={{
                    current: savedCurrentPage,
                    showSizeChanger: false,
                    pageSize,
                    onChange: savePageNumber,
                    total: listTotal,
                    showTotal: () => <h3>Total: {listTotal}</h3>,
                  }}
                  dataSource={taskList}
                />
              </div>
            </Card>
          </div>
        </>
      )}
    </Spin>
  );
}

export default ComplaintTasksList;
