import { useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_USER } from "../graphql/queries";
import { isEmpty } from "lodash";
import ResultComment from "./resultComment";
import InformationLoader from "./informationLoader";
import "./index.css";
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';

const ReviewAuthenticationInformation: React.FC<any> = ({
  transaction,
  reviewInfo,
  registrationTaskId,
}) => {
  const { company, type, employee } = transaction;

  const email =
    type.name === "Employee" ? employee.email : company.createdBy?.email;
  const [userInfo, setUserInfo] = useState<any>();

  const { loading } = useQuery(GET_USER, {
    skip: isEmpty(email),
    variables: {
      input: { email },
    },
    onCompleted: (data) => {
      setUserInfo(data.userData);
    },
  });

  const ResultComponentsProps = {
    ...transaction,
    step: "Authentication",
    reviewInfo,
    registrationTaskId,
  };

  if (loading) return <InformationLoader />;
  return <>
    <div className="flex-column information-block">
      <br />
      <span>
        {userInfo?.otpConfirmed ? (
          <>
            <span>
              OTP Confirmed{" "}
              <CheckCircleTwoTone twoToneColor="#52c41a" />
            </span>
          </>
        ) : (
          <>
            <span>
              OTP Not Confirmed{" "}
              <CloseCircleTwoTone twoToneColor="#f5222d" />
            </span>
          </>
        )}{" "}
        <label>(Resent {userInfo?.otpRetries || 0} times)</label>
      </span>
    </div>
    <br />
    <ResultComment {...ResultComponentsProps} />
  </>;
};
export default ReviewAuthenticationInformation;
