import { useQuery } from "@apollo/client";
import {
  Button,
  Card,
  Col,
  Row,
  Skeleton,
  Space,
  Table,
  Typography,
} from "antd";
import { GET_TOP_20_COLLECTORS } from "@/containers/Admin/graphql/queries";
import {
  Ref,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  forwardRef,
} from "react";
import { notifyError } from "@/utils/notification";
import { FilePdfOutlined } from "@ant-design/icons";
import { jsPDF } from "jspdf";
import { format } from "date-fns";
import { writeCSV } from "@/utils";
import { ReportHandle } from "../ReportingTypes";

const A4_WIDTH = 794;

function _Top20Collectors(_: unknown, ref: Ref<ReportHandle>) {
  const [_reportData, setReportData] = useState<any>();
  const pageRef = useRef(null);
  const [downloading, setDownloading] = useState(false);
  const { loading, error, data } = useQuery(GET_TOP_20_COLLECTORS);

  const runQuery = async () => {};

  const downloadCsv = async () => {};

  const downloadPdf = async () => {};

  useImperativeHandle(ref, () => ({
    run: runQuery,
    download: downloadCsv,
    downloadPdf,
    running: loading,
    downloading,
    resetData: () => {
      setReportData(undefined);
    },
  }));

  useEffect(() => {
    if (error) notifyError(error);
  }, [error]);

  if (loading || !data) {
    return (
      <Card>
        <Skeleton active />
        <Space>
          <Skeleton active />
          <Skeleton active />{" "}
        </Space>
      </Card>
    );
  }

  return (
    <Card style={{ maxWidth: A4_WIDTH, margin: "0 auto" }}>
      <div ref={pageRef} className="mb-16">
        <div className="text-center mb-5">
          <Typography.Title level={4}>
            COUNCIL FOR DEBT COLLECTORS
          </Typography.Title>
          <Typography.Title className="mt-1" level={4}>
            Top 20 Debt Collectors
          </Typography.Title>
        </div>

        <Typography.Paragraph className="mb-3">
          Date: <b>{format(new Date(), "dd MMMM yyyy")}</b>
        </Typography.Paragraph>

        <Table
          dataSource={data.getTopCollectors || []}
          rowKey={(record) => record.companyId}
          columns={[
            {
              title: "Company Name",
              dataIndex: "registeredName",
              key: "registeredName",
            },
            {
              title: "Number of Collectors",
              dataIndex: "numberOfEmployees",
              key: "numberOfEmployees",
            },
          ]}
          size="small"
          pagination={false}
        />
      </div>
      <Row justify={"start"}>
        <Col>
          <Button
            icon={<FilePdfOutlined />}
            className="purple-button"
            loading={downloading}
            disabled={downloading}
            onClick={() => {
              setDownloading(true);
              if (pageRef.current) {
                const pdf = new jsPDF({
                  orientation: "p",
                  unit: "pt",
                  format: "a4",
                });

                pdf.html(pageRef.current, {
                  callback: async () => {
                    pdf
                      .save(`top-20-collectors.pdf`, { returnPromise: true })
                      .catch(notifyError)
                      .finally(() => {
                        setDownloading(false);
                      });
                  },
                  x: 10,
                  y: 10,
                  html2canvas: {
                    scale: 0.7,
                  },
                });
              }
            }}
          >
            Download PDF
          </Button>
          <Button
            type="primary"
            className="ml-2"
            onClick={() => {
              writeCSV(
                "Top 20 Collectors",
                "top-20-collectors.csv",
                data.getTopCollectors.map((collector: any) => ({
                  "Company Name": collector.registeredName,
                  "Number of Collectors": collector.numberOfEmployees,
                }))
              );
            }}
          >
            Download CSV
          </Button>
        </Col>
      </Row>
    </Card>
  );
}

export const Top20Collectors = forwardRef(_Top20Collectors);
