import { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_REPORTS } from "../../graphql/queries";

import { notifyError } from "@/utils/notification";
import { writeCSV } from "@/utils";
import { ReportHandle } from "../ReportingTypes";
import { useReportingFilters } from "../useReportingFilters";
import { TableLoader } from "@/components/UserManagement/TableContentPlaceholder";
import { Card } from "antd";
import AuditCertSubmissionTable from "../../Reports/AuditCertSubmissionTable";

const currentYear = new Date().getFullYear();

export function AuditCertificateSubmissionComponent(
  _: unknown,
  ref: React.Ref<ReportHandle>
) {
  const [listTotal, setListTotal] = useState(0);
  const [reportList, setReportList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const { pageSize, pageNumber, setFilterState, skip, from, to, companyIds } =
    useReportingFilters();

  const handleError = (error: any) => {
    setLoading(false);
    setDownloading(false);
    notifyError(error);
  };

  const [getReports] = useLazyQuery(GET_REPORTS, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getReports }) => {
      if (getReports?.reports) setReportList(getReports.reports);
      if (getReports?.total) setListTotal(getReports.total);
      if (getReports?.reportString) {
        const data = JSON.parse(getReports.reportString);
        setReportList(data);
      }

      setLoading(false);
    },
    onError: handleError,
  });

  const runQuery = async () => {
    if (!from || !to || !companyIds?.length) {
      notifyError("Please select a company and date range");
      return;
    }
    setLoading(true);

    getReports({
      variables: {
        input: {
          company: companyIds.map((id) => +id),
          from: +from,
          to: +to,
          includeTotal: skip === 0 ? true : false,
          skip: skip,
          take: pageSize,
          sort: "DESC",
          type: "Audit Certificate Submissions",
        },
      },
    });
  };

  const downloadCsv = async () => {
    try {
      setDownloading(true);

      const csvData = reportList.map((r) => {
        const x = {};

        x["Company ID"] = r.company.id;
        x["Company"] = r.company.registeredName || r.company.registeredName;

        for (let i = +from; i <= +to; i++) {
          x[i] = r[i] ?? "NO";
        }

        return x;
      });

      const fileName = `audit-certificate-submission`;
      return writeCSV(fileName, fileName, csvData);
    } catch (err) {
      notifyError(err);
    } finally {
      setDownloading(false);
    }
  };

  useEffect(() => {
    if (!from) setFilterState({ from: currentYear - 1 });
    if (!to) setFilterState({ to: currentYear });
  }, [from, to]);

  useImperativeHandle(ref, () => ({
    run: runQuery,
    download: downloadCsv,
    running: loading,
    downloading,
    resetData: () => {
      setReportList([]);
      setListTotal(0);
    },
  }));

  useEffect(() => {
    runQuery();
  }, [pageNumber]);

  if (loading) {
    return <TableLoader />;
  }

  return (
    <div className="">
      <Card>
        <AuditCertSubmissionTable
          yearRange={[+from, +to]}
          auditSubmissionList={reportList}
          currentPage={pageNumber}
          pageSize={pageSize}
          listTotal={listTotal}
          handlePagination={(page) => setFilterState({ pageNumber: page })}
        />
      </Card>
    </div>
  );
}

export const AuditCertificateSubmission = forwardRef(
  AuditCertificateSubmissionComponent
);
