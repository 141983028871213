import MemberList from "../../../components/UserManagement/ListUser";
import PermissionsGuard from "../../../components/Auth/can";
import AccessDenied from "../../../components/Auth/accessDenied";

function MemberManagement() {
  return (
    <PermissionsGuard
      perform={"members:view"}
      yes={() => <MemberList />}
      no={() => <AccessDenied permission="members:view" />}
    />
  );
}

export default MemberManagement;
