import React, { useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Card, Table, Input, Spin, Button, Form } from "antd";
import { isEmpty } from "lodash";
import { useQuery, useMutation } from "@apollo/client";
import { GET_PERMISSIONS } from "../../containers/Admin/graphql/queries";
import {
  UPDATE_PERMISSIONS,
  CREATE_PERMISSIONS,
  DELETE_PERMISSIONS,
} from "../../containers/Admin/graphql/mutations";
import { openNotificationWithIcon } from "@/utils/notification";
import EditPermissionsForm from "./EditPermissions";
import { TableLoader } from "./TableContentPlaceholder";
import InternalErrorPage from "../../containers/InternalErrorPage";
import PermissionsGuard from "../../components/Auth/can";
import AccessDenied from "../../components/Auth/accessDenied";
import { CSVLink } from "react-csv";

function PermissionsList() {
  const [filterDropdownVisible, setFilterDropdownVisible] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [permissionsList, setPermissionsList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [saveForm] = Form.useForm();
  const [selectedPermissions, setSelectedPermissions] = useState();
  const [updatePermissions] = useMutation(UPDATE_PERMISSIONS);
  const [deletePermission] = useMutation(DELETE_PERMISSIONS);
  const [createPermissions] = useMutation(CREATE_PERMISSIONS);
  const [showSpin, setShowSpin] = useState(false);
  const [constantPermissionsList, setConstantPermissionsList] = useState([]);
  const permissions = localStorage.getItem("userPermissions");

  React.useEffect(() => {
    if (permissionsList) {
      if (!isEmpty(searchText)) {
        const reg = new RegExp(searchText, "gi");
        const filteredPermissionsList = permissionsList.filter((record: any) =>
          record.name.match(reg)
        );

        setPermissionsList([...filteredPermissionsList]);
      } else setPermissionsList([...constantPermissionsList]);
    }
  }, [searchText]);

  const onInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const _deletePermission = (id) => {
    setShowSpin(true);
    deletePermission({ variables: { id } })
      .then(() => {
        refetch().then((res) => setPermissionsList(res.data.permissionsList)); // refetch updated permissions list
        setShowSpin(false);
        openNotificationWithIcon(
          "success",
          "Delete operation success ",
          "Permission deleted successfully"
        );
      })
      .catch((error) => {
        setShowSpin(false);
        openNotificationWithIcon(
          "error",
          "Delete operation error ",
          error.message
        );
      });
  };
  const columns = [
    {
      title: "Name",
      render: (record) => record.name,
      key: "name",
      filterDropdown: (
        <div className="custom-filter-dropdown">
          <Input
            placeholder="Search name"
            value={searchText}
            onChange={onInputChange}
          />
        </div>
      ),
      filterDropdownVisible: filterDropdownVisible,
      onFilterDropdownVisibleChange: (visible) =>
        setFilterDropdownVisible(visible),
    },
    {
      title: "Description",
      render: (record) => record.description,
      key: "description",
    },

    {
      title: <span>Action</span>,
      key: "id",
      render: (record) => (
        <div>
          <PermissionsGuard
            permissions={permissions}
            perform={"permissions:edit"}
            yes={() => (
              <EditOutlined title="Edit" onClick={() => showModal(record)} />
            )}
          />
          {"  "}
          <PermissionsGuard
            permissions={permissions}
            perform={"permissions:delete"}
            yes={() => (
              <DeleteOutlined
                title="Delete"
                onClick={() => _deletePermission(record.id)}
              />
            )}
          />
        </div>
      ),
    },
  ];

  const showModal = (e) => {
    setSelectedPermissions(e);
    setModalVisible(true);
  };

  const handleCreate = async () => {
    const values = await saveForm.validateFields().catch(() => {});

    if (!values) return;

    setShowSpin(true);
    const createUpdateMutation = values.id
      ? updatePermissions
      : createPermissions;
    //submit data
    createUpdateMutation({
      variables: {
        id: values.id,
        name: values.name,
        description: values.description,
        permissions: values.permissions,
        userRole: values.userRole,
      },
    })
      .then(() => {
        refetch().then((res) => setPermissionsList(res.data.permissionsList)); // refetch updated permissions list
        setShowSpin(false);
        openNotificationWithIcon(
          "success",
          "Update success ",
          "Permission updated successfully"
        );
      })
      .catch((error) => {
        setShowSpin(false);
        openNotificationWithIcon("error", "Update Error ", error.message);
      });

    saveForm.resetFields();
    setModalVisible(false);
  };

  const ModalProps = {
    form: saveForm,
    visible: modalVisible,
    onCancel: () => setModalVisible(false),
    onCreate: () => handleCreate(),
    initialData: selectedPermissions,
  };

  const { loading, error, refetch } = useQuery(GET_PERMISSIONS, {
    onCompleted: (data) => {
      setPermissionsList(data.permissionsList);
      setConstantPermissionsList(data.permissionsList);
    },
  });

  if (loading) return <TableLoader />;
  if (error)
    return (
      <>
        <InternalErrorPage />
      </>
    );

  return (
    <PermissionsGuard
      permissions={permissions}
      perform={"permissions:list"}
      yes={() => (
        <>
          <Spin
            tip="Loading..."
            className="loader"
            style={{ display: showSpin ? "block" : "none" }}
          />
          <div className="col-sm-12 col-md-12 placeholder-table-card">
            <Card style={{ height: "14vh" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "15px",
                }}
              >
                <Card.Meta
                  title={"Permissions Management"}
                  description={
                    <p style={{ height: "fit-content" }}>
                      Manage Councilsmart user roles' permissions
                    </p>
                  }
                />
                <PermissionsGuard
                  permissions={permissions}
                  perform={"permissions:create"}
                  yes={() => (
                    <Button
                      type="primary"
                      style={{ alignSelf: "auto" }}
                      onClick={() => showModal(undefined)}
                    >
                      Add a Permission
                    </Button>
                  )}
                />
              </div>
            </Card>
          </div>
          <div className="col-sm-12 col-md-12 placeholder-table-card">
            <Card>
              <Table
                columns={columns}
                pagination={{ pageSize: 10, showSizeChanger: false }}
                dataSource={permissionsList}
                rowKey={(record) => record.id}
              />
              <Button
                type="primary"
                style={{ position: "relative", float: "right" }}
              >
                <CSVLink data={permissionsList}>Download</CSVLink>
              </Button>
            </Card>
          </div>
          <EditPermissionsForm {...ModalProps} />
        </>
      )}
      no={() => <AccessDenied permission={"permissions:create"} />}
    />
  );
}

export default PermissionsList;
