import { useCallback, useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Filters } from "@/containers/Admin/PendingApplications/PendingApplicationsFilters";

const PAGE_SIZE = 20;

export function usePendingApplicationsFilters() {
  const location = useLocation();
  const navigate = useNavigate();
  const [skip, setSkip] = useState<number>(0);

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const companyId = searchParams.get("companyId");
  const userId = searchParams.get("userId");
  const createdById = searchParams.get("createdById");
  const pageNumber = searchParams.get("pageNumber") || "1";
  const take = PAGE_SIZE;

  useEffect(() => {
    setSkip((+pageNumber - 1) * PAGE_SIZE);
  }, [pageNumber]);

  const clearFilters = useCallback(() => {
    searchParams.delete("companyId");
    searchParams.delete("userId");
    searchParams.delete("createdById");
    searchParams.delete("pageNumber");

    navigate(`?${searchParams.toString()}`, { replace: true });
  }, [history, searchParams]);

  const setFilterState = useCallback(
    (filters: Partial<Filters>) => {
      if (filters.companyId) {
        searchParams.set("companyId", filters.companyId);
      }

      if (filters.userId) {
        searchParams.set("userId", filters.userId);
      }

      if (filters.createdById) {
        searchParams.set("createdById", filters.createdById);
      }

      if (filters.pageNumber) {
        searchParams.set("pageNumber", filters.pageNumber);
      }

      navigate(`?${searchParams.toString()}`, { replace: true });
    },
    [history, searchParams]
  );

  return {
    userId,
    companyId,
    createdById,
    pageNumber: +pageNumber,
    take,
    skip,
    setFilterState,
    clearFilters,
  };
}
