export const validateMobilePhoneNumber = (
  rule: any,
  value: string,
  callback
) => {
  if (value) {
    const has_fixedLine_pattern = ["01", "02", "03", "04", "05", "09"].includes(
      value.substring(0, 2)
    );

    // no repetion of  number 6 times or more consecutively
    const has_six_repeat = /(.)\1\1\1\1\1/.test(value);

    if (
      [9, 10].includes(value.length) &&
      !has_six_repeat &&
      !has_fixedLine_pattern
    ) {
      callback();
    } else {
      callback("Please enter a valid cellphone number");
    }
  } else {
    // If value is unset, pass validation
    // Use a "required: true" rule where inputs are required
    callback();
  }
};

export const validateFixedLineNumber = (rule, value, callback) => {
  if (value) {
    callback("Invalid phone number format.");
  }
  callback();
};

export const formatPhoneNumber = (phone: string): string => {
  if (phone.substring(0, 3) == "027" || phone.substring(0, 3) == "+27") {
    return "+27" + phone.slice(3);
  } else if (phone.substring(0, 1) == "0") {
    return "+27" + phone.slice(1);
  } else if (phone.substring(0, 3) != "+27") {
    return "+27" + phone;
  }

  return phone;
};
