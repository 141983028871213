import { useState, useEffect, useRef } from "react";
import { CheckOutlined } from "@ant-design/icons";
import { Button, Radio, Input, Select, Spin, Skeleton, Form } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_COMPANY,
  UPDATE_COMPANY,
  VALIDATE_COMPANY_REGISTERATION_NUMBER,
  CREATE_APPLICATION,
  UPDATE_APPLICATION,
  UPDATE_USER,
} from "../../graphql/mutations";
import { isEmpty, isInteger } from "lodash";

import { notifyError, openNotificationWithIcon } from "@/utils/notification";
import { GET_CAPACITY_LIST, GET_USER_COMPANY } from "@/graphql/queries";
import { GET_USER_TYPES } from "../Admin/graphql/queries";
import InternalErrorPage from "@/containers/InternalErrorPage";
import {
  APPLICATION_FAILED,
  BUREAU_API_VERIFICATION_FAILED_MESSAGE,
  COMPANY_USER_ROLE,
  isTestingEnvironment,
} from "../../constants";
import Uploader, { IDocumentList } from "@/components/Uploader";
import { Row } from "@/components";
import { useRegistrationState } from "@/stores/registration";
import { useUserState } from "@/stores/user";

const { Option } = Select;

interface IRegistrationForm {
  registeredAs: string;
}

function RegistrationForm(props: IRegistrationForm) {
  const [form] = Form.useForm();
  const uploaderRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [createCompany] = useMutation(CREATE_COMPANY);
  const [updateCompany] = useMutation(UPDATE_COMPANY);
  const [bureauApiDown, setBureauApiDown] = useState(false);
  const [isVatRegistered, setIsVatRegistered] = useState(false);
  const [adminCode, setAdminCode] = useState("");
  const [companyInfo, setCompanyInfo] = useState<any>();
  const [isValidating, setIsValidating] = useState(false);
  const [roles, setRoles] = useState<any>();
  const [applicationInfo, setApplicationInfo] = useState<any>();
  const [documentList, setDocumentList] = useState<IDocumentList[]>([]);

  const [updateUserRole] = useMutation(UPDATE_USER);

  const [validateRegistration] = useMutation(
    VALIDATE_COMPANY_REGISTERATION_NUMBER
  );

  const {
    setRegistrationStateObject: setRegistrationState,
    registeringAs,
    registrationType,
    registrationCapacity,
    companyId,
    capacityId,
  } = useRegistrationState();

  const { id: userId } = useUserState();

  const isBusiness = registrationCapacity === "Business";
  const isNewRegistration = registrationType === "New";

  useEffect(() => {
    if (companyId) {
      refetchCompany({ userId }).then((resp) => {
        setRegistrationState({
          companyId: resp.data.userCompany?.id,
        });

        setCompanyInfo(resp.data.userCompany);
        setIsVatRegistered(resp.data.userCompany?.isRegisteredForVat);

        if (resp.data.userCompany?.applicationStatus === APPLICATION_FAILED) {
          window.location.replace("/register/fail/prequalification");
        }
      });
    }
  }, []);

  const [upsertUserApplication] = useMutation(
    isEmpty(applicationInfo) ? CREATE_APPLICATION : UPDATE_APPLICATION,
    {
      onError: () => {
        setIsSubmitting(false);
        openNotificationWithIcon(
          "error",
          "Save Error",
          "Error when saving company information. Please try again"
        );
      },
      onCompleted: (data) => {
        const res = isEmpty(applicationInfo)
          ? data.createApplication
          : data.updateApplication;
        setApplicationInfo(res);
        setIsSubmitting(false);
        setRegistrationState({ applicationId: res.id });
        openNotificationWithIcon(
          "success",
          "Create Success",
          `Company created successfully. ${
            bureauApiDown ? BUREAU_API_VERIFICATION_FAILED_MESSAGE : ""
          }`
        );
      },
    }
  );

  const {
    loading: loadingCapacity,
    error: errorCapacity,
    data: dataCapacity,
  } = useQuery(GET_CAPACITY_LIST);

  const { loading: loadingUserTypes } = useQuery(GET_USER_TYPES, {
    onCompleted: (data) => {
      const role = data.userTypesList.filter(
        (x) => x.name === COMPANY_USER_ROLE
      );
      setRoles(role[0]?.id);
    },
  });

  const {
    loading: loadingCompany,
    data: dataCompany,
    refetch: refetchCompany,
  } = useQuery(GET_USER_COMPANY, {
    variables: { userId },
    onCompleted: () => {
      if (!isEmpty(dataCompany) && !isEmpty(dataCompany.userCompany)) {
        setCompanyInfo(dataCompany.userCompany);
        setIsVatRegistered(dataCompany.userCompany.isRegisteredForVat);
        const caps: any[] = dataCompany.userCompany?.createdBy?.employment?.map(
          (x) => x.capacity
        );

        setRegistrationState({
          companyId: dataCompany.userCompany.id,
          companyName: dataCompany.userCompany.registeredName,
          capacityId: caps[0]?.id,
          step1Saved: true,
        });

        const application = dataCompany.userCompany?.application;
        if (!isEmpty(application)) {
          setApplicationInfo(application);
          setRegistrationState({ applicationId: application.id });
        }
      }
    },
    onError: () => {
      return <InternalErrorPage />;
    },
  });

  if (errorCapacity) {
    return <InternalErrorPage />;
  }

  const { capacityList } = !loadingCapacity && dataCapacity;

  // selectable options of capacity
  const capacityOptions: any = capacityList
    ? capacityList
        .filter((item) => {
          let alternativeCapacity;
          if (registeringAs === "A Sole Proprietor") {
            alternativeCapacity = "Sole Proprietor";
          } else if (registeringAs === "Company") {
            alternativeCapacity = "Director";
          } else if (registeringAs === "Closed Corporation") {
            alternativeCapacity = "Member";
          } else if (registeringAs === "Partnership") {
            alternativeCapacity = "Partner";
          } else if (registeringAs === "Trust") {
            alternativeCapacity = "Trustee";
          } else if (registeringAs === "Co-operative") {
            alternativeCapacity = "Director";
          }

          if (["Officer", alternativeCapacity].includes(item.name)) {
            return true;
          }

          return false;
        })
        .map((type) => {
          return (
            <Option key={type.id} value={type.id}>
              {type.name}
            </Option>
          );
        })
    : [];

  const handleSubmit = async (values: any) => {
    setIsSubmitting(true);

    // if capacity is Director - update user role from employee to company
    const selectedCapacity = capacityList.filter(
      (x) => x.id === values.capacity
    );

    if (
      ["Director", "Member", "Officer", "Partner", "Trustee"].includes(
        selectedCapacity && selectedCapacity[0].name
      )
    ) {
      await updateUserRole({
        variables: {
          input: {
            id: userId,
            userRoles: [roles],
            capacityId: values.capacity,
          },
        },
      });
    }

    const upsertCompany = isInteger(companyId) ? updateCompany : createCompany;

    const documents: number[] = await uploaderRef.current?.uploadDocument(
      documentList,
      {
        userId: +userId,
        forCompany: false,
        documentTypeId: 36,
      }
    );

    if (registeringAs === "Company") {
      if (!documents || documents.length === 0) {
        setRegistrationState({ cipcDocumentUploaded: undefined });
        setIsSubmitting(false);
        return notifyError("Please upload the CIPC document");
      } else {
        setRegistrationState({ cipcDocumentUploaded: true });
      }
    }

    // submit data to backend
    upsertCompany({
      variables: {
        input: {
          id: isInteger(companyId) ? companyId : undefined,
          registrationType: isNewRegistration
            ? "New Registration"
            : "Transfer from Existing Member",
          registrationCapacity: registrationCapacity,
          registeredName: values.registeredName,
          registeredNumber: values.registrationNumber,
          tradingName: values.tradingAsName,
          capacityId: values.capacity,
          userId: isInteger(companyId) ? undefined : +userId,
          natureOfBusiness: "DEBT COLLECTOR",
          tradingAs: registeringAs?.toUpperCase(),
          isRegisteredForVat: values.isVatRegistered,
          vatNumber: values.vatNumber,
          ...(documents?.length && { cipcDocument: documents[0] }),
        },
      },
    })
      .then((resp) => {
        let useCompanyId: number;
        let useCompanyName: string;

        if (isInteger(resp.data.createCompany?.id)) {
          useCompanyId = resp.data.createCompany?.id;
          useCompanyName = resp.data.createCompany?.registeredName;
        } else if (isInteger(resp.data.updateCompany?.id)) {
          useCompanyId = resp.data.updateCompany?.id;
          useCompanyName = resp.data.updateCompany?.registeredName;
        }

        const state: any = {};
        if (isInteger(useCompanyId)) {
          state.companyId = useCompanyId;
          state.companyName = useCompanyName;
        }

        state.registeringAs = registeringAs;
        state.capacityId = values.capacity;
        state.step1Saved = true;
        setRegistrationState(state);

        upsertUserApplication({
          variables: {
            input: {
              id: applicationInfo ? applicationInfo.id : undefined,
              registrationType: registrationType,
              registrationCapacity: registrationCapacity,
              registeredAs: registeringAs,
              applicantType: "Online Registration",
              applicantionType: registeringAs,
              companyId: useCompanyId,
              employeeId: userId,
              employerId: useCompanyId,
            },
          },
        });
      })
      .catch((err) => {
        setIsSubmitting(false);
        openNotificationWithIcon(
          "error",
          "Error",
          err.message.replace("GraphQL error:", "")
        );
      });
  };

  const companyRegisteredNumberValidatorAsync = async (
    rule,
    value,
    callback
  ) => {
    if (isTestingEnvironment || btoa(adminCode) === "NjU0MTIz")
      return callback();

    if (!isEmpty(value)) {
      const results = await handleRegistrationValidation(value);

      if (results && results?.registrationNumber === "0") {
        setBureauApiDown(true);
        return callback();
      }

      if (!results || !results?.registrationNumber?.includes(value)) {
        return callback("Incorrect registration number");
      }
    }
    return callback();
  };

  const handleRegistrationValidation: any = async (
    registrationNumber: string
  ) => {
    try {
      if (registrationNumber.length >= 11) {
        setIsValidating(true);
        const { data, errors } = await validateRegistration({
          variables: { registrationNumber },
        });

        setIsValidating(false);
        if (errors) {
          return notifyError(errors[0].message);
        }

        return data?.validateRegistrationNumber;
      }
      return registrationNumber;
    } catch (error) {
      // @ts-ignore
      const errMessage: string = error.message;
      setIsValidating(false);
      notifyError(errMessage);
      if (errMessage.includes("Internal Server Error")) {
        return {
          registrationNumber: "0",
          registrationName: "0",
        };
      }
    }
  };

  if (loadingCapacity || loadingCompany || loadingUserTypes) {
    return (
      <>
        <Skeleton active />
        <Skeleton active />
      </>
    );
  }

  return (
    <div className="flex-column">
      <div
        className="business-basic-details"
        style={{ display: isBusiness ? "block" : "none" }}
      >
        <Form form={form} onFinish={handleSubmit}>
          <div
            style={{
              background: "#f0f2f5",
              borderRadius: "10px",
              padding: "10px",
            }}
          >
            <Row>
              <p>Office Only</p>
              <Row>
                <Input
                  addonBefore="Admin Code"
                  addonAfter={
                    btoa(adminCode) === "NjU0MTIz" ? <CheckOutlined /> : null
                  }
                  placeholder="Enter Admin Code"
                  value={adminCode}
                  onChange={(e) => setAdminCode(e.target.value.trim())}
                />
              </Row>
            </Row>
          </div>

          {isValidating && (
            <div className="flex-column input-block">
              <Spin tip="Verifying company..." />
            </div>
          )}
          <div className="input-block-wrapper">
            <div className="flex-column input-block">
              <Form.Item
                label="Registration Number"
                hasFeedback
                {...{
                  name: "registrationNumber",
                  initialValue: companyInfo?.registeredNumber,
                  rules: [
                    {
                      required: Boolean(registeringAs !== "Partnership"),
                      message: "Please input the registration number",
                    },
                    {
                      validator: companyRegisteredNumberValidatorAsync,
                    },
                  ],
                }}
              >
                <Input
                  addonBefore={isValidating ? <Spin /> : null}
                  placeholder="Enter number as reflected on CIPC Documents"
                />
              </Form.Item>
            </div>
            <div className="flex-column input-block">
              <Form.Item
                label="Registered Name"
                hasFeedback
                {...{
                  name: "registeredName",
                  initialValue: companyInfo?.registeredName,
                  rules: [
                    {
                      required: true,
                      message: "Please input the registered name",
                    },
                  ],
                }}
              >
                <Input placeholder="Enter name as reflected on CIPC Documents" />
              </Form.Item>
            </div>
            <div className="flex-column input-block">
              <Form.Item
                label="Trading as Name"
                hasFeedback
                {...{
                  name: "tradingAsName",
                  initialValue: companyInfo?.tradingName,
                  rules: [
                    {
                      required: true,
                      message: "Please input the business trading name",
                    },
                  ],
                }}
              >
                <Input placeholder="Enter Business Trading Name" />
              </Form.Item>
            </div>
            {registeringAs === "Company" && (
              <div className="flex-column input-block">
                <Form.Item label="CIPC Document">
                  <Uploader
                    ref={uploaderRef}
                    defaults={{
                      filename: "Company-CIPC-Document",
                      directory: "cipc",
                    }}
                    companyId={+userId}
                    onPresigned={(documentList) =>
                      setDocumentList(documentList)
                    }
                    mutiple={false}
                  />
                </Form.Item>
              </div>
            )}
            <div className="flex-column input-block">
              <Form.Item
                label="Capacity"
                {...{
                  name: "capacity",
                  initialValue: capacityId,
                  rules: [
                    {
                      required: true,
                      message:
                        "Please select the capacity in which you're duly authorized",
                    },
                  ],
                }}
              >
                <Select
                  id="name"
                  placeholder="Select"
                  style={{ width: "100%", height: "40px" }}
                >
                  {capacityOptions}
                </Select>
              </Form.Item>
            </div>
            <div className="flex-column input-block">
              <Form.Item
                label="Is Business VAT Registered?"
                {...{
                  name: "isVatRegistered",
                  initialValue: companyInfo?.isRegisteredForVat,
                  rules: [
                    {
                      required: true,
                      message:
                        "Please select whether the business is VAT registered",
                    },
                  ],
                }}
              >
                <Radio.Group
                  onChange={(e) => {
                    setIsVatRegistered(e.target.value);
                  }}
                >
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
            {isVatRegistered && (
              <div className="flex-column input-block">
                <label>Please provide VAT number</label>
                <Form.Item
                  {...{
                    name: "vatNumber",
                    initialValue: companyInfo?.vatNumber,
                  }}
                >
                  <Input placeholder="Enter VAT number" />
                </Form.Item>
              </div>
            )}

            <Row>
              <Form.Item {...{ name: "" }}>
                <Button
                  htmlType="submit"
                  className="btn-registration-capacity-selected"
                  loading={isSubmitting}
                  disabled={isValidating}
                >
                  Save information
                </Button>
              </Form.Item>
            </Row>
          </div>
        </Form>
        <span>
          Based on the information above, the registration is subject to Form
          [1A] per Regulations relating to Debt Collectors Act, 2003
        </span>
      </div>
    </div>
  );
}

export default RegistrationForm;
