import {
  useState,
  forwardRef,
  Ref,
  useImperativeHandle,
  useEffect,
} from "react";
import { Card, Table, Empty } from "antd";
import { useLazyQuery } from "@apollo/client";
import { GET_REPORTS, DOWNLOAD_REPORTS } from "../../graphql/queries";
import { TableLoader } from "../../../../components/UserManagement/TableContentPlaceholder";
import { notifyError } from "@/utils/notification";
import { writeCSV } from "@/utils";
import { ReportHandle } from "../ReportingTypes";
import { useReportingFilters } from "../useReportingFilters";

export function _CertificateRun(_: unknown, ref: Ref<ReportHandle>) {
  const [reportList, setReportList] = useState([]);
  const [listTotal, setListTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const { companyIds, skip, setFilterState, pageNumber, pageSize, type } =
    useReportingFilters();

  const handleError = (error: any) => {
    setLoading(false);
    setDownloading(false);
    notifyError(error);
  };

  const [getReports] = useLazyQuery(GET_REPORTS, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getReports }) => {
      if (getReports?.reports) setReportList(getReports.reports);
      if (getReports?.total) setListTotal(getReports.total);
      if (getReports?.reportString) {
        const data = JSON.parse(getReports.reportString);
        setReportList(data);
      }
      setLoading(false);
    },
    onError: handleError,
  });

  const [downloadReports] = useLazyQuery(DOWNLOAD_REPORTS, {
    onCompleted: ({ getReports }) => {
      const data = getReports.reports.map((report, i) => {
        return {
          "#": i + 1,
          Company: report.company.registeredName,
          "#Employees": report.employees,
          "#Related Parties": report.relatedParties,
          "#Entity": report.entity,
          Total: report.total,
        };
      });

      writeCSV(type, type, data);
      setDownloading(false);
    },
    onError: handleError,
  });

  const runQuery = async () => {
    setLoading(true);

    getReports({
      variables: {
        input: {
          company: companyIds?.map((id) => +id),
          sort: "DESC",
          includeTotal: true,
          skip,
          take: pageSize,
          type: "Certificate Run",
        },
      },
    });
  };

  const downloadCsv = async () => {
    try {
      setDownloading(true);

      downloadReports({
        variables: {
          input: {
            company: companyIds?.map((id) => +id),
            sort: "DESC",
            includeTotal: true,
            skip,
            take: Math.min(listTotal, 500),
            type: "Certificate Run",
          },
        },
      });
    } catch (err) {
      setDownloading(false);
      notifyError(err);
    }
  };

  const columns = [
    {
      title: "Company",
      key: "company",
      render: ({ company }) =>
        (company?.registeredName || company?.tradingName).toUpperCase(),
    },
    {
      title: "#Employees",
      render: ({ employees }) => employees,
      key: "employees",
    },
    {
      title: "#Related Parties",
      render: ({ relatedParties }) => relatedParties,
      key: "relatedParties",
    },
    {
      title: "#Entity",
      render: ({ entity }) => entity,
      key: "entity",
    },
    {
      title: "Total",
      render: ({ total }) => total,
      key: "total",
    },
  ];

  useImperativeHandle(ref, () => ({
    run: runQuery,
    download: downloadCsv,
    running: loading,
    downloading,
    resetData: () => {
      setReportList([]);
      setListTotal(0);
    },
  }));

  useEffect(() => {
    runQuery();
  }, [pageNumber]);

  if (loading) return <TableLoader />;

  if (reportList.length === 0) {
    return <Empty description="Use the filters above to generate a report." />;
  }

  return (
    <div className="">
      <Card>
        <Table
          size="small"
          rowKey="id"
          columns={columns}
          pagination={{
            current: pageNumber,
            showSizeChanger: false,
            hideOnSinglePage: true,
            pageSize,
            onChange: (page) => setFilterState({ pageNumber: page }),
            total: listTotal,
            showTotal: () => <h3>Total: {listTotal}</h3>,
          }}
          dataSource={reportList}
        />
      </Card>
    </div>
  );
}

export const CertificateRun = forwardRef(_CertificateRun);
