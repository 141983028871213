import { DownOutlined } from "@ant-design/icons";
import { Table, Tag, Dropdown, Button } from "antd";
import { NavigateFunction } from "react-router-dom";
import { filterUnemployed } from "@/utils/filterUnemployed";
import { compareDesc, format, parseISO } from "date-fns";

type Props = {
  userList: any[];
  currentPage: number;
  pageSize: number;
  listTotal: number;
  handlePagination: (page: number) => void;
  navigate: NavigateFunction;
};

export default ({
  userList,
  currentPage,
  pageSize,
  listTotal,
  handlePagination,
  navigate,
}: Props) => {
  const columns = [
    {
      title: "User",
      key: "firstName",
      render: (record) =>
        (record.firstName && record.lastName
          ? record.firstName + " " + record.lastName
          : ""
        ).toUpperCase(),
    },
    {
      title: "ID Number",
      render: (record) => record.idNumber,
      key: "userList",
    },

    {
      title: "Company Name",
      key: "companyName",
      render: ({ employment }) => {
        if (!employment || !employment.length) return "";
        return filterUnemployed(employment)
          .map((x) => x.company?.registeredName || x.company?.tradingName)
          .join(", ")
          .toUpperCase();
      },
    },
    {
      title: "Capacity",
      key: "capacity",
      render: ({ employment }) => {
        if (!employment || !employment.length) return "";
        return filterUnemployed(employment)
          .map((x) => x?.capacity?.name)
          .join(", ");
      },
    },
    {
      title: "Council Membership Number",
      key: "company",
      render: ({ employment }) => {
        if (!employment || !employment.length) return "";
        return filterUnemployed(employment)
          .map((x) => x?.councilMembershipNumber)
          .join(", ");
      },
    },
    {
      title: "Valid Until",
      key: "certificates",
      render: ({ certificates }) => {
        if (!certificates || certificates.length === 0) return "n/a";
        return format(
          certificates
            .map(({ toDate }) => parseISO(toDate))
            .sort(compareDesc)[0],
          "yyyy-MM-dd"
        );
      },
    },
    {
      title: "Status",
      key: "userStatus",

      render: ({ userStatus }) => {
        const status =
          userStatus.charAt(0).toUpperCase() +
          userStatus.slice(1).toLowerCase();
        return (
          <span style={{ display: "flex" }}>
            <Tag color={status === "Active" ? "success" : "warning"}>
              {status}
            </Tag>
          </span>
        );
      },
    },
    {
      title: "Action",
      key: "id",
      render: (record) => {
        const items = [
          {
            key: "View user profile",
            label: (
              <Button
                type="link"
                onClick={() => navigate(`/admin/member/${record.id}`)}
              >
                View user profile
              </Button>
            ),
          },
        ];

        return (
          <Dropdown
            menu={{ items }}
            placement="bottomRight"
            trigger={["click"]}
          >
            <span className="ant-dropdown-link purple-link">
              More <DownOutlined title="member actions dropdown" />
            </span>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <Table
      rowKey="id"
      size="small"
      columns={columns}
      pagination={{
        current: currentPage,
        hideOnSinglePage: true,
        showSizeChanger: false,
        pageSize,
        onChange: (page) => handlePagination(page),
        total: listTotal,
        showTotal: () => <h3>Total: {listTotal}</h3>,
      }}
      dataSource={userList}
    />
  );
};
