import { Skeleton, Card } from "antd";

const ProfileLoader: React.FC<any> = () => {
  return (
    <>
      <Card>
        <Skeleton loading active />
      </Card>
      <br />
      <Card>
        <Skeleton loading active />
      </Card>
      <br />
      <Card>
        <Skeleton loading active />
      </Card>
      <br />
      <Card>
        <Skeleton loading active />
      </Card>
    </>
  );
};
export default ProfileLoader;
