import {
  useState,
  useEffect,
  useCallback,
  Fragment,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_CAPACITY_LIST } from "../../../graphql/queries";
import { Input, Row, Col, Select } from "antd";
import debounce from "lodash/debounce";
const { Option } = Select;

export const RepresentativeFilters = (
  { handleRepresentativeFilterChange, defaultMultiFilter, setError, mode },
  ref
) => {
  const [representativeCapacityList, setRepresentativeCapacityList] = useState(
    []
  );
  const usernameFilterRef = useRef();
  const capacityFilterRef = useRef();
  const sortFilterRef = useRef();

  useImperativeHandle(ref, () => ({
    // @ts-ignore: function called from parent
    resetFilters() {
      resetFilterCollection([
        {
          ref: usernameFilterRef,
          defaultValue: defaultMultiFilter["username"],
        },
        {
          ref: capacityFilterRef,
          defaultValue: defaultMultiFilter["capacity"],
        },
        {
          ref: sortFilterRef,
          defaultValue: defaultMultiFilter["sort"],
        },
      ]);
    },
  }));

  const resetFilterCollection = (refMetaCollection = []) => {
    for (let refMeta of refMetaCollection) {
      if (refMeta && refMeta.ref && refMeta.ref.current) {
        let currentRef = refMeta.ref.current;
        let useDefaultValue = refMeta.defaultValue
          ? refMeta.defaultValue
          : null;
        if (currentRef.rcSelect) {
          currentRef.rcSelect.setState({ value: [useDefaultValue] });
        } else if (currentRef.picker) {
          currentRef.picker.setState({
            value: useDefaultValue,
            showDate: useDefaultValue,
          });
        } else if (currentRef.input) {
          currentRef.input.value = useDefaultValue;
        }
      }
    }
  };

  const [getRepresentativeCapacities] = useLazyQuery(GET_CAPACITY_LIST, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setRepresentativeCapacityList(data.capacityList);
    },
    onError: (error) => {
      console.log({ error });
      setError(error);
    },
  });

  useEffect(() => {
    getRepresentativeCapacities({ variables: {} });
  }, []);

  const handleRepresentativeFilterUpdate = (data) => {
    handleRepresentativeFilterChange(data, "username");
  };

  const debouncedRepresentativeUsernameFilterUpdate = useCallback(
    debounce(handleRepresentativeFilterUpdate, 250),
    []
  );

  return (
    <Fragment>
      <Row gutter={[24, 24]}>
        <Col span={8}>
          <div className="flex-center-space-between">
            <label>Username:</label>
            <Input
              ref={usernameFilterRef}
              placeholder="Search representative by name"
              style={{ width: "calc(100% - 75px)" }}
              onChange={(event) =>
                debouncedRepresentativeUsernameFilterUpdate(event.target.value)
              }
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="flex-center-space-between">
            <label>Capacity:</label>
            <Select
              ref={capacityFilterRef}
              placeholder="Select representative capacity"
              onChange={(value) =>
                handleRepresentativeFilterChange(value, "capacityId")
              }
              style={{ width: "calc(100% - 75px)" }}
            >
              {representativeCapacityList.map((x) => {
                return (
                  <Option key={x.name} value={x.id}>
                    {x.name}
                  </Option>
                );
              })}
            </Select>
          </div>
        </Col>
        <Col span={8}>
          <div className="flex-center-space-between">
            <label>Sort:</label>
            <Select
              ref={sortFilterRef}
              placeholder="sort by..."
              onChange={(value) =>
                handleRepresentativeFilterChange(value, "sort")
              }
              style={{ width: "calc(100% - 75px)" }}
              defaultValue={defaultMultiFilter["sort"]}
            >
              <Option value="DESC">Newest</Option>
              <Option value="ASC">Oldest</Option>
            </Select>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

export default forwardRef(RepresentativeFilters);
