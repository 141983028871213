import { useState, useImperativeHandle, forwardRef, useEffect } from "react";
import { Card, Table, Empty } from "antd";
import { useLazyQuery } from "@apollo/client";
import { GET_COMPLIANCE_REPORTS } from "../../graphql/queries";
import { TableLoader } from "../../../../components/UserManagement/TableContentPlaceholder";
import { notifyError } from "@/utils/notification";
import { writeCSV } from "@/utils";
import { useReportingFilters } from "../useReportingFilters";
import { ReportHandle } from "../ReportingTypes";

function _ComplianceReport(_: unknown, ref: React.Ref<ReportHandle>) {
  const [loading, setLoading] = useState(false);
  const [reportList, setReportList] = useState([]);
  const [downloading, setDownloading] = useState(false);

  const { setFilterState, pageNumber, pageSize, reportType, dateRange } =
    useReportingFilters();

  const handlePagination = (page: number) => {
    setFilterState({ pageNumber: page });
  };

  const handleError = (error: any) => {
    setLoading(false);
    setDownloading(false);
    notifyError(error);
  };

  const [getComplianceReport] = useLazyQuery(GET_COMPLIANCE_REPORTS, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getComplianceReport }) => {
      setReportList(getComplianceReport);
      setLoading(false);
    },
    onError: handleError,
  });

  const runQuery = async () => {
    setLoading(true);

    const to = dateRange?.[1];
    const from = dateRange?.[0];

    if (!to || !from) {
      setLoading(false);
      return notifyError("Please select the date range");
    }

    getComplianceReport({
      variables: {
        input: {
          type: "Compliance Report",
          complianceReportType:
            reportType === "member" ? "debtCollector" : "business",
          to,
          from,
        },
      },
    });
  };

  const downloadCsv = async () => {
    try {
      setDownloading(true);
      writeCSV(
        "Compliance Report",
        `${reportType}-compliance-report`,
        reportList
      );
    } catch (err) {
      setDownloading(false);
      notifyError(err);
    }
  };

  let columns: any[] = [
    {
      title: reportType === "company" ? "Debt Collector Type" : "Business Type",
      key: "type",
      render: ({ capacity }) => (
        <span className={capacity === "Total" ? "bold" : ""}>
          {capacity?.toUpperCase()}
        </span>
      ),
    },
    {
      title: true ? "Received" : "Registered",
      key: "received_registered",
      render: ({ capacity, received }) => (
        <span className={capacity === "Total" ? "bold" : ""}>{received}</span>
      ),
    },
    {
      title: "Approved",
      key: "approved",
      render: ({ capacity, approved }) => (
        <span className={capacity === "Total" ? "bold" : ""}>{approved}</span>
      ),
    },
    {
      title: "Active",
      key: "active",
      render: ({ capacity, active }) => (
        <span className={capacity === "Total" ? "bold" : ""}>{active}</span>
      ),
    },
    ...(reportType === "company"
      ? [
          {
            title: "Unemployed",
            key: "unemployed",
            render: ({ capacity, unemployed }) => (
              <span className={capacity === "Total" ? "bold" : ""}>
                {unemployed}
              </span>
            ),
          },
        ]
      : []),
    {
      title: "Suspended",
      key: "suspended",
      render: ({ capacity, suspended }) => (
        <span className={capacity === "Total" ? "bold" : ""}>{suspended}</span>
      ),
    },
    {
      title: "Deregistered",
      key: "deregistered",
      render: ({ capacity, deregistered }) => (
        <span className={capacity === "Total" ? "bold" : ""}>
          {deregistered}
        </span>
      ),
    },
    {
      title: "Refused",
      key: "refused",
      render: ({ capacity, refused }) => (
        <span className={capacity === "Total" ? "bold" : ""}>{refused}</span>
      ),
    },
  ];

  useImperativeHandle(ref, () => ({
    run: runQuery,
    download: downloadCsv,
    running: loading,
    downloading,
    resetData: () => {
      setReportList([]);
    },
  }));

  useEffect(() => {
    if (!dateRange) return;
    runQuery();
  }, [pageNumber]);

  if (loading) {
    return <TableLoader />;
  }

  if (reportList.length === 0) {
    return <Empty description="Use the filters above to generate a report." />;
  }

  return (
    <div className="">
      <Card>
        <Table
          size="small"
          rowKey="id"
          columns={columns}
          pagination={{
            current: pageNumber,
            showSizeChanger: false,
            hideOnSinglePage: true,
            pageSize,
            onChange: (page) => handlePagination(page),
            total: reportList.length,
            showTotal: () => <h3>Total: {reportList.length}</h3>,
          }}
          dataSource={reportList}
        />
      </Card>
    </div>
  );
}

export const ComplianceReport = forwardRef(_ComplianceReport);
