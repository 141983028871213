import { Modal, Input, Select, Form } from "antd";
import { useQuery } from "@apollo/client";
import { GET_PERMISSIONS } from "../../containers/Admin/graphql/queries";

const EditRoleForm = ({ visible, onCancel, onCreate, initialData, form }) => {
  const { Option } = Select;
  const { loading, error, data } = useQuery(GET_PERMISSIONS);

  if (loading) return <>loading...</>;
  if (error) return <>{error.message}</>;

  const { permissionsList } = data;

  //selectable options of permissions
  const children: any = permissionsList
    ? permissionsList.map((type) => {
        return (
          <Option key={type.id} value={type.id}>
            {type.name}
          </Option>
        );
      })
    : [];

  // array of permissions that the role is assigned
  const permissionId: any = [];
  const permissionName: any = [];
  if (initialData && initialData.permissions)
    initialData.permissions.map((perm) => {
      permissionId.push(perm.id);
      permissionName.push(perm.name);
      return false;
    });
  return (
    <Modal
      open={visible}
      title={initialData && initialData.id ? "Edit Role" : "Add Role"}
      okText="Save"
      onCancel={onCancel}
      onOk={onCreate}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="ID"
          style={{ display: "none" }}
          {...{
            name: "id",
            initialValue: initialData ? initialData.id : undefined,
          }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Role Name"
          {...{
            name: "name",
            initialValue: initialData ? initialData.name : "",
            rules: [
              {
                required: true,
                message: "Please input name of the role!",
              },
            ],
          }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name={"description"}
          {...{
            initialValue: initialData ? initialData.description : "",
            rules: [
              {
                required: true,
                message: "Please input the role's description!",
              },
            ],
          }}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Permissions"
          {...{
            name: "permissions",
            initialValue: permissionId,
            rules: [
              {
                required: true,
                message: "Please select role permission(s)",
              },
            ],
          }}
        >
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            defaultValue={permissionName}
          >
            {children}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditRoleForm;
