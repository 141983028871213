import { useEffect, useState } from "react";
import { Button, Radio, Input, Select, Col, Form } from "antd";
import { useQuery, useMutation } from "@apollo/client";
import { isEmpty, isBoolean } from "lodash";
import InternalErrorPage from "@/containers/InternalErrorPage";
import { openNotificationWithIcon } from "@/utils/notification";
import { GET_ETHNICITY_LIST, GET_MARITAL_STATUS_LIST } from "@/graphql/queries";
import { UPDATE_USER } from "../../../../../graphql/mutations";
import { validateMobilePhoneNumber } from "@/utils/validatePhoneNumber";
import { Auth } from "aws-amplify";
import ConfirmAccountForm from "./confirmAccount";

Auth.configure({
  Auth: {
    identityPoolId: import.meta.env.VITE_USER_POOL_CLIENT_ID,
    region: import.meta.env.VITE_REGION,
    userPoolId: import.meta.env.VITE_USER_POOL_ID,
    UserPoolId: import.meta.env.VITE_USER_POOL_ID,
    ClientId: import.meta.env.VITE_USER_POOL_CLIENT_ID,
    userPoolWebClientId: import.meta.env.VITE_USER_POOL_CLIENT_ID,
  },
});

function PersonalInformationForm({ userInfo }) {
  const [form] = Form.useForm();
  const { Option } = Select;
  const [email, setEmail] = useState("");
  const [isEmancipated, setIsEmancipated] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [age, setAge] = useState<number>();
  const [updateUser] = useMutation(UPDATE_USER);
  const [saveForm] = Form.useForm();

  const {
    loading: loadingEthnicity,
    error: errorEthnicity,
    data: dataEthnicity,
  } = useQuery(GET_ETHNICITY_LIST);
  const {
    loading: loadingMaritalStatus,
    error: errorMaritalStatus,
    data: dataMaritalStatus,
  } = useQuery(GET_MARITAL_STATUS_LIST);

  const { ethnicityList } = !loadingEthnicity && dataEthnicity;
  const { maritalStatusList } = !loadingMaritalStatus && dataMaritalStatus;

  //selectable ethnicity options
  const ethnicityOptions: any = ethnicityList
    ? ethnicityList.map((type: any) => {
        return (
          <Option key={type.id} value={type.name}>
            {type.name}
          </Option>
        );
      })
    : [];

  //selectable ethnicity options
  const maritalStatusOptions: any = maritalStatusList
    ? maritalStatusList.map((type) => {
        return (
          <Option key={type.id} value={type.name}>
            {type.name}
          </Option>
        );
      })
    : [];

  useEffect(() => {
    setEmail(userInfo.email);
  }, []);

  const handleUpdateEmailConfirm = async () => {
    const values = await saveForm.validateFields().catch(() => {});
    if (!values) return;

    Auth.verifyCurrentUserAttributeSubmit("email", values.code).then((res) => {
      if (res) {
        openNotificationWithIcon("success", "Email confirmed", "");
      } else {
        openNotificationWithIcon(
          "error",
          "Email update failed",
          "Please try again or contact support."
        );
      }
      setModalVisible(false);
    });
  };

  const handleSubmitUserAdditionalInfo = async (values: any) => {
    setIsSubmitting(true);

    //update user details
    const resp = await updateUser({
      variables: {
        input: {
          id: userInfo.id,
          idNumber: values.idNumber,
          firstName: values.firstName,
          lastName: values.lastName,
          phone: values.phone,
          email: email,
          secondaryPhone: values.phoneNumber2,
          workPhone: values.workNumber,
          homePhone: values.homeNumber,
          secondaryEmail: values.email2,
          fax: values.faxNumber,
          age: Number.parseInt(values.age),
          maritalStatus: values.maritalStatus,
          ethnicity: values.ethnicity,
          isEmancipated: values.isEmancipated,
        },
      },
    });

    if (resp.errors) {
      setIsSubmitting(false);
      return openNotificationWithIcon("error", "Error", resp.errors[0].message);
    }

    setIsSubmitting(false);

    openNotificationWithIcon(
      "success",
      "Save Success",
      "User information updated successfully"
    );

    // under 18 years old and not emancipated
    // fail the application
    if (resp.data.updateUser.age < 18 && !resp.data.updateUser.isEmancipated) {
      window.location.replace("/register/fail/emancipation");
    }
  };

  const ModalProps = {
    form: saveForm,
    visible: modalVisible,
    onCancel: () => setModalVisible(false),
    onCreate: () => handleUpdateEmailConfirm(),
    okText: "Save",
    modalHeading: "Email address Confirmation",
    cancelText: "Cancel",
  };

  if (errorEthnicity || errorMaritalStatus) return <InternalErrorPage />;

  return (
    <>
      <Form
        form={form}
        onFinish={handleSubmitUserAdditionalInfo}
        onFieldsChange={(changes) => {
          if (changes[0].name[0] === "age") {
            setAge(changes[0].value);
          }
        }}
      >
        <div className="input-block-wrapper" style={{ marginTop: "0px" }}>
          <Col span={8}>
            <Form.Item
              label="ID/Passport number"
              {...{
                name: "idNumber",
                initialValue:
                  userInfo && !isEmpty(userInfo.idNumber)
                    ? userInfo.idNumber
                    : "",
                rules: [
                  {
                    required: true,
                    message: "Please enter ID/Passport number",
                  },
                ],
              }}
            >
              <Input placeholder="Enter ID/Passport" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="First name"
              {...{
                name: "firstName",
                initialValue:
                  userInfo && !isEmpty(userInfo.firstName)
                    ? userInfo.firstName
                    : "",
                rules: [
                  {
                    required: true,
                    message: "Please enter first name",
                  },
                ],
              }}
            >
              <Input placeholder="Enter first name" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Last name"
              {...{
                name: "lastName",
                initialValue:
                  userInfo && !isEmpty(userInfo.lastName)
                    ? userInfo.lastName
                    : "",
                rules: [
                  {
                    required: true,
                    message: "Please enter last name",
                  },
                ],
              }}
            >
              <Input placeholder="Enter last name" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Phone number"
              {...{
                name: "phone",
                initialValue:
                  userInfo && !isEmpty(userInfo.phone) ? userInfo.phone : "",
                rules: [
                  {
                    required: false,
                    message: "Enter your phone number",
                  },
                ],
              }}
            >
              <Input placeholder="Enter your phone number" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Email"
              {...{
                name: "email",
                initialValue: userInfo?.email || "",
                rules: [
                  {
                    required: false,
                    message: "Enter your primary email",
                  },
                  {
                    type: "email",
                    message: "Enter a valid email",
                  },
                ],
              }}
            >
              <Input placeholder="Enter your primary email" readOnly />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Phone number 2"
              {...{
                name: "phoneNumber2",
                initialValue:
                  userInfo && !isEmpty(userInfo.secondaryPhone)
                    ? userInfo.secondaryPhone
                    : "",
                rules: [
                  {
                    required: false,
                    message: "Enter your alternate phone number",
                  },
                  {
                    validator: validateMobilePhoneNumber,
                  },
                ],
              }}
            >
              <Input placeholder="Enter alternative phone number" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Home number"
              {...{
                name: "homeNumber",
                initialValue:
                  userInfo && !isEmpty(userInfo.homePhone)
                    ? userInfo.homePhone
                    : "",
                rules: [
                  {
                    required: false,
                    message: "Enter your home number",
                  },
                ],
              }}
            >
              <Input placeholder="Enter your home number" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Work number"
              {...{
                name: "workNumber",
                initialValue:
                  userInfo && !isEmpty(userInfo.workPhone)
                    ? userInfo.workPhone
                    : "",
                rules: [
                  {
                    required: true,
                    message: "Enter your work number",
                  },
                ],
              }}
            >
              <Input placeholder="Enter your work number" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Email private"
              {...{
                name: "email2",
                initialValue:
                  userInfo && !isEmpty(userInfo.secondaryEmail)
                    ? userInfo.secondaryEmail
                    : "",
                rules: [
                  {
                    required: false,
                    message: "Enter your 2nd email",
                  },
                  {
                    type: "email",
                    message: "Enter a valid email",
                  },
                ],
              }}
            >
              <Input placeholder="Enter your 2nd email address" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Work Fax"
              {...{
                name: "faxNumber",
                initialValue: userInfo?.fax || "",
                rules: [
                  {
                    required: false,
                    message: "Enter your fax number",
                  },
                ],
              }}
            >
              <Input placeholder="Enter your fax number" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Marital status"
              {...{
                name: "maritalStatus",
                initialValue:
                  userInfo && !isEmpty(userInfo.maritalStatus)
                    ? userInfo.maritalStatus
                    : "",
                rules: [
                  {
                    required: true,
                    message: "Enter your marital status",
                  },
                ],
              }}
            >
              <Select className="input-select">{maritalStatusOptions}</Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Ethnicity"
              {...{
                name: "ethnicity",
                initialValue:
                  userInfo && !isEmpty(userInfo.ethnicity)
                    ? userInfo.ethnicity
                    : "",
                rules: [
                  {
                    required: true,
                    message: "Please select your ethnicity",
                  },
                ],
              }}
            >
              <Select className="input-select">{ethnicityOptions}</Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Age"
              {...{
                name: "age",
                initialValue:
                  userInfo && !isNaN(userInfo.age) ? userInfo.age : 0,
                rules: [
                  {
                    required: true,
                    message: "Enter your age",
                  },
                  {
                    validator: (_, value) =>
                      value >= 16
                        ? Promise.resolve()
                        : Promise.reject(new Error("Age must be at least 16")),
                  },
                ],
              }}
            >
              <Input type="number" />
            </Form.Item>
          </Col>

          {age < 18 && (
            <Col span={8}>
              <Form.Item
                label="Have you been emancipated?"
                {...{
                  name: "isEmancipated",
                  initialValue: isBoolean(userInfo?.isEmancipated)
                    ? userInfo?.isEmancipated
                    : "",
                  rules: [
                    {
                      required: true,
                      message: "Enter your age",
                    },
                  ],
                }}
              >
                <Radio.Group
                  onChange={(e) => {
                    setIsEmancipated(e.target.value);
                  }}
                  value={isEmancipated}
                  className="input-select input-spacer"
                >
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          )}

          <Col span={24}>
            <Button
              htmlType="submit"
              className="purple-button"
              loading={isSubmitting}
            >
              Save information
            </Button>
          </Col>
        </div>
      </Form>
      <ConfirmAccountForm {...ModalProps} />
    </>
  );
}

export default PersonalInformationForm;
