import { Modal, Input, Form } from "antd";

function EditBusinessUnitForm({
  visible,
  onCancel,
  onCreate,
  initialData,
  form,
}) {
  return (
    <Modal
      open={visible}
      title={
        initialData && initialData.id
          ? "Edit Business Unit"
          : "Add Business Unit"
      }
      okText="Save"
      onCancel={onCancel}
      onOk={onCreate}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="ID"
          style={{ display: "none" }}
          name={"id"}
          initialValue={initialData ? initialData.id : undefined}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Business Uint"
          name="name"
          {...{
            initialValue: initialData ? initialData.name : "",
            rules: [
              {
                required: true,
                message: "Please input name of the business unit!",
              },
            ],
          }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name={"description"}
          {...{
            initialValue: initialData ? initialData.description : "",
            rules: [
              {
                required: true,
                message: "Please input the business unit's description!",
              },
            ],
          }}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default EditBusinessUnitForm;
