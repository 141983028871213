import { useState, useEffect } from "react";
import { Tabs, Breadcrumb } from "antd";
import { useLazyQuery } from "@apollo/client";
import MemberProfile from "./profile";
import BusinessProfile from "../../Companies/CompanyOverview/companyProfile";
import MemberTasks from "../../Tasks/memberTasks";
import MemberTransactions from "../../Transactions/memberTransactions";
import MemberEmployees from "../../Employees/companyEmployees";
import MemberDocuments from "../../Documents/memberDocuments";
import { GET_MEMBER_OVERVIEW_INFORMATION } from "../../graphql/queries";
import { filterUnemployed } from "@/utils/filterUnemployed";
import { useParams } from "react-router-dom";
import { EmploymentHistory } from "../../EmploymentHistory/EmploymentHistory";

function MemberOverview() {
  const { memberId } = useParams();
  const [useMemberOverviewData, setMemberOverviewData] = useState<any>({});
  const [_isLoading, setIsLoading] = useState(true);
  const [useCompanyId, setCompanyId] = useState(null);
  const [useCompanyName, setCompanyName] = useState(null);
  const [idNumber, setIdNumber] = useState(null);
  const [showBusinessAndEmployeeTabs, setShowBusinessAndEmployeesTabs] =
    useState(false);
  const [useCompanyInformation, setCompanyInformation] = useState(null);
  const [employments, setEmployments] = useState(null);
  const [fetchMemberOverviewInformation] = useLazyQuery(
    GET_MEMBER_OVERVIEW_INFORMATION,
    {
      variables: {
        userId: +memberId,
      },
      onCompleted: (data) => {
        setMemberOverviewData(data);
        setIdNumber(data.userData.idNumber);
        setIsLoading(false);
        if (typeof data?.userData?.employment[0]?.company?.id === "number") {
          setCompanyId(data.userData.employment[0].company.id);
          setCompanyName(
            data.userData.employment[0]?.company?.registeredName ||
              data.userData.employment[0]?.company?.tradingName
          );
          setCompanyInformation(data.userData.employment[0]);
          setEmployments(filterUnemployed(data.userData.employment));
          if (
            ["Director", "Sole Proprietor"].includes(
              data.userData?.employment[0].capacity?.name
            )
          ) {
            setShowBusinessAndEmployeesTabs(true);
          }
        }
      },
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    fetchMemberOverviewInformation();
  }, []);

  const tabItems = [
    {
      label: "Profile",
      key: "1",
      children: (
        <MemberProfile
          profileData={useMemberOverviewData}
          companyId={useCompanyId}
          companyInformation={useCompanyInformation}
          refetchProfile={fetchMemberOverviewInformation}
          employments={employments}
        />
      ),
    },
    ...(showBusinessAndEmployeeTabs
      ? [
          {
            label: "Business",
            key: "2",
            children: (
              <BusinessProfile
                profileData={useMemberOverviewData}
                companyId={useCompanyId}
                companyInformation={useCompanyInformation.company}
              />
            ),
          },
        ]
      : []),
    ...(showBusinessAndEmployeeTabs
      ? [
          {
            label: "Employees",
            key: "3",
            children: (
              <MemberEmployees
                companyId={Number.parseInt(useCompanyId)}
                mode="member"
              />
            ),
          },
        ]
      : []),
    {
      label: "Tasks",
      key: "4",
      children: (
        <MemberTasks
          memberId={Number.parseInt(memberId)}
          mode="member-overview-tasks"
        />
      ),
    },
    {
      label: "Transactions",
      key: "5",
      children: (
        <MemberTransactions
          memberId={Number.parseInt(memberId)}
          mode="member-overview-transactions"
        />
      ),
    },
    {
      label: "Documents",
      key: "6",
      children: (
        <MemberDocuments
          companyId={useCompanyId}
          companyName={useCompanyName}
          memberId={Number.parseInt(memberId)}
          memberIdNumber={idNumber}
          mode="member-overview-documents"
        />
      ),
    },
    {
      label: "Employment History",
      key: "7",
      children: <EmploymentHistory userId={+memberId} />,
    },
  ];

  return (
    <>
      <div className="member-profile-header-div">
        <Breadcrumb>
          <Breadcrumb.Item>
            <a href="/admin/members">Members</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <a href={`/admin/member/${memberId}`}>
              {useMemberOverviewData && useMemberOverviewData.userData
                ? `${useMemberOverviewData.userData.firstName} ${useMemberOverviewData.userData.lastName}`
                : memberId}
            </a>
          </Breadcrumb.Item>
        </Breadcrumb>
        {useMemberOverviewData && useMemberOverviewData.userData && (
          <h2>{`${useMemberOverviewData.userData.firstName}
            ${useMemberOverviewData.userData.lastName}
            : ${
              employments
                ?.map((e) => e?.capacity?.name)
                .join(" ")
                .trim() || "Employee"
            }`}</h2>
        )}
      </div>
      <Tabs tabPosition="top" items={tabItems} defaultActiveKey="1" />
    </>
  );
}

export default MemberOverview;
