import EmptyTaskIcon from "../../assets/images/undraw_empty_xct9.svg";
import EmptyDocumentIcon from "../../assets/images/undraw_add_document_0hek.svg";
import EmptyTransactionIcon from "../../assets/images/undraw_online_payments_luau.svg";
import EmptyTeamIcon from "../../assets/images/undraw_team_ih79.svg";
import { Col, Row, Card } from "antd";

const EmptyState: React.FC<any> = ({
  location,
  headingText,
  bodyText,
  children,
}) => {
  return (
    <div className="col-sm-12 col-md-12 placeholder-table-card">
      <Card
        bodyStyle={{ paddingTop: "13%", paddingBottom: "13%", minHeight: 600 }}
      >
        <Row>
          <Col span={9} offset={2}>
            {location === "tasks" && (
              <img src={EmptyTaskIcon} alt="" width="100%" />
            )}
            {location === "docs" && (
              <img src={EmptyDocumentIcon} alt="" width="100%" />
            )}
            {location === "transactions" && (
              <img src={EmptyTransactionIcon} alt="" width="100%" />
            )}
            {location === "team" && (
              <img src={EmptyTeamIcon} alt="" width="100%" />
            )}
          </Col>
          <Col span={9} offset={1}>
            <span style={{ fontSize: 30 }}>
              {headingText}
              <br />
            </span>
            <span style={{ fontSize: 20, color: "#9a9a9a" }}>{bodyText}</span>
            {children}
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default EmptyState;
