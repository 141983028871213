import { create } from "zustand";
import { notification } from "antd";
import { devtools, persist, createJSONStorage } from "zustand/middleware";
import { MailOutlined } from "@ant-design/icons";

// Define the WebSocket message type (adjust as needed)
interface NewMessage {
  action: string;
  payload: string;
}

type IncomingMessage = {
  type: string;
  payload: any;
};

type Notification = {
  title: string;
  content: string;
  isRead: boolean;
  createdAt: string;
};

// WebSocket store interface
interface WebSocketStore {
  connection: WebSocket | null;
  notifications: Notification[];
  isConnected: boolean;
  isDrawerOpen: boolean;
  error: string | null;
  connect: () => void;
  disconnect: () => void;
  sendMessage: (message: NewMessage) => void;
  clearMessages: () => void;
  toggleDrawer: () => void;
}

export const useNotificationStore = create<WebSocketStore>()((set, get) => ({
  connection: null,
  notifications: [],
  isConnected: false,
  isDrawerOpen: false,
  error: null,

  toggleDrawer: () => {
    set((state) => ({
      isDrawerOpen: !state.isDrawerOpen,
    }));
  },

  connect: () => {
    const existingConnection = get().connection;

    if (existingConnection) {
      return console.log("ws already connected");
    }

    const accessToken = localStorage.getItem("id_token");
    const url =
      import.meta.env.VITE_WEBSOCKET_ENDPOINT + "?Auth=" + accessToken;

    if (!accessToken) {
      set({ error: "No access token found" });
      return;
    }

    const client = new WebSocket(url);

    client.onopen = () => {
      console.log("ws connection established");
      set({
        connection: client,
        isConnected: true,
        error: null,
      });

      get().sendMessage({
        action: "message",
        payload: "init",
      });
    };

    // Message handler
    client.onmessage = (event) => {
      try {
        console.log("ws message:", event.type, event.data);
        const message: IncomingMessage = JSON.parse(event.data);

        console.log("message1", message);

        if (message.type === "notifications") {
          const newNotifications = message.payload as Notification[];

          if (!get().isDrawerOpen) {
            openNotification();
          }

          set((state) => ({
            notifications: [...state.notifications, ...newNotifications],
          }));
        }
      } catch (parseError) {
        console.error("Failed to parse message", parseError);
      }
    };

    client.onerror = (error) => {
      console.error("ws error:", error);
      set({
        error: "ws error",
        isConnected: false,
      });
    };

    client.onclose = (event) => {
      console.log("ws connection closed", event);
      set({
        connection: null,
        isConnected: false,
      });
    };
  },

  disconnect: () => {
    const connection = get().connection;
    if (connection) {
      connection.close();
      set({
        connection: null,
        isConnected: false,
      });
    }
  },

  sendMessage: (message: NewMessage) => {
    const connection = get().connection;
    if (connection && connection.readyState === connection.OPEN) {
      connection.send(JSON.stringify(message));
    } else {
      set({ error: "ws is not connected" });
    }
  },

  clearMessages: () => {
    set({ notifications: [] });
  },
}));

const openNotification = () => {
  notification.open({
    message: "Notification Title",
    description:
      "This is the content of the notification. This is the content of the notification. This is the content of the notification.",
    icon: <MailOutlined />,
    duration: 20000,
  });
};
