import { Card, Tabs } from "antd";
import ProfileManager from "./profile";
import PermissionsGuard from "@/components/Auth/can";
import AccessDenied from "@/components/Auth/accessDenied";

function Profile() {
  const tabsItems = [
    {
      label: "Basic Settings",
      key: "1",
      children: <ProfileManager />,
    },
  ];

  return (
    <PermissionsGuard
      perform={"admin_team_profile:view"}
      yes={() => (
        <>
          <Card className="body-card">
            <Tabs tabPosition="left" items={tabsItems} />
          </Card>
        </>
      )}
      no={() => <AccessDenied permission="admin_team_profile:view" />}
    />
  );
}

export default Profile;
