import { gql } from "@apollo/client";
import { ADDRESS_LIST_FRAGMENT } from "../../../graphql/queries";

export const USER_DATA_FRAGMENT = gql`
  fragment UserDataFragment on User {
    id
    email
    otpConfirmed
    otpRetries
    emailVerified
    firstName
    lastName
    idNumber
    phone
    secondaryPhone
    secondaryEmail
    workPhone
    homePhone
    createdDate
    age
    maritalStatus
    ethnicity
    isEmancipated
    avatarUrl
    userStatus
    councilRegistrationNumber
    highestGrade
    highestGradeYear
    certificates {
      id
      toDate
    }
    applications {
      id
      registrationType
      registrationCapacity
      registeredAs
      applicationStatus {
        id
        name
      }
      company {
        id
      }
    }
    prequalification {
      id
      reportUrl
    }
    employment {
      id
      councilMembershipNumber
      employerAddressType
      employerBranchLocation
      capacity {
        id
        name
      }
      businessUnit {
        id
        name
      }
      company {
        id
        financialYearStartDate
        financialYearEndDate
        registeredNumber
        registeredName
        membershipNumber
        vatNumber
        tradingName
        tradingAs
        status
      }
    }
    userUserRoles {
      id
      company {
        id
        registeredName
      }
      userRole {
        name
        permissions {
          id
          name
        }
        stream {
          id
          name
        }
      }
    }
    requirePasswordReset
    isMigratedUser
  }
`;

export const GET_USER = gql`
  query ($input: GetUserInput!) {
    userData: user(input: $input) {
      ...UserDataFragment
    }
  }
  ${USER_DATA_FRAGMENT}
`;

export const GET_USER_LIST = gql`
  query users($role: String!, $skip: Int!, $take: Int!) {
    users(role: $role, skip: $skip, take: $take) {
      userList {
        id
        userStatus
        firstName
        lastName
        email
        phone
        capacity {
          id
          name
        }
        employment {
          id
          employerAddressType
          employerBranchLocation
          capacity {
            id
            name
          }
          businessUnit {
            id
            name
          }
          company {
            id
            financialYearStartDate
            financialYearEndDate
            registeredNumber
            registeredName
            membershipNumber
            vatNumber
            tradingName
          }
        }
        applications {
          id
          registrationType
          registrationCapacity
          registeredAs
          applicationStatus {
            id
            name
          }
        }
        createdDate
        userUserRoles {
          userRole {
            name
            permissions {
              id
              name
            }
          }
        }
      }
      total
    }
  }
`;

export const GET_ADMINS = gql`
  query admins($take: Int, $skip: Int, $disablePermissions: Boolean) {
    admins(take: $take, skip: $skip, disablePermissions: $disablePermissions) {
      userList {
        id
        idNumber
        firstName
        lastName
        email
        phone
        userStatus
        createdDate
        userUserRoles {
          userRole {
            name
            permissions {
              id
              name
            }
          }
        }
      }
      total
    }
  }
`;

export const GET_USERS_BY_FILTER = gql`
  query usersByFilter(
    $role: String!
    $filter: String!
    $criteria: String!
    $skip: Int!
    $take: Int!
    $includeTotal: Boolean!
    $isTeam: Boolean
  ) {
    usersByFilter(
      role: $role
      filter: $filter
      criteria: $criteria
      skip: $skip
      take: $take
      includeTotal: $includeTotal
      isTeam: $isTeam
    ) {
      userList {
        id
        idNumber
        firstName
        lastName
        email
        phone
        userStatus
        employment {
          id
          employerAddressType
          employerBranchLocation
          capacity {
            id
            name
          }
          businessUnit {
            id
            name
          }
          company {
            id
            financialYearStartDate
            financialYearEndDate
            registeredNumber
            registeredName
            membershipNumber
            vatNumber
            tradingName
          }
        }
        certificates {
          id
          toDate
          fromDate
          active
          debtCollectorEmployment {
            id
          }
        }
        applications {
          id
          dateCreated
          registeredAs
          registrationType
          registrationCapacity
          applicationStatus {
            id
            name
          }
        }
        createdDate
        userUserRoles {
          userRole {
            name
            permissions {
              id
              name
            }
          }
        }
      }
      total
    }
  }
`;

export const GET_USER_TYPES = gql`
  query userRoles {
    userTypesList: userRoles {
      id
      name
      description
      permissions {
        id
        name
        description
      }
    }
  }
`;

export const GET_PERMISSIONS = gql`
  query permissions {
    permissionsList: permissions {
      id
      name
      description
      roles {
        id
        name
      }
    }
  }
`;

export const GET_BLACKLISTED_ENTITIES = gql`
  query getBlacklist($type: String!) {
    getBlacklist(type: $type) {
      id
      reason
      createdAt
      isActive
      listedUntil
      listedBy {
        id
        firstName
        lastName
      }
      user {
        id
        firstName
        lastName
        idNumber
      }
      company {
        id
        registeredName
      }
    }
  }
`;

export const GET_BLACKLIST_STATUS = gql`
  query getEntityBlacklistStatus($id: Int!, $type: String!) {
    getEntityBlacklistStatus(id: $id, type: $type) {
      id
      reason
      createdAt
      isActive
      listedUntil
      listedBy {
        id
        firstName
        lastName
      }
    }
  }
`;

export const GET_USER_FILTERS = gql`
  query userFilters {
    userFilters {
      capacities {
        id
        name
      }
      userTypes {
        id
        name
      }
    }
  }
`;

export const GET_PROJECT_STATUS = gql`
  query projectStatus {
    projectStatusList: projectStatus {
      id
      name
    }
  }
`;

export const GET_BILLING_STATUS = gql`
  query billingStatus {
    billingStatusList: billingStatus {
      id
      name
    }
  }
`;

export const GET_BUSINESS_UNITS = gql`
  query bus {
    buList: bus {
      id
      name
      description
    }
  }
`;
export const GET_USER_ROLES = gql`
  query userRoles {
    userRolesList: userRoles {
      id
      name
    }
  }
`;

export const GET_TEAM_ROLES = gql`
  query teamRoles {
    teamRolesList: teamRoles {
      id
      name
    }
  }
`;

export const GET_COMPANIES = gql`
  query companies($role: String!, $skip: Int!, $take: Int!) {
    companies(role: $role, skip: $skip, take: $take) {
      companyList {
        id
        registeredName
        tradingName
        membershipNumber
      }
    }
  }
`;

export const GET_COMPANIES_BY_MULTIFILTER = gql`
  query companiesByMultiFilter($input: CompanyQueryInput!) {
    companiesByMultiFilter(input: $input) {
      companyList {
        id
        registeredName
        tradingName
        membershipNumber
        employmentCount
        status
        certificates {
          id
          toDate
          fromDate
        }
      }
      total
    }
  }
`;

export const GET_WITHDRAWN_EMPLOYMENT_BY_FILTER = gql`
  query getWithdrawnEmploymentsByFilter($input: WithdrawnEmploymentFilter!) {
    getWithdrawnEmploymentsByFilter(input: $input) {
      result {
        id
        withdrawnBy
        createdAt
        reason
        employment {
          id
          councilMembershipNumber
          company {
            id
            registeredName
            tradingName
          }
          user {
            id
            firstName
            lastName
          }
        }
      }
      count
    }
  }
`;

export const GET_PENDING_APPLICATIONS_BY_FILTER = gql`
  query getPendingApplicationsByFilter($input: PendingApplicationFilters!) {
    getPendingApplicationsByFilter(input: $input) {
      result {
        id
        dateCreated
        registrationType
        applicantionType
        employee {
          id
          firstName
          lastName
          userStatus
        }
        employer {
          id
          registeredName
          tradingName
        }
        createdBy {
          id
          firstName
          lastName
        }
      }
      count
    }
  }
`;

export const GET_COMPANY_INFO = gql`
  query companyBusinessInformation($companyId: Int!) {
    companyBusinessInformation(companyId: $companyId) {
      members {
        id
        email
        firstName
        lastName
        idNumber
        phone
        secondaryEmail
        secondaryPhone
        workPhone
        homePhone
        age
        isEmancipated
        capacity {
          id
          name
        }
      }
      cipcDocument {
        id
        name
        documentPath
      }
      locations {
        id
        addressName
        city
        suburb
        addressType {
          id
          name
        }
        domicilliumAddress
        streetAddress
        postalCode
        location
        country
      }
      contacts {
        id
        businessUnitContactName
        businessUnit {
          id
          name
        }
        cellphone
        secondaryCellphone
        email
        secondaryEmail
        workPhone
        homePhone
      }
      company {
        id
        registrationType
        registrationCapacity
        natureOfBusiness
        registeredName
        registeredNumber
        tradingName
        tradingAs
        financialYearStartDate
        financialYearEndDate
        membershipNumber
        isRegisteredForVat
        vatNumber
        createdBy {
          id
        }
        cpbReport
      }
    }
  }
`;

export const SEARCH_COMPANIES = gql`
  query searchCompanies($filter: String!, $criteria: String!) {
    companiesByFilter(filter: $filter, criteria: $criteria) {
      companyList {
        id
        registeredName
        tradingName
        membershipNumber
      }
    }
  }
`;

export const SEARCH_USERS = gql`
  query searchUsers($name: String!) {
    searchUsers(name: $name) {
      id
      firstName
      lastName
    }
  }
`;

export const GET_USER_EMAIL_BY_ID = gql`
  query userEmailByIdNumber($idNumber: String!) {
    userEmailByIdNumber(idNumber: $idNumber)
  }
`;

export const GENERATE_MEMBERSHIP_CARD = gql`
  query generateMembershipCard($userId: Int!, $companyId: Int!) {
    generateMembershipCard(userId: $userId, companyId: $companyId)
  }
`;

export const GET_MEMBER_OVERVIEW_INFORMATION = gql`
  query getMemberOverviewInformation($userId: Int!) {
    qualificationsList: educationalQualifications(userId: $userId) {
      id
      institutionName
      year
      qualification
      type {
        id
        name
      }
    }
    languageProficiencyList: languageProficiencies(userId: $userId) {
      id
      proficiencyLevel {
        id
        name
      }
      abilities {
        id
        name
      }
      language {
        id
        name
      }
    }
    userData: user(input: { id: $userId }) {
      ...UserDataFragment
    }
    personalLocationData: addresses(userId: $userId) {
      ...AddressListFragment
    }
  }
  ${USER_DATA_FRAGMENT}
  ${ADDRESS_LIST_FRAGMENT}
`;

export const GET_COMPANY_OVERVIEW_INFORMATION = gql`
  query getCompanyOverviewInformation($companyId: Int!) {
    company(input: { id: $companyId }) {
      id
      registeredName
      tradingName
    }
  }
`;

export const GET_USERS_BY_ROLE_STREAM = gql`
  query usersByRoleStream($stream: String!) {
    usersByRoleStream(stream: $stream) {
      userList {
        id
        firstName
        lastName
      }
    }
  }
`;

export const SEARCH_EMPLOYEES = gql`
  query searchCompanyEmployee($input: SearchEmployeeInput!) {
    searchCompanyEmployee(input: $input) {
      id
      firstName
      lastName
      idNumber
    }
  }
`;

export const GET_TOP_20_COLLECTORS = gql`
  query getTopCollectors {
    getTopCollectors {
      companyId
      registeredName
      tradingName
      numberOfEmployees
    }
  }
`;

export const GET_COMPLIANCE_REPORTS = gql`
  query getComplianceReport($input: GetComplianceReportInput!) {
    getComplianceReport(input: $input) {
      id
      capacity
      received
      approved
      active
      failed
      deregistered
      unemployed
      refused
      suspended
    }
  }
`;

export const GET_FUTURE_CONTROL_LIST_REPORT = gql`
  query getFutureControlListReport($input: GetFutureControlListReportInput!) {
    getFutureControlListReport(input: $input)
  }
`;

export const GET_CUSTOM_REPORTS = gql`
  query getCustomReport($input: GetCustomReportInput!) {
    getCustomReport(input: $input) {
      total
      userListString
      companyList {
        id
        registeredName
        tradingName
        membershipNumber
        employmentCount
        status
        dateCreated
        natureOfBusiness
        membershipNumber
        certificates {
          toDate
        }
      }
      auditList {
        dateCreated
        id
        bbeeeLevel
        industry
        geographicalArea
        valueOfBooks
        amountRecovered
        financialYear
        task {
          id
          taskStatus
          isManual
          company {
            tradingName
            registeredName
          }
        }
      }
    }
  }
`;

export const GET_REPORTS = gql`
  query getReports($input: GetReportInput!) {
    getReports(input: $input) {
      total
      reportString
      reports {
        id
        employees
        relatedParties
        entity
        total
        date
        renewalTask {
          id
          taskStatus
          createdDate
          modifiedDate
          applicantType
          type {
            id
            name
          }
          relatedTask {
            id
            type {
              name
            }
          }
          relatedTasks {
            id
            type {
              name
            }
          }
          transactions {
            id
            status {
              id
              name
            }
          }
          application {
            id
            applicantType
            applicationStatus {
              id
              name
            }
          }
          employee {
            firstName
            lastName
            id
            email
            idNumber
            applications {
              id
              applicantType
              applicationStatus {
                id
                name
              }
            }
          }
          company {
            id
            registeredName
            natureOfBusiness
            registeredName
            registeredNumber
            tradingName
            tradingAs
            vatNumber
            createdBy {
              id
              email
              firstName
              lastName
            }
            application {
              id
              applicantType
              applicationStatus {
                id
                name
              }
            }
          }
          complaint {
            id
            firstName
            lastName
            email
            contactNumber
            identificationType
            identificationNumber
            complaintAgainst
            debtCollectorName
            debtCollectorCompany
            debtCollectorContactNumber
            debtCollectorEmail
            description
            debtCollector {
              id
              userStatus
            }
          }
          employeeInvitation {
            id
          }
          invoice {
            id
            amount
            sagePayload
            sageInvoiceId
          }
        }
        company {
          id
          registeredName
          tradingName
          createdBy {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;
export const DOWNLOAD_REPORTS = gql`
  query getReports($input: GetReportInput!) {
    getReports(input: $input) {
      total
      reports {
        id
        employees
        relatedParties
        entity
        total
        renewalTask {
          id
        }
        company {
          id
          registeredName
          tradingName
        }
      }
    }
  }
`;

export const GET_REPORT_AMOUNT = gql`
  query getReportAmount($input: GetReportAmountInput!) {
    getReportAmount(input: $input) {
      amount
    }
  }
`;

export const GET_BACKGROUND_JOBS = gql`
  query getLambdaExecutionLogs {
    getLambdaExecutionLogs {
      id
      functionName
      functionDescription
      status
      details
      startedAt
      endedAt
    }
  }
`;

export const GET_DASHBOARD_DATA = gql`
  query getDashboardData {
    getDashboardData {
      userCount {
        status
        count
      }
      companyCount {
        status
        count
      }
      taskCount {
        status
        count
      }
      withdrawnEmployment {
        count
        month
        name
      }
      application {
        count
        month
        name
      }
      renewal {
        count
        month
        name
      }
      jobs {
        functionName
        status
        startedAt
        endedAt
        id
      }
    }
  }
`;

export const GET_USER_EMPLOYMENT_HISTORY = gql`
  query getUserEmploymentHistory($userId: Int!) {
    getUserEmploymentHistory(userId: $userId) {
      id
      firstName
      lastName
      idNumber
      status
      employmentHistory {
        id
        membershipNumber
        dateOfRegistration
        capacity
        employmentDate
        company {
          id
          name
        }
        certificates {
          id
          to
          from
        }
      }
    }
  }
`;
