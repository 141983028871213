import { useState, useEffect } from "react";
import { UploadOutlined, UserOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  Card,
  Divider,
  Comment,
  Avatar,
  Upload,
  Spin,
} from "antd";
import { useMutation, useLazyQuery, useQuery } from "@apollo/client";
import {
  CREATE_REVIEW_COMMENT,
  CREATE_DOCUMENT,
} from "../../../graphql/mutations";
import {
  GET_APPLICATION_REVIEW_INFO,
  GET_PRESIGNED_URL,
  GET_DOWNLOAD_URL,
} from "../../../graphql/queries";
import { isEmpty } from "lodash";
import { parseISO, format } from "date-fns";
import { notifyError, openNotificationWithIcon } from "@/utils/notification";
import { uploadToS3 } from "@/utils/uploadToS3";
import { useNavigate } from "react-router-dom";
import { useGlobalState } from "@/globalStore";

const ReviewComment: React.FC<any> = (props) => {
  const { state } = useGlobalState();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [comment, setComment] = useState("");
  const [reviewStepComments, setReviewStepComments] = useState([]);
  const [filePath, setFilePath] = useState<{ path: string; name: string }>();
  const [uploading, setUploading] = useState(false);
  const [file, setFile] = useState<any>();
  const [uploadedDocs, setUploadedDocs] = useState([]);
  const [loadingDoc, setLoadingDoc] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState("");

  const { id, step, company } = props;
  const taskType = props.type.name;

  useEffect(() => {
    if (downloadUrl) window.open(downloadUrl);
  }, [downloadUrl]);

  const isAdmin = (userId: string): boolean => {
    return (
      state.lookups?.admins?.userList?.some((a) => a.id === userId) ?? false
    );
  };

  const { loading: loadingReview, refetch } = useQuery(
    GET_APPLICATION_REVIEW_INFO,
    {
      fetchPolicy: "network-only",
      skip: !id,
      variables: { taskId: id },
      onCompleted: async (data) => {
        if (data.reviewByTask) {
          const comments = data.reviewByTask?.steps?.reduce((prev, curr) => {
            return [...prev, ...curr.comments];
          }, []);
          setReviewStepComments(comments);
        }
      },
      onError: (err) => {
        if (err.graphQLErrors[0]?.message === "Not Authorised!") {
          refetch().then((res) => {
            console.log(res.data);
          });
        } else {
          notifyError(err);
        }
      },
    }
  );

  const [createReviewComment] = useMutation(CREATE_REVIEW_COMMENT, {
    onCompleted: () => {
      setIsSubmitting(false);
      navigate("/tasks");
    },
    onError: (err) => {
      openNotificationWithIcon("error", "Save Error", err.message);
      setIsSubmitting(false);
    },
  });

  const [createDocument] = useMutation(CREATE_DOCUMENT);

  const [getDownloadUrl] = useLazyQuery(GET_DOWNLOAD_URL, {
    fetchPolicy: "no-cache",
    onError: (err) => {
      setLoadingDoc(false);
      console.error("generate document error", err);
      openNotificationWithIcon(
        "error",
        "Load Error",
        "There was an error retrieving the document"
      );
    },
    onCompleted: (data) => {
      setLoadingDoc(false);
      setDownloadUrl(data.preSignedDownloadUrl);
    },
  });

  const [getPresignedUrl] = useLazyQuery(GET_PRESIGNED_URL, {
    fetchPolicy: "network-only",
    onCompleted: async ({ preSignedUrl }) => {
      if (preSignedUrl) {
        await uploadToS3(preSignedUrl, file.type, file);
        const { data } = await createDocument({
          variables: {
            input: {
              documentName: filePath?.name,
              documentPath: filePath?.path,
              documentTypeId: 27,
              documentStatusId: 1,
              documentFormat: file.type,
              forCompany: true,
              companyId: company?.id || 1,
            },
          },
        });

        setUploadedDocs([...uploadedDocs, data.createDocument.id]);
      }
    },
    onError: (err) => {
      console.error({ err });
    },
  });

  const handleUpload = async ({ file, onSuccess }: any) => {
    setUploading(true);
    setFile(file);

    const ext = file.type.split("/").pop();
    const name = `user_upload_${Date.now()}`;
    const path = `review-documents/${name}.${ext}`;
    setFilePath({ path, name });

    getPresignedUrl({
      variables: {
        input: {
          filePath: path,
          contentType: file.type,
          forCompany: false,
          companyName: "Admin",
        },
      },
    });

    setTimeout(() => {
      setUploading(false);
      onSuccess();
    }, 3500);
  };

  const handleViewDocument = (filePath: string) => {
    setLoadingDoc(true);
    getDownloadUrl({
      variables: {
        input: {
          filePath,
          forCompany: true,
        },
      },
    });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    if (taskType === "Audit Certificate") {
      await createReviewComment({
        variables: {
          input: {
            step,
            creatorId: Number.parseInt(localStorage.getItem("userId")),
            companyId: company?.id,
            taskId: id,
            comment: !isEmpty(comment) ? comment : undefined,
          },
        },
      });
    }
  };

  return reviewStepComments?.length ? (
    <div>
      <Divider />
      <div>
        <h3>Comments</h3>
        <Spin spinning={loadingDoc}>
          {reviewStepComments.map((comment) => {
            return (
              <Comment
                key={comment.id?.toString()}
                author={`${comment?.author?.firstName} ${
                  comment?.author?.lastName
                } ${comment?.author?.id === props.userId ? "(You)" : ""}`}
                avatar={
                  <Avatar
                    style={{
                      backgroundColor: isAdmin(comment?.author?.id)
                        ? "rgb(82, 196, 26)"
                        : "#408ef3",
                    }}
                    icon={<UserOutlined />}
                  />
                }
                content={
                  <div>
                    <span>{comment.body}</span>
                    <br />
                    {comment?.documents?.map(({ documentPath }) => (
                      <span
                        onClick={() => handleViewDocument(documentPath)}
                        className="purple-link pointer"
                        key={documentPath}
                      >
                        {documentPath.split("/")[1]}
                      </span>
                    ))}
                  </div>
                }
                datetime={format(
                  parseISO(comment.createdDate),
                  "yyyy-MM-dd hh:mm:ss"
                )}
              />
            );
          })}
        </Spin>
      </div>
      <h3> Add a comment</h3>
      <Card
        className="comment-card"
        cover={
          <Input.TextArea
            style={{ resize: "none" }}
            rows={4}
            placeholder="Start typing..."
            value={comment}
            disabled={isSubmitting}
            onChange={(e) => setComment(e.target.value)}
          />
        }
      >
        <Card.Meta
          title={
            <div className="comment-action">
              <Upload disabled={uploading} customRequest={handleUpload}>
                <Button>
                  <UploadOutlined /> Upload
                </Button>
              </Upload>
              <div>
                <Button
                  className="purple-button"
                  loading={isSubmitting || loadingReview}
                  onClick={handleSubmit}
                  style={{ width: "150px" }}
                >
                  Save
                </Button>
              </div>
            </div>
          }
        />
      </Card>
    </div>
  ) : null;
};

export default ReviewComment;
