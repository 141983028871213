export async function uploadToS3(
  fileUploadUrl: string,
  contentType: string,
  file: any
): Promise<any> {
  try {
    const response = await fetch(fileUploadUrl, {
      method: "PUT",
      headers: {
        Accept: contentType,
        "Content-Type": file.type,
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Origin": "*"
      },
      body: file
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.text();
  } catch (error) {
    return Promise.reject(error);
  }
}
