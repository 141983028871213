import PrequalificationCompany from "../RegisterCompany/prequalification";
import PrequalificationEmployee from "../RegisterEmployee/prequalification";
import { If } from "@/components/If";
import { useRegistrationState } from "@/stores/registration";

type Props = {
  nextStep: () => void;
};

export default function Prequalification({ nextStep }: Props) {
  const { registrationCapacity } = useRegistrationState();

  return (
    <>
      <If condition={registrationCapacity === "Business"}>
        <PrequalificationCompany />
      </If>
      <If condition={registrationCapacity === "Individual"}>
        <PrequalificationEmployee nextStep={nextStep} />
      </If>
    </>
  );
}
