import { useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import { Button, Input, Select, Card, Skeleton, Table, Form } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_COMPANY_CONTACT,
  UPDATE_COMPANY_CONTACT,
} from "../../graphql/mutations";
import {
  GET_BUSINESS_UNITS_LIST,
  GET_COMPANY_CONTACTS,
} from "../../graphql/queries";

import InternalErrorPage from "@/containers/InternalErrorPage";
import {
  notifyInfo,
  notifySuccess,
  openNotificationWithIcon,
} from "@/utils/notification";

import { isEmpty } from "lodash";
import { validateMobilePhoneNumber } from "@/utils/validatePhoneNumber";
import { useRegistrationState } from "@/stores/registration";
import { If } from "@/components/If";

interface IProps {
  useCompanyId?: number | boolean;
  onDone?: (
    isSaved: boolean,
    requiredContactsSaved: boolean,
    contacts: any
  ) => void;
}

function ContactsInfoForm({ onDone, useCompanyId = false }: IProps) {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { Option } = Select;
  const [addingContact, setAddingContact] = useState(false);
  const [selectedContact, setSelectedContact] = useState<any>();
  const [companyContactsInfo, setCompanyContactsInfo] = useState([]);
  const [createCompanyContact] = useMutation(CREATE_COMPANY_CONTACT);
  const [updateCompanyContact] = useMutation(UPDATE_COMPANY_CONTACT);

  const { companyId: cid } = useRegistrationState();

  const companyId = useCompanyId ? +useCompanyId : cid;

  const {
    loading: loadingBusinessUnits,
    error: errorBusinessUnits,
    data: dataBusinessUnits,
  } = useQuery(GET_BUSINESS_UNITS_LIST);

  const {
    loading: loadingContacts,
    error: errorContacts,
    data: dataContacts,
    refetch: refetchCompanyContacts,
  } = useQuery(GET_COMPANY_CONTACTS, {
    variables: {
      companyId,
    },
    onCompleted: () => {
      if (!isEmpty(dataContacts.companyContacts)) {
        setCompanyContactsInfo(dataContacts.companyContacts);

        // check if Legal, Finance and Administration business unit contacts have been saved
        const requiredContactsSaved =
          dataContacts.companyContacts.filter((x) =>
            ["Finance", "Legal", "Administration"].includes(x.businessUnit.name)
          ).length >= 3;

        // pass contact information to parent
        if (onDone) {
          onDone(false, requiredContactsSaved, dataContacts.companyContacts);
        }
      }
    },
  });

  if (loadingBusinessUnits || loadingContacts) {
    return <Skeleton active paragraph={{ rows: 5 }} />;
  }

  if (errorBusinessUnits || errorContacts) {
    return <InternalErrorPage />;
  }

  const { businessUnitList } = !loadingBusinessUnits && dataBusinessUnits;

  // selectable business units options
  const businessUnitOptions: any = businessUnitList
    ? businessUnitList
        .filter(
          (unit) =>
            ["Finance", "Legal", "Administration"].indexOf(unit.name) > -1
        )
        .map((type) => {
          return (
            <Option key={type.id} value={type.id}>
              {type.name}
            </Option>
          );
        })
    : [];

  const handleSubmitContactInformation = async (values: any) => {
    setIsSubmitting(true);
    //update user details
    const upsertCompanyContact = !isEmpty(selectedContact)
      ? updateCompanyContact
      : createCompanyContact;

    const resp = await upsertCompanyContact({
      variables: {
        input: {
          id: selectedContact?.id,
          secondaryCellphone: values.phoneNumber2,
          email: values.email,
          workPhone: values.workNumber,
          secondaryEmail: values.email2,
          businessUnitId: values.businessUnitId,
          businessUnitContactName: values.businessUnitContact,
          cellphone: values.phoneNumber,
          homePhone: values.homeNumber,
          companyId: isEmpty(selectedContact) ? companyId : undefined,
        },
      },
    });

    if (resp.errors) {
      setIsSubmitting(false);
      return notifyInfo(resp.errors[0].message);
    }

    form.resetFields();

    //setIsUserAdditionalInfoSaved(true);
    const refetch_resp = await refetchCompanyContacts();
    if (refetch_resp.errors) {
      return openNotificationWithIcon(
        "error",
        "Refetch",
        "Error encountered when refetching data"
      );
    }

    setSelectedContact({});
    setIsSubmitting(false);
    setAddingContact(false);
    setCompanyContactsInfo(refetch_resp.data.companyContacts);

    // check if Legal, Finance and Administration business unit contacts have been saved
    const requiredContactsSaved =
      refetch_resp.data.companyContacts.filter((x) =>
        ["Finance", "Legal", "Administration"].includes(x.businessUnit.name)
      ).length >= 3;

    if (onDone) {
      onDone(true, requiredContactsSaved, companyContactsInfo);
    }
    return notifySuccess("Information updated successfully");
  };

  return (
    <>
      <If condition={!isEmpty(companyContactsInfo)}>
        <Table
          dataSource={companyContactsInfo}
          pagination={false}
          rowKey={(record) => String(record.id)}
          columns={[
            {
              key: "unit",
              title: "Unit",
              render: (record) => record.businessUnit.name,
            },
            {
              key: "email",
              title: "Email",
              render: (record) => record.email,
            },
            {
              key: "cellphone",
              title: "Cellphone",
              render: (record) => record.cellphone,
            },
            {
              key: "workPhone",
              title: "Work Phone",
              render: (record) => record.workPhone,
            },
            {
              key: "homePhone",
              title: "Home Phone",
              render: (record) => record.homePhone,
            },
            {
              key: "cellphone2",
              title: "Secondary Cellphone",
              render: (record) => record.secondaryCellphone,
            },
            {
              key: "email2",
              title: "Secondary Email",
              render: (record) => record.secondaryEmail,
            },
            {
              key: "actions",
              title: "Actions",
              render: (record: any) => (
                <Button
                  icon={<EditOutlined />}
                  type="link"
                  className="purple-text"
                  onClick={() => {
                    setSelectedContact(record);
                    setAddingContact(true);
                  }}
                >
                  Edit
                </Button>
              ),
            },
          ]}
        />
      </If>
      <br />
      {addingContact && (
        <Card className="">
          <Form
            form={form}
            onFinish={handleSubmitContactInformation}
            layout="vertical"
          >
            <div className="flex-row gap-4">
              <Form.Item
                className="flex-1"
                label="Business Unit"
                required
                {...{
                  name: "businessUnitId",
                  initialValue: selectedContact?.businessUnit?.id,
                  rules: [
                    {
                      required: true,
                      message: "Please select business unit",
                    },
                  ],
                }}
              >
                <Select style={{ width: "100%" }} placeholder="Select">
                  {businessUnitOptions}
                </Select>
              </Form.Item>
              <Form.Item
                className="flex-1"
                label="Business Unit Contact Name"
                required
                {...{
                  name: "businessUnitContact",
                  initialValue: selectedContact?.businessUnitContactName,
                  rules: [
                    {
                      required: true,
                      message: "Please enter name",
                    },
                  ],
                }}
              >
                <Input placeholder="Enter name" />
              </Form.Item>
            </div>

            <div className="flex-row gap-4">
              <Form.Item
                className="flex-1"
                label="Cellphone"
                required
                {...{
                  name: "phoneNumber",
                  initialValue: selectedContact?.cellphone,
                  rules: [
                    {
                      required: true,
                      message: "Please enter a phone number",
                    },
                    {
                      validator: validateMobilePhoneNumber,
                    },
                  ],
                }}
              >
                <Input placeholder="Enter cellphone" />
              </Form.Item>
              <Form.Item
                className="flex-1"
                label="Cellphone 2"
                {...{
                  name: "phoneNumber2",
                  initialValue: selectedContact?.secondaryCellphone,
                  rules: [
                    {
                      required: false,
                    },
                    {
                      validator: validateMobilePhoneNumber,
                    },
                  ],
                }}
              >
                <Input placeholder="Enter secondary phone" />
              </Form.Item>
            </div>

            <div className="flex-row gap-4">
              <Form.Item
                className="flex-1"
                label="Work Number"
                required
                {...{
                  name: "workNumber",
                  initialValue: selectedContact?.workPhone,
                  rules: [
                    {
                      required: true,
                      message: "Please enter work number",
                    },
                  ],
                }}
              >
                <Input placeholder="Enter work number" />
              </Form.Item>
              <Form.Item
                className="flex-1"
                label="Home Number"
                {...{
                  name: "homeNumber",
                  initialValue: selectedContact?.homePhone,
                  rules: [
                    {
                      required: false,
                      message: "Please enter home number",
                    },
                  ],
                }}
              >
                <Input placeholder="Enter home number" />
              </Form.Item>
            </div>

            <div className="flex-row gap-4">
              <Form.Item
                className="flex-1"
                label="Email"
                required
                {...{
                  name: "email",
                  initialValue: selectedContact?.email,
                  rules: [
                    {
                      required: true,
                      message: "Please input email address",
                    },
                    {
                      type: "email",
                      message: "Please enter a valid email address",
                    },
                  ],
                }}
              >
                <Input placeholder="Enter email" />
              </Form.Item>
              <Form.Item
                className="flex-1"
                label="Email 2"
                {...{
                  name: "email2",
                  initialValue: selectedContact?.secondaryEmail,
                  rules: [
                    {
                      required: false,
                      message: "Please input secondary email",
                    },
                    {
                      type: "email",
                      message: "Please enter a valid email address",
                    },
                  ],
                }}
              >
                <Input placeholder="Enter secondary email" />
              </Form.Item>
            </div>

            <div className="flex-row">
              <Button
                className="btn-registration-capacity-selected"
                htmlType="submit"
                loading={isSubmitting}
              >
                Save contact information
              </Button>
            </div>
          </Form>
        </Card>
      )}
      <div className="input-block input-select ">
        <Button
          type="default"
          className="purple-button"
          style={{
            width: "100%",
            height: "40px",
          }}
          onClick={() => setAddingContact(true)}
          disabled={addingContact}
          loading={isSubmitting}
        >
          + Add Contact
        </Button>
      </div>
    </>
  );
}

export default ContactsInfoForm;
