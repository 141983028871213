import { useState } from "react";
import { Table } from "antd";
import { downloadPdfBuffer } from "@/utils";

type Props = {
  reportList: any[];
  currentPage: number;
  pageSize: number;
  listTotal: number;
};

export default ({
  reportList = [],
  currentPage,
  pageSize,
  listTotal,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const columns = [
    {
      title: "Company Name",
      key: "name",
      render: ({ name }) => name?.toUpperCase(),
    },
    {
      title: "Month",
      render: (record) => record.month,
    },
    {
      title: "Employees",
      render: (record) => record.members,
    },
    {
      title: "Directors",
      render: (record) => record.directors,
    },
    {
      title: "Entity",
      render: (record) => record.entity,
    },
    {
      title: "Action",
      key: "html",
      render: ({ html, month, name }) => {
        return (
          <h4
            style={{ cursor: loading ? "disabled" : "pointer" }}
            className="purple-link"
            onClick={async () => {
              setLoading(true);
              downloadPdfBuffer(html, `${name} - ${month}.pdf`);
              setLoading(false);
            }}
          >
            {loading ? "Downloading ...." : "Download"}
          </h4>
        );
      },
    },
  ];

  return (
    <Table
      rowKey="id"
      size="small"
      columns={columns}
      pagination={{
        current: currentPage,
        hideOnSinglePage: true,
        showSizeChanger: false,
        pageSize,
        total: listTotal,
        showTotal: () => <h3>Total: {listTotal}</h3>,
      }}
      dataSource={reportList}
    />
  );
};
