import { Table, Tag } from "antd";
import { format } from "date-fns";

type Props = {
  auditList: any[];
  currentPage: number;
  pageSize: number;
  listTotal: number;
  handlePagination: (page: number) => void;
};
export default ({
  auditList,
  currentPage,
  pageSize,
  listTotal,
  handlePagination,
}: Props) => {
  debugger;
  console.log("-------------auditList--------------------", auditList);
  const columns = [
    {
      title: "Created",
      key: "dateCreated",
      render: ({ dateCreated: record }) =>
        format(new Date(record), "dd/MM/yyyy"),
    },
    {
      title: "Company",
      key: "task.company",
      render: (record) =>
        record?.task?.company
          ? (
              record?.task?.company?.tradingName ||
              record?.task.company?.registeredName
            ).toUpperCase()
          : "",
    },
    {
      title: "BBEEE Level",
      key: "bbeeeLevel",
      render: (record) => record.bbeeeLevel,
    },
    {
      title: "Industry",
      render: (record) => record.industry,
      key: "industry",
    },
    {
      title: "Geographical Area",
      render: (record) => record.geographicalArea,
      key: "geographicalArea",
    },
    {
      title: "Value of Books",
      render: (record) => record.valueOfBooks,
      key: "valueOfBooks",
    },
    {
      title: "Amount Recovered",
      render: (record) => record.amountRecovered,
      key: "amountRecovered",
    },
    {
      title: "Task ID",
      render: (record) => record.task?.id,
      key: "task.id",
    },
    {
      title: "Status",
      key: "status",
      render: (record) => {
        const _status = record.task?.taskStatus;
        if (!_status) return "";

        const status =
          _status.charAt(0).toUpperCase() + _status.slice(1).toLowerCase();

        return (
          <span style={{ display: "flex" }}>
            <Tag color={status === "Complete" ? "success" : "warning"}>
              {status}
            </Tag>
          </span>
        );
      },
    },
  ];

  return (
    <Table
      rowKey="id"
      size="small"
      columns={columns}
      pagination={{
        current: currentPage,
        hideOnSinglePage: true,
        showSizeChanger: false,
        pageSize,
        onChange: (page) => handlePagination(page),
        total: listTotal,
        showTotal: () => <h3>Total: {listTotal}</h3>,
      }}
      dataSource={auditList}
    />
  );
};
