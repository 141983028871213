import { useState, useMemo } from "react";
import { useMutation } from "@apollo/client";
import { Modal, Spin, Select, Input, InputNumber, Row, Col, Form } from "antd";
import { notifyError, notifySuccess } from "@/utils/notification";
import { CREATE_FINANCIAL_TASK } from "../graphql/mutations";

type Props = {
  companyDetails: any;
  visible: boolean;
  onCancel: () => void;
  setModalVisible: (arg: boolean) => void;
};

function calculateTaxExcl(amountIncl: number) {
  return Number((amountIncl / 1.15).toFixed(2));
}

export default function CreateFinancialTaskModal(props: Props) {
  const { onCancel, setModalVisible, companyDetails, visible } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [createTask] = useMutation<{ id: number }>(CREATE_FINANCIAL_TASK);

  const name = useMemo(() => {
    if (companyDetails)
      return (
        companyDetails.tradingName || companyDetails.registeredName
      ).toUpperCase();
    else return "";
  }, [companyDetails]);

  const handleCreateTask = async () => {
    try {
      setLoading(true);
      const values = await new Promise<any>((resolve) => {
        form
          .validateFields()
          .then((val) => {
            return resolve(val);
          })
          .catch(() => {});
      });

      if (!values) return;

      const { errors } = await createTask({
        variables: {
          input: { ...values, amountExcl: calculateTaxExcl(values.amountIncl) },
        },
      });

      if (errors?.length) {
        return notifyError(errors[0].message);
      }

      notifySuccess(`Successfully created a financial ticket for ${name}`);

      setModalVisible(false);
    } catch (error) {
      notifyError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      width="60%"
      open={visible}
      title={"Create Financial Task"}
      okText={"Create Task"}
      onCancel={onCancel}
      onOk={handleCreateTask}
      okButtonProps={{
        className: "purple-button",
        disabled: loading,
      }}
      cancelButtonProps={{
        disabled: loading,
      }}
      okType="default"
    >
      <Spin tip="Loading..." spinning={loading}>
        <Form form={form} layout="vertical">
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <Form.Item
                label="Type of Financial Ticket"
                name={"taskType"}
                {...{
                  initialValue: "Financial Ticket",
                  rules: [
                    {
                      required: true,
                      message: "Please select the type of financial ticket",
                    },
                  ],
                }}
              >
                <Select className="input-select">
                  <Select.Option value="Financial Ticket">
                    Financial Ticket (Registration)
                  </Select.Option>
                  <Select.Option value="Renewal">Renewal</Select.Option>
                  <Select.Option value="Penalty">Penalty</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Company"
                name="companyId"
                {...{
                  initialValue: companyDetails.id,
                  rules: [
                    {
                      required: true,
                      message: "Please select the company",
                    },
                  ],
                }}
              >
                <Select className="input-select">
                  <Select.Option
                    key={`item-${companyDetails.id}`}
                    value={companyDetails.id}
                  >
                    {name}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <Form.Item
                label={`Invoice Reference`}
                name="reference"
                {...{
                  initialValue: name,
                  rules: [
                    {
                      required: true,
                      message: "Please enter the payment reference",
                    },
                  ],
                }}
              >
                <Input
                  className="input-select"
                  placeholder="Please enter the payment reference"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Invoice Amount (Tax Incl)"
                name="amountIncl"
                required
                initialValue={0}
              >
                <InputNumber
                  step={1000}
                  min={0}
                  className="input-select"
                  placeholder="Please enter the invoice amount"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <Form.Item
                label={`Invoice Description`}
                name={"description"}
                required
              >
                <Input
                  className="input-select"
                  placeholder="Please enter the payment description"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={`Add a comment`} name="comment">
                <Input.TextArea
                  style={{ resize: "none" }}
                  rows={5}
                  placeholder="Start typing..."
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
}
