import { useState, useMemo, useEffect, useCallback } from "react";
import { Select, DatePicker, Button, InputNumber, Input, Row, Col } from "antd";
import { useLazyQuery } from "@apollo/client";
import debounce from "lodash/debounce";
import { GET_COMPANIES_BY_FILTER } from "../../../graphql/queries";
import { SEARCH_EMPLOYEES } from "../graphql/queries";
import { isFuture } from "date-fns";
import { TCustomFilter } from "@/typings";
import {
  bbeeeLevels,
  ETHNICITY_LIST,
  industries,
  MARITAL_STATUSES,
  months,
  provinces,
  USER_STATUS_TYPES,
} from "../../../constants";
import {
  COMPANY_COLUMNS,
  USER_COLUMNS,
  AUDIT_COLUMNS,
} from "../Reports/columns";
import { useReportingFilters } from "./useReportingFilters";
import { useAppState } from "@/stores/app";
import { PlusOutlined } from "@ant-design/icons";

const filterStyle = {
  width: "200px",
};

const initialFilter = Object.seal({
  1: { name: "id", display: "ID" },
});

export const CustomReportingFilters = () => {
  const [filters, setFilters] =
    useState<Record<number, TCustomFilter>>(initialFilter);
  const [companySearchResults, setCompanySearchResults] = useState<any[]>([]);
  const [userSearchResults, setUserSearchResults] = useState<any[]>([]);

  const companyTypes = useAppState((state) => state.lookups.companyTypes);
  const capacities = useAppState((state) => state.lookups.capacities);
  const { type: _type, customFilter, setFilterState } = useReportingFilters();

  const type = useMemo(() => {
    return _type.charAt(0).toUpperCase() + _type.slice(1);
  }, [_type]);

  const addFilter = (key: number, val: TCustomFilter) => {
    if (Object.keys(filters).length > 10) return;

    setFilters({ ...filters, [key]: val });
  };

  const [searchCompanies, { loading: loadingCompanies }] = useLazyQuery(
    GET_COMPANIES_BY_FILTER,
    {
      onError: console.error,
      onCompleted: ({ companiesByFilter }) => {
        setCompanySearchResults(companiesByFilter?.companyList);
      },
    }
  );

  const [searchUsers, { loading: loadingUsers }] = useLazyQuery(
    SEARCH_EMPLOYEES,
    {
      onError: console.error,
      onCompleted: ({ searchCompanyEmployee }) => {
        if (searchCompanyEmployee) setUserSearchResults(searchCompanyEmployee);
      },
    }
  );

  const handleCompanySearch = useCallback(debounce(searchCompanies, 250), []);

  const handleUserSearch = useCallback(debounce(searchUsers, 250), []);

  const columns = useMemo<any>(() => {
    switch (type) {
      case "Company":
        return COMPANY_COLUMNS;
      case "Member":
        return USER_COLUMNS;
      case "Audit":
        return AUDIT_COLUMNS;
      default:
        return [];
    }
  }, [type]);

  useEffect(() => {
    const values = Object.values(filters).reduce((acc, v) => {
      acc[v.name] = v.value;
      return acc;
    }, {});
    setFilterState({ custom: values });
  }, [filters]);

  useEffect(() => {
    setFilters({
      1: { name: "id", display: "ID" },
    });
  }, [type]);

  useEffect(() => {
    setFilterState({ custom: undefined });
  }, []);

  useEffect(() => {
    if (!customFilter || Object.keys(customFilter).length === 0) {
      setFilters(initialFilter);
    }
  }, [customFilter]);

  return (
    <Row>
      <Col span={21}>
        <Row gutter={24}>
          <Col className="flex-row flex-wrap gap-2">
            {Object.entries(filters).map(([k, f]) => (
              <div key={k} className="flex-row gap-2 mb-1">
                {
                  <>
                    <Select
                      onChange={(name: string) => addFilter(+k, { ...f, name })}
                      placeholder="Select the field"
                      style={filterStyle}
                    >
                      {columns.map((t) => (
                        <Select.Option key={t.name} value={t.name}>
                          {t.display || t.name}
                        </Select.Option>
                      ))}
                    </Select>{" "}
                    {f.name.startsWith("employment") && (
                      <Select
                        style={filterStyle}
                        onChange={(name: string) =>
                          addFilter(+k, {
                            ...f,
                            name: `${f.name.substring(
                              0,
                              f.name.indexOf("_") > 0
                                ? f.name.indexOf("_")
                                : undefined
                            )}_${name}`,
                          })
                        }
                        placeholder="Select the field"
                      >
                        {columns
                          .find((c) => c.name === "employment")
                          ?.fields.map((t) => (
                            <Select.Option key={t.name} value={t.name}>
                              {t.display || t.name}
                            </Select.Option>
                          ))}
                      </Select>
                    )}
                    {f.name.startsWith("certificate") && (
                      <Select
                        style={filterStyle}
                        onChange={(name: string) =>
                          addFilter(+k, {
                            ...f,
                            name: `${f.name.substring(
                              0,
                              f.name.indexOf("_") > 0
                                ? f.name.indexOf("_")
                                : undefined
                            )}_${name}`,
                          })
                        }
                        placeholder="Select the field"
                      >
                        {columns
                          .find((c) => c.name === "certificate")
                          ?.fields.map((t) => (
                            <Select.Option key={t.name} value={t.name}>
                              {t.display || t.name}
                            </Select.Option>
                          ))}
                      </Select>
                    )}
                    {f.name.startsWith("company") && (
                      <Select
                        style={filterStyle}
                        onChange={(name: string) =>
                          addFilter(+k, {
                            ...f,
                            name: `${f.name.substring(
                              0,
                              f.name.indexOf("_") > 0
                                ? f.name.indexOf("_")
                                : undefined
                            )}_${name}`,
                          })
                        }
                        placeholder="Select the field"
                      >
                        {columns
                          .find((c) => c.name === "company")
                          ?.fields.map((t) => (
                            <Select.Option key={t.name} value={t.name}>
                              {t.display || t.name}
                            </Select.Option>
                          ))}
                      </Select>
                    )}
                    {f.name.startsWith("user") && f.name !== "userStatus" && (
                      <Select
                        style={filterStyle}
                        onChange={(name: string) =>
                          addFilter(+k, {
                            ...f,
                            name: `${f.name.substring(
                              0,
                              f.name.indexOf("_") > 0
                                ? f.name.indexOf("_")
                                : undefined
                            )}_${name}`,
                          })
                        }
                        placeholder="Select the field"
                      >
                        {columns
                          .find((c) => c.name === "user")
                          ?.fields.map((t) => (
                            <Select.Option key={t.name} value={t.name}>
                              {t.display || t.name}
                            </Select.Option>
                          ))}
                      </Select>
                    )}
                  </>
                }
                :
                <>
                  {[
                    "registeredName",
                    "registeredNumber",
                    "tradingName",
                    "company_registeredName",
                    "company_registeredNumber",
                    "company_tradingName",
                    "firstName",
                    "lastName",
                    "idNumber",
                    "user_firstName",
                    "user_lastName",
                    "user_idNumber",
                  ].includes(f.name) && (
                    <Select
                      style={filterStyle}
                      placeholder="Search employee"
                      defaultActiveFirstOption={false}
                      showArrow
                      filterOption={false}
                      notFoundContent={null}
                      showSearch
                      onSearch={(val) =>
                        [
                          "registeredName",
                          "registeredNumber",
                          "tradingName",
                          "company_registeredName",
                          "company_registeredNumber",
                          "company_tradingName",
                        ].includes(f.name)
                          ? handleCompanySearch({
                              variables: {
                                criteria: "searchText",
                                filter: val,
                              },
                            })
                          : handleUserSearch({
                              variables: {
                                input: { searchTerm: val, companyId: 0 },
                              },
                            })
                      }
                      onChange={(val: string[]) =>
                        addFilter(+k, { ...f, value: val })
                      }
                      loading={loadingCompanies || loadingUsers}
                    >
                      {([
                        "registeredName",
                        "registeredNumber",
                        "tradingName",
                        "company_registeredName",
                        "company_registeredNumber",
                        "company_tradingName",
                      ].includes(f.name)
                        ? companySearchResults
                        : userSearchResults
                      ).map((d) => (
                        <Select.Option
                          key={d.id}
                          value={
                            d[
                              f.name
                                ?.replace("company_", "")
                                ?.replace("user_", "")
                            ]
                          }
                        >
                          {d[
                            f.name
                              ?.replace("company_", "")
                              ?.replace("user_", "")
                          ]?.toUpperCase()}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                  {[
                    "phone",
                    "user_phone",
                    "vatNumber",
                    "natureOfBusiness",
                    "company_vatNumber",
                    "company_natureOfBusiness",
                    "email",
                    "secondaryEmail",
                    "membershipNumber",
                    "user_email",
                    "user_secondaryEmail",
                    "company_membershipNumber",
                  ].includes(f.name) && (
                    <Input
                      style={filterStyle}
                      placeholder="Enter the field value"
                      onChange={(e) =>
                        addFilter(+k, { ...f, value: e.target.value })
                      }
                    />
                  )}
                  {[
                    "id",
                    "age",
                    "highestGradeYear",
                    "user_id",
                    "company_id",
                    "user_age",
                    "user_highestGradeYear",
                    "employment_id",
                    "employment_userId",
                    "certificate_userId",
                    "certificate_id",
                    "amountRecovered",
                    "valueOfBooks",
                  ].includes(f.name) && (
                    <InputNumber
                      style={filterStyle}
                      placeholder="Enter the field value"
                      value={f.value}
                      onChange={(val) => addFilter(+k, { ...f, value: val })}
                    />
                  )}
                  {[
                    "dateOfRegistration",
                    "company_dateOfRegistration",
                    "dateCreated",
                    "dateOfBirth",
                    "user_createdDate",
                    "user_dateOfBirth",
                    "employment_dateCreated",
                    "employment_approvedDate",
                    "certificate_createdDate",
                    "certificate_fromDate",
                    "certificate_toDate",
                    "financialYear",
                  ].includes(f.name) && (
                    <DatePicker
                      style={filterStyle}
                      placeholder="Please select the date"
                      disabledDate={(current) =>
                        isFuture(current.startOf("day").toDate())
                      }
                      onChange={(date) =>
                        addFilter(+k, { ...f, value: date.toDate() })
                      }
                    />
                  )}
                  {[
                    "status",
                    "company_status",
                    "userStatus",
                    "user_userStatus",
                    "tradingAs",
                    "company_tradingAs",
                    "highestGrade",
                    "maritalStatus",
                    "ethnicity",
                    "user_highestGrade",
                    "user_maritalStatus",
                    "user_ethnicity",
                    "isRegisteredForVat",
                    "company_isRegisteredForVat",
                    "employment_employerAddressType",
                    "employment_active",
                    "certificate_active",
                    "employment_capacity",
                    "financialYearStartDate",
                    "company_financialYearStartDate",
                    "financialYearEndDate",
                    "company_financialYearEndDate",
                    "bbeeeLevel",
                    "industry",
                    "geographicalArea",
                  ].includes(f.name) && (
                    <Select
                      style={filterStyle}
                      placeholder="Select the field value(s)"
                      onChange={(value) => addFilter(+k, { ...f, value })}
                      mode={
                        ["industry", "geographicalArea"].includes(f.name)
                          ? undefined
                          : "multiple"
                      }
                    >
                      {[
                        "status",
                        "company_status",
                        "userStatus",
                        "user_userStatus",
                      ].includes(f.name) &&
                        USER_STATUS_TYPES.map((s) => (
                          <Select.Option key={s} value={s}>
                            {s}
                          </Select.Option>
                        ))}
                      {["tradingAs"].includes(f.name) &&
                        companyTypes
                          ?.filter((t) => t)
                          .map((t) => (
                            <Select.Option key={t} value={t}>
                              {t.toUpperCase()}
                            </Select.Option>
                          ))}
                      {["highestGrade", "user_highestGrade"].includes(f.name) &&
                        [
                          "Grade 8",
                          "Grade 9",
                          "Grade 10",
                          "Grade 11",
                          "Grade 12",
                        ].map((s) => (
                          <Select.Option key={s} value={s.split(" ")[1]}>
                            {s}
                          </Select.Option>
                        ))}
                      {["ethnicity", "user_ethnicity"].includes(f.name) &&
                        ETHNICITY_LIST.map((s) => (
                          <Select.Option key={s} value={s}>
                            {s}
                          </Select.Option>
                        ))}
                      {["employment_capacity"].includes(f.name) &&
                        capacities.map((s) => (
                          <Select.Option key={s.id} value={s.id}>
                            {s.name}
                          </Select.Option>
                        ))}
                      {[
                        "financialYearStartDate",
                        "financialYearEndDate",
                        "company_financialYearStartDate",
                        "company_financialYearEndDate",
                      ].includes(f.name) &&
                        months.map((s) => (
                          <Select.Option key={s} value={s.substring(0, 3)}>
                            {s}
                          </Select.Option>
                        ))}
                      {["maritalStatus", "user_maritalStatus"].includes(
                        f.name
                      ) &&
                        MARITAL_STATUSES.map((s) => (
                          <Select.Option key={s} value={s}>
                            {s}
                          </Select.Option>
                        ))}
                      {["bbeeeLevel"].includes(f.name) &&
                        bbeeeLevels.map((s) => (
                          <Select.Option key={s} value={s}>
                            {s}
                          </Select.Option>
                        ))}
                      {["industry"].includes(f.name) &&
                        industries.map((s) => (
                          <Select.Option key={s} value={s}>
                            {s}
                          </Select.Option>
                        ))}
                      {["geographicalArea"].includes(f.name) &&
                        provinces.map((s) => (
                          <Select.Option key={s} value={s}>
                            {s}
                          </Select.Option>
                        ))}
                      {[
                        "isRegisteredForVat",
                        "company_isRegisteredForVat",
                        "employment_active",
                        "certificate_active",
                      ].includes(f.name) &&
                        ["Yes", "No"].map((s) => (
                          <Select.Option key={s} value={s}>
                            {s}
                          </Select.Option>
                        ))}
                      {["employment_employerAddressType"].includes(f.name) &&
                        ["Branch", "Head Office"].map((s) => (
                          <Select.Option key={s} value={s}>
                            {s}
                          </Select.Option>
                        ))}
                    </Select>
                  )}
                </>
              </div>
            ))}
          </Col>
        </Row>
      </Col>
      <Col span={3} className="flex-row">
        <Button
          className="ml-auto"
          icon={<PlusOutlined />}
          type="ghost"
          onClick={() =>
            addFilter(+Object.keys(filters).pop() + 1, {
              name: "id",
              display: "ID",
            })
          }
        >
          Add Filter
        </Button>
      </Col>
    </Row>
  );
};
