import { Button, Descriptions } from "antd";
import { User } from "@/typings/types";

type Props = {
  title?: string;
  user: User;
  onEdit: () => void;
};

function UserInformation({ title = "Your Information", user, onEdit }: Props) {
  return (
    <div className="input-block-wrapper">
      <Descriptions layout="vertical" title={title} column={3}>
        <Descriptions.Item label="ID / PassportNumber">
          {user.idNumber}
        </Descriptions.Item>
        <Descriptions.Item label="First Name">
          {user.firstName}
        </Descriptions.Item>
        <Descriptions.Item label="Last Name">{user.lastName}</Descriptions.Item>
        <Descriptions.Item label="Age">{user.age}</Descriptions.Item>
        <Descriptions.Item label="Marital Status">
          {user.maritalStatus || "<Unspecified>"}
        </Descriptions.Item>
        <Descriptions.Item label="Ethnicity">
          {user.ethnicity || "<Unspecified>"}
        </Descriptions.Item>
        <Descriptions.Item label="Phone">{user.phone}</Descriptions.Item>
        <Descriptions.Item label="Phone">
          {user.secondaryPhone || "<Unspecified>"}
        </Descriptions.Item>
        <Descriptions.Item label="Email Address">
          {user.email}
        </Descriptions.Item>
        <Descriptions.Item label="Secondary Email Address">
          {user.secondaryEmail || "<Unspecified>"}
        </Descriptions.Item>
        <Descriptions.Item label="Work Number">
          {user.workPhone || "<Unspecified>"}
        </Descriptions.Item>
        <Descriptions.Item label="Home Number">
          {user.homePhone || "<Unspecified>"}
        </Descriptions.Item>
        <Descriptions.Item label="Capacity">
          {user.capacity?.name || "<Unspecified>"}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Business Unit">
          {"<Unspecified>"}
        </Descriptions.Item>
      </Descriptions>
      <Button onClick={onEdit}>Edit Details</Button>
    </div>
  );
}

export default UserInformation;
