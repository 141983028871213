import { GET_CFDC_USERS_REPORT } from "@/graphql/queries";
import { notifyError, notifySuccess } from "@/utils/notification";
import { FileSearchOutlined } from "@ant-design/icons";
import { useLazyQuery } from "@apollo/client";
import { Button, Tooltip } from "antd";
import { useState } from "react";

type ActiveMemberReportButtonProps = {
  disabled: boolean;
  className?: string;
  render?: any;
};

export function ActiveMemberReportButton({
  disabled = false,
  className = "purple-button",
  render,
}: ActiveMemberReportButtonProps) {
  const [downloading, setDownloading] = useState(false);

  const [getCfdcUsersReport] = useLazyQuery(GET_CFDC_USERS_REPORT, {
    fetchPolicy: "no-cache",
    onCompleted: () => {
      setDownloading(false);
      notifySuccess(
        "📝 Report generated. Please download the report from the documents section."
      );
    },
    onError: (error: any) => {
      setDownloading(false);
      if (error.message.includes("Received status code 504")) {
        notifySuccess(
          "📝 Report generated. Please download the report from the documents section."
        );
      } else {
        notifyError(error?.graphQLErrors[0]?.message || error.message);
      }
    },
  });

  const onClick = () => {
    setDownloading(true);
    getCfdcUsersReport();
  };

  return (
    <Tooltip title="Generating this report takes some time, the reporting will be generated in the background and get uploaded to the documents area when complete.">
      {render ? (
        render({ loading: downloading, onClick })
      ) : (
        <Button
          icon={<FileSearchOutlined />}
          className={className}
          disabled={disabled}
          loading={downloading}
          onClick={onClick}
        >
          {downloading ? "Generating CSV..." : "Generate User CSV"}
        </Button>
      )}
    </Tooltip>
  );
}
