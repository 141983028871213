import { useState, useEffect } from "react";
import { Card, Spin, Row, Col, Divider, Button, Table } from "antd";
import { useLazyQuery, useQuery } from "@apollo/client";
import { format, compareDesc, parseISO } from "date-fns";
import InternalErrorPage from "../../../InternalErrorPage";
import ProfileInformationLoader from "./profileInformationLoader";
import CompanyProfileEditable from "./companyProfileEditable";
import { GET_COMPANY_SUMMARY_MEMBER_PROFILE } from "../../../../graphql/queries";
import { ADMIN_STREAMS } from "../../../../constants";
import { GET_BLACKLIST_STATUS } from "@/containers/Admin/graphql/queries";
import { notifyError } from "@/utils/notification";
import BlacklistStatusAlert from "@/components/BlacklistStatusAlert";
import AddressTable from "@/components/AddressTable";
import { useUserState } from "@/stores/user";

interface IProps {
  profileData?: any;
  companyId: number;
  companyInformation?: any;
}

function BusinessProfile({
  profileData,
  companyId,
  companyInformation,
}: IProps) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState(null);
  const [useCompanyInformation, setCompanyInformation] =
    useState(companyInformation);
  const [useCompanyId, setCompanyId] = useState(companyId);
  const [useCompanyMembershipNumber, setCompanyMembershipNumber] =
    useState(null);
  const [useCompanyFinancialInformation, setCompanyFinancialInformation] =
    useState(null);
  const [useCompanyRepresentatives, setCompanyRepresentatives] = useState(null);
  const [useCompanyContacts, setCompanyContacts] = useState(null);
  const [useCompanyAccountant, setCompanyAccountant] = useState(null);
  const [useCompanyAddresses, setCompanyAddresses] = useState(null);
  const [useEditMode, setEditMode] = useState(false);

  const { activeUserRole, userRoles } = useUserState();

  const role = userRoles.find((ur) => ur.id === activeUserRole);

  const { data: blacklistData, refetch } = useQuery(GET_BLACKLIST_STATUS, {
    skip: !companyId,
    variables: {
      id: companyId,
      type: "company",
    },
    onError: (error) => {
      notifyError(error);
    },
  });

  const [getCompanyOverviewInfo] = useLazyQuery(
    GET_COMPANY_SUMMARY_MEMBER_PROFILE,
    {
      onCompleted: (data) => {
        if (data) {
          if (data.companyFinancialInformation) {
            setCompanyFinancialInformation(data.companyFinancialInformation);
          }
          if (data.company) {
            setCompanyInformation(data.company);
          }
          if (data.companyRepresentatives) {
            setCompanyRepresentatives(data.companyRepresentatives);
          }
          if (data.companyContacts) {
            setCompanyContacts(data.companyContacts);
          }
          if (data.companyAccountant) {
            setCompanyAccountant(data.companyAccountant);
          }
          if (data.addresses) {
            setCompanyAddresses(data.addresses);
          }
          if (data.company && data.company.membershipNumber) {
            setCompanyMembershipNumber(data.company.membershipNumber);
          }
        }
        setLoading(false);
      },
      onError: (error) => {
        setError(error);
      },
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (typeof companyId === "number") {
      setCompanyId(companyId);
      refetch();
      getCompanyOverviewInfo({
        variables: {
          companyId: companyId,
        },
      });
    }
  }, [companyId]);

  useEffect(() => {
    if (profileData) {
      if (profileData.userData) {
        setUserData(profileData.userData);
      }
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [profileData]);
  useEffect(() => {
    setCompanyInformation(companyInformation);
  }, [companyInformation]);
  const disableEditAndRefreshCompany = () => {
    setEditMode(false);
    getCompanyOverviewInfo({
      variables: {
        companyId: companyId,
      },
    });
  };
  if (error) {
    return <InternalErrorPage error={error} />;
  } else {
    return (
      <Spin tip="Loading..." spinning={loading}>
        <div className="col-sm-12 col-md-12 card-page-border">
          <Card>
            {!useCompanyMembershipNumber && <ProfileInformationLoader />}
            {useCompanyMembershipNumber && useEditMode && (
              <>
                <div className="card-header">
                  <h3>Edit Business Info</h3>
                  <h3>Membership number: {useCompanyMembershipNumber}</h3>
                </div>
                <CompanyProfileEditable
                  companyId={useCompanyId}
                  companyDetails={useCompanyInformation}
                />
                <Row gutter={[24, 24]}>
                  <Col span={24}>
                    <Button
                      onClick={() => disableEditAndRefreshCompany()}
                      className="purple-button"
                      style={{ marginRight: "20px" }}
                    >
                      View Profile
                    </Button>
                  </Col>
                </Row>
              </>
            )}
            {useCompanyInformation && !useEditMode && (
              <>
                {!!blacklistData?.getEntityBlacklistStatus && (
                  <BlacklistStatusAlert
                    type="company"
                    blacklistData={blacklistData.getEntityBlacklistStatus}
                  />
                )}
                <div className="card-header">
                  <h3>Business Basic Info</h3>
                  <div className="">
                    <h3>
                      Membership number:{" "}
                      <span className="bold">
                        {useCompanyInformation?.membershipNumber || "N/A"}
                      </span>
                    </h3>
                    <h3>
                      Valid Until:{" "}
                      <span className="bold">
                        {useCompanyInformation?.certificates?.length
                          ? format(
                              useCompanyInformation?.certificates
                                .map((c) => parseISO(c.toDate))
                                .sort(compareDesc)[0],
                              "dd-MM-yyyy"
                            )
                          : "N/A"}
                      </span>
                    </h3>
                    <h3>
                      Status:{" "}
                      <span className="bold">
                        {useCompanyInformation?.status || "N/A"}
                      </span>
                    </h3>
                  </div>
                </div>
                {useCompanyInformation && useCompanyInformation && (
                  <Row gutter={[24, 24]}>
                    <Col span={6}>
                      <span className="bold">Registered name: </span>
                      {useCompanyInformation?.registeredName
                        ? useCompanyInformation?.registeredName
                        : "N/A"}
                    </Col>
                    <Col span={6}>
                      <span className="bold">Registered number: </span>
                      {useCompanyInformation?.registeredNumber
                        ? useCompanyInformation?.registeredNumber
                        : "N/A"}
                    </Col>
                    <Col span={6}>
                      <span className="bold">Trading name: </span>
                      {useCompanyInformation?.tradingName
                        ? useCompanyInformation?.tradingName
                        : "N/A"}
                    </Col>
                    <Col span={6}>
                      <span className="bold">VAT number: </span>
                      {useCompanyInformation?.vatNumber || "N/A"}
                    </Col>
                    <Col span={8}></Col>
                  </Row>
                )}
                {userData && userData.idNumber && (
                  <>
                    <Divider />
                    <h3>Representative information</h3>
                    <Row gutter={[24, 24]}>
                      <Col span={6}>
                        <span className="bold">ID number: </span>
                        {userData.idNumber ? userData.idNumber : "N/A"}
                      </Col>
                      <Col span={6}>
                        <span className="bold">First name: </span>
                        {userData.firstName ? userData.firstName : "N/A"}
                      </Col>
                      <Col span={6}>
                        <span className="bold">Last name: </span>
                        {userData.lastName ? userData.lastName : "N/A"}
                      </Col>
                      <Col span={6}>
                        <span className="bold">Phone number: </span>
                        {userData.phone ? userData.phone : "N/A"}
                      </Col>
                      <Col span={6}>
                        <span className="bold">Email: </span>
                        {userData.email ? userData.email : "N/A"}
                      </Col>
                      <Col span={6}>
                        <span className="bold">Capacity: </span>
                        {userData.capacity ? userData.capacity?.name : "N/A"}
                      </Col>
                      <Col span={6}>
                        <span className="bold">Business unit: </span>
                        {userData.businessUnit
                          ? userData.businessUnit?.name
                          : "N/A"}
                      </Col>
                      <Col span={6}>
                        <span className="bold">Phone number 2: </span>
                        {userData.secondaryPhone
                          ? userData.secondaryPhone
                          : "N/A"}
                      </Col>
                      <Col span={6}>
                        <span className="bold">Work number: </span>
                        {userData.workPhone ? userData.workPhone : "N/A"}
                      </Col>
                      <Col span={6}>
                        <span className="bold">Home number: </span>
                        {userData.homePhone ? userData.homePhone : "N/A"}
                      </Col>
                      <Col span={6}>
                        <span className="bold">Work fax: </span>
                        {userData.fax ? userData.fax : "N/A"}
                      </Col>
                      <Col span={6}>
                        <span className="bold">Email private: </span>
                        {userData.email ? userData.email : "N/A"}
                      </Col>
                      <Col span={6}>
                        <span className="bold">Marital status: </span>
                        {userData.maritalStatus
                          ? userData.maritalStatus
                          : "N/A"}
                      </Col>
                      <Col span={6}>
                        <span className="bold">Ethnicity: </span>
                        {userData.ethnicity ? userData.ethnicity : "N/A"}
                      </Col>
                      <Col span={6}>
                        <span className="bold">Age: </span>
                        {userData.age ? userData.age : "N/A"}
                      </Col>
                    </Row>
                  </>
                )}
                <Divider />
                <div className={loading ? "no-interact" : null}>
                  <h3>Business Location Info</h3>
                  <AddressTable addresses={useCompanyAddresses} />

                  <h3 className="mt-5">Financial info</h3>
                  {useCompanyFinancialInformation &&
                    useCompanyFinancialInformation.company && (
                      <>
                        <Row gutter={[24, 24]}>
                          <Col span={6}>
                            <span className="bold">Financial start date: </span>
                            {
                              useCompanyFinancialInformation.company
                                ?.financialYearStartDate
                            }
                          </Col>
                          <Col span={6}>
                            <span className="bold">Financial end date: </span>
                            {
                              useCompanyFinancialInformation.company
                                ?.financialYearEndDate
                            }
                          </Col>
                          <Col span={8}>
                            <span className="bold">Business trades as: </span>
                            {useCompanyFinancialInformation.company?.tradingAs}
                          </Col>
                          <Col span={4}></Col>
                        </Row>
                        <h3 className="mt-5">Bank Accounts Details</h3>
                        <Table
                          pagination={false}
                          dataSource={[
                            ...useCompanyFinancialInformation.businessAccounts.map(
                              (item: any) => ({
                                type: "Business",
                                ...item,
                              })
                            ),
                            ...useCompanyFinancialInformation.trustAccounts.map(
                              (item: any) => ({
                                type: "Trust",
                                ...item,
                              })
                            ),
                          ]}
                          columns={[
                            {
                              title: "Type",
                              render: (record: any) => record.type,
                              key: "type",
                            },
                            {
                              title: "Name",
                              render: (record: any) => record.accountName,
                              key: "accountName",
                            },
                            {
                              title: "Account No.",
                              key: "accountNumber",
                              render: (record: any) => record.accountNumber,
                            },
                            {
                              title: "Bank",
                              key: "bank",
                              render: (record: any) => record.bank,
                            },
                            {
                              title: "Branch Code",
                              key: "branchCode",
                              render: (record: any) => record.branchCode,
                            },
                            {
                              title: "Account Type",
                              key: "accountType",
                              render: (record: any) => record.accountType,
                            },
                          ]}
                        />
                      </>
                    )}

                  <h3 className="mt-5">Related Party/Team Members</h3>
                  <Table
                    dataSource={useCompanyRepresentatives || []}
                    pagination={false}
                    columns={[
                      {
                        title: "Capacity",
                        render: (record: any) => {
                          return record.capacity?.name || "";
                        },
                        key: "role",
                      },
                      {
                        title: "First Name",
                        render: (record: any) => record.firstName ?? "",
                        key: "firstName",
                      },
                      {
                        title: "Last Name",
                        render: (record: any) => record.lastName ?? "",
                        key: "lastName",
                      },
                      {
                        title: "ID Number",
                        render: (record: any) => record.idNumber ?? "",
                        key: "idNumber",
                      },
                    ]}
                  />

                  <h3 className="mt-5">Contact Information</h3>
                  <Table
                    dataSource={useCompanyContacts || []}
                    pagination={false}
                    rowKey={(record) => record.id}
                    columns={[
                      {
                        title: "Business Unit",
                        render: (record: any) =>
                          record.businessUnit?.name || "",
                        key: "businessUnit",
                      },
                      {
                        title: "Contact Person",
                        render: (record: any) =>
                          record.businessUnitContactName || "",
                        key: "contactPersonName",
                      },
                      {
                        title: "Cellphone",
                        render: (record: any) => record.cellphone || "",
                        key: "cellphone",
                      },
                      {
                        title: "Cellphone2",
                        render: (record: any) => record.cellphone2 || "",
                        key: "cellphone2",
                      },
                      {
                        title: "Email",
                        render: (record: any) => record.email || "",
                        key: "email",
                      },
                      {
                        title: "Email2",
                        render: (record: any) => record.email2 || "",
                        key: "email2",
                      },
                      {
                        title: "WorkPhone",
                        render: (record: any) => record.workPhone || "",
                        key: "workPhone",
                      },
                      {
                        title: "HomePhone",
                        render: (record: any) => record.homePhone || "",
                        key: "homePhone",
                      },
                    ]}
                  />

                  <h3 className="mt-5">Auditor/Accountant info</h3>
                  {useCompanyAccountant && (
                    <Row gutter={[24, 24]}>
                      <Col span={6}>
                        <span className="bold">Name of business: </span>
                        {useCompanyAccountant?.businessName}
                      </Col>
                      <Col span={6}>
                        <span className="bold">Contact person: </span>
                        {useCompanyAccountant?.contactPersonName}
                      </Col>
                      <Col span={6}>
                        <span className="bold">Contact number: </span>
                        {useCompanyAccountant?.contactCellNumber}
                      </Col>
                      <Col span={6}>
                        <span className="bold">Contact number (office): </span>
                        {useCompanyAccountant?.contactOfficeNumber}
                      </Col>
                      <Col span={6}>
                        <span className="bold">Email address: </span>
                        {useCompanyAccountant?.email}
                      </Col>
                    </Row>
                  )}
                  <Divider />
                  <Row gutter={[24, 24]}>
                    <Col span={24}>
                      <Button
                        onClick={() => setEditMode(true)}
                        className="purple-button"
                        style={{ marginRight: "20px" }}
                        disabled={!ADMIN_STREAMS.includes(role.stream)}
                      >
                        Edit Profile
                      </Button>
                    </Col>
                  </Row>
                </div>
              </>
            )}
          </Card>
        </div>
      </Spin>
    );
  }
}

export default BusinessProfile;
