import { Modal, Input, Select, Form } from "antd";
import { useQuery } from "@apollo/client";
import { GET_USER_TYPES } from "@/containers/Admin/graphql/queries";

const EditPermissionForm = ({
  visible,
  onCancel,
  onCreate,
  initialData,
  form,
}) => {
  const { Option } = Select;
  const { data } = useQuery(GET_USER_TYPES);

  let children: any = [];

  // only superAdmin can add another super admin
  if (data && data.userTypesList) {
    data.userTypesList.map((type) => {
      children.push(
        <Option key={type.id} value={type.id}>
          {type.name}
        </Option>
      );
    });
  }

  // array of user types/role that user is assigned
  const userRolesId: any = [];
  const userRolesName: any = [];
  if (initialData)
    initialData?.roles.map((role) => {
      userRolesId.push(role.id);
      userRolesName.push(role.name);
    });

  return (
    <Modal
      open={visible}
      title={
        initialData && initialData.id ? "Edit Permission" : "Add Permission"
      }
      okText="Save"
      onCancel={onCancel}
      onOk={onCreate}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="ID"
          style={{ display: "none" }}
          name={"id"}
          initialValue={initialData ? initialData.id : undefined}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Permission Name"
          name={"name"}
          initialValue={initialData ? initialData.name : ""}
          rules={[
            {
              required: true,
              message: "Please input name of the permission!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name={"description"}
          initialValue={initialData?.description || ""}
          rules={[
            {
              required: true,
              message: "Please input the permission's description!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="User Type"
          name={"userRole"}
          rules={[
            {
              required: true,
              message: "Please select user type(s)",
            },
          ]}
          initialValue={userRolesId}
        >
          <Select mode="multiple" style={{ width: "100%" }}>
            {children}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditPermissionForm;
