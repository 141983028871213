import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { GQL_HTTP_ENDPOINT } from "./constants";
import { useUserState } from "./stores/user";

// Connect to GraphQL API
const httpLink = createHttpLink({
  uri: GQL_HTTP_ENDPOINT,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage
  const token = localStorage.getItem("id_token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
      "X-Role-Id": useUserState.getState().activeUserRole ?? "",
    },
  };
});

const refreshLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );

  const { response } = operation.getContext();

  if (response?.status === 401) {
    const currentLocation = window.location.pathname;
    location.href = `/login?from=${currentLocation}`;
  }
});

// Initialize APolloClient
const client = new ApolloClient({
  link: ApolloLink.from([authLink, refreshLink, httpLink]),
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

export default client;
