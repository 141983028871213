import {
  useState,
  useMemo,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import { Card, Empty } from "antd";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_CUSTOM_REPORTS } from "../../graphql/queries";
import { TableLoader } from "../../../../components/UserManagement/TableContentPlaceholder";
import UserTable from "../../Reports/UserTable";
import { notifyError, openNotificationWithIcon } from "@/utils/notification";
import CompanyTable from "../../Reports/CompanyTable";
import { compareDesc, format } from "date-fns";
import AuditTable from "../../Reports/AuditTable";
import { writeCSV } from "@/utils";
import { useNavigate } from "react-router-dom";
import { ReportHandle } from "../ReportingTypes";
import { useReportingFilters } from "../useReportingFilters";

function _CustomReport(_: unknown, ref: React.Ref<ReportHandle>) {
  const navigate = useNavigate();
  const [reportList, setReportList] = useState([]);
  const [listTotal, setListTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const { pageSize, pageNumber, setFilterState, type, skip, customFilter } =
    useReportingFilters();

  const reportType = useMemo(
    () => type.charAt(0).toUpperCase() + type.slice(1),
    [type]
  );

  const handleError = (error: any) => {
    setLoading(false);
    setDownloading(false);
    notifyError(error);
  };

  const [getCustomReport] = useLazyQuery(GET_CUSTOM_REPORTS, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getCustomReport }) => {
      const { total, companyList, userListString, auditList } = getCustomReport;
      const _reports = userListString
        ? JSON.parse(userListString)
        : companyList ?? auditList;

      setReportList(_reports);
      setListTotal(total);
      setLoading(false);
    },
    onError: handleError,
  });

  const { refetch: downloadCustomReport } = useQuery(GET_CUSTOM_REPORTS, {
    fetchPolicy: "no-cache",
    skip: true,
    onError: notifyError,
  });

  const runQuery = async () => {
    setLoading(true);

    getCustomReport({
      variables: {
        input: {
          type: type.charAt(0).toUpperCase() + type.slice(1),
          filters: customFilter,
          skip,
        },
      },
    });
  };

  const downloadCsv = async () => {
    try {
      setDownloading(true);

      let reportData = [];
      let skip = 0;
      while (listTotal !== reportData.length) {
        const { data, errors } = await downloadCustomReport({
          input: {
            type: reportType,
            filters: customFilter,
            skip,
            take: Math.min(listTotal, 2000),
          },
        });

        if (errors && errors.length) {
          setDownloading(false);
          return openNotificationWithIcon("error", "Error", errors[0].message);
        }

        switch (reportType) {
          case "Member":
            reportData.push(
              ...JSON.parse(data.getCustomReport?.userListString)
            );
            break;
          case "Company":
            reportData.push(...data.getCustomReport?.companyList);
            break;
          case "Audit":
            reportData.push(...data.getCustomReport?.auditList);
            break;
          default:
        }

        skip += 2000;
      }

      let csvData: any;

      if (!reportData.length) throw new Error("Report download failed.");
      if (reportType === "Member") {
        csvData = reportData.map((i) => {
          return {
            "ID Number": i.idNumber,
            "First Name": i.firstName?.toUpperCase(),
            "Last Name": i.lastName?.toUpperCase(),
            Status: (i.userStatus || "")?.toUpperCase(),
            Capacity: i.employment
              .map((i) => i?.capacity?.name || "")
              .join(" | "),
            Company: i.employment
              ?.map(
                (i) => i?.company?.tradingName || i?.company?.registeredName
              )
              .join(" | ")
              ?.toUpperCase(),
            "Membership Number": i.employment
              .map((i) => i.councilMembershipNumber)
              .join(" | "),
            "Valid Until": i.certificates?.length
              ? format(
                  i.certificates
                    .map((i) => new Date(i.toDate))
                    .sort(compareDesc)[0],
                  "dd MMM yyyy"
                )
              : "",
          };
        });
      } else if (reportType === "Company") {
        csvData = reportData.map((i) => {
          return {
            "Registered Name": i.registeredName,
            "Trading Name": i.tradingName,
            "Membership Number": i.membershipNumber,
            Status: i.status,
            "Nature Of Business": i.natureOfBusiness,
            "Employee Count": i.employmentCount,
            "Valid Until": i.certificates?.length
              ? format(
                  i.certificates
                    .map((i) => new Date(i.toDate))
                    .sort(compareDesc)[0],
                  "dd MMM yyyy"
                )
              : "",
          };
        });
      } else if (reportType === "Audit") {
        csvData = reportData.map((i) => {
          return {
            ID: i.id,
            "Date Created": format(new Date(i.dateCreated), "dd MMM yyy"),
            Company:
              i?.task?.company?.registeredName ||
              i?.task?.company?.tradingName ||
              "",
            "BBEEE Level": i.bbeeeLevel || "",
            Industry: i?.industry || "",
            "Geographical Area": i?.geographicalArea || "",
            "Value of Books": i?.valueOfBooks || "",
            "Amount Recovered": i?.amountRecovered || "",
            "Financial Year": i?.financialYear
              ? format(new Date(i.financialYear), "MMMM yyyy")
              : "",
            "Task ID": i?.task?.id || "",
            "Task Status": i?.task?.taskStatus || "",
          };
        });
      }

      setDownloading(false);
      const fileName = `${reportType} - ${reportType}`;
      writeCSV(fileName, fileName, csvData);
    } catch (err) {
      setDownloading(false);
      notifyError(err);
    }
  };

  useImperativeHandle(ref, () => ({
    run: runQuery,
    download: downloadCsv,
    running: loading,
    downloading,
    resetData: () => {
      setReportList([]);
      setListTotal(0);
    },
  }));

  useEffect(() => {
    runQuery();
  }, [pageNumber]);

  if (loading) {
    return <TableLoader />;
  }

  if (reportList.length === 0) {
    return <Empty description="Use the filters above to generate a report." />;
  }

  return (
    <div className="">
      <Card>
        <div className={loading ? "no-interact" : null}>
          {reportType === "Member" && (
            <UserTable
              navigate={navigate}
              handlePagination={(page) => setFilterState({ pageNumber: page })}
              userList={reportList}
              pageSize={pageSize}
              currentPage={pageNumber}
              listTotal={listTotal}
            />
          )}

          {reportType === "Company" && (
            <CompanyTable
              navigate={navigate}
              handlePagination={(page) => setFilterState({ pageNumber: page })}
              companyList={reportList}
              pageSize={pageSize}
              currentPage={pageNumber}
              listTotal={listTotal}
            />
          )}

          {reportType === "Audit" && (
            <AuditTable
              handlePagination={(page) => setFilterState({ pageNumber: page })}
              auditList={reportList}
              pageSize={pageSize}
              currentPage={pageNumber}
              listTotal={listTotal}
            />
          )}
        </div>
      </Card>
    </div>
  );
}

export const CustomReport = forwardRef(_CustomReport);
