import { useIsOnline } from "react-use-is-online";
import { ApolloProvider } from "@apollo/client";
import { createRoot } from "react-dom/client";
import client from "./apollo";
import Navigation from "@/navigation";
import OfflinePage from "@/containers/OfflinePage";
import { GlobalStateProvider } from "@/globalStore";
import { AdminFilterContext } from "@/contexts/AdminFilterContext";
import * as Sentry from "@sentry/react";
import "./index.css";

Sentry.init({
  dsn: "https://3bc8152e1ce52898b5a25cbc773e3006@o1089962.ingest.us.sentry.io/4507830616195072",
  integrations: [],
  debug: false,
});

function App() {
  const { isOnline } = useIsOnline();

  return (
    <>
      {isOnline ? (
        <ApolloProvider client={client}>
          <GlobalStateProvider>
            <AdminFilterContext>
              <Navigation />
            </AdminFilterContext>
          </GlobalStateProvider>
        </ApolloProvider>
      ) : (
        <OfflinePage />
      )}
    </>
  );
}

createRoot(document.getElementById("root")!).render(<App />);
