import { useState } from "react";
import { Modal, Input } from "antd";
import { notifyError, openNotificationWithIcon } from "@/utils/notification";

type Props = {
  visible: boolean;
  onCancel: () => void;
  initiateProsecution: (args: any) => Promise<any>;
  complaintId: number;
  refreshComplaint: () => void;
  updateTask: () => void;
};

const InitiateProsecution = ({
  visible,
  onCancel,
  initiateProsecution,
  complaintId,
  refreshComplaint,
  updateTask,
}: Props) => {
  const [reason, setReason] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const { errors } = await initiateProsecution({
        variables: { input: { complaintId, reason } },
      });

      if (errors && errors.length) throw new Error(errors[0].message);

      updateTask();
      openNotificationWithIcon(
        "success",
        "Success",
        "Prosecution initiated. Recommendation sent to Admin for approval"
      );
      refreshComplaint();
      onCancel();
    } catch (err) {
      notifyError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={visible}
      title="Initiate Prosecution"
      onCancel={onCancel}
      onOk={handleSubmit}
      okText="Prosecute"
      okButtonProps={{ className: "purple-button", loading }}
      cancelButtonProps={{ disabled: loading }}
      confirmLoading={loading}
    >
      <h3>Reason for prosecution</h3>
      <Input.TextArea
        readOnly={loading}
        rows={4}
        onChange={(e) => setReason(e.target.value)}
      />
    </Modal>
  );
};

export default InitiateProsecution;
