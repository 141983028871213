import { useState, useEffect } from "react";
import {
  Input,
  Avatar,
  Upload,
  Button,
  message,
  Spin,
  Select,
  Form,
} from "antd";
import { GET_USER } from "../../graphql/queries";
import {
  UPDATE_USER,
  CREATE_ADDRESS,
  UPDATE_ADDRESS,
} from "../../../../graphql/mutations";
import { isEmpty } from "lodash";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import {
  GET_PRESIGNED_URL,
  GET_ADDRESS_LIST,
  GET_ADDRESS_TYPES,
} from "../../../../graphql/queries";
import { uploadToS3 } from "@/utils/uploadToS3";
import { openNotificationWithIcon } from "@/utils/notification";
import { HOME_PHYSICAL_ADDRESS, isSuperAdmin } from "../../../../constants";
import { useGlobalState } from "../../../../globalStore";
import { useNavigate, useParams } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";
import { useUserState } from "@/stores/user";

function AdminProfileForm() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { state } = useGlobalState();
  const [file, setFile] = useState<any>();
  const [contentType, setContentType] = useState("");
  const [avatarUrl, setAvatarUrl] = useState<string>();
  const [userId, setUserId] = useState<number>();
  const [userInfo, setUserInfo] = useState<any>();
  const [userAddress, setUserAddress] = useState<any>();
  const [uploading, setUploading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [addressTypeId, setAddressTypeId] = useState<number>();
  const { id: _id } = useUserState();

  const { teamMemberId } = useParams();
  const id = +teamMemberId;
  const userRoleList = state?.lookups?.userRoles || [];

  const isOwner = id === _id;

  const isAllowed = Boolean(
    localStorage.getItem("userRoles") === "Executive" ||
      localStorage.getItem("userRoles") === "Administration Manager"
  );

  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: (data) => {
      setUserInfo(data.updateUser);
    },
  });

  const [updateUserAddress] = useMutation(
    userAddress ? UPDATE_ADDRESS : CREATE_ADDRESS,
    {
      onCompleted: (data) => {
        const addressList = userAddress ? userAddress : [];
        addressList.push(userAddress ? data.updateAddress : data.createAddress);
        setUserAddress(addressList);
      },
    }
  );

  const [getUser] = useLazyQuery(GET_USER, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setUserInfo(data.userData);
      form.setFieldsValue({
        firstName: data.userData.firstName,
        lastName: data.userData.lastName,
        email: data.userData.email,
        phoneNumber: data.userData.phone,
        country: userAddress ? userAddress[0].country : "",
        city: userAddress ? userAddress[0].city : "",
        streetAddress: userAddress ? userAddress[0].streetAddress : "",
        userRole: data.userData.userUserRoles?.[0].userRole?.name,
        id: data.userData.idNumber,
      });
    },
  });

  const { data: addressTypes, loading } = useQuery(GET_ADDRESS_TYPES, {
    onCompleted: () => {
      //get home physical address type
      const { addressTypeList } = addressTypes;
      const physical = addressTypeList.filter(
        (x: any) => x.name === HOME_PHYSICAL_ADDRESS
      );
      if (physical) setAddressTypeId(physical[0].id);
    },
  });

  const [getUserAddress] = useLazyQuery(GET_ADDRESS_LIST, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (!isEmpty(data.addresses)) {
        setUserAddress(data.addresses);
      }
    },
  });

  const [getPresignedUrl] = useLazyQuery(GET_PRESIGNED_URL, {
    fetchPolicy: "network-only",
    onCompleted: async (data) => {
      if (!isEmpty(data.preSignedUrl)) {
        setAvatarUrl("Avatar");
        await uploadToS3(data.preSignedUrl, contentType, file);
      }
    },
  });

  useEffect(() => {
    setUserId(id);
    getUser({
      variables: {
        input: { id },
      },
    });
    getUserAddress({
      variables: { userId: id },
    });
  }, []);

  const customRequest = async (option) => {
    const { file, onSuccess } = option;

    try {
      setUploading(true);
      setFile(file);
      setContentType(file.type);

      await getPresignedUrl({
        variables: {
          input: {
            filePath: `${localStorage.getItem("idNumber")}/avatar`,
            contentType: file.type,
            forCompany: false,
            userId,
            companyName: "Admin",
          },
        },
      });

      onSuccess();
      setUploading(false);
      message.success(`${file.name} file uploaded successfully`);
    } catch {
      setUploading(false);
      message.error(`${file.name} failed to uploaded`);
    }
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        try {
          setSubmitting(true);
          //update user details
          await updateUser({
            variables: {
              input: {
                id: userId,
                firstName: values.firstName,
                lastName: values.lastName,
                idNumber: values.id,
                phone: values.phoneNumber,
                avatarUrl,
                ...(values.userRole !==
                  userInfo?.userUserRole[0]?.userRole?.name && {
                  userRoles: [+values.userRole],
                }),
              },
            },
          });

          // update user address details
          await updateUserAddress({
            variables: {
              input: {
                id: userAddress ? userAddress[0].id : undefined,
                userId: userAddress ? undefined : userId,
                country: values.country,
                city: values.city,
                streetAddress: values.streetAddress,
                addressTypeId: addressTypeId,
              },
            },
          });
          setSubmitting(false);
          openNotificationWithIcon(
            "success",
            "Save Success",
            "Profile updated successfully"
          );
        } catch {
          setSubmitting(false);
          openNotificationWithIcon(
            "error",
            "Save Error",
            "There was an error updating your profile"
          );
        }
      })
      .catch((error) => {});
  };

  if (loading) return <Spin />;

  return (
    <>
      <div className="card-form">
        <h3>Basic Profile</h3>
        <br />
        <span>Please complete your profile before proceeding</span>
        <br />
        <br />

        <Form form={form} layout="vertical">
          {(isOwner || isAllowed || isSuperAdmin) && (
            <Form.Item
              label="ID"
              name="id"
              initialValue={userInfo?.idNumber}
              rules={[
                { required: isOwner, message: "Please enter your ID number" },
              ]}
            >
              <Input readOnly={!(isOwner || isSuperAdmin)} />
            </Form.Item>
          )}
          <Form.Item
            label="First Name"
            name="firstName"
            initialValue={userInfo?.firstName}
            rules={[
              { required: isOwner, message: "Please enter your first name" },
            ]}
          >
            <Input readOnly={!(isOwner || isSuperAdmin)} />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastName"
            initialValue={userInfo?.lastName}
            rules={[
              { required: isOwner, message: "Please enter your last name" },
            ]}
          >
            <Input readOnly={!(isOwner || isSuperAdmin)} />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            initialValue={userInfo?.email ? userInfo?.email : ""}
            rules={[
              { required: isOwner, message: "Please enter your email address" },
            ]}
          >
            <Input readOnly={!(isOwner || isSuperAdmin)} />
          </Form.Item>
          <Form.Item
            label="Country"
            name="country"
            initialValue={userAddress ? userAddress[0].country : ""}
            rules={[
              { required: isOwner, message: "Please enter your country" },
            ]}
          >
            <Input readOnly={!(isOwner || isSuperAdmin)} />
          </Form.Item>
          <Form.Item
            label="City"
            name="city"
            initialValue={userAddress ? userAddress[0]?.city : ""}
            rules={[{ required: isOwner, message: "Please enter your city" }]}
          >
            <Input readOnly={!(isOwner || isSuperAdmin)} />
          </Form.Item>
          <Form.Item
            label="Street Address"
            name="streetAddress"
            initialValue={userAddress ? userAddress[0]?.streetAddress : ""}
            rules={[
              { required: isOwner, message: "Please enter street address" },
            ]}
          >
            <Input readOnly={!(isOwner || isSuperAdmin)} />
          </Form.Item>
          <Form.Item
            label="Phone Number"
            name="phoneNumber"
            rules={[
              { required: isOwner, message: "Please enter phone number" },
            ]}
          >
            <Input readOnly={!(isOwner || isSuperAdmin)} />
          </Form.Item>
          <Form.Item
            label="User Role"
            name="userRole"
            initialValue={userInfo?.userUserRoles[0]?.userRole?.name}
          >
            <Select
              disabled={!isSuperAdmin}
              defaultValue={userInfo?.userUserRoles[0]?.userRole?.name}
            >
              {userRoleList.map(({ id, name }) => (
                <Select.Option key={id.toString()} value={id.toString()}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
        <br />
        <div className="stepper-btn-container">
          {(isOwner || isSuperAdmin) && (
            <Button
              className="btn-upload"
              loading={submitting}
              onClick={handleSubmit}
            >
              Update
            </Button>
          )}

          <Button
            type="default"
            onClick={() => navigate(-1)}
            className="btn-default"
          >
            Cancel
          </Button>
        </div>
      </div>
      <div className="avatar-column">
        <Avatar src={userInfo?.avatarUrl} />
        <Upload customRequest={customRequest} disabled={uploading}>
          {(isOwner || isSuperAdmin) && (
            <Button>
              <UploadOutlined />
              <span>Change Avatar</span>
            </Button>
          )}
        </Upload>
      </div>
    </>
  );
}

export default AdminProfileForm;
