import { useCallback, useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Filters } from "./ReportingFilters";

const PAGE_SIZE = 20;

export function useReportingFilters() {
  const location = useLocation();
  const navigate = useNavigate();
  const { reportType, type } = useParams();

  const [skip, setSkip] = useState<number>(0);

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const dateRange = searchParams.get("dateRange")?.split(",");
  const companyIds = searchParams.get("companyIds")?.split(",");
  const employeeIds = searchParams.get("employeeIds")?.split(",");
  const months = searchParams.get("months")?.split(",");
  const sort = searchParams.get("sort") || "DESC";
  const fromYear = searchParams.get("from");
  const toYear = searchParams.get("to");
  const status = searchParams.get("status");
  const custom = searchParams.get("custom");

  const pageNumber = searchParams.get("pageNumber") || "1";
  const take = PAGE_SIZE;

  useEffect(() => {
    setSkip((+pageNumber - 1) * PAGE_SIZE);
  }, [pageNumber]);

  const clearFilters = useCallback(() => {
    searchParams.delete("dateRange");
    searchParams.delete("companyIds");
    searchParams.delete("employeeIds");
    searchParams.delete("months");
    searchParams.delete("sort");
    searchParams.delete("from");
    searchParams.delete("to");
    searchParams.delete("status");
    searchParams.delete("pageNumber");
    searchParams.delete("custom");

    navigate(`?${searchParams.toString()}`, { replace: true });
  }, [history, searchParams]);

  const setFilterState = useCallback(
    (filters: Partial<Filters>) => {
      Object.entries(filters).forEach(([key, value]) => {
        if (key === "custom") {
          if (!value) {
            searchParams.delete("custom");
          } else {
            const customFilter = JSON.parse(custom) || {};
            Object.keys(value).forEach((_key) => {
              if (value[_key]) customFilter[_key] = value[_key];
            });
            searchParams.set("custom", JSON.stringify(customFilter));
          }
        } else {
          if (value) {
            searchParams.set(
              key,
              Array.isArray(value) ? value.join(",") : String(value)
            );
          } else {
            searchParams.delete(key);
          }
        }
      });

      navigate(`?${searchParams.toString()}`, { replace: true });
    },
    [navigate, searchParams]
  );

  return {
    type,
    reportType,
    dateRange,
    companyIds,
    employeeIds,
    months,
    sort,
    from: fromYear,
    to: toYear,
    status,
    customFilter: custom ? JSON.parse(custom) : {},
    pageNumber: +pageNumber,
    pageSize: take,
    skip,
    setFilterState,
    clearFilters,
  };
}
