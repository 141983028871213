import { COMPANY_TYPES } from "../../constants";
import BusinessInfoCompany from "../RegisterCompany/personalInformation";
import PersonalInfoSoleProprietor from "../RegisterSoleProprietor/personalInformation";
import PersonalInfoEmployee from "../RegisterEmployee/personalInformation";
import { useRegistrationState } from "@/stores/registration";

type Props = { nextStep: () => void };

export default function BusinessInfo({ nextStep }: Props) {
  const { registeringAs, registrationCapacity } = useRegistrationState();

  const isCompany =
    registrationCapacity === "Business" &&
    COMPANY_TYPES.includes(registeringAs);

  const isSP =
    registrationCapacity === "Business" &&
    registeringAs === "A Sole Proprietor";

  return (
    <>
      {isCompany && <BusinessInfoCompany nextStep={nextStep} />}
      {isSP && <PersonalInfoSoleProprietor nextStep={nextStep} />}
      {registrationCapacity === "Individual" && (
        <PersonalInfoEmployee nextStep={nextStep} />
      )}
    </>
  );
}
