import { Button, Input, Form } from "antd";
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import cfdcLogo from "../../assets/images/cfdc-logo.png";
import { PASSWORD_REGEX, PASSWORD_REGEX_MESSAGE } from "../../constants";
import { openNotificationWithIcon } from "@/utils/notification";
import "./index.css";

Auth.configure({
  Auth: {
    identityPoolId: import.meta.env.VITE_USER_POOL_CLIENT_ID,
    region: import.meta.env.VITE_REGION,
    userPoolId: import.meta.env.VITE_USER_POOL_ID,
    UserPoolId: import.meta.env.VITE_USER_POOL_ID,
    ClientId: import.meta.env.VITE_USER_POOL_CLIENT_ID,
    userPoolWebClientId: import.meta.env.VITE_USER_POOL_CLIENT_ID,
  },
});

function ForgotPassword() {
  const [codeForm] = Form.useForm();
  const [form] = Form.useForm();
  const [code] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [codeSent, setCodeSent] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false);

  useEffect(() => {
    if (confirmed) {
      openNotificationWithIcon(
        "success",
        "Password Saved!!",
        "Your new password has been saved successfully. You can sign in"
      );
      setTimeout(() => window.location.replace("/"), 2000);
    }
  }, [confirmed]);

  const handleSendCodeClick = async (event: any) => {
    setIsSendingCode(true);

    try {
      await Auth.forgotPassword(idNumber);
      setCodeSent(true);
    } catch (e) {
      if (e.message === "Username/client id combination not found.") {
        setIsSendingCode(false);
        setIdNumber("");
        return openNotificationWithIcon(
          "error",
          "Username Error",
          "The given ID does not exist in our system. Please make sure you type the correct ID number to reset your password."
        );
      }
      setIsSendingCode(false);
    }
  };

  const handleConfirmClick = async (values: any) => {
    setIsConfirming(true);

    try {
      await Auth.forgotPasswordSubmit(
        values.idNumber,
        values.code,
        values.password
      );

      setConfirmed(true);
    } catch (e) {
      setConfirmed(false);
      setIsConfirming(false);
      openNotificationWithIcon("error", "Validation Error", e.message);
    }
  };

  const renderRequestCodeForm = () => {
    return (
      <Form
        form={codeForm}
        layout="vertical"
        onFinish={handleSendCodeClick}
        className="password-reset-form"
      >
        <div className="flex-column">
          <div style={{ textAlign: "center" }}>
            <img src={cfdcLogo} alt="cfdc" className="logo-password-reset" />
          </div>
          <div className="form-title">
            <h2>Forgot Password</h2>
            <div className="instruction-text">
              {
                "Please enter your ID number or Email and we'll send you a confirmation code to reset your password"
              }
            </div>
          </div>
        </div>
        <Form.Item
          label="ID Number or Email"
          className="form-item"
          initialValue={idNumber}
          rules={[{ required: true, message: "Please enter your ID number" }]}
          name={"idNumber"}
        >
          <Input autoFocus />
        </Form.Item>

        <Form.Item {...{ name: "" }}>
          <Button
            type="primary"
            htmlType="submit"
            className="btn-password-reset"
            loading={isSendingCode}
          >
            Send Confirmation
          </Button>
        </Form.Item>
        <span>
          <Link to="/">Cancel</Link>
        </span>
      </Form>
    );
  };

  const renderConfirmationForm = () => {
    return (
      <Form
        form={form}
        layout="vertical"
        onFinish={handleConfirmClick}
        className="password-reset-form"
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ textAlign: "center" }}>
            <img src={cfdcLogo} alt="cfdc" className="logo-password-reset" />
          </div>
          <div className="form-title">
            <h2>Forgot Password</h2>
            <div className="instruction-text">
              {"Please enter your new password and confirm it"}
            </div>
          </div>
        </div>
        <Form.Item
          label="Confirmation Code: (Sent via email)"
          htmlFor="code"
          className="form-item"
          style={{ paddingTop: "2em" }}
          name={"code"}
          initialValue={code}
          rules={[
            {
              required: true,
              message: "Please your confirmation code",
            },
          ]}
        >
          <Input autoFocus />
        </Form.Item>
        <hr />
        <Form.Item
          htmlFor="password"
          className="form-item"
          name={"password"}
          rules={[
            {
              required: true,
              message: "Please enter new password",
            },
            {
              min: 8,
              message: "Password has to be longer than 8 characters",
            },
            {
              pattern: PASSWORD_REGEX,
              message: PASSWORD_REGEX_MESSAGE,
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Confirm Password"
          className="form-item"
          name={"confirmPassword"}
          rules={[
            {
              required: true,
              message: "Confirm password",
            },
            {
              min: 8,
              message: "Password has to be longer than 8 characters",
            },
            {
              pattern: PASSWORD_REGEX,
              message: PASSWORD_REGEX_MESSAGE,
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item {...{ name: "" }}>
          <Button
            block
            type="primary"
            htmlType="submit"
            className="btn-password-reset"
            onClick={handleConfirmClick}
            loading={isConfirming}
          >
            Save new password
          </Button>
        </Form.Item>
        <span>
          <Link to="/">Cancel</Link>
        </span>
      </Form>
    );
  };

  return (
    <div className="password-reset-form-wrapper">
      <div className="password-reset-sidebar">
        {!codeSent ? renderRequestCodeForm() : renderConfirmationForm()}
      </div>
      <div className="password-reset-sider-background" />
    </div>
  );
}

export default ForgotPassword;
