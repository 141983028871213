import { useState, useEffect, useRef } from "react";
import { ExclamationCircleFilled, PlusOutlined } from "@ant-design/icons";
import { Card, Table, Button, Spin, Divider, Badge, Form } from "antd";
import { isEmpty } from "lodash";
import { parseISO, format } from "date-fns";
import { useLazyQuery, useMutation } from "@apollo/client";
import { openNotificationWithIcon } from "@/utils/notification";
import { uploadToS3 } from "@/utils/uploadToS3";
import { GET_MY_FILTERED_TASK_LIST } from "../../../graphql/queries";
import {
  CREATE_TASK,
  CREATE_DOCUMENT,
  CREATE_AUDIT_TASK,
} from "../../../graphql/mutations";
import TaskFilters from "./filters";
import { TableLoader } from "../../../components/UserManagement/TableContentPlaceholder";
import InternalErrorPage from "../../InternalErrorPage";
import CreateTaskForm from "./createTask";
import PermissionsGuard from "../../../components/Auth/can";
import EmptyState from "../../../components/Styled/EmptyState";
import {
  ADMIN_STREAM,
  FINANCE_STREAM,
  LEGAL_STREAM,
  MEMBER_STREAM,
  SUPER_USER_STREAM,
  CREATE_TASK_TYPES,
  ADMIN_STREAMS,
  TASK_PAGE_SIZE,
} from "../../../constants";
import { formatFilters, isAdmin, prioritize } from "../../../utils";
import { useFilterState } from "@/contexts/AdminFilterContext";
import { useNavigate } from "react-router-dom";

interface IDocumentList {
  fileName: string;
  presignedUrl: string;
  type: string;
  popFile: any;
  path: string;
}

const defaultMultiFilter = {
  taskId: false,
  taskTypeId: false,
  taskStatus: false,
  userId: false,
  dateStart: false,
  dateEnd: false,
  assigneeId: false,
  companyId: false,
  sort: "DESC",
};

interface IProps {
  memberId?: number;
  companyId?: number;
  mode: string;
}

function MemberTasksList({ memberId, companyId, mode }: IProps) {
  const navigate = useNavigate();
  const [taskList, setTaskList] = useState([]);
  const [listTotal, setListTotal] = useState(0);
  const [showEmptyState, setShowEmptyState] = useState(true);
  const [initialising, setInitialising] = useState(true);
  const [showSpin, setShowSpin] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [createTaskModalVisible, setCreateTaskModalVisible] = useState(false);
  const [roleStream, setRoleStream] = useState("");
  const userRole = localStorage.getItem("userRoles");
  const username = localStorage.getItem("username");
  const [createTaskForm] = Form.useForm();

  const {
    filters: { filter, savedSkip, savedCurrentPage },
    savePageNumber,
    filterReset,
  } = useFilterState();

  const prePopulatedFilter =
    (mode === "member-overview-tasks" || mode === "my-tasks") &&
    userRole !== "Company" &&
    memberId
      ? {
          assigneeId: memberId,
        }
      : mode === "company-overview-tasks" && companyId
      ? {
          companyId: companyId,
        }
      : userRole === "Company"
      ? {
          companyId: +localStorage.getItem("companyId"),
        }
      : {};

  const filterData = { ...filter, ...prePopulatedFilter };

  const [useMode] = useState(mode);
  const [createTask] = useMutation(CREATE_TASK);
  const [createAuditTask] = useMutation(CREATE_AUDIT_TASK);
  const [createDocument] = useMutation(CREATE_DOCUMENT);

  const filtersRef = useRef();

  const clearFilters = () => {
    if (filtersRef && filtersRef.current) {
      // @ts-ignore
      filtersRef.current.resetFilters();
      filterReset();
      runMultiFilterTaskQuery();
    }
  };

  const [getFilteredTaskList] = useLazyQuery(GET_MY_FILTERED_TASK_LIST, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setTaskList(data.tasks.taskList);
      if (data.tasks.total) {
        setListTotal(data.tasks.total);
        if (showEmptyState) setShowEmptyState(false);
      }
      setLoading(false);
      setInitialising(false);
      setShowSpin(false);
    },
    onError: (error) => {
      setError(error.message);
    },
  });

  const runMultiFilterTaskQuery = () => {
    setShowSpin(true);
    setLoading(true);

    getFilteredTaskList({
      variables: {
        input: {
          ...formatFilters(filterData),
          includeTotal: true,
          skip: savedSkip,
          take: TASK_PAGE_SIZE,
        },
      },
    });
  };

  useEffect(() => {
    setRoleStream(localStorage.getItem("roleStream"));
  }, []);

  useEffect(() => {
    runMultiFilterTaskQuery();
  }, []);

  useEffect(() => {
    runMultiFilterTaskQuery();
  }, [savePageNumber, savedSkip, JSON.stringify(filterData)]);

  const columns = [
    {
      title: "",
      key: "priority",
      render: ({ type, priorityStatus }) =>
        prioritize(type, priorityStatus) ? (
          <ExclamationCircleFilled style={{ color: "red", fontSize: "15px" }} />
        ) : (
          ""
        ),
    },
    {
      title: "Task ID",
      key: "id",
      render: ({ id, type, priorityStatus }) => (
        <span className={prioritize(type, priorityStatus) ? "bold" : ""}>
          {id}
        </span>
      ),
    },
    ...(["my-tasks", "company-overview-tasks"].indexOf(mode) > -1
      ? [
          {
            title: "User",
            key: "firstName",
            render: (record) => (
              <span
                className={
                  prioritize(record.type, record.priorityStatus) ? "bold" : ""
                }
              >
                {formatName(record).toUpperCase()}
              </span>
            ),
          },
        ]
      : []),
    {
      title: "Type",
      key: "type",
      render: ({ type, priorityStatus }) => (
        <span className={prioritize(type, priorityStatus) ? "bold" : ""}>
          {type?.name}
        </span>
      ),
    },
    {
      title: "Date Created",
      key: "createdDate",
      render: ({ type, createdDate, priorityStatus }) => (
        <span className={prioritize(type, priorityStatus) ? "bold" : ""}>
          {createdDate ? format(parseISO(createdDate), "yyyy-MM-dd") : ""}
        </span>
      ),
    },
    ...(useMode === "my-tasks"
      ? [
          {
            title: "Last Modified",
            key: "modifiedDate",
            render: ({ type, modifiedDate, priorityStatus }) => (
              <span className={prioritize(type, priorityStatus) ? "bold" : ""}>
                {modifiedDate
                  ? format(parseISO(modifiedDate), "yyyy-MM-dd")
                  : "N/A"}
              </span>
            ),
          },
        ]
      : []),
    {
      title: "Reminder",
      key: "reminderDate",
      render: ({ type, reminderDate, priorityStatus }) => (
        <span className={prioritize(type, priorityStatus) ? "bold" : ""}>
          {reminderDate ? format(parseISO(reminderDate), "yyyy-MM-dd") : "N/A"}
        </span>
      ),
    },
    {
      title: "Status",
      key: "status",
      render: ({ type, taskStatus, priorityStatus, relatedTask }) => {
        const badgeStatus =
          relatedTask?.taskStatus === "Complete" ? "success" : "error";

        return taskStatus ? (
          <span className={prioritize(type, priorityStatus) ? "bold" : ""}>
            {["Employee", "Company", "Sole Proprietor"].includes(
              type?.name
            ) && <Badge status={badgeStatus} />}
            {taskStatus}
          </span>
        ) : (
          ""
        );
      },
    },
    {
      title: <span>Action</span>,
      key: "action",
      render: (record) => {
        const userRole = localStorage.getItem("userRoles");
        const className = prioritize(record.type, record.priorityStatus)
          ? "ant-dropdown-link purple-link bold"
          : "ant-dropdown-link purple-link";

        if (
          !isAdmin() &&
          record.taskStatus !== "Awaiting Payment" &&
          !["Complaints", "Audit Certificate"].includes(record.type?.name)
        ) {
          return <h4 className="purple-link">View</h4>;
        }

        if (record.type?.name === "Registration" && useMode === "my-tasks") {
          return (
            <a
              className={className}
              onClick={() => {
                navigate(`/tasks/registration/${record.id}`, {
                  state: { ...record },
                });
              }}
            >
              View
            </a>
          );
        }

        if (useMode === "my-tasks" || username === "doreen@cfdc.org.za") {
          if (
            username !== "doreen@cfdc.org.za" &&
            [
              "Financial Ticket",
              "Sole Proprietor",
              "Company",
              "Employee",
              "Renewal",
            ].includes(record.type?.name) &&
            !["Sole Proprietor", "Company", "Employee"].includes(userRole)
          )
            return (
              <PermissionsGuard
                perform={"application:review"}
                yes={() => (
                  <a
                    className={className}
                    onClick={() => {
                      localStorage.removeItem("reviewId");
                      navigate("/admin/tasks/review", {
                        state: { ...record },
                      });
                    }}
                  >
                    View
                  </a>
                )}
                no={() => (
                  <a
                    className="ant-dropdown-link purple-link no-interact"
                    onClick={(e) => e.preventDefault()}
                  >
                    View
                  </a>
                )}
              />
            );

          if (
            ["Financial Ticket"].includes(record.type?.name) &&
            (["Sole Proprietor", "Company", "Employee"].includes(userRole) ||
              username === "doreen@cfdc.org.za")
            // && !isNaN(record?.employeeInvitation?.id)
          ) {
            return (
              <a
                className={className}
                onClick={() => {
                  navigate(`/tasks/payment/${record.id}`, {
                    state: { task: record },
                  });
                }}
              >
                View
              </a>
            );
          }

          if (
            record.type?.name === "Renewal" &&
            record.taskStatus === "Awaiting Payment" &&
            (["Sole Proprietor", "Company", "Employee"].includes(userRole) ||
              username === "doreen@cfdc.org.za")
          ) {
            return (
              <a
                className={className}
                onClick={() => {
                  navigate(`/tasks/renewal/${record.id}`, {
                    state: { task: record },
                  });
                }}
              >
                View
              </a>
            );
          }

          if (
            record.type?.name === "Penalty" &&
            record.taskStatus === "Awaiting Payment"
          ) {
            return (
              <a
                className={className}
                onClick={() => {
                  navigate(`/tasks/penalty/${record.id}`, {
                    state: { task: record },
                  });
                }}
              >
                View
              </a>
            );
          }

          if (
            record.type?.name === "Audit Certificate" &&
            record.taskStatus === "Pending"
          ) {
            return (
              <a
                className={className}
                onClick={() => {
                  navigate(`/tasks/audit/${record.id}`, {
                    state: { task: record },
                  });
                }}
              >
                View
              </a>
            );
          }

          if (record.type?.name === "Complaints") {
            return (
              <a
                className={className}
                onClick={() => {
                  navigate(`/admin/tasks/${record.id}`, {
                    state: { ...record },
                  });
                }}
              >
                View
              </a>
            );
          }

          if (
            (CREATE_TASK_TYPES.includes(record.type?.name) &&
              ADMIN_STREAMS.includes(localStorage.getItem("roleStream"))) ||
            ["Company"].includes(userRole)
          ) {
            return (
              <a
                className={className}
                onClick={() => {
                  navigate(`/tasks/direct/${record.id}`, {
                    state: { task: record },
                  });
                }}
              >
                View
              </a>
            );
          }
        }

        return (
          <a
            className="ant-dropdown-link purple-link no-interact"
            onClick={(e) => e.preventDefault()}
          >
            View
          </a>
        );
      },
    },
  ];

  const formatName = (record) => {
    const isEmployee =
      ["Employee", "Registration"].includes(record.type?.name) ||
      !isEmpty(record.employee);
    const isComplaint = record.type?.name.includes("Complaint");
    const isFinance =
      record.type?.name.includes("Financial") &&
      !isEmpty(record.employee) &&
      isEmpty(record.company);
    let name = "";

    if (isEmployee || isFinance) {
      name = `${record.employee?.firstName} ${record.employee?.lastName}`;
    } else if (isComplaint) {
      name = record.complaint?.debtCollectorName;
    } else {
      name = `${record.company?.createdBy?.firstName} ${record.company?.createdBy?.lastName}`;
    }

    return name.toUpperCase();
  };

  const uploadDocument = async (documentList: IDocumentList[]) => {
    const documents: number[] = [];

    for (let { presignedUrl, type, popFile, path, fileName } of documentList) {
      uploadToS3(presignedUrl, type, popFile);
      const { data } = await createDocument({
        variables: {
          input: {
            documentName: fileName,
            documentPath: path,
            documentStatusId: 1,
            documentFormat: type,
            companyId: +companyId,
            forCompany: true,
            documentTypeId: 10,
          },
        },
      });

      documents.push(data?.createDocument?.id);
    }

    return documents;
  };

  const handleCreateTask = async (args: {
    documentList: IDocumentList[];
    assignToCompany: boolean;
    companyId?: number;
  }) => {
    const values = await createTaskForm.validateFields().catch(() => {});

    if (!values) {
      return;
    }

    setShowSpin(true);

    try {
      let taskId: number;

      if (values.taskType === "Audit Certificate") {
        const { data } = await createAuditTask({
          variables: {
            input: {
              companyId: Number(args.companyId),
              financialYear: Number(values.financialYear),
            },
          },
        });

        taskId = data?.createAuditTask?.id;
      } else {
        const documents =
          args?.documentList?.length > 0
            ? await uploadDocument(args.documentList)
            : [];

        const { data } = await createTask({
          variables: {
            input: {
              taskType: values.taskType,
              priorityStatus: values.priorityStatus,
              companyId: +args.companyId,
              comment: values.comment,
              commentDocIds: documents,
              employeeId: +values.adminUserId,
              creatorId: +localStorage.getItem("userId"),
            },
          },
        });

        taskId = data?.createTask?.id;
      }

      setShowSpin(false);
      openNotificationWithIcon(
        "success",
        "Task creation success",
        `Task #${taskId} created successfully`
      );

      createTaskForm.resetFields();
      setCreateTaskModalVisible(false);
    } catch (err) {
      setShowSpin(false);
      // @ts-ignore
      console.log("create_task_error::", err.message);
      openNotificationWithIcon(
        "error",
        "Task creation error",
        "Error occurred while creating task"
      );
    }
  };

  const CreateTaskModalProps = {
    form: createTaskForm,
    visible: createTaskModalVisible,
    onCancel: () => setCreateTaskModalVisible(false),
    onCreate: (data: { documentList: IDocumentList[]; companyId: number }) =>
      handleCreateTask({
        documentList: data.documentList,
        assignToCompany: true,
        companyId: data.companyId,
      }),
    initialData: false,
    assignToCompany: true,
    enableDirectCreate: true,
  };

  if (error) {
    return <InternalErrorPage error={error} />;
  }

  return (
    <Spin tip="Loading..." className="loader" spinning={showSpin}>
      {initialising && <TableLoader />}
      {!initialising && showEmptyState && (
        <EmptyState
          location="tasks"
          headingText="Nothing to see here"
          bodyText="You currently have no incoming tickets. You can create a ticket to our support team to help with any problems you may be facing."
        >
          {![
            ADMIN_STREAM,
            FINANCE_STREAM,
            LEGAL_STREAM,
            MEMBER_STREAM,
            SUPER_USER_STREAM,
          ].includes(roleStream) && (
            <p>
              <Button
                className="purple-button"
                onClick={() => setCreateTaskModalVisible(true)}
              >
                <PlusOutlined title="Create new task" />
                Create new task
              </Button>
            </p>
          )}
        </EmptyState>
      )}
      <CreateTaskForm {...CreateTaskModalProps} />
      {!showEmptyState && (
        <>
          <div className="col-sm-12 col-md-12 placeholder-table-card">
            <Card>
              <div className="card-header">
                {useMode === "my-tasks" ? <h3>My Tasks</h3> : <h3>Tasks</h3>}
                <div>
                  {[
                    ADMIN_STREAM,
                    FINANCE_STREAM,
                    LEGAL_STREAM,
                    MEMBER_STREAM,
                    SUPER_USER_STREAM,
                  ].includes(roleStream) && (
                    <Button
                      className="purple-button"
                      onClick={() => setCreateTaskModalVisible(true)}
                    >
                      <PlusOutlined title="Create new task" />
                      Create new task
                    </Button>
                  )}
                  <Button
                    style={{ marginRight: "20px" }}
                    className="purple-button"
                    onClick={clearFilters}
                  >
                    Clear filters
                  </Button>
                  {["my-tasks", "member-overview-tasks"].indexOf(mode) > -1 &&
                    ![
                      ADMIN_STREAM,
                      FINANCE_STREAM,
                      LEGAL_STREAM,
                      MEMBER_STREAM,
                      SUPER_USER_STREAM,
                    ].includes(roleStream) && (
                      <Button
                        className="purple-button"
                        onClick={() => setCreateTaskModalVisible(true)}
                      >
                        <PlusOutlined title="Create new task" />
                        Create new task
                      </Button>
                    )}
                </div>
              </div>
              <div className={loading ? "no-interact" : null}>
                {!initialising && (
                  <TaskFilters
                    ref={filtersRef}
                    defaultMultiFilter={defaultMultiFilter}
                    mode={useMode}
                  />
                )}
                <Divider />
                <Table
                  rowKey={(record) => record.id}
                  columns={columns}
                  size="small"
                  pagination={{
                    current: savedCurrentPage,
                    showSizeChanger: false,
                    pageSize: TASK_PAGE_SIZE,
                    onChange: savePageNumber,
                    total: listTotal,
                    showTotal: () => <h3>Total: {listTotal}</h3>,
                  }}
                  dataSource={taskList}
                />
              </div>
            </Card>
          </div>
        </>
      )}
    </Spin>
  );
}

export default MemberTasksList;
