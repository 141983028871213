import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { Tabs } from "antd";
import { GET_MEMBER_OVERVIEW_INFORMATION } from "../Admin/graphql/queries";
import MemberTransactions from "../Admin/Transactions/memberTransactions";
import "./index.css";
import { useUserState } from "@/stores/user";

const AllTransactions = () => {
  const getRole = useUserState((s) => s.getActiveRole);

  const role = getRole();

  const tabItems = [
    {
      label: "Transactions",
      key: "1",
      children: (
        <>
          <MemberTransactions
            mode="company-overview-transactions"
            companyId={role.companyId}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <div className="tasks-header-div">
        <h2>Transactions</h2>
      </div>
      <Tabs tabPosition="top" items={tabItems} />
    </>
  );
};

export default AllTransactions;
