import { useMemo } from "react";
import AWaitingConfirmation from "../Profile/awaitingCofirmation";
import CompanyProfile from "../Admin/Companies/CompanyOverview/companyProfile";
import { APPLICATION_VERIFIED } from "../../constants";
import { useUserState } from "@/stores/user";

function MemberBusinessProfile() {
  const { activeUserRole, userRoles } = useUserState();

  const role = useMemo(
    () => userRoles.find((ur) => ur.id === activeUserRole),
    [activeUserRole, userRoles]
  );

  return (
    <>
      {role.applicationStatus !== APPLICATION_VERIFIED ? (
        <AWaitingConfirmation />
      ) : (
        <CompanyProfile companyId={role.companyId} />
      )}
    </>
  );
}

export default MemberBusinessProfile;
