import { useState, useEffect } from "react";
import {
  Button,
  Radio,
  Select,
  DatePicker,
  Divider,
  Skeleton,
  Form,
} from "antd";
import moment from "moment";
import { useQuery, useMutation } from "@apollo/client";
import { GET_OFFENCES_LIST, GET_PREQUALIFICATION } from "../../graphql/queries";
import {
  CREATE_PREQUALIFICATION,
  UPDATE_PREQUALIFICATION,
} from "../../graphql/mutations";
import { COMPANY_TYPES } from "../../constants";

import InternalErrorPage from "@/containers/InternalErrorPage";
import { notifyError, openNotificationWithIcon } from "@/utils/notification";
import { isEmpty } from "lodash";
import { useRegistrationState } from "@/stores/registration";

interface IProps {}

function PrequalificationForm({}: IProps) {
  const [form] = Form.useForm();

  const {
    setRegistrationStateObject: setRegistrationState,
    registeringAs: registeredAs,
    companyId,
    ...rest
  } = useRegistrationState();

  const [isSubmitting, setIsSubmiting] = useState(false);

  const [showOffenceDetails, setShowOffenceDetails] = useState(false);
  const [showConvictionDetails, setShowConvictionDetails] = useState(false);

  const [_, setHasOffence] = useState<boolean>();
  const [convicted] = useState<boolean>();
  const [curatedForLegalCapacity, setCuratedForLegalCapacity] =
    useState<boolean>();
  const [improperConduct] = useState<boolean>();
  const [appliedForExemption] = useState<boolean>();

  const [prequalificationId, setPrequalificationId] = useState<number>();
  const [prequalification, setPrequalification] = useState<any>();

  const [currentStep, setCurrentStep] = useState("step3Saved");
  const [createPrequalification] = useMutation(CREATE_PREQUALIFICATION);
  const [updatePrequalification] = useMutation(UPDATE_PREQUALIFICATION);

  const isSP = registeredAs === "A Sole Proprietor";
  const isCompany = COMPANY_TYPES.includes(registeredAs);
  const step =
    registeredAs === "A Sole Proprietor" ? "step4Saved" : "step3Saved";

  useEffect(() => {
    setCurrentStep(step);

    if (!!rest[step]) {
      refecthPrequalification().then((resp) => {
        const _prequalification = resp.data.prequalification;

        setPrequalificationId(_prequalification?.id);
        setPrequalification(_prequalification);
      });
    }
  }, []);

  const stepDetails = {
    title: `Step ${isSP ? 4 : 3}: Prequalification`,
    content: "Complete the short form that enables you for prequalification",
  };

  const {
    loading: loadingPrequalification,
    error: errorPrequalification,
    data: dataPrequalification,
    refetch: refecthPrequalification,
  } = useQuery(GET_PREQUALIFICATION, {
    variables: {
      companyId,
    },
    onCompleted: () => {
      if (!isEmpty(dataPrequalification.prequalification)) {
        const _prequalification = dataPrequalification.prequalification;

        setPrequalificationId(_prequalification?.id);
        setPrequalification(_prequalification);
        setHasOffence(_prequalification?.onTrial);

        setRegistrationState({
          appliedForExemption: _prequalification.appliedForExemption,
          [currentStep]: true,
        });

        // currated for legal capacity or has offence and convicted but has not applied for exemption
        // fail the application
        if (
          (_prequalification?.onTrial &&
            _prequalification?.convicted &&
            !_prequalification?.appliedForExemption) ||
          _prequalification?.curatedForLegalCapacity
        ) {
          window.location.replace("/register/fail/prequalification");
        }
      }
    },
  });

  const {
    loading: loadingOffences,
    error: errorOffences,
    data: dataOffences,
  } = useQuery(GET_OFFENCES_LIST);

  if (errorOffences || errorPrequalification) {
    return <InternalErrorPage />;
  }

  const { offenceList } = !loadingOffences && dataOffences;

  //selectable options of offences
  const offenceOptions: any = offenceList
    ? offenceList.map((type) => {
        return (
          <Select.Option key={type.id} value={type.id}>
            {type.name}
          </Select.Option>
        );
      })
    : [];

  const handleSubmit = async (values: any) => {
    // when company is on trial for offence - Yes to Q1
    if (!values.hasOffence) {
      const input = {
        id: prequalificationId ? prequalificationId : undefined,
        onTrial: values.hasOffence,
        convicted: false,
        appliedForExemption: false,
        curatedForLegalCapacity: false,
        dateOfTrial: null,
        typeOfOffence: null,
        guiltyOfImproperConduct: false,
        isProofOfExemptionApplicationUploaded: false,
        companyId,
      };
      return savePrequalification(input);
    }

    const input = {
      id: prequalificationId ? prequalificationId : undefined,
      onTrial: isCompany ? false : values.hasOffence,
      convicted: isCompany ? false : values.convicted,
      appliedForExemption: isCompany ? false : values.appliedForExemption,
      curatedForLegalCapacity: values.curatedForLegalCapacity,
      dateOfTrial: values.dateOfTrialOffence,
      typeOfOffence: isCompany ? null : values.typeOfOffence,
      guiltyOfImproperConduct: values.improperConduct,
      companyId,
    };

    savePrequalification(input);
  };

  const savePrequalification = (values: any) => {
    setIsSubmiting(true);

    const upsertPrequalification = prequalificationId
      ? updatePrequalification
      : createPrequalification;

    upsertPrequalification({
      variables: {
        input: { ...values },
      },
    })
      .then(async (res) => {
        const _prequalification = prequalificationId
          ? res.data.updatePrequalification
          : res.data.createPrequalification;

        setPrequalificationId(_prequalification.id);
        setPrequalification(_prequalification);

        setIsSubmiting(false);

        setRegistrationState({
          appliedForExemption: values.appliedForExemption,
          [currentStep]: true,
        });

        openNotificationWithIcon(
          "success",
          "Save Success",
          "Prequalification data successfuly saved"
        );

        // currated for legal capacity or has offence and convicted but has not applied for exemption
        // fail the application
        if (
          (_prequalification?.onTrial &&
            _prequalification?.convicted &&
            !_prequalification?.appliedForExemption) ||
          _prequalification?.curatedForLegalCapacity
        ) {
          window.location.replace("/register/fail/prequalification");
        }
      })
      .catch((err) => {
        setIsSubmiting(false);
        notifyError(err);
      });
  };

  if (loadingOffences || loadingPrequalification) {
    return <Skeleton active paragraph={{ rows: 6 }} />;
  }

  return (
    <div className="flex-column">
      <div>
        <span className="step-title">{stepDetails.title}</span>
        <p>{stepDetails.content}</p>
        <Divider />
      </div>

      <Form
        form={form}
        onFinish={handleSubmit}
        onFinishFailed={(errors) => {
          console.log(errors);
        }}
        onFieldsChange={(changes) => {
          console.log(changes);
          if (changes[0].name[0] === "hasOffence") {
            setShowOffenceDetails(changes[0].value);
          }

          if (changes[0].name[0] === "convicted") {
            setShowConvictionDetails(changes[0].value);
          }
        }}
        layout="vertical"
        preserve={false}
      >
        <div className="">
          {!isCompany && (
            <>
              <div className="input-spacer">
                <Form.Item
                  label="1. Are you/any party related to the Business, on trial for an
                offence of which violence, dishonesty, extortion or intimidation
                is an element or have in the preceding 10 years been convicted
                of such offence?"
                  {...{
                    name: "hasOffence",
                    initialValue: !isEmpty(prequalification)
                      ? prequalification.onTrial
                      : false,
                    rules: [
                      {
                        required: true,
                        message: "Please indicate",
                      },
                    ],
                  }}
                >
                  <Radio.Group>
                    <Radio.Button value={true}>Yes</Radio.Button>
                    <Radio.Button value={false}>No</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </div>
            </>
          )}

          {!isCompany && (prequalification?.onTrial || showOffenceDetails) && (
            <>
              <Form.Item
                label="Date of trial/offence"
                className="form-input-width"
                {...{
                  name: "dateOfTrialOffence",
                  initialValue: !isEmpty(prequalification)
                    ? moment(prequalification.dateOfTrial)
                    : moment(),
                  rules: [
                    {
                      required: true,
                      message: "Please select date of trial or offence",
                    },
                  ],
                }}
              >
                <DatePicker className="input-height input-select" />
              </Form.Item>

              <Form.Item
                label="Type of Offence"
                className="form-input-width"
                {...{
                  name: "typeOfOffence",
                  initialValue: !isEmpty(prequalification?.typeOfOffence)
                    ? prequalification.typeOfOffence?.id
                    : undefined,
                  rules: [
                    {
                      required: true,
                      message: "Please select date type of offence",
                    },
                  ],
                }}
              >
                <Select className="input-select input-height">
                  {offenceOptions}
                </Select>
              </Form.Item>

              <Form.Item
                label="Convicted?"
                {...{
                  name: "convicted",
                  initialValue: !isEmpty(prequalification)
                    ? prequalification.convicted
                    : convicted ?? false,
                  rules: [
                    {
                      required: true,
                      message: "Please indicate",
                    },
                  ],
                }}
              >
                <Radio.Group className="input-select input-spacer">
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </>
          )}

          <div className="flex-column">
            {(prequalification?.convicted || showConvictionDetails) && (
              <div className="">
                <Form.Item
                  label="1.1 Has an application for exemption in terms of section 10(2)
                  been lodged with the Ministry of Justice and Constitutional
                  Development?"
                  {...{
                    name: "appliedForExemption",
                    initialValue: !isEmpty(prequalification)
                      ? prequalification.appliedForExemption
                      : appliedForExemption,
                    rules: [
                      {
                        required: true,
                        message: "Please indicate",
                      },
                    ],
                  }}
                >
                  <Radio.Group>
                    <Radio.Button value={true}>Yes</Radio.Button>
                    <Radio.Button value={false}>No</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </div>
            )}

            <div className="">
              <Form.Item
                label={`${
                  isCompany ? 1 : 2
                }. Has the Business ever, in terms of section
                15 of Act, been found guilty of improper conduct?`}
                {...{
                  name: "improperConduct",
                  initialValue: !isEmpty(prequalification)
                    ? prequalification.guiltyOfImproperConduct
                    : improperConduct,
                  rules: [
                    {
                      required: true,
                      message: "Please indicate",
                    },
                  ],
                }}
              >
                <Radio.Group className="r">
                  <Radio.Button value={true}>Yes</Radio.Button>
                  <Radio.Button value={false}>No</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </div>

            <div className="">
              <Form.Item
                label={`${
                  isCompany ? 2 : 3
                }. Have you or any person in this registration been placed under curatorship for legal capacity?`}
                {...{
                  name: "curatedForLegalCapacity",
                  initialValue: !isEmpty(prequalification)
                    ? prequalification.curatedForLegalCapacity
                    : curatedForLegalCapacity,
                  rules: [
                    {
                      required: true,
                      message: "Please indicate",
                    },
                  ],
                }}
              >
                <Radio.Group className="">
                  <Radio.Button
                    value={true}
                    onClick={() => setCuratedForLegalCapacity(true)}
                  >
                    Yes
                  </Radio.Button>
                  <Radio.Button
                    value={false}
                    onClick={() => setCuratedForLegalCapacity(false)}
                  >
                    No
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </div>
          </div>

          <div className="form-input-width">
            <Button
              htmlType="submit"
              className="btn-registration-capacity-selected"
              loading={isSubmitting}
            >
              Save information
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default PrequalificationForm;
