import { Result, Button } from "antd";
import { useRouteError } from "react-router";

type Props = {
  error?: string;
};
export default function InternalErrorPage({ error }: Props) {
  const err = useRouteError() as Error;

  console.error("Internal Error", err, error);

  return (
    <Result
      status="500"
      title="An error has occurred"
      subTitle={
        error ||
        err.message ||
        JSON.stringify(err) ||
        "Something went wrong on this page. Try reloading the page."
      }
      extra={
        <Button onClick={() => window.location.reload()} type="primary">
          Reload Page
        </Button>
      }
    />
  );
}
