import { useState, useMemo } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { LinkOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Modal,
  Spin,
  Select,
  Input,
  Card,
  Menu,
  Button,
  Upload,
  Form,
} from "antd";
import { GET_PRESIGNED_URL } from "../../../graphql/queries";
import { CREATE_DOCUMENT } from "../../../graphql/mutations";
import {
  ARCHIVE_COMPANY,
  CHANGE_COMPANY_STATUS,
  DELETE_COMPANY,
} from "../graphql/mutations";
import { uploadToS3 } from "@/utils/uploadToS3";
import { openNotificationWithIcon } from "@/utils/notification";
import { If } from "@/components/If";

type Props = {
  typeOfChange: "status" | "delete" | "archive";
  companyDetails: any;
  visible: boolean;
  onCancel: () => void;
  refreshList: () => void;
  setModalVisible: (arg: boolean) => void;
};

export default function ChangeCompanyModal(props: Props) {
  const {
    onCancel,
    refreshList,
    setModalVisible,
    companyDetails,
    visible,
    typeOfChange,
  } = props;
  const [form] = Form.useForm();
  const [status, setStatus] = useState("");
  const [reason, setReason] = useState("");
  const [filePath, setFilePath] = useState<{ path: string; name: string }>();
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<any>();

  const name = useMemo(() => {
    if (companyDetails)
      return `(
        ${companyDetails.tradingName || companyDetails.registeredName}
      )`.toUpperCase();
    else return "";
  }, [companyDetails]);

  const reasons = useMemo<string[]>(() => {
    switch (status) {
      case "active":
        return [
          "Database Error",
          "Misallocation of Payment",
          "Termination of Suspension",
        ];
      case "deactivated":
        return ["Outcome of Hearing"];
      case "suspended":
        return [
          "Under Investigation",
          "Outstanding Fees",
          "Outcome of Hearing",
          "Outstanding Audit Certificate",
        ];
      default:
        return [""];
    }
  }, [status]);

  const statuses = ["Active", "Suspended", "Deregistered"];

  const handleChange = async () => {
    try {
      setLoading(true);
      if (typeOfChange === "status") {
        await changeStatus({
          variables: {
            input: {
              status,
              reason,
              id: companyDetails?.id,
            },
          },
        });
      } else if (typeOfChange === "delete") {
        await deleteCompany({ variables: { id: companyDetails.id } });
      } else {
        await archiveCompany({ variables: { id: companyDetails.id } });
      }

      openNotificationWithIcon(
        "success",
        "Success",
        typeOfChange === "status"
          ? "successfully updated the company status"
          : "successfully deleted the company from the platform"
      );

      setModalVisible(false);
      refreshList();
    } catch (error) {
      console.error(error);
      openNotificationWithIcon(
        "error",
        "Change Company Error",
        error.message.replace("GraphQL error: ", "")
      );
    } finally {
      setLoading(false);
    }
  };

  const [changeStatus] = useMutation(CHANGE_COMPANY_STATUS);

  const [archiveCompany] = useMutation(ARCHIVE_COMPANY);

  const [deleteCompany] = useMutation(DELETE_COMPANY);

  const [createDocument] = useMutation(CREATE_DOCUMENT);

  const [getPresignedUrl] = useLazyQuery(GET_PRESIGNED_URL, {
    fetchPolicy: "network-only",
    onCompleted: async ({ preSignedUrl }) => {
      if (preSignedUrl) {
        await uploadToS3(preSignedUrl, file.type, file);
        await createDocument({
          variables: {
            input: {
              documentName: filePath?.name,
              documentPath: filePath?.path,
              documentTypeId: 27,
              documentStatusId: 1,
              documentFormat: file.type,
              forCompany: true,
              companyId: companyDetails?.id,
            },
          },
        });
      }
    },
    onError: (err) => {
      console.error({ err });
    },
  });

  const handleUpload = async ({ file, onSuccess }: any) => {
    setUploading(true);
    setFile(file);

    const ext = file.type.split("/").pop();
    const name = `change_status_${Date.now()}`;
    const path = `${companyDetails?.id}/admin-uploads/${name}.${ext}`;
    setFilePath({ path, name });

    getPresignedUrl({
      variables: {
        input: {
          filePath: path,
          contentType: file.type,
          forCompany: false,
          companyName: "Admin",
        },
      },
    });

    setTimeout(() => {
      setUploading(false);
      onSuccess();
    }, 3500);
  };

  const title = useMemo(() => {
    switch (typeOfChange) {
      case "status":
        return "Change Company Status";
      case "delete":
        return `Delete Company ${name}`;
      case "archive":
        return `Archive Company ${name}`;
      default:
        return "";
    }
  }, [typeOfChange, name]);

  const btnText = useMemo(() => {
    switch (typeOfChange) {
      case "status":
        return "Change";
      case "delete":
        return "Delete";
      case "archive":
        return "Archive";
      default:
        return "";
    }
  }, [typeOfChange]);

  return (
    <Modal
      open={visible}
      title={title}
      okText={btnText}
      onCancel={onCancel}
      onOk={handleChange}
      okButtonProps={{
        className: "purple-button",
        disabled: loading || uploading,
      }}
      cancelButtonProps={{
        disabled: loading || uploading,
      }}
      okType="default"
    >
      <Spin tip="Loading..." spinning={loading}>
        <If condition={typeOfChange === "archive"}>
          <div style={{ textAlign: "center" }}>
            <h3>
              You are about to archive the company and all its associated data.{" "}
              <br />
              The company will no longer be visible on the platform, but the
              data will not be deleted and can be restored later.
            </h3>
            <br />
          </div>
        </If>

        <If condition={typeOfChange === "delete"}>
          <div style={{ textAlign: "center" }}>
            <h3>
              You are about to delete a company and all its associated data.
              <br />
              Are you sure you want to continue with this irreversible action?
            </h3>
            <br />
          </div>
        </If>

        <If condition={typeOfChange === "status"}>
          <Form form={form} layout="vertical">
            <Form.Item
              label="New Status"
              name={"status"}
              {...{
                initialValue: "",
                rules: [
                  {
                    required: true,
                    message: "Please select the new member status",
                  },
                ],
              }}
            >
              <Select
                className="input-select"
                onChange={(value) => setStatus(value.toString())}
              >
                {statuses.map((item, index) => (
                  <Select.Option
                    key={`item-${index}`}
                    value={item.toLowerCase().replace(/ /g, "_")}
                  >
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={`Reason for change of status`}
              name="reason"
              {...{
                initialValue: "",
                rules: [
                  {
                    required: true,
                    message: "Please select one of the reasons",
                  },
                ],
              }}
            >
              <Select
                className="input-select"
                onChange={(value) => setReason(value.toString())}
              >
                {reasons.map((r) => (
                  <Select.Option
                    key={r}
                    value={r.toLowerCase().replace(/ /g, "_")}
                  >
                    {r}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <h4>Add a comment</h4>
            <Card
              className="comment-card"
              cover={
                <Input.TextArea
                  style={{ resize: "none" }}
                  rows={3}
                  placeholder="Start typing..."
                  disabled
                />
              }
            >
              <Card.Meta
                title={
                  <div className="comment-action">
                    <Menu
                      mode="vertical"
                      theme="light"
                      className="link-user-menu"
                      style={{ visibility: "hidden" }}
                    >
                      <Menu.SubMenu
                        title={
                          <span>
                            <LinkOutlined
                              style={{
                                fontSize: "26px",
                                color: "darkslategray",
                              }}
                            />
                            <span>Link</span>
                          </span>
                        }
                      >
                        <Menu.Item
                          key={"y"}
                          id={"1"}
                          onClick={(e) => {
                            // setLinkedUserId(e.key);
                            // setLinkedUserName(e.item.props.id);
                          }}
                        >
                          Hello
                        </Menu.Item>
                        <Menu>
                          {[].map((u) => (
                            <Menu.Item
                              key={u.id}
                              id={`${u.firstName} ${u.lastName}`}
                              onClick={(e) => {}}
                            >
                              {u.firstName} {u.lastName}(Admin)
                            </Menu.Item>
                          ))}
                        </Menu>
                      </Menu.SubMenu>
                    </Menu>
                    <div>
                      <Button className="btn-add-comment" disabled>
                        Save
                      </Button>
                    </div>
                  </div>
                }
              />
            </Card>
            <Upload
              disabled={loading || uploading}
              customRequest={handleUpload}
            >
              <Button disabled={!!!companyDetails?.id}>
                <UploadOutlined /> Upload
              </Button>
            </Upload>
          </Form>
        </If>
      </Spin>
    </Modal>
  );
}
