import { Row, Col, Divider } from "antd";
import PersonalInformationForm from "./EditableProfileForms/PersonalInformationForm";
import AddressForm from "../../../RegisterEmployee/addressForm";
import EducationExperienceForm from "../../../RegisterEmployee/educationExperience";

const MemberProfileEditable = ({ userData }) => {
  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <PersonalInformationForm userInfo={userData} />
        </Col>
      </Row>
      <Divider />
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <div className="card-header">
            <h3>Location info</h3>
          </div>
          <AddressForm editProfileMode={true} userId={userData.id} />
        </Col>
      </Row>
      <Divider />
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <div className="card-header">
            <h3>Language & Education info</h3>
          </div>
          <EducationExperienceForm
            editProfileMode={true}
            userId={userData.id}
          />
        </Col>
      </Row>
    </>
  );
};

export default MemberProfileEditable;
