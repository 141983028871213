import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { FileOutlined } from "@ant-design/icons";
import { Table, Button, Spin, Descriptions, Typography } from "antd";
import {
  GET_COMPANY_REGISTRATION_FEES,
  GET_EMPLOYEE_REGISTRATION_FEES,
  GET_TRANSACTION_BY_FILTER,
  GET_DOCUMENTS,
  GET_DOWNLOAD_URL,
  GENERATE_SAGE_DOC,
  GET_USER_EMPLOYMENT_BY_COMPANY,
} from "../../../graphql/queries";
import ResultComment from "./resultComment";
import InformationLoader from "./informationLoader";
import "./index.css";
import { openNotificationWithIcon } from "@/utils/notification";
import fileIcon from "../../../assets/images/pdf_icon.png";

function ReviewPaymentInformation({
  transaction: task,
  reviewInfo,
  registrationTaskId,
}: any) {
  const { company, employee, type, id, invoice: _invoice, relatedTask } = task;
  const invoice = _invoice || relatedTask.invoice;
  const companyId =
    type.name !== "Employee"
      ? company.id
      : Number(localStorage.getItem("employerId"));
  const userId = employee?.id;
  const companyName =
    type.name === "Employee"
      ? undefined
      : company.registeredName || company.tradingName;
  const [businessFee, setBusinessFee] = useState(0);
  const [transactionData, setTransactionData] = useState(null);
  const [proofOfPaymentDocs, setProofOfPaymentDocs] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [noTransactionFound, setNoTransactionFound] = useState(null);
  const [presignedDownloadUrl, setPresignedDownloadUrl] = useState(null);
  const [showSpin, setShowSpin] = useState(false);
  const [loadingTransactionInfo, setLoadingTransactionInfo] = useState(false);
  const [loadingDocuments, setLoadingDocuments] = useState(true);
  const [relatedTaskId] = useState(task.relatedTask?.id);
  const [userEmploymentInfo, setUserEmploymentInfo] = useState<any>();
  const [relatedPartyFee, setRelatedPartyFee] = useState(0);
  const [employeeFee, setEmployeeFee] = useState(0);
  const [total, setTotal] = useState(0);
  const taskType = task.type?.name;
  const isFinancialTicket = taskType === "Financial Ticket";
  const [isFinanceApproved, setIsFinanceApproved] = useState(false);

  const isAdmin = localStorage
    .getItem("userPermissions")
    .split(",")
    .includes("review:edit");

  const relatedTaskPresent = !!(
    task.relatedTask || task.relatedTasks.length > 0
  );

  useEffect(() => {
    getTransactionBasedOnTask();
    getFilteredDocuments();
  }, []);

  const [getEmploymentInfo] = useLazyQuery(GET_USER_EMPLOYMENT_BY_COMPANY, {
    fetchPolicy: "no-cache",
    variables: {
      userId,
      companyId,
    },
    onCompleted: (data) => {
      setUserEmploymentInfo(data.userEmploymentByCompany);
    },
  });

  const [getFilteredDocuments, { loading: loadingPOP }] = useLazyQuery(
    GET_DOCUMENTS,
    {
      variables: {
        input: {
          companyId,
          includeTotal: true,
        },
      },
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        if (data.documents?.documentList) {
          const proofOfPaymentDocs = data.documents.documentList.filter((doc) =>
            doc.name.includes(
              transactionData?.directDepositInvoiceNumber?.replace("-", "_")
            )
          );
          setProofOfPaymentDocs(proofOfPaymentDocs);
        }
        setLoadingDocuments(false);
      },
      onError: () => {
        setLoadingDocuments(false);
        openNotificationWithIcon(
          "error",
          "Load Error",
          "There was an error retrieving the document"
        );
      },
    }
  );

  const [getDownloadUrl] = useLazyQuery(GET_DOWNLOAD_URL, {
    fetchPolicy: "no-cache",
    onError: (err) => {
      setShowSpin(false);
      openNotificationWithIcon(
        "error",
        "Load Error",
        "There was an error retrieving the document"
      );
    },
    onCompleted: (data) => {
      setShowSpin(false);
      setPresignedDownloadUrl(data.preSignedDownloadUrl);
    },
  });

  const [getSageDocument] = useLazyQuery(GENERATE_SAGE_DOC, {
    fetchPolicy: "no-cache",
    onError: (err) => {
      setShowSpin(false);
      openNotificationWithIcon(
        "error",
        "Load Error",
        "There was an error retrieving the document"
      );
    },
    onCompleted: (data) => {
      setShowSpin(false);
      setPresignedDownloadUrl(data.generateSageDocument);
    },
  });

  const [getTransactionBasedOnTask] = useLazyQuery(GET_TRANSACTION_BY_FILTER, {
    fetchPolicy: "no-cache",
    variables: {
      input: {
        taskId: isFinancialTicket ? id : relatedTaskId,
        includeTotal: false,
      },
    },
    onError: () => {
      setLoadingTransactionInfo(false);
      openNotificationWithIcon(
        "error",
        "Error",
        "Error loading transactions for review"
      );
    },
    onCompleted: (data) => {
      setLoadingTransactionInfo(false);
      if (
        data.transactions.transactionList &&
        data.transactions.transactionList[0]
      ) {
        setPaymentMethod(data.transactions.transactionList[0].paymentMethod);
        setTransactionData(data.transactions.transactionList[0]);
        getEmploymentFeeInfo();
        setNoTransactionFound(false);
      } else {
        setNoTransactionFound(true);
      }
    },
  });

  const [getEmploymentFeeInfo] = useLazyQuery(
    userId ? GET_EMPLOYEE_REGISTRATION_FEES : GET_COMPANY_REGISTRATION_FEES,
    {
      fetchPolicy: "no-cache",
      variables: {
        companyId,
        userId,
      },
      onError: () => {
        setLoadingTransactionInfo(false);
        openNotificationWithIcon(
          "error",
          "Error",
          "Error loading financial information"
        );
      },
      onCompleted: (data) => {
        setLoadingTransactionInfo(false);
        const regFeeData = userId
          ? data.employeeRegistrationFees
          : data.companyRegistrationFees;
        setBusinessFee(
          regFeeData.businessFee ? regFeeData.businessFee.amountIncl : 0
        );
        setRelatedPartyFee(
          regFeeData.relatedPartyFee ? regFeeData.relatedPartyFee.amountIncl : 0
        );
        setEmployeeFee(
          regFeeData.employeeFee ? regFeeData.employeeFee.amountIncl : 0
        );
      },
    }
  );

  const handleViewTemplate = () => {
    setShowSpin(true);
    getSageDocument({
      variables: {
        input: {
          docType: "invoice",
          companyId: company.id,
          sageInvoiceId: invoice.sageInvoiceId,
        },
      },
    });
  };

  const isRepresentativeRegistration = (capacity: string) =>
    ["Director", "Member", "Officer", "Partner", "Trustee"].includes(capacity);

  const handleViewDocument = (filePath) => {
    setShowSpin(true);
    getDownloadUrl({
      variables: {
        input: {
          filePath,
          forCompany: true,
        },
      },
    });
  };

  useEffect(() => {
    setTotal(businessFee + relatedPartyFee + employeeFee);
  }, [businessFee, relatedPartyFee, employeeFee]);

  useEffect(() => {
    if (presignedDownloadUrl) window.open(presignedDownloadUrl);
  }, [presignedDownloadUrl]);

  useEffect(() => {
    setLoadingTransactionInfo(true);
    if (!isNaN(userId) && !isNaN(companyId)) {
      getEmploymentInfo();
    }

    if (["Complete", "Resolved"].includes(relatedTask?.taskStatus)) {
      setIsFinanceApproved(true);
    }
  }, []);

  if (loadingTransactionInfo) return <InformationLoader />;

  const companyFeesDataSource = [
    {
      key: "1",
      fee: `R ${businessFee}`,
      description:
        "New Registration Fee (inclusive of Annual Fees) of business type:",
    },
    {
      key: "2",
      fee: `R ${relatedPartyFee}`,
      description: "Registration Fee calculation per [PARTY] of business:",
    },
    {
      key: "3",
      fee: `R ${employeeFee}`,
      description: "Registration Fee calculation per [RELATION] of business:",
    },
    {
      key: "4",
      fee: `R ${total}`,
      description: "Total",
    },
  ];

  const employeeFeesDataSource = [
    {
      key: "1",
      fee: invoice?.amount ? `R ${(+invoice.amount).toFixed(2)}` : "n/a",
      description: "Registration Fee calculation per [RELATION] of business:",
    },
    {
      key: "4",
      fee: invoice?.amount ? `R ${(+invoice.amount).toFixed(2)}` : "n/a",
      description: "Total",
    },
  ];

  const columns = [
    {
      title: "Description",
      render: (record) => record.description,
      key: "description",
    },
    {
      title: "Amount",
      render: (record) => record.fee,
      key: "fee",
    },
  ];

  return (
    <>
      <Spin
        tip="Loading..."
        className="loader"
        style={{ display: showSpin ? "block" : "none" }}
      />
      <div
        style={{ margin: "1vw" }}
        className={
          showSpin ||
          (!isAdmin && isFinancialTicket && paymentMethod === "Direct")
            ? "no--interact"
            : null
        }
      >
        {!relatedTaskPresent &&
          !["Financial Ticket", "Renewal"].includes(taskType) && (
            <div
              style={{
                background: "#ccc",
                width: "100%",
                padding: "20px",
                borderRadius: "10px",
                color: "#121212",
              }}
            >
              This task has no related financial task/ticket and should not be
              passed.
            </div>
          )}

        {noTransactionFound === false && !loadingTransactionInfo && (
          <>
            {paymentMethod === "Direct" &&
              task.type.name !== "Financial Ticket" &&
              isFinanceApproved && (
                <div
                  style={{
                    background: "#50af50",
                    width: "100%",
                    padding: "20px",
                    borderRadius: "10px",
                    color: "#fff",
                    marginBottom: "30px",
                  }}
                >
                  This step of the review has already been approved by finance
                </div>
              )}

            {paymentMethod === "Direct" &&
              task.type.name !== "Financial Ticket" &&
              !isFinanceApproved && (
                <div
                  style={{
                    background: "red",
                    width: "100%",
                    padding: "20px",
                    borderRadius: "10px",
                    color: "#fff",
                    marginBottom: "30px",
                  }}
                >
                  This step of the review is yet to be approved or has been
                  rejected by finance. Application review can only be accepted
                  once this step has been approved. Please contact your finance
                  admin.
                </div>
              )}

            <div className="flex-column information-block">
              <Descriptions layout="vertical">
                <Descriptions.Item label="Status">
                  {transactionData?.status?.name
                    ? transactionData?.status?.name
                    : "Loading..."}
                </Descriptions.Item>
                <Descriptions.Item label="Employee">
                  {`${task.employee.firstName} ${task.employee.lastName} [${task.employee.idNumber}]`}
                </Descriptions.Item>
                <Descriptions.Item label="Payment Method">
                  {paymentMethod === "Direct"
                    ? "Direct Deposit"
                    : paymentMethod}
                </Descriptions.Item>
                <Descriptions.Item label="Payee Responsible">
                  {companyName}
                </Descriptions.Item>
                {transactionData?.directDepositInvoiceNumber &&
                  transactionData?.paymentMethod.toLowerCase() === "direct" && (
                    <Descriptions.Item label="Direct Deposit Reference">
                      {transactionData.directDepositInvoiceNumber}
                    </Descriptions.Item>
                  )}
                {task.comment && (
                  <Descriptions.Item label="Comment">
                    {task?.comment}
                  </Descriptions.Item>
                )}
              </Descriptions>
            </div>
            <br />
            <Table
              dataSource={
                userId ? employeeFeesDataSource : companyFeesDataSource
              }
              columns={columns}
              pagination={false}
              title={() => <Typography.Text>Payee Breakdown</Typography.Text>}
            />
            <br />
            {isFinancialTicket && (
              <>
                <div className="flex-column information-block">
                  <span>Invoice:</span>
                  <div style={{ paddingLeft: "15px" }}>
                    <div>
                      <span>
                        <img
                          style={{ height: "25px", width: "25px" }}
                          src={fileIcon}
                        />
                        &nbsp; &nbsp;
                        {"invoice"}
                        <Button
                          type="link"
                          loading={loadingDocuments}
                          onClick={() => handleViewTemplate()}
                        >
                          View
                        </Button>
                      </span>
                      <br />
                    </div>
                  </div>
                </div>
                <br />
                <div className="flex-column information-block">
                  <span>
                    Proof of Payments:{" "}
                    <Button
                      onClick={() => getFilteredDocuments()}
                      type="link"
                      loading={loadingPOP}
                    >
                      Refresh
                    </Button>
                  </span>
                  <div style={{ paddingLeft: "15px" }}>
                    <Spin
                      tip="Loading proof of payments..."
                      className="loader"
                      style={{ display: loadingDocuments ? "block" : "none" }}
                    />
                    {!loadingDocuments &&
                      proofOfPaymentDocs.map((doc) => {
                        return (
                          <div key={doc.id}>
                            <span>
                              <img
                                style={{ height: "25px", width: "25px" }}
                                src={fileIcon}
                              />
                              &nbsp; &nbsp;
                              {doc.name}
                              <Button
                                type="link"
                                loading={loadingDocuments}
                                onClick={() =>
                                  handleViewDocument(doc.documentPath)
                                }
                              >
                                View
                              </Button>
                            </span>
                            <br />
                          </div>
                        );
                      })}
                  </div>
                </div>
              </>
            )}
            <br />
            {false && ( // TODO: To be removed.
              <div className="flex-column information-block">
                <span>Receipt:</span>
                <Button
                  type="default"
                  onClick={() => handleViewTemplate()}
                  disabled={transactionData?.sageReceiptId ? false : true}
                >
                  <FileOutlined />
                  View Receipt
                </Button>
              </div>
            )}
          </>
        )}
        {!isAdmin && noTransactionFound === true && !loadingTransactionInfo && (
          <div className="flex-column information-block">
            <span>
              No transaction found.
              <br />
              <br />
              {noTransactionFound === true &&
                !isRepresentativeRegistration(
                  userEmploymentInfo?.capacity?.name
                ) && (
                  <span style={{ color: "red" }}>
                    Since this application is for a non-representative role (
                    {userEmploymentInfo?.capacity?.name
                      ? userEmploymentInfo?.capacity?.name
                      : "N/A"}
                    ) payment must be made before this step may be passed or
                    failed.
                  </span>
                )}
              {!isAdmin &&
                noTransactionFound === true &&
                isRepresentativeRegistration(
                  userEmploymentInfo?.capacity?.name
                ) && (
                  <span>
                    Since this application is for a company representative role
                    (
                    {userEmploymentInfo?.capacity?.name
                      ? userEmploymentInfo?.capacity?.name
                      : "N/A"}
                    ) no payment is required (provided the applicant already
                    exists as a valid representative of the company).
                  </span>
                )}
            </span>
          </div>
        )}
        <br />
        <div
          className={
            !isAdmin &&
            noTransactionFound === true &&
            !isRepresentativeRegistration(userEmploymentInfo?.capacity?.name)
              ? "no-interact"
              : null
          }
        >
          <ResultComment
            {...{
              ...task,
              step: "RegistrationFee",
              reviewInfo,
              relatedTaskId,
              isForRenewal: false,
              registrationTaskId,
            }}
          />
        </div>
      </div>
    </>
  );
}

export default ReviewPaymentInformation;
