import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";
import Completed from "../../assets/images/task_complete.svg";
import "./index.css";

export default function Verification() {
  const navigate = useNavigate();

  return (
    <div className="verification-result">
      <Result
        className="flex-column"
        title="Successful Payment"
        subTitle="Your payment was successfully processed."
        extra={[
          <Button
            type="default"
            onClick={() => navigate("/tasks", { replace: true })}
          >
            Go back to tasks
          </Button>,
        ]}
        icon={
          <img
            src={Completed}
            alt="Successful payment"
            className="result-icon"
            width={"50%"}
          />
        }
      />
    </div>
  );
}
