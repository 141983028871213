import {
  useState,
  useMemo,
  useImperativeHandle,
  forwardRef,
  Ref,
  useEffect,
} from "react";
import { Card, Table, Empty } from "antd";
import { useLazyQuery } from "@apollo/client";
import { GET_REPORTS, DOWNLOAD_REPORTS } from "../../graphql/queries";
import { TableLoader } from "../../../../components/UserManagement/TableContentPlaceholder";
import { formatCurrency } from "@/utils/formatCurrency";
import { notifyError } from "@/utils/notification";
import { useReportingFilters } from "../useReportingFilters";
import { ReportHandle } from "../ReportingTypes";
import { writeCSV } from "@/utils";
import { format } from "date-fns";

function _RenewalFeesDue(_: unknown, ref: Ref<ReportHandle>) {
  const [reportList, setReportList] = useState([]);
  const [listTotal, setListTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const {
    pageSize,
    pageNumber,
    setFilterState,
    skip,
    companyIds,
    months,
    reportType,
  } = useReportingFilters();

  const handleError = (error: any) => {
    setLoading(false);
    setDownloading(false);
    notifyError(error);
  };

  const [getReports] = useLazyQuery(GET_REPORTS, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getReports }) => {
      if (getReports?.reports) setReportList(getReports.reports);
      if (getReports?.total) setListTotal(getReports.total);
      if (getReports?.reportString) {
        const data = JSON.parse(getReports.reportString);
        setReportList(data);
      }

      setLoading(false);
    },
    onError: handleError,
  });

  const [downloadReports] = useLazyQuery(DOWNLOAD_REPORTS, {
    onCompleted: ({ getReports }) => {
      const data = getReports.reports.map((report, i) => {
        return {
          "#": i + 1,
          Month: report.date
            ? format(new Date(report.date), "MMM yyyy").toUpperCase()
            : "",
          Company: report.company.registeredName,
          "#Employees": report.employees,
          "#Related Parties": report.relatedParties,
          "#Entity": report.entity,
          Amount: report.total,
        };
      });

      writeCSV("Renewal Fees Due", "renewal-fees-due", data);
      setDownloading(false);
    },
    onError: handleError,
  });

  const input = useMemo(() => {
    return {
      company: companyIds?.map((id) => +id),
      month: months?.[0],
      sort: "DESC",
      type: "Renewal Fees Due",
      includeTotal: true,
      skip,
      take: pageSize,
    };
  }, [companyIds, months, pageSize, skip]);

  const runQuery = async () => {
    setLoading(true);

    getReports({
      variables: {
        input,
      },
    });
  };

  const downloadCsv = async () => {
    try {
      setDownloading(true);

      downloadReports({
        variables: {
          input: {
            ...input,
            take: Math.min(listTotal, 500),
          },
        },
      });
    } catch (err) {
      setDownloading(false);
      notifyError(err);
    }
  };

  const columns = useMemo<any[]>(() => {
    let reportColumns: any[] = [
      {
        title: "Month",
        key: "month",
        render: ({ date }) =>
          date ? format(new Date(date), "MMM yyyy").toUpperCase() : "",
      },
      {
        title: "Company",
        key: "company",
        render: ({ company }) =>
          (company?.registeredName || company?.tradingName).toUpperCase(),
      },
      ...(reportType === "Renewal Fees Due"
        ? [
            {
              title: "Trn ID",
              key: "TransactionId",
              render: ({ renewalTask }) => renewalTask?.transactions?.[0]?.id,
            },
          ]
        : []),
      {
        title: "#Employees",
        render: ({ employees }) =>
          reportType === "Renewal Fees Due"
            ? `R ${formatCurrency(employees)}`
            : employees,
      },
      {
        title: "#Related Parties",
        render: ({ relatedParties }) =>
          reportType === "Renewal Fees Due"
            ? `R ${formatCurrency(relatedParties)}`
            : relatedParties,
      },
      {
        title: "#Entity",
        render: ({ entity }) =>
          reportType === "Renewal Fees Due"
            ? `R ${formatCurrency(entity)}`
            : entity,
      },
      {
        title: "Amount",
        render: ({ renewalTask, total }) => {
          const val =
            reportType === "Subscription Control List"
              ? renewalTask?.id
              : total;

          return reportType === "Renewal Fees Due"
            ? `R ${formatCurrency(val)}`
            : val;
        },
      },
      {
        title: "Trn Status",
        key: "transactionStatus",
        render: ({ renewalTask }) =>
          renewalTask?.transactions?.[0]?.status?.name,
      },
    ];

    return reportColumns;
  }, [reportType]);

  useImperativeHandle(ref, () => ({
    run: runQuery,
    download: downloadCsv,
    running: loading,
    downloading,
    resetData: () => {
      setReportList([]);
      setListTotal(0);
    },
  }));

  useEffect(() => {
    runQuery();
  }, [pageNumber]);

  if (loading) {
    return <TableLoader />;
  }

  if (reportList.length === 0) {
    return <Empty description="Use the filters above to generate a report." />;
  }

  return (
    <>
      renewal-fees due
      <div className="">
        <Card>
          <Table
            size="small"
            rowKey="id"
            columns={columns}
            pagination={{
              current: pageNumber,
              showSizeChanger: false,
              hideOnSinglePage: true,
              pageSize,
              onChange: (page) => setFilterState({ pageNumber: page }),
              total: listTotal,
              showTotal: () => <h3>Total: {listTotal}</h3>,
            }}
            dataSource={reportList}
          />
        </Card>
      </div>
    </>
  );
}

export const RenewalFeesDue = forwardRef(_RenewalFeesDue);
