// Development
export const IS_PROD = process.env.NODE_ENV === "production";

// GraphQL
export const GQL_HTTP_ENDPOINT =
  import.meta.env.VITE_HTTP_ENDPOINT || "http://localhost:3000/uat/graphql";
export const GQL_WS_ENDPOINT = import.meta.env.VITE_WS_ENDPOINT || "";

// complaints endpoints
const endpoints = GQL_HTTP_ENDPOINT.split("/graphql");
export const BASE_ENDPOINT = endpoints[0];

// Bugsnag
export const BUGSNAG_API_KEY = import.meta.env.VITE_BUGSNAG_API_KEY || "123";

let EXTERNAL_PROJECTS_URL = "http://localhost:3001";
if (import.meta.env.VITE_RELEASE_STAGE === "prod") {
  EXTERNAL_PROJECTS_URL = "https://councilsmart.co.za";
} else if (import.meta.env.VITE_RELEASE_STAGE === "dev") {
  EXTERNAL_PROJECTS_URL = "https://dev.councilsmart.co.za";
} else if (import.meta.env.VITE_RELEASE_STAGE === "uat") {
  EXTERNAL_PROJECTS_URL = "https://uat.councilsmart.co.za";
}
export { EXTERNAL_PROJECTS_URL };

export const isTestingEnvironment =
  !import.meta.env.VITE_RELEASE_STAGE ||
  ["dev", "uat"].indexOf(import.meta.env.VITE_RELEASE_STAGE) > -1;

export const TERMS = [
  "All information provided by myself, as part of this registration, is complete and accurate to the best of my knowledge.",
  "I provide consent for validation of information supplied against external data sources, specifically Credit Bureau Enquiry",
  "Any information  provided  will  only  be  used  for  purposes  of  Member  Registration  with CFDC and will not be used for distribution, dissemination or disclosure to any 3rd party other than authorised above.",
  "Where applicable, I am duly authorised in capacity, to execute and submit this registration of behalf of a Business Entity.",
  "Terms & Conditions & Legal Policy",
];

/**
 * Password regex for alphanumeric password with special characters
 * ^                  The password string will start this way
 * (?=.*[a-z])        The string must contain at least 1 lowercase alphabetical character
 * (?=.*[A-Z])        The string must contain at least 1 uppercase alphabetical character
 * (?=.*[0-9])        The string must contain at least 1 numeric character
 * (?=.*[!@#$%^&*])   The string must contain at least 1 special character
 * @see https://www.thepolyglotdeveloper.com/2015/05/use-regex-to-test-password-strength-in-javascript/
 */
export const PASSWORD_REGEX = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])"
);

export const PASSWORD_REGEX_MESSAGE =
  "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1" +
  " number and 1 special character";

export const ID_TOKEN_NAME = "id_token";
export const DEFAULT_PASSWORD = "Default@123";
export const PHONE_REGEX = new RegExp("^[^0-9]([0-9][^0-9]){8,10}$");
export const GreyOne = "#66788a";
export const GreyTwo = "#425a70";
export const employees = [
  {
    value: "1-5",
    desc: "1-5",
  },
  {
    value: "6-20",
    desc: "6-20",
  },
  {
    value: "21-50",
    desc: "21-50",
  },
  {
    value: "51-200",
    desc: "51-200",
  },
  {
    value: "201-500",
    desc: "201-500",
  },
  {
    value: "501-1000",
    desc: "501-1000",
  },
  {
    value: "1001-5000",
    desc: "1001-5000",
  },
  {
    value: "5001+",
    desc: "5001+",
  },
];
export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// complaint ticket status
export const COMPLAINT_WITHDRAWAL = "Withdrawal";
export const COMPLAINT_OTHER = "Other";
export const APPLICATION_FAILED = "Failed";
export const APPLICATION_IN_PROGRESS = "In Progress";
export const APPLICATION_TO_BE_VERIFIED = "Awaiting Verification";
export const APPLICATION_VERIFIED = "Verified";
export const TASK_COMPLETED = "Complete";
export const TASK_FAILED = "Failed";

export const CREATE_TASK_TYPES = [
  "Finance Support",
  "Team Update",
  "Complaint",
  "Audit Report",
  "General",
  "News",
  "Account Support",
  "Termination of Membership",
  "Payment Disputes",
  "Audit Certificate",
  "Document Request",
];

export const APPLICATION_TYPES = [
  { name: "Company" },
  { name: "Employee" },
  { name: "Sole Proprietor" },
];

export const APPLICANT_TYPES = [
  { name: "Online Registration" },
  { name: "Manual Registration" },
];

export const TASK_STATUS_TYPES = [
  { name: "Awaiting Payment" },
  { name: "Awaiting Verification" },
  { name: "Complete" },
  { name: "Failed" },
  { name: "In Progress" },
  { name: "Pending" },
  { name: "Resolved" },
  { name: "Other" },
];

export const USER_STATUS_TYPES = [
  "Active",
  "Deregistered",
  "Due",
  "Outstanding",
  "Pending",
  "Suspended",
];

export const BUREAU_API_VERIFICATION_FAILED_MESSAGE =
  "We failed to communicate with the CPB Bureau, your company details will be verified at a later time";
export const NEW_APPLICANTS = "New Applicants";
export const ADMIN_STREAM = "Administration";
export const ADMIN_SUPPORT = "Administration Support";
export const FINANCE_STREAM = "Finance";
export const FINANCE_MANAGER = "Finance Manager";
export const FINANCE_ADMIN = "Finance Admin";
export const LEGAL_STREAM = "Legal";
export const EXECUTIVE_STREAM = "Executive";
export const MEMBER_STREAM = "Member";
export const EMPLOYEE_USER_ROLE = "Employee";
export const COMPANY_USER_ROLE = "Company";
export const SOLE_PROPRIETOR_USER_ROLE = "Sole Proprietor";
export const SUPER_USER_STREAM = "Super User";
export const HOME_PHYSICAL_ADDRESS = "Home Physical";
export const WORK_PHYSICAL_ADDRESS = "Work Physical";
export const EMPLOYEE_OF_A_BUSINESS = "Employee of a Business";

export const ADMIN_STREAMS = [
  ADMIN_STREAM,
  FINANCE_STREAM,
  FINANCE_ADMIN,
  FINANCE_MANAGER,
  LEGAL_STREAM,
  EXECUTIVE_STREAM,
  SUPER_USER_STREAM,
  NEW_APPLICANTS,
  ADMIN_SUPPORT,
];

export const SIMILARITY_THRESHOLD = 0.6;
export const EAGLE_REPORT = "eagle-eye-report.pdf";
export const COMPLAINT_SUPPORTING_DOCUMENT_TYPE_ID = 33;

export const COMPANY_TYPES = [
  "Company",
  "Closed Corporation",
  "Partnership",
  "Trust",
  "Co-operative",
];

export const MANUAL_REGISTRATION_ROLES = [
  ...ADMIN_STREAMS,
  COMPANY_USER_ROLE,
  SOLE_PROPRIETOR_USER_ROLE,
];

// Legal Notice document names
export const REGULATION_7_4_NOTICE = "Regulation 7(4) Notice";
export const REGULATION_7_12_NOTICE = "Form 7(12) First Appearance";
export const REGULATION_7_8_a_NOTICE = "Form 7(8)(a) Notice";
export const REGULATION_7_12_NOTICE_AOG = "Regulation 7(12) Notice AOG";
export const REGULATION_7_12_NOTICE_NO_AOG = "Regulation 7(12) Notice NO AOG";
export const ADDRESS_DIRECTIONS_TEMPLATE = "Address directions";

// Local storage keys
export const MANUAL_REGISTER_ID = "manualRegisterId";

// company status
export const DEREGISTERED_COMPANY = "Deregistered";

export const isSuperAdmin = localStorage.getItem("userRoles") === "Super User";

export const DEFAULT_TASK_FILTERS = Object.seal({
  taskStatus: false,
  taskId: false,
  taskTypeId: false,
  applicantType: false,
  userId: false,
  dateStart: false,
  dateEnd: false,
  sort: "DESC",
  searchTerm: false,
  excludeIds: [4, 10, 22],
});

export const MARITAL_STATUSES = [
  "Single",
  "Married In Community",
  "Married ANC",
  "Married Traditional",
  "Widowed",
  "Common Law Marriage",
  "Married Out of Community",
];

export const ETHNICITY_LIST = [
  "African",
  "Asian",
  "Coloured",
  "Caucasian",
  "Indian",
];

export const TASK_STATUSES = {
  complete: "Complete",
  resolved: "Resolved",
  inProgress: "In Progress",
  failed: "Failed",
  underInvestigation: "Under Investigation",
  prosecution: "Prosecution",
  prosecutionResolved: "Prosecution Resolved",
};

export const UNREGISTERED_COMPANY_ID = 23185;

export const years = [
  2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030,
];

export const industries = [
  "Agriculture, forestry and fishing",
  "Mining and quarrying",
  "Manufacturing (incl Space industry)",
  "Electricity, gas and water",
  "Construction",
  "Wholesale and retail trade, hotels and restaurants",
  "Transport, storage and communication",
  "Finance, real estate and business services",
  "General government services",
  "Personal services",
];

export const provinces = [
  "Western Cape",
  "Eastern Cape",
  "Northern Cape",
  "North West",
  "Free State",
  "Kwa Zulu Natal",
  "Gauteng",
  "Limpopo",
  "Mpumalanga",
];

export const bbeeeLevels = [
  "Level 1",
  "Level 2",
  "Level 3",
  "Level 4",
  "Level 5",
  "Level 6",
  "Level 7",
  "Level 8",
  "Non-Compliant",
];

export const TASK_PAGE_SIZE = 10;
