import { Skeleton, Card } from "antd";

export default function InformationLoader() {
  return (
    <div className="self-start full-width">
      <Card>
        <Skeleton loading active />
        <Skeleton loading active />
      </Card>
    </div>
  );
}
