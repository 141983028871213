import { useState, useEffect, useMemo } from "react";
import {
  BellOutlined,
  CheckSquareFilled,
  CheckSquareOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PlusOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Layout,
  Dropdown,
  Button,
  Menu,
  Badge,
  Drawer,
  Space,
  Typography,
  List,
  Comment,
} from "antd";
import { useQuery, useMutation } from "@apollo/client";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import logo from "../../assets/images/layout-logo.png";
import { signOut } from "@/utils/auth/signOut";
import Navigation from "./legalFinanceAdminNavigation";
import { GET_LOOKUPS, GET_USER_REGISTRATIONS } from "../../graphql/queries";
import {
  MEMBER_STREAM,
  ADMIN_STREAM,
  FINANCE_STREAM,
  LEGAL_STREAM,
  SUPER_USER_STREAM,
  EXECUTIVE_STREAM,
  APPLICATION_TO_BE_VERIFIED,
  APPLICATION_VERIFIED,
  MANUAL_REGISTRATION_ROLES,
} from "../../constants";
import MemberNavigation from "./memberNavigation";
import SuperUserNavigation from "./superAdminNavigation";
import { useGlobalState } from "../../globalStore";
import { useFilterState } from "@/contexts/AdminFilterContext";
import { If } from "@/components/If";
import { notifyError } from "@/utils/notification";
import { CREATE_BATCH_REGISTRATION } from "@/graphql/mutations";
import { useRegistrationState } from "@/stores/registration";
import { useUserState } from "@/stores/user";
import { useNotificationStore } from "@/stores/notification";
import { formatRelative } from "date-fns";

const { Header, Content, Sider } = Layout;

const stage = import.meta.env.VITE_RELEASE_STAGE;
const stageLabel = stage === "prod" ? "" : `[${stage.toUpperCase()}] `;

export default function DefaultLayout() {
  const location = useLocation();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [roles, setRoles] = useState("");
  const [permissions, setPermissions] = useState("");
  const [roleStream, setRoleStream] = useState("");
  const [applicationStatus, setApplicationStatus] = useState("");
  const { setGlobalState, clearGlobalState } = useGlobalState();
  const { clearLocal } = useFilterState();
  const notificationCount = useNotificationStore(
    (state) => state.notifications.length
  );
  const notifications = useNotificationStore((state) => state.notifications);
  const isDrawerOpen = useNotificationStore((state) => state.isDrawerOpen);
  const toggleDrawer = useNotificationStore((state) => state.toggleDrawer);

  const {
    setRegistrationState,
    setRegistrationStateObject,
    batchRegistrationId,
    manualRegistrationType,
  } = useRegistrationState();

  // const connect = useNotificationStore((state) => state.connect);

  const {
    clearUserState,
    setUserState,
    setUserStateObject,
    idNumber,
    firstName,
    lastName,
    email,
    stream,
    userRoles,
    activeUserRole,
  } = useUserState();

  const activeRole = userRoles.find((ur) => ur.id === activeUserRole);

  const isAdmin = [
    ADMIN_STREAM,
    FINANCE_STREAM,
    LEGAL_STREAM,
    SUPER_USER_STREAM,
    EXECUTIVE_STREAM,
  ].includes(roleStream);

  useQuery(GET_LOOKUPS, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getLookups }) => {
      if (getLookups) setGlobalState({ lookups: getLookups });
    },
    onError: (error) => console.error("get_lookups_error", error),
  });

  useQuery(GET_USER_REGISTRATIONS, {
    onCompleted: (data) => {
      if (data?.getUserRegistrations && data.getUserRegistrations.length > 0) {
        const latestIndex = data.getUserRegistrations.length - 1;
        const {
          id,
          employee,
          employer,
          registrationType: _todo, // TODO: sort out the registration type
        } = data.getUserRegistrations[latestIndex];

        setUserState("pendingApplications", data?.getUserRegistrations);

        setRegistrationStateObject({
          manualRegistrationApplicationId: id,
          manualRegisterId: employee?.id || undefined,
          manualRegisterCompanyId: employer?.id || undefined,
          manualRegistrationType: "New",
        });
      }
    },
    onError: (error) => {
      notifyError(error);
    },
  });

  const [createBatchRegistration, { loading }] = useMutation(
    CREATE_BATCH_REGISTRATION
  );

  useEffect(() => {
    // const t = setTimeout(connect, 2000);
    // return () => clearTimeout(t);
  }, []);

  useEffect(() => {
    setRoles(localStorage.getItem("userRoles"));
    setRoleStream(localStorage.getItem("roleStream"));
    setPermissions(localStorage.getItem("permissions"));
    setApplicationStatus(localStorage.getItem("applicationStatus"));
  }, []);

  useEffect(() => {
    let isMounted = true;
    function collapseMenuOnRegistration() {
      if (isMounted && location.pathname === "/register") {
        setCollapsed(true);
      }
    }
    collapseMenuOnRegistration();
    return () => {
      isMounted = false;
    };
  }, [location]);

  const onCollapse = () => {
    setCollapsed(!collapsed);
  };

  const NavigatorProps = {
    permissions,
    roles,
    roleStream,
  };

  let backgroundColor = [
    ADMIN_STREAM,
    FINANCE_STREAM,
    SUPER_USER_STREAM,
    EXECUTIVE_STREAM,
    LEGAL_STREAM,
  ].includes(activeRole?.stream)
    ? "darkgreen"
    : activeRole?.stream === MEMBER_STREAM &&
      [APPLICATION_TO_BE_VERIFIED, APPLICATION_VERIFIED].includes(
        applicationStatus
      ) &&
      activeRole?.name === "Sole Proprietor"
    ? "#ca8c2b"
    : activeRole?.stream === MEMBER_STREAM &&
      [APPLICATION_TO_BE_VERIFIED, APPLICATION_VERIFIED].includes(
        applicationStatus
      ) &&
      activeRole?.name === "Employee"
    ? "#c92127"
    : activeRole?.stream === MEMBER_STREAM &&
      [APPLICATION_TO_BE_VERIFIED, APPLICATION_VERIFIED].includes(
        applicationStatus
      ) &&
      activeRole?.name === "Company"
    ? "#292a66"
    : "white";

  async function batchRegistration() {
    if (batchRegistrationId) {
      navigate(`/register/batch-registration/${batchRegistrationId}`);
    }

    try {
      const { data, errors } = await createBatchRegistration({
        variables: {
          input: {},
        },
      });

      if (errors) {
        throw errors[0];
      }

      const batchRegData = data.createBatchRegistration;
      setRegistrationState("batchRegistrationId", batchRegData.id);

      navigate(`/register/batch-registration/${batchRegData.id}`);
    } catch (error) {
      // @ts-ignore
      notifyError(error);
    }
  }

  const manualRegEnabled = useMemo(() => {
    return !!batchRegistrationId && !loading;
  }, [batchRegistrationId, manualRegistrationType, loading]);

  const batchRegEnabled = useMemo(() => {
    return !!manualRegistrationType && !loading && !batchRegistrationId;
  }, [batchRegistrationId, manualRegistrationType, loading]);

  const newItemMenu = (
    <Menu style={{ width: "220px" }}>
      <Menu.Item
        icon={<UsergroupAddOutlined />}
        disabled={batchRegEnabled}
        onClick={batchRegistration}
      >
        {batchRegistrationId
          ? "Continue Batch Registration"
          : "Batch Registration"}
      </Menu.Item>
      {MANUAL_REGISTRATION_ROLES.includes(roleStream) && (
        <Menu.Item
          icon={<UserAddOutlined />}
          disabled={manualRegEnabled}
          onClick={() => {
            navigate("/admin/team/manual-register");
          }}
        >
          {manualRegistrationType && !batchRegistrationId
            ? "Continue Registration"
            : "Manual Registration"}
        </Menu.Item>
      )}
    </Menu>
  );

  function refreshPlatform(roleId: number) {
    setUserStateObject({
      activeUserRole: roleId,
      applicationId: activeRole?.applicationId,
      applicationStatus: activeRole?.applicationStatus,
      companyId: activeRole?.companyId,
      capacityId: activeRole?.capacityId,
      companyStatus: activeRole?.companyStatus,
    });

    localStorage.setItem("companyStatus", activeRole?.companyStatus ?? "");
    localStorage.setItem(
      "capacityId",
      activeRole?.capacityId ? String(activeRole?.capacityId) : ""
    );
    localStorage.setItem(
      "applicationStatus",
      activeRole?.applicationStatus ?? ""
    );
    localStorage.setItem(
      "applicationId",
      activeRole?.applicationId ? String(activeRole?.applicationId) : ""
    );
    localStorage.setItem(
      "companyId",
      activeRole?.companyId ? String(activeRole?.companyId) : ""
    );

    window.location.reload();
  }

  const userMenu = (
    <Menu>
      <div
        style={{
          borderBottom: "0.1px solid gray",
          width: "400px",
        }}
        className="p-3 mb-2 flex-column items-center justify-center text-center"
      >
        <span>
          <b>
            {firstName} {lastName}
          </b>
        </span>
        <span style={{ textAlign: "center" }}>{email}</span>
        <span>({stream})</span>

        <div className="flex-column items-start full-width mt-3 gap-2">
          {userRoles.map((role) => {
            return (
              <div
                key={role.id}
                className="flex-row gap-2 cursor-pointer items-start company-profile"
                onClick={() => {
                  refreshPlatform(role.id);
                }}
              >
                {role.id === activeUserRole ? (
                  <CheckSquareFilled style={{ fontSize: "22px" }} />
                ) : (
                  <CheckSquareOutlined style={{ fontSize: "22px" }} />
                )}
                <div className="text-left">
                  <p className="bold m-0">{role.company}</p>
                  <p className="m-0">
                    {role.name} ({role.stream})
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Menu.Item
        onClick={() => {
          navigate(isAdmin ? "/admin/profile" : "/profile");
        }}
      >
        Profile
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          clearUserState();
          clearLocal();
          signOut({
            email,
            idNumber,
          });
          clearGlobalState();
        }}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout>
      <Sider
        width="20%"
        trigger={null}
        breakpoint="lg"
        collapsible
        collapsed={collapsed}
      >
        <div className="logo-container">
          <img
            src={logo}
            alt="CFDC Logo"
            className="logo"
            style={{
              marginLeft: collapsed ? 0 : "16px",
            }}
          />
        </div>
        <hr />
        <If condition={isAdmin && ![SUPER_USER_STREAM].includes(roleStream)}>
          <Navigation {...NavigatorProps} />
        </If>
        {roleStream === MEMBER_STREAM && (
          <MemberNavigation {...NavigatorProps} />
        )}
        {[SUPER_USER_STREAM].includes(roleStream) && (
          <SuperUserNavigation {...NavigatorProps} />
        )}
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            fontFamily: "system-ui",
            backgroundColor: backgroundColor,
          }}
          className="header"
        >
          <div className="header-content">
            {collapsed ? (
              <MenuUnfoldOutlined
                className="trigger"
                style={{
                  color: backgroundColor === "white" ? "black" : "white",
                }}
                onClick={onCollapse}
              />
            ) : (
              <MenuFoldOutlined
                className="trigger"
                style={{
                  color: backgroundColor === "white" ? "black" : "white",
                }}
                onClick={onCollapse}
              />
            )}

            {[
              ADMIN_STREAM,
              FINANCE_STREAM,
              LEGAL_STREAM,
              SUPER_USER_STREAM,
              EXECUTIVE_STREAM,
            ].includes(roleStream) ? (
              <h3>{stageLabel}CFDC Portal: Admin</h3>
            ) : roleStream === MEMBER_STREAM &&
              [APPLICATION_TO_BE_VERIFIED, APPLICATION_VERIFIED].includes(
                applicationStatus
              ) ? (
              <h3>
                {stageLabel}CFDC Portal: Member(
                {userRoles?.find((ur) => ur.id === activeUserRole)?.name}){" "}
              </h3>
            ) : (
              ""
            )}
            <div
              style={{
                marginRight: notificationCount > 0 ? "25px" : undefined,
              }}
            >
              <If condition={isAdmin}>
                <Dropdown
                  overlay={newItemMenu}
                  placement="bottom"
                  trigger={["click"]}
                >
                  <Button
                    loading={loading}
                    icon={<PlusOutlined />}
                    style={{ marginRight: "10px" }}
                  />
                </Dropdown>
              </If>

              <Dropdown
                overlay={userMenu}
                placement="bottom"
                trigger={["click"]}
              >
                <Button
                  icon={<UserOutlined />}
                  style={{ marginRight: "10px" }}
                />
              </Dropdown>

              {/* <Badge count={notificationCount} showZero={false}>
                <Button
                  icon={<BellOutlined />}
                  style={{ marginRight: "10px" }}
                  onClick={toggleDrawer}
                />
              </Badge> */}
            </div>
          </div>
        </Header>
        <Content className="content-wrapper">
          <Outlet />
        </Content>
        <Drawer
          title={"Notifications"}
          placement="right"
          onClose={toggleDrawer}
          open={isDrawerOpen}
        >
          <If condition={notificationCount === 0}>
            <Space>
              <Typography.Title level={4} style={{ textAlign: "center" }}>
                No Notifications
              </Typography.Title>
              <Typography.Text style={{ textAlign: "center" }}>
                You have no new notifications, check back later
              </Typography.Text>
              <p>Some contents...</p>
            </Space>
          </If>

          <If condition={notificationCount > 0}>
            <List
              dataSource={notifications.map((n) => ({
                author: n.title,
                content: n.content,
                datetime: formatRelative(new Date(n.createdAt), new Date()),
                actions: [
                  <span key="view">View</span>,
                  <span key="read">Dismiss</span>,
                ],
              }))}
              itemLayout="horizontal"
              renderItem={(props) => <Comment {...props} />}
            />
          </If>
        </Drawer>
      </Layout>
    </Layout>
  );
}
